import moment from "moment"

export const checkIsUpdateRequired = (updatedOn) => {
  const lastUpdatedDate = updatedOn ? moment(updatedOn) : null
  // const checkDate = moment('17-11-2023', 'DD-MM-YYYY')
  const checkDate = moment('31-01-2025', 'DD-MM-YYYY')

  if (!lastUpdatedDate) {
    return true
  }

  if (lastUpdatedDate) {
    if (lastUpdatedDate.isBefore(checkDate)) {
      return true
    }
  }

  return false
}
