import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import {
  borderTextbox,
  greyShade7,
  errorTextColor,
  greyShade10,
  blackColor,
} from '../configs/styles/muiThemes'

function NumberInput({
  placeholder,
  textProps,
  name,
  value,
  onChange,
  icon,
  onIconClick,
  showPassword,
  maxLength,
  inputType,
  isError,
  error,
  errorMsg,
  helperText,
  autoFocus,
  inputRef,
  onBlur,
  showAutoComplete,
}) {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: borderTextbox,
          my: '6px !important',
          py: '12px',
        }}
      >
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={13}
          fontWeight={500}
          color={blackColor}
        >
          +91
        </Typography>

        <input
          type='text'
          name='number'
          value={value}
          pattern='[0-9]+'
          onChange={onChange}
          maxLength={10}
          placeholder='Enter Phone Number'
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          style={{
            border: 'none',
            padding: '0 10px',
            flex: '1',
            fontSize: '13px',
            fontWeight:'500',
            fontFamily: 'Poppins',
            margin: '0 10px 0 0',
          }}
          autocomplete={showAutoComplete ? 'on' : 'off'}
        />

        <Box m='0 5px -10px 0'>{icon}</Box>
      </Box>

      {isError ? (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={13}
          color={errorTextColor}
        >
          {errorMsg}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant='body1'
          component='p'
          fontSize={11}
          color={greyShade7}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  )
}

export default NumberInput
