import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  authLogin,
  changePassword,
  checkUser,
  checkUserGuest,
  eRegister,
  getCodeToMail,
  getProfileData,
  storeUserData,
  updateUserData,
} from "services/api_calls/auth/login_api";
import { getAccessToken, getUserName } from "utils/authHelpers";

const initialState = {
  loginDetails: null,
  isUserLogin: false,
  err: null,
  profile: {} || undefined,
  loading: false,
  isLoggedIn: false,
};

//Fetching all coupons
export const getLoginDetials = createAsyncThunk(
  "getLoginDetials",
  async (param, thunkAPI) => {
    const res = await authLogin(param);
    if (res.status === 200) {
      sessionStorage.setItem("token", res.data.access_token);
      sessionStorage.setItem("profileId", res.data.randomNo);
      sessionStorage.setItem("phone", param.phone);
    }
    return res;
  }
);

export const checkExistingUser = createAsyncThunk(
  "checkExistingUser",
  async (param, thunkAPI) => {
    const res = await checkUser(param);
    return res;
  }
);
export const checkExistingUserGuest = createAsyncThunk(
  "checkExistingUser",
  async (param, thunkAPI) => {
    const res = await checkUserGuest(param);
    return res;
  }
);

export const saveUserDetails = createAsyncThunk(
  "saveUserDetails",
  async (param, thunkAPI) => {
    const res = await storeUserData(param);
    return res;
  }
);
export const updateUserDetails = createAsyncThunk(
  "updateUserDetails",
  async (param, thunkAPI) => {
    const res = await updateUserData(param);
    return res;
  }
);

export const getEregister = createAsyncThunk(
  "getEregister",
  async (param, thunkAPI) => {
    const res = await eRegister(param);
    return res;
  }
);

export const getCode = createAsyncThunk("getCode", async (param, thunkAPI) => {
  const res = await getCodeToMail(param);
  return res;
});

export const finishSetup = createAsyncThunk(
  "finishSetup",
  async (param, thunkAPI) => {
    const res = await changePassword(param);
    return res;
  }
);

export const checkUserExist = createAsyncThunk(
  "checkUserExist",
  async (param, thunkAPI) => {
    // const res = await checkUser(param)
  }
);

export const getProfileDetails = createAsyncThunk(
  "getProfileDetails",
  async (thunkAPI) => {
    const res = await getProfileData();
    // console.log("Profile",res)
    return res;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearLoginData: (state) => {
      state.isLoggedIn = false;
      state.loginDetails = null;
    },
    checkUserLogin: (state) => {
      state.isLoggedIn =
        getUserName().length === 0 && getAccessToken() === null ? false : true;
    },
    setLoginData: (state) => {
      state.isLoggedIn = true;
    },
    updateIsLogin: (state, action) => {
      const token = sessionStorage.getItem("token");
      state.isUserLogin = token ? true : false;
    },
  },
  extraReducers: {
    [getLoginDetials.pending]: (state) => {
      state.loading = true;
    },
    [getLoginDetials.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isUserLogin = true;
      state.loginDetails = payload?.status == 200 ? payload : null;
    },
    [getLoginDetials.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [getProfileDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProfileDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.profile = payload;
    },
    [getProfileDetails.rejected]: (state) => {
      state.loading = false;
    },

    [getCode.pending]: (state) => {
      state.loading = true;
    },
    [getCode.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.profile = payload;
    },
    [getCode.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setLoginData, clearLoginData, checkUserLogin, updateIsLogin } =
  authSlice.actions;

export const authReducer = authSlice.reducer;
