import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import {
  backgroundColor,
  backgroundColor01,
  blackColor,
  greyShade63,
  navHeaderColor,
  navLinkColor,
  offerTextColor,
  orangeColor01,
  primaryColor,
  productLabelColor,
  whiteColor,
} from "configs/styles/muiThemes";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { skeletonBox } from "utils/styleUtility";
import SearchIcon from "@mui/icons-material/Search";
import {
  navBar_MenuCatalog,
  navBar_MenuDealsCounpons,
  navBar_MenuHome,
  navBar_MenuLoginSignup,
  navBar_MenuOverview,
  navBar_MenuReview,
} from "configs/Constants";
import { allowOnlyEnglish } from "utils/utilities";
import { geProfilePhoto, getPhoneNumber, getUserName } from "utils/authHelpers";
import ProfileCharAvatar from "components/ProfileCharAvatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { Padding, PersonOutlineRounded } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { navigateTo } from "utils/urlHelper";

const NavigationBar = ({
  logoUrl,
  commercialDetails,
  businessName,
  businessFullName,
  allProducts,
  activeMenu,
  query,
  handleSearchQuery,
  onSearch,
  showSearchBox,
  setShowSearchBox,
  cartCount,
  cartDisabled,
  loading,
  isLoggedIn,
  setLogin,
  onLogOut,
  businessAffiliateData,
  rellerProfileData,
  clearSearch,
}) => {
  const navigate = useNavigate();

  const name = getUserName();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box width="42.5%">
        <Box
          display="flex"
          alignItems="center"
          // justifyContent='center'
          // gap="6px"
        >
          {/* <NavLink
            exact
            activeClassName="active"
            to={`/${businessName}`}
            p={"23px 0px"}
          > */}
          <Button
            sx={{
              ...styles.navBtn(false),
              color: activeMenu === "home" ? orangeColor01 : blackColor,
            }}
            onClick={() => {
              navigateTo(navigate, "");
            }}
          >
            {navBar_MenuHome}
          </Button>
          {/* </NavLink> */}

          {/* <NavLink
            activeClassName="active"
            // to={allProducts?.productList?.length == 0 ? `#` : 'catalog'}
          > */}
          <Button
            sx={{
              ...styles.navBtn(false),
              color:
                allProducts?.productList?.length == 0
                  ? offerTextColor
                  : activeMenu === "catalog"
                  ? orangeColor01
                  : blackColor,
            }}
            variant="text"
            disabled={allProducts?.productList?.length == 0}
            onClick={() => {
              navigateTo(navigate, "catalog");
            }}
          >
            {navBar_MenuCatalog}
          </Button>
          {/* </NavLink> */}

          {/* <NavLink
            activeClassName="active"
            to={`/${businessName}/dealsCoupons`}
          > */}
          <Button
            sx={{
              ...styles.navBtn(false),
              color: activeMenu === "coupons" ? orangeColor01 : blackColor,
            }}
            onClick={() => {
              navigateTo(navigate, "dealsCoupons");
            }}
          >
            {navBar_MenuDealsCounpons}
          </Button>
          {/* </NavLink> */}

          {/* <NavLink activeClassName="active" to={`/${businessName}/overview`}> */}
          <Button
            sx={{
              ...styles.navBtn(),
              color:
                activeMenu === "overview"
                  ? orangeColor01
                  : // : allProducts?.productList?.length == 0
                    // ? offerTextColor
                    blackColor,
            }}
            onClick={() => {
              navigateTo(navigate, "overview");
            }}
          >
            {navBar_MenuOverview}
          </Button>
          {/* </NavLink> */}

          {/* <NavLink activeClassName="active" to={`/${businessName}/review`}> */}
          <Button
            sx={{
              ...styles.navBtn(false),
              color: activeMenu === "reviews" ? orangeColor01 : blackColor,
            }}
            onClick={() => {
              navigateTo(navigate, "review");
            }}
          >
            {navBar_MenuReview}
          </Button>
          {/* </NavLink> */}
        </Box>
      </Box>

      <Box width="15%" textAlign="center">
        {logoUrl ? (
          <IconButton
            // href={`/${businessName}`}
            onClick={() => {
              if (commercialDetails?.info?.costboPowered?.domainURL) {
                window.open(
                  commercialDetails?.info?.costboPowered?.domainURL,
                  "_self"
                );
              } else {
                // navigate(`/${businessName}`);
                navigateTo(navigate, '')
              }
            }}
            sx={{ p: 1.35 }}
            disableRipple
          >
            <Box
              sx={{
                width: "63px",
                height: "63px",
                border: `1px solid #e8e8f0`,
                borderRadius: "5px",
              }}
            >
              <img
                src={logoUrl}
                alt={businessFullName + " CostBo"}
                style={{
                  width: "61px",
                  height: "61px",
                  borderRadius: "5px",
                  // aspectRatio: 4 / 3,

                  padding: "2px",
                }}
              />
            </Box>
          </IconButton>
        ) : (
          <Box
            sx={{
              width: "65px",
              height: "65px",
              ...skeletonBox.boxAnim,
              backgroundColor: "#e8e8e8",
              ml:8.4,
              my:1.3
            }}
          />
        )}
      </Box>

      <Box width="42.5%" display="flex" justifyContent="flex-end">
        <Box position="relative">
          {showSearchBox && (
            <Box mr={1} sx={{ position: "absolute", left: "-277px" }}>
              <SearchBar
                value={query}
                onChangeText={(text) => {
                  handleSearchQuery(text);
                }}
                onSearch={onSearch}
                onClose={() => {
                  handleSearchQuery("");
                  setShowSearchBox(false);
                  clearSearch();
                }}
                disabled={
                  commercialDetails?.info?.commerceEnable == false ||
                  allProducts?.productList?.length == 0
                }
              />
            </Box>
          )}

          <IconButton
            onClick={() => {
              if (!showSearchBox) {
                setShowSearchBox(!showSearchBox);
              } else {
                onSearch();
              }
            }}
          >
            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/shopweb/affiliates/search.svg"
            />
          </IconButton>
          {commercialDetails?.info?.costboPowered?.affiliateEnabled &&
            businessAffiliateData?.businessResellers?.length > 0 && (
              <IconButton
                onClick={() => {
                  navigateTo(
                    navigate,
                    rellerProfileData?.details ? `affiliateHome` : `affiliates`,
                    {
                      state: { disableAutoNav: true },
                    }
                  );
                  // navigate(
                  //   rellerProfileData?.details
                  //     ? `/${businessName}/affiliateHome`
                  //     : `/${businessName}/affiliates`,
                  //   {
                  //     state: { disableAutoNav: true },
                  //   }
                  // );
                }}
              >
                <Box
                  component="img"
                  src="https://storage.googleapis.com/bodefaults/shopweb/affiliates/share-earn-black.svg"
                  width={"21px"}
                />
              </IconButton>
            )}

          <IconButton
            onClick={() => {
              if (!cartDisabled) {
                navigateTo(navigate, `cart`, null);
                // navigate(`/${businessName}/cart`);
              }
            }}
            disabled={cartDisabled}
          >
            <Badge
              badgeContent={cartCount}
              color="primary"
              sx={{
                "& .MuiBadge-badge": {
                  fontSize: 11,
                },
              }}
            >
              <Box
                component="img"
                src="https://storage.googleapis.com/bodefaults/shopweb/affiliates/cart.svg"
              />
            </Badge>
          </IconButton>
        </Box>

        {loading === false && (
          <Profile
            onLoginClick={() => setLogin(true)}
            name={name}
            profilePhoto={geProfilePhoto()}
            isLoggedIn={isLoggedIn}
            onLogOut={() => onLogOut()}
            businessName={businessName}
          />
        )}
      </Box>
    </Box>
  );
};

export default NavigationBar;

const SearchBar = ({ value, onChangeText, onSearch, disabled, onClose }) => {
  return (
    <form>
      <Box
        sx={{
          background: backgroundColor01,
          borderRadius: "9px",
          border: `1px solid ${greyShade63}`,
          display: "flex",
          alignItems: "center",
          pl: "20px",
          height: "35px",
          minWidth: { lg: "250px", xs: "300px" },
        }}
      >
        <TextField
          value={value}
          placeholder="Search Products"
          variant="standard"
          sx={{ flex: 1 }}
          InputProps={{
            disableUnderline: true,
          }}
          onChange={({ target }) => {
            const finalValue = allowOnlyEnglish(target.value);
            onChangeText(finalValue);
          }}
          disabled={disabled}
        />
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            onSearch();
          }}
          disabled={disabled}
          type="submit"
          sx={{ width: 30, height: 30, visibility: "hidden" }}
        >
          <CloseIcon sx={{ fontSize: "18px", color: "rgba(0,0,0,0)" }} />
        </IconButton>

        <IconButton
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          disabled={disabled}
          sx={{ width: 30, height: 30 }}
        >
          <CloseIcon sx={{ fontSize: "18px", color: "rgba(0,0,0,0.4)" }} />
        </IconButton>
      </Box>
    </form>
  );
};

const Profile = ({
  onLoginClick,
  isLoggedIn,
  onLogOut,
  hideName,
  businessName,
}) => {
  const isProfileImgAvailabel = geProfilePhoto();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [text, SetText] = useState("");

  const handleClose = (event) => {
    setAnchorEl(null);
    SetText(event.target.innerText);
  };

  const username = getUserName();
  const navigate = useNavigate()

  return isLoggedIn ? (
    <Box sx={{ cursor: "pointer", ml: 1.3 }}>
      <Box
        display="flex"
        alignItems="center"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {isProfileImgAvailabel == "" ? (
          <ProfileCharAvatar
            imgwidth="33px"
            imgheight="33px"
            username={getUserName()}
          />
        ) : (
          <Avatar
            alt="User"
            src={geProfilePhoto()}
            sx={{ width: "35px", height: "35px" }}
          />
        )}

        {!hideName ? (
          <Box>
            <Typography
              variant="h6"
              marginLeft="10px"
              fontSize={14}
              fontWeight={500}
              lineHeight={1}
              color={blackColor}
            >
              {username?.length > 13
                ? username?.slice(0, 13) + "..."
                : username}
            </Typography>
          </Box>
        ) : null}
        <ArrowDropDownIcon sx={{ color: blackColor }} />
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        mt={6}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        variant="selectedMenu"
      >
        <Box px={2} width={150} display="flex" flexDirection={"column"}>
          <Typography
            fontSize={15}
            fontWeight={600}
            color={blackColor}
            lineHeight={1.5}
            mb={0.5}
            sx={{ textDecoration: "none" }}
          >
            My Account
          </Typography>
          <Typography
            // component={Link}
            // to='/profileDetails'
            sx={{ textDecoration: "none" }}
            color={blackColor}
          >
            {getPhoneNumber()}
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <Box px={2}>
          <Typography
            fontSize={14}
            fontWeight={400}
            color={blackColor}
            lineHeight={1.5}
            sx={{ textDecoration: "none", display: "block" }}
            // component={Link}
            // to={`/${businessName}/Dashboard`}
            onClick={()=>{
              navigateTo(navigate, 'Dashboard')
            }}
            my={1}
          >
            Dashboard
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={400}
            color={blackColor}
            lineHeight={1.5}
            sx={{ textDecoration: "none", display: "block" }}
            // component={Link}
            // to={`/${businessName}/AllOrders`}
            onClick={()=>{
              navigateTo(navigate, 'AllOrders')
            }}
            my={1}
          >
            My Orders
          </Typography>

          <Typography
            fontSize={14}
            fontWeight={400}
            color={blackColor}
            lineHeight={1.5}
            my={1}
            sx={{ cursor: "pointer" }}
            onClick={onLogOut}
          >
            Logout
          </Typography>
        </Box>
      </Menu>
    </Box>
  ) : (
    <Box display="flex" onClick={onLoginClick} ml={1}>
      <IconButton sx={{ background: backgroundColor }}>
        <PersonOutlineRounded
          sx={{
            fontSize: "22px",
            color: productLabelColor,
          }}
        />
      </IconButton>

      <Box py={0.5}>
        <Typography
          variant="h6"
          fontSize={13}
          marginLeft="10px"
          color={blackColor}
          sx={{ cursor: "pointer" }}
        >
          {navBar_MenuLoginSignup}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  navBtn: (disabled) => ({
    fontWeight: 500,
    fontSize: "14.5px",
    textTransform: "none",
    backgroundColor: whiteColor,
    color: blackColor,
    p: "5px 25px 5px 0px",
    "&:hover": {
      color: disabled ? offerTextColor : orangeColor01,
      background: whiteColor,
    },
    "&.Mui-disabled": {
      bgcolor: `${whiteColor} !important`,
      border: "none !important",
      opacity: 0.6,
    },
  }),
};
