import { Box, Button, Typography } from "@mui/material";
import {
  blackColor,
  greenColor05,
  greyShade70,
  greyShade71,
  lightGreen04,
  orangeColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import React from "react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const BranchCard = ({
  logoUrl,
  branchName,
  address,
  distance,
  branchId,
  onClick,
}) => {
  const currentBusinessId = sessionStorage.getItem("businessId");

  return (
    <Box
      sx={{
        ...styles.card,
        border:
          currentBusinessId == branchId
            ? `2px solid ${greenColor05}`
            : `1px solid ${greyShade70}`,
      }}
    >
      <Box flex={1} p="10px" display="flex" alignItems="center" gap="10px">
        <Box component="img" src={logoUrl} sx={styles.logoImg} />

        <Box>
          <Typography
            fontSize={{ xs: 12, md: 13 }}
            fontWeight={600}
            color={blackColor}
            lineHeight={{ xs: "18px", md: "24px" }}
            mb="2px !important"
          >
            {branchName}
          </Typography>

          <Box
            display="flex"
            alignItems={{ xs: "flex-start", md: "flex-start" }}
            gap="4px"
            ml={{ xs: 0, md: -0.25 }}
          >
            <PlaceOutlinedIcon
              sx={{
                fontSize: "17px",
                color: greyShade71,
                mt: -0.06,
                display: { xs: "none", md: "block" },
              }}
            />
            <Typography
              fontSize={{ xs: 10.5, md: 12 }}
              fontWeight={400}
              color={greyShade71}
              lineHeight={{ xs: "17px", md: "16px" }}
            >
              {address}
            </Typography>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              ...styles.distanceTag,

            }}
          >
            <PlaceOutlinedIcon
              sx={{ fontSize: "14px", color: greenColor05 }}
            />
            <Typography
              fontSize={{ xs: 10, md: 11 }}
              fontWeight={600}
              color={greenColor05}
              lineHeight="13px"
            >
              {distance}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Button
        variant="outlined"
        sx={{
          ...styles.btn,
        }}
        onClick={onClick}
        endIcon={
          <ArrowForwardIosRoundedIcon
            sx={{ fontSize: {xs:'9px !important', md:"11px !important"}, m: "-1px 0 0 -4px" }}
          />
        }
      >
        ORDER HERE
      </Button>

      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          ...styles.distanceTag,
        }}
      >
        <PlaceOutlinedIcon sx={{ fontSize: "16.5px", color: greenColor05 }} />
        <Typography
          fontSize={{ xs: 10.5, md: 11 }}
          fontWeight={600}
          color={greenColor05}
          lineHeight="13px"
        >
          {distance}
        </Typography>
      </Box>

      {/* <Box sx={styles.distanceInfoBox}>
        <PlaceOutlinedIcon sx={{ fontSize: "20px", color: greenColor05 }} />
        <Typography
          fontSize={{ xs: 10.5, md: 11 }}
          fontWeight={600}
          color={greenColor05}
          lineHeight="13px"
          mt="4px !important"
        >
          {distance}
        </Typography>
      </Box> */}
    </Box>
  );
};

export default BranchCard;

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${greyShade70}`,
    bgcolor: whiteColor,
    borderRadius: "10px",
    overflow: "hidden",
    mb: "15px",
    cursor: "pointer",
    position: "relative",

    // "&:hover": {
    //   border: `1px solid ${greenColor05}`,
    // },
  },

  logoImg: {
    width: { xs: "40px", md: "58px" },
    height: { xs: "40px", md: "58px" },
    borderRadius: "7px",
    border: `1px solid ${greyShade70}`,
    display: { xs: "none", md: "block" },
  },

  distanceInfoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: { xs: whiteColor, md: lightGreen04 },
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px 0px 0px 8px",
    height: { xs: "65px", md: "80px" },
    width: { xs: "78px", md: "88px" },
  },

  btn: {
    fontSize: { xs: 9.5, md: 11 },
    fontWeight: 600,
    color: orangeColor01,
    bgcolor: { xs: "transparent", md: whiteColor },
    lineHeight: "18px",
    border: `1px solid ${orangeColor01}`,
    borderRadius: { xs: "3px", md: "6px" },
    p: { xs: "2px 9px", md: "5px 9px" },
    m: { xs: "0 10px 0", md: "25px 7px 0 0" },
    "&:hover": {
      color: whiteColor,
      bgcolor: orangeColor01,
      border: `1px solid ${orangeColor01}`,
    },
  },

  distanceTag: {
    alignItems: "center",
    gap: "4px",
    bgcolor: { xs: whiteColor, md: lightGreen04 },
    borderRadius: "0px 0px 0px 6px",
    p: {xs:'3px 0 0 0', md:"3px 6px"},
    position: {xs:'relative', md:"absolute"},
    right: 0,
    top: 0,
  },
};
