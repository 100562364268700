import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Slider from "react-slick/lib/slider";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  getCommercialDetails,
  getProductDetails,
  getSimilarProductDetails,
} from "redux/productDescription/ProductDescriptionSlice";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import TopComponent from "./TopComponent";
import Loader from "components/Loader";
import VariantProduct from "./SingleProduct/VariantProduct/VariantProduct";
import EmptyCard from "components/EmptyCard";
import { noProductImgUrl } from "configs/Constants";
import OfferPopupModal from "components/OfferPopupModal/OfferPopupModal";
import ProductDescriptionSideBar from "./SingleProduct/ProductDescriptionSideBar";
import {
  backgroundColor01,
  blackColor,
  borderCart,
  borderTextbox,
  filterBgColor,
  greyShade59,
  headingColor,
  iconInactiveColor,
  navLinkColor,
  orangeColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import BreadcrumbNew from "components/BreadcrumbNew";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProductCard from "./CatalogMui/ProductCard";
import MobileProductCard from "./Home/MobileUICards/MobileProductCard";
import { getOutOfStock } from "utils/productHelper";
import ProductDescription from "./SingleProduct/ProductDescription";
import {
  addLocalCart,
  addNewProductCart,
  createCart,
  removeProductLocalCart,
  updateCartDetails,
  updateCartProductQuantity,
  updateToLocalCart,
} from "redux/cart/CartSlice";
import {
  createCartLoginHelper,
  removeCartProductLoginHelper,
} from "utils/cartHelper";
import { getUserName } from "utils/authHelpers";
import { getCartDetails } from "redux/addToCart/AddToCartSlice";
import DescriptionSpecificationTabs from "./SingleProduct/DescriptionSpecificationTabs";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { getRewardPointsDetails } from "redux/checkout/CheckoutSlice";
import { checkCatalogPartnetInventory } from "utils/catalogValidationHelper";
import FooterMui from "components/FooterMui";
import CartDetailsBottonCard from "components/CartDetailsBottonCard";
import SingleProductSidebarShimmer from "components/ShimmerComponents/SingleProductSidebarShimmer";
import SingleProductShimmer from "components/ShimmerComponents/SingleProductShimmer";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import BreadcrumbLoader from "components/skeletonLoaders/BreadcrumbLoader";
import { getActiveBusiness } from "redux/header/headerSlice";
import SingleProductDetail from "./SingleProduct/SingleProductDetail";
import {
  getOndcFullSpecificationAPI,
  getOndcSpecificationAPI,
} from "services/api_calls/productDescription/ProductDescriptionApi";
import SingleProductLoader from "components/skeletonLoaders/SingleProduct/SingleProductLoader";
import {
  getBuyMoreInventoryApi,
  getUnicomInventoryApi,
} from "services/api_calls/catalogPartner/CatalogPartnerApis";
import { navigateTo, resolveDomainUrl } from "utils/urlHelper";

function ProductDetail({isDomainUrl}) {
  const [isProductValid, setIsProductValid] = useState(false);
  const [cashbackData, setCashbackData] = useState(null);
  const [value, setValue] = useState(0);

  const [cartProductList, setCartProductList] = useState(null);
  const [totalQty, setTotalQty] = useState(0);
  const [productQty, setProductQty] = useState(0);

  const [recommendedProducts, setRecommendedProducts] = useState([]);

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [qtyLoading, setQtyLoading] = useState(false);

  const [inventoryLoader, setInventoryLoader] = useState(true);
  const [unicomInventoryInfo, setUnicomInventoryInfo] = useState(null);
  const [buymoreInventoryInfo, setBuymoreInventoryInfo] = useState(null);

  const [specification, setSpecification] = useState(null);
  const [fullSpecification, setFullSpecification] = useState(null);

  const slider = useRef(null);

  const isLoggedIn = getUserName().length === 0 ? false : true;

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { businessName, productId } = useParams();

  const [searchParams] = useSearchParams();
  const atag = searchParams?.get("atag");

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const {
    commercialDetails,
    productDetails,
    loader,
    similarProductDetails,
    recLoading,
  } = useSelector((state) => state.product);
  const { businessDetails } = useSelector((state) => state.header);
  const { businessId } = useSelector((state) => state.rootSlice);
  const { cartDetails } = useSelector((state) => state.cart);
  const { updateQty, addedProduct, createCartRes, updateCartIntRes } =
    useSelector((state) => state.cartupdateadd);

  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [commerceInfoData, setCommerceInfoData] = useState(null);
  const { rewardpoints } = useSelector((state) => state.checkout);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Customize based on your layout
    slidesToScroll: 3,
    arrows: false,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    // beforeChange: (oldIndex, newIndex) => {
    //   setCurrentSlideIndex(newIndex);
    // },
  };

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      sessionStorage.setItem("cartData", "null");
    }

    dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  const onAddProductToCart = () => {
    let gstTaxValue = 0;

    if (productDetails?.product?.gstInfo != null) {
      gstTaxValue = productDetails?.product?.gstInfo?.gstPercentage.replace(
        /[&\/\\#,+()$~%.'":*?<>{}]/g,
        ""
      );
    } else {
      gstTaxValue = 0;
    }

    let productAmtWithoutGST =
      (productDetails?.product?.productSalePrice * 100) /
      (100 + Number(gstTaxValue));

    let productTaxPerUnit =
      productDetails?.product?.productSalePrice - productAmtWithoutGST;

    const addProductData = {
      cartId: cartDetails?.cartId,
      product: {
        productId: productDetails?.product?.id,
        productName: productDetails?.product?.productName,
        productPicURL: productDetails?.product?.productPhoto[0]?.docURL,
        topicId: "",
        catalogPartnerItemInfo:
          productDetails?.product?.catalogPartnerItemInfo?.[0] || null,
        productOriginalPrice: productDetails?.product?.productOriginalPrice,
        productSalePrice: productDetails?.product?.productSalePrice,
        originalQuantity: productDetails?.product?.minOrderQuantity,
        currentQuantity: productDetails?.product?.minOrderQuantity,
        currencySymbol: "₹",
        percentageOff: productDetails?.product?.percentageOff,
        quantityChangedFlag: false,
        priceChangedFlag: false,
        productTotalPrice:
          productDetails?.product?.minOrderQuantity *
          productDetails?.product?.productSalePrice,
        productTax: productTaxPerUnit.toFixed(2),
        productTaxRate: gstTaxValue,
        measurementInfo: productDetails?.product?.measurementInfo,
      },
      clientType: "web",
    };

    if (isLoggedIn) {
      setQtyLoading(true);

      const cartData = JSON.parse(sessionStorage.getItem("cartData"));
      if (cartData) {
        dispatch(addNewProductCart(addProductData));
      } else {
        const postData = createCartLoginHelper(addProductData);
        dispatch(createCart(postData));
      }
    } else {
      // addtoLocalCart(data?.product)
      dispatch(addLocalCart(addProductData?.product)).then((res) => {
        res?.payload?.productList?.forEach((item) => {
          if (item.productId === productDetails?.product?.id) {
            setProductQty(item?.currentQuantity);
          }
        });
        setCartProductList(res?.payload);
        setTotalQty(res?.payload?.totalQuantity);
      });
    }
  };

  const removeProductFromCart = (data) => {
    if (isLoggedIn) {
      setQtyLoading(true);
      const postData = removeCartProductLoginHelper(data.productId);
      dispatch(updateCartDetails({ ...postData, id: cartDetails?.cartId }));
    } else {
      dispatch(removeProductLocalCart({ productId: data.productId })).then(
        (res) => {
          setProductQty(0);
          res?.payload?.productList?.forEach((item) => {
            if (item.productId === productDetails?.product?.id) {
              setProductQty(item?.currentQuantity);
            }
          });
          setCartProductList(res?.payload);
          setTotalQty(res?.payload?.totalQuantity);
        }
      );
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onSlideNext = () => {
    if (currentSlideIndex < recommendedProducts.length - 7) {
      slider.current.slickNext();
    }
  };

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev();
    }
  };

  // Unicommerce Validation
  // const getUnicommerceInventory = async () => {
  //   setUnicommerceLoader(true);
  //   const catalogPartnerInfo = commercialDetails?.info?.catalogPartnerInfo?.[0];

  //   if (catalogPartnerInfo?.status == "active") {
  //     const productSkuCode =
  //       productDetails?.product?.catalogPartnerItemInfo?.[0]?.skuCode || "";
  //     const skuList = [productSkuCode];

  //     const facilityCode = catalogPartnerInfo?.warehouse?.facilityCode;

  //     const inventoryRes = await getUnicomInventoryApi({
  //       facilityCode,
  //       skuList,
  //     });

  //     if (inventoryRes) {
  //       console.log("FETCH__Catalog_Inventory__DONE:", inventoryRes);
  //       setUnicomInventoryInfo(inventoryRes?.inventorySnapshots || []);
  //     }
  //   }

  //   setUnicommerceLoader(false);
  // };

  // Catalog Partner Inventory Info
  const getCatalogPartnerInventory = async () => {
    setInventoryLoader(true);
    const catalogPartnerInfo = commercialDetails?.info?.catalogPartnerInfo?.[0];

    if (catalogPartnerInfo?.status != "active") {
      setInventoryLoader(false);
      return;
    }

    // Extract valid SKU code
    const productSkuCode =
      productDetails?.product?.catalogPartnerItemInfo?.[0]?.skuCode || "";
    const skuList = [productSkuCode];

    const facilityCode = catalogPartnerInfo?.warehouse?.facilityCode;

    if (!skuList.length || !facilityCode) return;

    if (catalogPartnerInfo?.catalogPartner == "unicommerce") {
      const inventoryRes = await getUnicomInventoryApi({
        facilityCode,
        skuList,
      });

      if (inventoryRes) {
        setUnicomInventoryInfo(inventoryRes?.inventorySnapshots || []);
      }
    } else {
      const inventoryRes = await getBuyMoreInventoryApi({
        facilityCode,
        skuList,
      });

      if (inventoryRes) {
        setBuymoreInventoryInfo(inventoryRes?.final_list || []);
      }
    }

    setInventoryLoader(false);
  };

  // Check Catalog Partner Product Inventory
  const checkPartnerProductInventory = (productInfo) => {
    const catalogPartnerInfo = commercialDetails?.info?.catalogPartnerInfo?.[0];
    const catalogPartner =
      catalogPartnerInfo?.catalogPartner?.toLowerCase() || "";

    switch (catalogPartner) {
      case "unicommerce":
        return checkCatalogPartnetInventory(unicomInventoryInfo, productInfo);

      case "buymore":
        return checkCatalogPartnetInventory(buymoreInventoryInfo, productInfo);

      default:
        return true;
    }
  };

  const getSpecificationData = async (product) => {
    const specificationPayload = {
      pscCode1: `${product?.productMainCategory?.[0]?.ondcCode}${product?.productSubCategory?.[0]?.ondcSubCode}`,
      status: "active",
    };
    const fullSpecificationPayload = {
      domainCode: product?.productMainCategory?.[0]?.ondcCode,
      status: "active",
    };
    const spec = await getOndcSpecificationAPI(specificationPayload);
    const fullSpec = await getOndcFullSpecificationAPI(
      fullSpecificationPayload
    );
    setSpecification(spec);
    setFullSpecification(fullSpec);
  };

  useEffect(() => {
    if (isDomainUrl) {
      resolveDomainUrl(dispatch);
      sessionStorage.setItem("businessName", businessName);
      return;
    }
    getBusinessData();
  }, [businessName]);

  useEffect(() => {
    if (businessId?.validate) {
      dispatch(getProductDetails(productId)).then((res) => {
        console.log("PROD_VALID__:", businessId);
        console.log("PROD_VALID__:", res);

        const productBusinessId = res?.payload?.product?.businessId || null;
        const currentBusinessId = businessId?.businessId;
        if (currentBusinessId === productBusinessId) {
          setIsProductValid(true);
        } else {
          setIsProductValid(false);
        }
      });
      if (!businessDetails) {
        dispatch(getActiveBusiness());
      }
    }
    if (!cartDetails) {
      dispatch(getCartDetails(businessId));
    }
  }, [businessId]);

  useEffect(() => {
    if (productDetails) {
      getSpecificationData(productDetails?.product);
      getCatalogPartnerInventory();

      dispatch(
        getSimilarProductDetails({
          businessId: productDetails?.product?.businessId,
          subCat: productDetails?.product?.productSubCategory[0]?.code,
        })
      );
      console.log("Product", productDetails?.product);

      let count = 0;
      const cartProductData = JSON.parse(sessionStorage.getItem("cartData"));
      cartProductData?.productList?.forEach((item) => {
        if (item.productId === productDetails?.product?.id) {
          setProductQty(item?.currentQuantity);
        }

        count = count + item.currentQuantity;
      });
      setTotalQty(count);
      setCartProductList(cartProductData);
      setQtyLoading(false);
    }
  }, [productDetails]);

  useEffect(() => {
    if (!commercialDetails) {
      dispatch(getCommercialDetails());
    }
  }, []);

  useEffect(() => {
    const filterArray = similarProductDetails?.details?.filter(
      (item) => item.id !== productId
    );
    setRecommendedProducts(filterArray);
    // setProductCount(filterArray?.length);
  }, [similarProductDetails]);

  useEffect(() => {
    if (productDetails?.product?.id !== productId) return;

    let count = 0;
    const cartProductData = JSON.parse(sessionStorage.getItem("cartData"));
    cartProductData?.productList?.forEach((item) => {
      if (item.productId === productDetails?.product?.id) {
        setProductQty(item?.currentQuantity);
      }

      count = count + item.currentQuantity;
    });
    setTotalQty(count);
    setCartProductList(cartProductData);
    setQtyLoading(false);
  }, [cartDetails, updateQty]);

  useEffect(() => {
    if (productDetails?.product?.id !== productId) return;

    let count = 0;
    setProductQty(0);

    if (isLoggedIn) {
      dispatch(getCartDetails(businessId)).then((carRes) => {
        sessionStorage.setItem(
          "cartData",
          JSON.stringify(carRes?.payload?.detail)
        );
        carRes?.payload?.detail?.productList?.forEach((item) => {
          if (item.productId === productDetails?.product?.id) {
            setProductQty(item?.currentQuantity);
          }
          count = count + item.currentQuantity;
        });
        setTotalQty(count);
        setCartProductList(carRes?.payload?.detail);
        setQtyLoading(false);
      });
    }
  }, [addedProduct, createCartRes, updateCartIntRes]);

  useEffect(() => {
    setProductQty(0);
    if (businessId?.validate) {
      dispatch(getProductDetails(productId)).then((res) => {
        console.log("PROD_VALID__:", businessId);
        console.log("PROD_VALID__:", res);

        const productBusinessId = res?.payload?.product?.businessId || null;
        const currentBusinessId = businessId?.businessId;
        if (currentBusinessId === productBusinessId) {
          setIsProductValid(true);
        } else {
          setIsProductValid(false);
        }
      });
      dispatch(getCommercialDetails());
      if (!cartDetails) {
        dispatch(getCartDetails(businessId));
      }

      let count = 0;
      const cartProductData = JSON.parse(sessionStorage.getItem("cartData"));
      cartProductData?.productList?.forEach((item) => {
        if (item.productId === productDetails?.product?.id) {
          setProductQty(item?.currentQuantity);
        }

        count = count + item.currentQuantity;
      });
      setTotalQty(count);
      setCartProductList(cartProductData);
      setQtyLoading(false);
    }
  }, [productId, businessId]);

  useEffect(() => {
    if (productDetails) {
      if (productDetails?.product?.id !== productId) return;

      setIsOutOfStock(getOutOfStock(productDetails?.product));
      dispatch(
        getSimilarProductDetails({
          businessId: productDetails?.product?.businessId,
          subCat: productDetails?.product?.productSubCategory[0]?.code,
        })
      );

      const cartData = JSON.parse(sessionStorage.getItem("cartData"));
      cartData?.productList?.forEach((item) => {
        if (item.productId === productDetails?.product?.id) {
          setProductQty(item?.currentQuantity);
        }
      });

      setQtyLoading(false);
    }
  }, [productDetails]);

  useEffect(() => {
    const commerceInfo = JSON.parse(sessionStorage.getItem("commerceInfo"));
    const cashback = JSON.parse(sessionStorage.getItem("cashback"));

    if (!commerceInfo) {
      dispatch(getCommercialDetails());
    } else {
      setCommerceInfoData(commerceInfo);
    }
    if (!cashback) {
      dispatch(getRewardPointsDetails());
    } else {
      setCashbackData(cashback);
    }
  }, [rewardpoints, commercialDetails]);

  return (
    <Stack bgcolor={backgroundColor01}>
      {commercialDetails && businessDetails ? (
        <TopComponent value="singleProduct" disableBanners />
      ) : (
        <HeaderLoader hideBanners />
      )}

      {/* {!loader && !isProductValid && productDetails && commercialDetails && (
        <Box mt={12}>
          <EmptyCard
            message={"Product Not Found"}
            description={"Explore our products."}
            imageUrl={noProductImgUrl}
            actionBtnText={"Go To Catalog"}
          />
        </Box>
      )} */}

      <>
        {matches ? (
          commercialDetails ? (
            <Box
              sx={{
                position: "sticky",
                top: 126.5,
                width: "100%",
                zIndex: 1000,
                backgroundColor: { xs: whiteColor, md: backgroundColor01 },
                pt: { xs: 3, md: 2 },
                // pb: 1,
              }}
            >
              <>
                <Container
                  sx={{
                    maxWidth: "1350px !important",
                    p: { xs: "0", md: "0px 15px 0px 15px" },
                  }}
                >
                  <BreadcrumbNew
                    links={[
                      { label: "Catalog", to: `/Catalog` },
                      {
                        label: "Product Description",
                        to: `/singleProduct/${productId}`,
                      },
                    ]}
                    itemCount={1}
                    discount={""}
                    // totalPrice={
                    //   cartProductList
                    //     ? cartProductList.orderTotalProductCost
                    //     : 0
                    // }
                  />
                  {cartProductList?.productList?.length > 0 ? (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      py={1.5}
                      // px={7}
                      // pl={1}
                      // display={"none"}
                    >
                      <Grid item>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Typography
                              variant="h5"
                              fontSize={14}
                              fontWeight={500}
                              color={blackColor}
                            >
                              Items in your cart
                            </Typography>
                          </Grid>
                          {/* <Grid item>
                      <Box sx={{ width: '40vw' }}>
                        <Slider {...cartListSettings}>
                          {cartProductList?.productList?.map((item, index) => {
                            return (
                              <ProductCount
                                src={item.productPicURL}
                                alt={item.productName}
                                count={item.currentQuantity}
                                key={'cart_product_' + index}
                                mx={0.5}
                              />
                            )
                          })}
                        </Slider>
                      </Box>
                    </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid item display={{ xs: "none", md: "block" }}>
                        <Typography
                          variant="h6"
                          color={greyShade59}
                          fontSize="13px"
                          fontWeight={600}
                          display="inline"
                          px={"14px"}
                          borderRight={borderCart}
                        >
                          {totalQty} items in cart
                        </Typography>

                        <Typography
                          variant="h5"
                          color={headingColor}
                          fontSize="14px"
                          fontWeight={600}
                          display="inline"
                          mx={"12px"}
                        >
                          ₹{" "}
                          {Number(
                            cartProductList?.orderTotalProductCost
                          ).toFixed(2)}
                        </Typography>

                        <Button
                          // component={Link}
                          // to={`/${businessName}/cart`}
                          sx={{
                            height: "34px",
                            fontSize: "12px",
                            fontWeight: 600,
                            color: whiteColor,
                            p: "6px 14px",
                            border: "2px solid " + orangeColor01,
                            textTransform: "none",
                            borderRadius: "5px",
                            "&:hover": {
                              backgroundColor: whiteColor,
                              color: orangeColor01,
                            },
                          }}
                          variant="contained"
                          disableElevation
                          size="small"
                          onClick={() => {
                            navigateTo(
                              navigate,
                              `cart`
                            );
                          }}
                        >
                          GO TO CART
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                </Container>
              </>
            </Box>
          ) : (
            <BreadcrumbLoader />
          )
        ) : null}

        <Container
          sx={{
            maxWidth: "1350px !important",
            p: {
              xs: "0",
              md:
                cartProductList?.productList?.length > 0
                  ? "0px 15px 0px 15px"
                  : "15px 15px 0px 15px",
            },
          }}
        >
          <Box
            py={{ xs: 1, md: 1 }}
            mb={{ md: recommendedProducts?.length === 0 ? 3 : 0, xs: 0 }}
          >
            {isProductValid && productDetails && commercialDetails ? (
              productDetails?.product?.variantInfo?.masterProductVariantId ? (
                <VariantProduct
                  productId={
                    productDetails?.product?.variantInfo?.masterProductVariantId
                  }
                  activeProductId={productId}
                  cashbackData={cashbackData}
                  recommendedProducts={recommendedProducts}
                  specification={specification}
                  fullSpecification={fullSpecification}
                />
              ) : (
                <SingleProductDetail
                  productDetails={productDetails}
                  similarProductDetails={similarProductDetails}
                  commercialDetails={commercialDetails}
                  recLoading={recLoading}
                  productId={productId}
                  cartProductList={cartProductList}
                  totalQty={totalQty}
                  productQty={productQty}
                  cashback={cashbackData}
                  // isOutOfStock={isOutOfStock}
                  isOutOfStock={
                    commercialDetails?.info?.catalogPartnerInfo?.[0]?.status ==
                    "active"
                      ? checkPartnerProductInventory(productDetails?.product)
                      : getOutOfStock(productDetails?.product)
                  }
                  inventoryLoader={inventoryLoader}
                  onQtyUpdate={(quantity) => {
                    if (qtyLoading) return;

                    if (isLoggedIn) {
                      dispatch(
                        updateCartProductQuantity({
                          cartId: cartDetails?.cartId,
                          productId: productDetails?.product?.id,
                          quantity: quantity,
                        })
                      );
                      setQtyLoading(true);
                    } else {
                      dispatch(
                        updateToLocalCart({
                          cartId: cartDetails?.cartId,
                          productId: productDetails?.product?.id,
                          quantity: quantity,
                        })
                      ).then((res) => {
                        res?.payload?.productList?.forEach((item) => {
                          if (item.productId === productDetails?.product?.id) {
                            setProductQty(item?.currentQuantity);
                          }
                        });
                        setCartProductList(res?.payload);
                        setTotalQty(res?.payload?.totalQuantity);
                      });
                    }
                  }}
                  onAddProduct={() => {
                    if (qtyLoading) return;
                    onAddProductToCart();
                  }}
                  onRemoveProduct={() => {
                    if (qtyLoading) return;
                    removeProductFromCart({
                      productId: productDetails?.product?.id,
                    });
                  }}
                  cashbackData={cashbackData}
                  recommendedProducts={recommendedProducts}
                  specification={specification}
                  fullSpecification={fullSpecification}
                />
              )
            ) : (
              <SingleProductLoader />
            )}
          </Box>

          {recommendedProducts?.length > 0 ? (
            <>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                borderTop={`1px solid #e8e8f0`}
                mt={{ md: "12px", xs: 0 }}
                alignItems={"center"}
                p={{ xs: "15px", md: `10px 0px 0px 0px` }}
                backgroundColor={{ md: backgroundColor01, xs: whiteColor }}
              >
                <Typography
                  variant="h4"
                  fontSize={16}
                  color={blackColor}
                  display="inline"
                  fontWeight={600}
                  lineHeight={"22px"}
                  textAlign={"center"}
                >
                  Recommended Products
                </Typography>
                {/* {currentSlideIndex < recommendedProducts.length - 7 && ( */}
                {recommendedProducts.length > 7 && (
                  <Grid item display={{ xs: "none", md: "block" }}>
                    <Grid container columnSpacing={1}>
                      <Grid item>
                        <Box
                          width="30px"
                          height="30px"
                          // backgroundColor={arrowBgColor}
                          backgroundColor={
                            currentSlideIndex > 0 ? filterBgColor : whiteColor
                          }
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={2}
                          sx={{ cursor: "pointer" }}
                          // onClick={() => onPrev()}
                          onClick={onSlidePrev}
                          border={borderTextbox}
                        >
                          <ChevronLeft
                            sx={{
                              fontSize: "22px",
                              color:
                                currentSlideIndex > 0
                                  ? navLinkColor
                                  : iconInactiveColor,
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item>
                        <Box
                          width="30px"
                          height="30px"
                          // backgroundColor={arrowBgColor}
                          backgroundColor={
                            currentSlideIndex < recommendedProducts.length - 7
                              ? filterBgColor
                              : whiteColor
                          }
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={2}
                          sx={{ cursor: "pointer" }}
                          // onClick={() => onNext()}
                          onClick={onSlideNext}
                          border={borderTextbox}
                        >
                          <ChevronRight
                            sx={{
                              fontSize: "22px",
                              color:
                                currentSlideIndex <
                                recommendedProducts.length - 7
                                  ? navLinkColor
                                  : iconInactiveColor,
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Box
                mb={2}
                backgroundColor={{ md: backgroundColor01, xs: whiteColor }}
              >
                {recommendedProducts?.length <= 7 ? (
                  matches ? (
                    <Grid container mt={0} spacing={2}>
                      {recommendedProducts?.map((item) => {
                        // if (item?.variantInfo?.masterVariant) return null;

                        return (
                          <Grid item md={1.8}>
                            <ProductCard
                              img={item.productPhoto[0]?.docURL}
                              price={item.productSalePrice}
                              oldPrice={item.productOriginalPrice}
                              description={item.productName}
                              discount={item.percentageOff}
                              style={{ my: 1.5 }}
                              isOutOfStock={item.isOutOfStock}
                              isComingSoon={item.isComingSoon}
                              disableAddtoCart={true}
                              product={item}
                              variant={
                                item?.variantInfo?.masterProductVariantId
                              }
                              variantCount={item?.count}
                              circularCountView
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "12px",
                        overflowX: "scroll",
                      }}
                      mb={totalQty > 0 ? 15 : 7}
                    >
                      {/* {recommendedProducts?.slice(0, 10).map((item, index) => { */}
                      {recommendedProducts?.map((item, index) => {
                        // if (item?.variantInfo?.masterVariant) return null;

                        return (
                          <Box py={{ md: 1.5, xs: 0 }}>
                            <Box
                              pl={{
                                md: index === 0 ? 0 : "15px",
                                xs: "16px",
                              }}
                            >
                              {matches ? (
                                <ProductCard
                                  img={item.productPhoto[0]?.docURL}
                                  price={item.productSalePrice}
                                  oldPrice={item.productOriginalPrice}
                                  description={item.productName}
                                  discount={item.percentageOff}
                                  style={{ my: 1.5 }}
                                  isOutOfStock={item.isOutOfStock}
                                  isComingSoon={item.isComingSoon}
                                  disableAddtoCart={true}
                                  product={item}
                                  variant={
                                    item?.variantInfo?.masterProductVariantId
                                  }
                                  variantCount={item?.count}
                                  circularCountView
                                />
                              ) : (
                                <MobileProductCard
                                  productDescription={item?.productDescription}
                                  minQty={item?.minOrderQuantity}
                                  maxQty={item?.maxOrderQuantity}
                                  productid={item?.id}
                                  // direction={mod % 2 === 0 ? true : false}
                                  direction={false}
                                  mapLength={recommendedProducts?.length}
                                  img={item.productPhoto[0]?.docURL}
                                  price={item.productSalePrice}
                                  oldPrice={item.productOriginalPrice}
                                  description={item.productName}
                                  discount={item.percentageOff}
                                  style={{ my: 1.5 }}
                                  isOutOfStock={getOutOfStock(item)}
                                  isComingSoon={item?.commerceEnabled === "NO"}
                                  disableAddtoCart={true}
                                  product={item}
                                  variant={
                                    item?.variantInfo?.masterProductVariantId
                                  }
                                  variantCount={item?.count}
                                />
                              )}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )
                ) : matches ? (
                  <Slider
                    ref={slider}
                    {...settings}
                    beforeChange={(oldIndex, newIndex) => {
                      setCurrentSlideIndex(newIndex);
                    }}
                  >
                    {/* {recommendedProducts?.slice(0, 10).map((item, index) => { */}
                    {recommendedProducts?.map((item, index) => {
                      // if (item?.variantInfo?.masterVariant) return null;

                      return (
                        <Box py={1.5}>
                          <Box
                            pl={{ md: index === 0 ? 0 : "15px", xs: "15px" }}
                          >
                            {matches ? (
                              <ProductCard
                                img={item.productPhoto[0]?.docURL}
                                price={item.productSalePrice}
                                oldPrice={item.productOriginalPrice}
                                description={item.productName}
                                discount={item.percentageOff}
                                style={{ my: 1.5 }}
                                isOutOfStock={item.isOutOfStock}
                                isComingSoon={item.isComingSoon}
                                disableAddtoCart={true}
                                product={item}
                                variant={
                                  item?.variantInfo?.masterProductVariantId
                                }
                                variantCount={item?.count}
                                circularCountView
                              />
                            ) : (
                              <MobileProductCard
                                productDescription={item?.productDescription}
                                minQty={item?.minOrderQuantity}
                                maxQty={item?.maxOrderQuantity}
                                productid={item?.id}
                                // direction={mod % 2 === 0 ? true : false}
                                direction={true}
                                mapLength={recommendedProducts?.length}
                                img={item.productPhoto[0]?.docURL}
                                price={item.productSalePrice}
                                oldPrice={item.productOriginalPrice}
                                description={item.productName}
                                discount={item.percentageOff}
                                style={{ my: 1.5 }}
                                isOutOfStock={getOutOfStock(item)}
                                isComingSoon={item?.commerceEnabled === "NO"}
                                disableAddtoCart={true}
                                product={item}
                                variant={
                                  item?.variantInfo?.masterProductVariantId
                                }
                                variantCount={item?.count}
                              />
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Slider>
                ) : (
                  <Box
                    sx={{ display: "flex", gap: "12px", overflowX: "scroll" }}
                    mb={totalQty > 0 ? 15 : 7}
                  >
                    {/* {recommendedProducts?.slice(0, 10).map((item, index) => { */}
                    {recommendedProducts?.map((item, index) => {
                      // if (item?.variantInfo?.masterVariant) return null;

                      return (
                        <Box py={{ md: 1.5, xs: 0 }}>
                          <Box
                            pl={{ md: index === 0 ? 0 : "15px", xs: "15px" }}
                          >
                            {matches ? (
                              <ProductCard
                                img={item.productPhoto[0]?.docURL}
                                price={item.productSalePrice}
                                oldPrice={item.productOriginalPrice}
                                description={item.productName}
                                discount={item.percentageOff}
                                style={{ my: 1.5 }}
                                isOutOfStock={item.isOutOfStock}
                                isComingSoon={item.isComingSoon}
                                disableAddtoCart={true}
                                product={item}
                                variant={
                                  item?.variantInfo?.masterProductVariantId
                                }
                                variantCount={item?.count}
                                circularCountView
                              />
                            ) : (
                              <MobileProductCard
                                productDescription={item?.productDescription}
                                minQty={item?.minOrderQuantity}
                                maxQty={item?.maxOrderQuantity}
                                productid={item?.id}
                                // direction={mod % 2 === 0 ? true : false}
                                direction={false}
                                mapLength={recommendedProducts?.length}
                                img={item.productPhoto[0]?.docURL}
                                price={item.productSalePrice}
                                oldPrice={item.productOriginalPrice}
                                description={item.productName}
                                discount={item.percentageOff}
                                style={{ my: 1.5 }}
                                isOutOfStock={getOutOfStock(item)}
                                isComingSoon={item?.commerceEnabled === "NO"}
                                disableAddtoCart={true}
                                product={item}
                                variant={
                                  item?.variantInfo?.masterProductVariantId
                                }
                                variantCount={item?.count}
                              />
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </>
          ) : null}
        </Container>
      </>

      {/* {loader && (
        <Box
          minHeight="20vh"
          size={25}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Loader />
        </Box>
      )} */}
      {totalQty > 0 ? (
        <CartDetailsBottonCard
          amount={cartProductList ? cartProductList.orderTotalProductCost : 0}
          cartProductList={cartProductList}
          itemCount={totalQty}
          gapdown={6}
        />
      ) : null}
      {atag && productDetails && <OfferPopupModal couponCode={atag} />}
      {matches && commercialDetails && !loader && productDetails && (
        <FooterMui />
      )}
    </Stack>
  );
}

export default ProductDetail;
