import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Suspense, useEffect, useRef, useState } from "react";
import Slider from "react-slick/lib/slider";
import { getOutOfStock } from "utils/productHelper";
import {
  backgroundColor,
  blackColor,
  borderCard01,
  borderColor,
  greyShade51,
  orangeColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import CategoryGroupHeader from "./CategoryGroupHeader";
import ProductCard from "./ProductCard";
import ScrollIndicator from "components/ScrollIndicator";
import ProductMobileCard from "./ProductMobileCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function CategoryGroup({
  title,
  subCat,
  products,
  count,
  onViewMore,
  cartData,
  cartId,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
  showInGrid,
  index,
  variant,
  hasCatalogPartner,
  checkPartnerInventory,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [showTable, setShowTable] = useState(index === 1);
  // const [showTable, setShowTable] = useState(false)
  const slider = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const containerRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  const onSlideNext = () => {
    if (currentSlideIndex < products.length - 5) {
      slider.current.slickNext();
    }
  };

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev();
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      // Check if the horizontal scroll has reached the end
      const isEnd =
        container?.scrollLeft + container?.clientWidth + 200 >=
        container?.scrollWidth;

      setIsScrollEnd(isEnd);
    };

    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" position="relative">
      {matches ? (
        <Box my={showInGrid ? 0.5 : 0}>
          <CategoryGroupHeader
            title={title}
            onNext={onSlideNext}
            onPrev={onSlidePrev}
            slideIndex={currentSlideIndex}
            productCount={products.length - 4}
            totalProduct={count}
            variant={variant}
            // totalProduct={count}
            onViewMore={(catCode) => {
              onViewMore(catCode);
            }}
            subCat={subCat}
            noAction={showInGrid}
          />
        </Box>
      ) : (
        <Box
          my={showInGrid ? 0.5 : 0}
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
          borderBottom={
            showTable || (index !== 0 && `3px solid ${borderColor}`)
            // !showTable || (index !== 1 && `3px solid ${borderColor}`)
          }
          p="10px 15px 10px 0px"
        >
          <CategoryGroupHeader
            title={title}
            onNext={onSlideNext}
            onPrev={onSlidePrev}
            slideIndex={currentSlideIndex}
            productCount={products.length - 4}
            variant={variant}
            totalProduct={count}
            onViewMore={(catCode) => {
              onViewMore(catCode);
            }}
            subCat={subCat}
            noAction={showInGrid}
          />
          <Box
            // sx={styles.expandMoreLess}
            onClick={() => setShowTable((prev) => !prev)}
          >
            {showTable ? (
              <ExpandMoreIcon sx={{ fontSize: "25px" }} />
            ) : (
              <ExpandLessIcon sx={{ fontSize: "25px" }} />
            )}
          </Box>
        </Box>
      )}

      {/* <Box sx={styles.scrollBox}>
        {products.map((item, index) => {
          return (
            <>
              <Box py={1.5} mr={1.2}>
                <ProductCard
                  img={item.productPhoto[0].docURL}
                  price={item.productSalePrice}
                  oldPrice={item.productOriginalPrice}
                  description={item.productName}
                  discount={item.percentageOff}
                  style={{ my: 1.5 }}
                  isOutOfStock={getOutOfStock(item)}
                  isComingSoon={item.commerceEnabled === 'NO'}
                  minQty={item.minOrderQuantity}
                  maxQty={item.maxOrderQuantity}
                  cartData={cartData}
                  product={item}
                  cartId={cartId}
                  onQtyUpdate={(data) => {
                    onQtyUpdate(data)
                  }}
                  onAddProduct={(data) => {
                    onAddProduct(data)
                  }}
                  onRemoveProduct={onRemoveProduct}
                />
              </Box>
            </>
          )
        })}

        {products.length > 10 ? (
          <Box sx={styles.productCard}>
            <Button
              onClick={() => {
                onViewMore(subCat.subcatcode)
              }}
              sx={{
                width:'143px',
                textTransform: 'none',
                color: 'primary',
                textDecorationLine: 'underline',
                fontSize: '15px',
              }}
            >
              View More
            </Button>
          </Box>
        ) : null}
      </Box> */}

      {showInGrid ? (
        <Grid
          container
          py={{ md: 1.5, xs: 0 }}
          spacing={2.5}
          alignItems={"start"}
        >
          {products.map((item, index) => {
            if (item?.variantInfo?.masterVariant) return null;

            return (
              <Grid item xs={12} md={2.4} key={`cat_products_${index}`}>
                {matches ? (
                  <ProductCard
                    img={item?.productPhoto[0]?.docURL}
                    price={item.productSalePrice}
                    oldPrice={item.productOriginalPrice}
                    description={item.productName}
                    discount={item.percentageOff}
                    style={{ my: 1.5 }}
                    isOutOfStock={
                      hasCatalogPartner
                        ? checkPartnerInventory(item)
                        : getOutOfStock(item)
                    }
                    // isOutOfStock={getOutOfStock(item)}
                    isComingSoon={item.commerceEnabled === "NO"}
                    minQty={item.minOrderQuantity}
                    maxQty={item.maxOrderQuantity}
                    cartData={cartData}
                    product={item}
                    cartId={cartId}
                    onQtyUpdate={(data) => {
                      onQtyUpdate(data);
                    }}
                    onAddProduct={(data) => {
                      onAddProduct(data);
                    }}
                    onRemoveProduct={onRemoveProduct}
                    subscribe={item?.subscription?.status === "active"}
                    availability={
                      item?.availability?.[0]?.toLowerCase() === "yes"
                    }
                    subscription={item?.subscription}
                    variant={item?.variantInfo?.masterProductVariantId}
                    variantCount={item?.count}
                  />
                ) : (
                  <ProductMobileCard
                    productid={item?.id}
                    img={item?.productPhoto?.[0]?.docURL}
                    price={item.productSalePrice}
                    oldPrice={item.productOriginalPrice}
                    description={item.productName}
                    discount={item.percentageOff}
                    style={{ my: 1.5 }}
                    isOutOfStock={
                      hasCatalogPartner
                        ? checkPartnerInventory(item)
                        : getOutOfStock(item)
                    }
                    // isOutOfStock={getOutOfStock(item)}
                    isComingSoon={item.commerceEnabled === "NO"}
                    minQty={item.minOrderQuantity}
                    maxQty={item.maxOrderQuantity}
                    cartData={cartData}
                    product={item}
                    cartId={cartId}
                    onQtyUpdate={(data) => {
                      onQtyUpdate(data);
                    }}
                    onAddProduct={(data) => {
                      onAddProduct(data);
                    }}
                    onRemoveProduct={onRemoveProduct}
                    subscribe={item?.subscription?.status === "active"}
                    availability={
                      item?.availability?.[0]?.toLowerCase() === "yes"
                    }
                    subscription={item?.subscription}
                    variant={item?.variantInfo?.masterProductVariantId}
                    variantCount={item?.count}
                    index={index}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <>
          {/* Slider in Large Screen */}
          {products.length > 4 && (
            <Box display={{ xs: "none", md: "block" }} position="relative">
              <Slider
                ref={slider}
                {...settings}
                beforeChange={(oldIndex, newIndex) => {
                  setCurrentSlideIndex(newIndex);
                }}
              >
                {products?.map((item, index) => {
                  if (item?.variantInfo?.masterVariant) return null;

                  return (
                    <>
                      <Box py={1.5} mr={1.2}>
                        <ProductCard
                          img={item?.productPhoto?.[0]?.docURL}
                          price={item.productSalePrice}
                          oldPrice={item.productOriginalPrice}
                          description={item.productName}
                          discount={item.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={
                            hasCatalogPartner
                              ? checkPartnerInventory(item)
                              : getOutOfStock(item)
                          }
                          // isOutOfStock={getOutOfStock(item)}
                          isComingSoon={item.commerceEnabled === "NO"}
                          minQty={item.minOrderQuantity}
                          maxQty={item.maxOrderQuantity}
                          cartData={cartData}
                          product={item}
                          cartId={cartId}
                          onQtyUpdate={(data) => {
                            onQtyUpdate(data);
                          }}
                          onAddProduct={(data) => {
                            onAddProduct(data);
                          }}
                          onRemoveProduct={onRemoveProduct}
                          subscribe={item?.subscription?.status === "active"}
                          availability={
                            item?.availability?.[0]?.toLowerCase() === "yes"
                          }
                          subscription={item?.subscription}
                          variant={item?.variantInfo?.masterProductVariantId}
                          variantCount={item?.count}
                        />
                      </Box>
                    </>
                  );
                })}

                {count > 15 ? (
                  <Box py={1.5} mr={1.2}>
                    <Box sx={styles.productCard}>
                      <Typography
                        onClick={() => {
                          onViewMore(subCat.subcatcode);
                        }}
                        sx={{
                          textTransform: "none",
                          textDecoration: "underline",
                          color: blackColor,
                          fontSize: "15px",
                          fontWeight: 500,
                          display: { xs: "none", md: "flex" },
                          cursor: "pointer",
                        }}
                      >
                        View More
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Slider>

              {/* {currentSlideIndex > 0 && (
                <IconButton
                  sx={{ ...styles.arrowIconBtn, left: "-20px" }}
                  onClick={onSlidePrev}
                >
                  <KeyboardArrowLeftIcon color="inherit" />
                </IconButton>
              )}

              {currentSlideIndex < products?.length - 5 && (
                <IconButton
                  sx={{ ...styles.arrowIconBtn, right: "-10px" }}
                  onClick={onSlideNext}
                >
                  <KeyboardArrowRightIcon color="inherit" />
                </IconButton>
              )} */}
            </Box>
          )}

          {/* Horizontal Scroll in Small Screen */}
          {products.length > 0 && showTable && (
            <Box display={{ xs: "block", md: "none" }}>
              <Box>
                {products.map((item, index) => {
                  return (
                    <>
                      <Box>
                        <ProductMobileCard
                          productid={item?.id}
                          img={item?.productPhoto?.[0]?.docURL}
                          price={item.productSalePrice}
                          oldPrice={item.productOriginalPrice}
                          description={item.productName}
                          productDescription={item.productDescription}
                          discount={item.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={
                            hasCatalogPartner
                              ? checkPartnerInventory(item)
                              : getOutOfStock(item)
                          }
                          // isOutOfStock={getOutOfStock(item)}
                          isComingSoon={item.commerceEnabled === "NO"}
                          minQty={item.minOrderQuantity}
                          maxQty={item.maxOrderQuantity}
                          cartData={cartData}
                          product={item}
                          cartId={cartId}
                          onQtyUpdate={(data) => {
                            onQtyUpdate(data);
                          }}
                          onAddProduct={(data) => {
                            onAddProduct(data);
                          }}
                          onRemoveProduct={onRemoveProduct}
                          subscribe={item?.subscription?.status === "active"}
                          availability={
                            item?.availability?.[0]?.toLowerCase() === "yes"
                          }
                          subscription={item?.subscription}
                          variant={item?.variantInfo?.masterProductVariantId}
                          variantCount={item?.count}
                          index={index}
                        />
                      </Box>
                    </>
                  );
                })}

                {products.length > 10 && matches ? (
                  <Box sx={styles.productCard}>
                    <Button
                      onClick={() => {
                        onViewMore(subCat.subcatcode);
                      }}
                      sx={{
                        width: "143px",
                        textTransform: "uppercase",
                        color: orangeColor01,
                        textDecorationLine: "underline",
                        fontSize: "15px",
                      }}
                    >
                      View More
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </Box>
          )}

          {/* Displaying Products in Grid, If there are only 4 or less */}
          {products.length <= 4 && matches && (
            <Box py={1.5}>
              <Grid container spacing={1.5}>
                {products.map((item, index) => {
                  if (item?.variantInfo?.masterVariant) return null;

                  return (
                    <Grid item xs={12} md={2.4} key={`${item?.id}_${index}`}>
                      {matches ? (
                        <ProductCard
                          productid={item?.id}
                          img={item?.productPhoto?.[0]?.docURL}
                          price={item.productSalePrice}
                          oldPrice={item.productOriginalPrice}
                          description={item.productName}
                          discount={item.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={
                            hasCatalogPartner
                              ? checkPartnerInventory(item)
                              : getOutOfStock(item)
                          }
                          // isOutOfStock={getOutOfStock(item)}
                          isComingSoon={item.commerceEnabled === "NO"}
                          minQty={item.minOrderQuantity}
                          maxQty={item.maxOrderQuantity}
                          cartData={cartData}
                          product={item}
                          cartId={cartId}
                          onQtyUpdate={(data) => {
                            onQtyUpdate(data);
                          }}
                          onAddProduct={(data) => {
                            onAddProduct(data);
                          }}
                          onRemoveProduct={onRemoveProduct}
                          subscribe={item?.subscription?.status === "active"}
                          availability={
                            item?.availability?.[0]?.toLowerCase() === "yes"
                          }
                          subscription={item?.subscription}
                          variant={item?.variantInfo?.masterProductVariantId}
                          variantCount={item?.count}
                        />
                      ) : (
                        <ProductMobileCard
                          productid={item?.id}
                          img={item?.productPhoto?.[0]?.docURL}
                          price={item.productSalePrice}
                          oldPrice={item.productOriginalPrice}
                          description={item.productName}
                          discount={item.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={
                            hasCatalogPartner
                              ? checkPartnerInventory(item)
                              : getOutOfStock(item)
                          }
                          // isOutOfStock={getOutOfStock(item)}
                          isComingSoon={item.commerceEnabled === "NO"}
                          minQty={item.minOrderQuantity}
                          maxQty={item.maxOrderQuantity}
                          cartData={cartData}
                          product={item}
                          cartId={cartId}
                          onQtyUpdate={(data) => {
                            onQtyUpdate(data);
                          }}
                          onAddProduct={(data) => {
                            onAddProduct(data);
                          }}
                          onRemoveProduct={onRemoveProduct}
                          subscribe={item?.subscription?.status === "active"}
                          availability={
                            item?.availability?.[0]?.toLowerCase() === "yes"
                          }
                          subscription={item?.subscription}
                          variant={item?.variantInfo?.masterProductVariantId}
                          variantCount={item?.count}
                          index={index}
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            // <Box
            //   py={1.5}
            //   display={{ xs: 'none', md: 'flex' }}
            //   flexDirection='row'
            // >
            //   {products.map((item, index) => {
            //     return (
            //       <Box mr={2.2}>
            //         <ProductCard
            //           productid={item?.id}
            //           img={item.productPhoto[0]?.docURL}
            //           price={item.productSalePrice}
            //           oldPrice={item.productOriginalPrice}
            //           description={item.productName + "xdjhcdhchbdcjhbdc dhcd cdcv hjd chd cjhdvchdb "}
            //           discount={item.percentageOff}
            //           style={{ my: 1.5 }}
            //           isOutOfStock={getOutOfStock(item)}
            //           isComingSoon={item.commerceEnabled === 'NO'}
            //           minQty={item.minOrderQuantity}
            //           maxQty={item.maxOrderQuantity}
            //           cartData={cartData}
            //           product={item}
            //           cartId={cartId}
            //           onQtyUpdate={(data) => {
            //             onQtyUpdate(data)
            //           }}
            //           onAddProduct={(data) => {
            //             onAddProduct(data)
            //           }}
            //           onRemoveProduct={onRemoveProduct}
            //           subscribe={item?.subscription?.status === 'active'}
            //           availability={
            //             item?.availability?.[0]?.toLowerCase() === 'yes'
            //           }
            //           subscription={item?.subscription}
            //         />
            //       </Box>
            //     )
            //   })}
            // </Box>
          )}
        </>
      )}

      {/* Scroll Indicator Icon */}
      {!isScrollEnd && products?.length > 2 && matches && (
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            position: "absolute",
            right: "0px",
            top: "49%",
            zIndex: 50,
          }}
        >
          <ScrollIndicator show={isScrollEnd} />
        </Box>
      )}
    </Box>
  );
}

export default CategoryGroup;

const styles = {
  productCard: {
    width: "190px",
    height: "248px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    backgroundColor: whiteColor,
    border: `1px solid ${greyShade51}`,
    transition: "all 0.6s ease",
    "&:hover": {
      boxShadow: "0 4px 5px 0 rgba(0, 0, 0, 0.1)",
    },
  },

  scrollBox: {
    display: "flex",
    alignItems: "center",

    overflowX: "scroll",
    overscrollBehaviorInline: "contain",

    "&::-webkit-scrollbar": {
      display: "none",
    },

    "-webkit-overflow-scrolling": "touch",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  scrollBoxY: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    overflowY: "scroll",
    overscrollBehaviorInline: "contain",

    "&::-webkit-scrollbar": {
      display: "none",
    },

    "-webkit-overflow-scrolling": "touch",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  expandMoreLess: {
    backgroundColor: backgroundColor,
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    cursor: "pointer",
    borderRadius: "6px",
    left: "77vw",
    top: 1,
  },

  arrowIconBtn: {
    width: "32px",
    height: "42px",
    color: blackColor,
    bgcolor: whiteColor,
    p: 0.7,
    border: borderCard01,
    borderRadius: "7px",
    position: "absolute",
    top: "48%",
    boxShadow: "0 1px 5px rgb(202 202 214 / 55%)",
    "&:hover": {
      color: blackColor,
      bgcolor: whiteColor,
    },
  },
};
