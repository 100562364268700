import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import {
  backgroundColor01,
  blackColor,
  dashboardDescriptionColor,
  emptyMessageColor,
  greyColor9,
  whiteColor,
} from '../configs/styles/muiThemes'
import { Link, useNavigate } from 'react-router-dom'
import { navigateTo } from 'utils/urlHelper'

export default function EmptyCard({
  message,
  messageSize,
  description,
  imageUrl,
  actionBtnText,
  type,
  onTypeClick,
  iconWidth,
  bgcolor,
  iconHeignt,
  imgpadding,
  cardbottom,
  imageboxpadding,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const navigate = useNavigate()

  const businessName = sessionStorage.getItem('businessName')
  let businessFullName = sessionStorage.getItem('businessFullName')

  return matches ? (
    <Container sx={styles.container} backgroundColor={whiteColor}>
      <Box backgroundColor={whiteColor} padding={imageboxpadding ? imageboxpadding :"35px"} borderRadius={"10px"}>
        <Box
          component="img"
          src={imageUrl}
          width={{
            xs: iconWidth ? iconWidth : "100px",
            md: iconWidth ? iconWidth : "100px",
          }}
          height="auto"
          alt={businessFullName + " CostBo"}
        />
        <Typography
          variant="h6"
          fontSize={{ xs: 13.5, md: messageSize ? messageSize : 15 }}
          color={emptyMessageColor}
          marginBottom={"8px"}
          lineHeight={{ xs: "20px", md: "26px" }}
        >
          {message}
        </Typography>

        {description ? (
          <Typography
            variant="body1"
            fontSize={{ xs: 11.5, md: 13 }}
            color={dashboardDescriptionColor}
            lineHeight={{ xs: "20px", md: "23px" }}
          >
            {description}
          </Typography>
        ) : null}

        {actionBtnText ? (
          <Button
            // component={Link}
            // to={`/${businessName}/catalog`}
            onClick={()=>{
              navigateTo(navigate, `catalog`)
            }}
            variant="contained"
            disableElevation
            sx={styles.actionBtnMobile}
          >
            {actionBtnText}
          </Button>
        ) : null}

        {type ? (
          <Button
            variant="contained"
            disableElevation
            onClick={onTypeClick}
            sx={styles.actionBtnMobile}
          >
            {type}
          </Button>
        ) : null}
      </Box>
    </Container>
  ) : (
    <Box
      width={{xs:"auto", md:"100%"}}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      backgroundColor={bgcolor ? bgcolor : whiteColor}
      padding={{ md: 2, xs: 1 }}
      borderRadius={"10px"}
      mb={cardbottom ? cardbottom : 5}
      mt={1.5}
    >
      {/* <Box sx={{ padding: '20px' }}> */}
      <Box
        component="img"
        src={imageUrl}
        width={{ xs: iconWidth ? iconWidth : "60px", md: "100px" }}
        height={{ xs: iconHeignt ? iconHeignt : "60px", md: "100px" }}
        alt={businessFullName + " CostBo"}
        sx={{ padding: imgpadding ? imgpadding : "none" }}
      />
      {/* </Box> */}

      <Typography
        sx={{
          fontSize: { md: 15, xs: 13 },
          fontWeight: 600,
          textAlign: "center",
          color: blackColor,
          mt: { md: -0.2, xs: 1 },
        }}
      >
        {message}
      </Typography>
      {description ? (
        <Typography
          variant="body1"
          fontSize={{ xs: 12.5, md: 13.5 }}
          color={{ md: greyColor9, xs: greyColor9 }}
          lineHeight={{ xs: "20px", md: "23px" }}
          mt={0.7}
          textAlign={"center"}
        >
          {description}
        </Typography>
      ) : null}

      {actionBtnText ? (
        <Button
          // component={Link}
          // to={`/${businessName}/catalog`}
          onClick={()=>{
            navigateTo(navigate, `catalog`)
          }}
          variant="contained"
          disableElevation
          sx={styles.actionBtnMobile}
        >
          {actionBtnText}
        </Button>
      ) : null}

      {type ? (
        <Button
          variant="contained"
          disableElevation
          onClick={onTypeClick}
          sx={styles.actionBtnMobile}
        >
          {type}
        </Button>
      ) : null}
    </Box>
  );
}

const styles = {
  container: {
    maxWidth: { xs: "auto", md: "1370px !important" },
    px: { xs: "30px", md: "0px" },
    // py: "15px",
    textAlign: "center",
    borderRadius: "10px !important",
  },
  actionBtn: {
    mt: 3,
    "&:hover": {
      color: whiteColor,
    },
  },
  actionBtnMobile: {
    backgroundColor: "#000000",
    color: "#fff",
    border: "1px solid #000",
    padding: "7px 30px",
    borderRadius: "5px",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    marginTop: 1.8,
    mb: 2,
    fontWeight: "600",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: whiteColor,
      color: blackColor,
    },
  },
};
