// BASE URL
export const BASE_URL = "https://web.costbo.com/";

// External URL
export const SHORT_IO = "https://api.short.io/";

// ENDPOINTS
export const GET_BUSINESS_OVERVIEW_API = "business-query-side/api/v1/business";
export const GET_BUSINESS_COUPONS_API =
  "https://stagej.costbo.com/reward-query-side/api/v1/reseller";
export const BUSINESS_REVIEW_VIEW = "review-query-side/api/v1/review";
export const BUSINESS_REVIEW_MODIFY_QUERY = "review-command-side/api/v1/review";
export const GET_BUSINESS_ACTIVITY = "activity-query-side/api/v1/activity";
export const GET_BUSINESS_PRODUCT_CATEGORY = "product-query-side/api/v1/";

export const METRIC_URL =
  "product-query-side/api/v1/open/product/matrixAllProducts";
// export const METRIC_URL = 'product-query-side/api/v1/open/product/matrix'
export const MODIFY_ADDRESS_API =
  "account-command-side/api/v1/account/updateAddress";
export const STATE_CITY_API =
  "cart-query-side/api/v2/cart/consumer/getGeoPoint";
export const GET_COORDINATES_API =
  "cart-query-side/api/v2/cart/consumer/getGeoCoordinates";
export const CREATE_CART_API = "cart-command-side/api/v1/cart/createCart";
export const VALIDATE_CODE_API =
  "reward-query-side/api/v1/reseller/validateCode?discountCode=";
export const FIRST_TIME_DISCOUNT_API =
  "business-query-side/api/v1/wallet/getWalletEligibility";
export const CHECK_RESELLER_COUPON_API =
  "reward-query-side/api/v1/reseller/checkBusinessReseller";
export const SEARCH_PRODUCTS_API =
  "search-service/api/v1/open/search/productBusinessSearchV2";
// "search-service/api/v1/costbo/productBusinessSearch";
export const INSTANT_DISCOUNT_API =
  "reward-query-side/api/v1/reward/getMyInstantDiscount";
export const VIEW_ACTIVE_BUSINESS_API =
  "business-query-side/api/v1/business/viewActiveBusiness";
export const CHECK_DELIVERY_AVAILABILITY_API =
  "cart-query-side/api/v2/cart/consumer/verifyDeliveryAvailability?zipcode=";
export const DELHIVERY_SERVICEABILITY_API =
  "cart-query-side/api/v2/cart/consumer/delhiveryServiceability";
export const DELHIVERY_COST_CALCUTATION_API =
  "cart-query-side/api/v2/cart/consumer/deliveryCalculation";
export const DELHIVERY_SHIPMENTPRICE_API =
  "cart-query-side/api/v2/cart/consumer/delhiveryShipmentPrice";
export const BUSINESS_CONTACT_API =
  "business-contact-query-side/api/v1/business/contact/getBusinessContact";
export const UPDATE_WEB_ORDER_PAYMENT =
  "cart-command-side/api/v2/cart/consumer/updateWebOrderPayment";
export const SEND_ORDER_MESSAGE =
  "cart-command-side/api/v2/cart/consumer/sendOrderMessage";
export const SEND_ORDER_NOTIFICATION =
  "notification-service/api/v1/notification/sendOrderNotification";
export const ADD_PAYMENT_PROCESS =
  "paymentprocess-command-side/api/v1/paymentprocess/addPaymentProcess";
export const GENERATE_PAYMENT_LINK =
  "cart-command-side/api/v2/cart/consumer/generatePaymentLink";
export const UPGRADE_TO_PAYCART =
  "cart-command-side/api/v2/cart/consumer/upgradeToPayCart";
export const GET_DELHIVERY_STATUS =
  "cart-query-side/api/v2/cart/consumer/getDelhiveryStatus";
export const GET_TELYPORT_STATUS = "cart-query-side/api/v1/cart/getTelyport";

export const GET_PAYCART_DELHIVERY_STATUS =
  "cart-query-side/api/v2/open/cart/consumer/getPayCartDelhiveryStatus";
export const GET_PAYCART_TELYPORT_STATUS =
  "cart-query-side/api/v2/open/cart/consumer/getPayCartTelyportStatus";

export const ADD_SEARCH_INFO_URL =
  "searchinfo-command-side/api/v1/searchinfo/addSearchInfo";
export const ADD_TIMELINE_SHARE_URL =
  "timeline-command-side/api/v1/timeline/timeLineShare";
export const CREATE_GUEST_USER_URL = "costbouaa/api/createGuestUser";
export const AUTH_TOKEN_URL = "costbouaa/oauth/token";
export const TELYPORT_PRICING_URL = "https://telyport.com/api/v2/beta/pricing";
export const TELYPORT_PRICING_V2_URL =
  "https://stagej.costbo.com/cart-query-side/api/v2/cart/consumer/telyport/telyport/price";
export const TELYPORT_REGION_CHECK_URL =
  "cart-command-side/api/v2/cart/consumer/telyport/serviceRegion";
export const TELYPORT_SERVICE_TIME_CHECK_URL =
  "cart-command-side/api/v2/cart/consumer/telyport/serviceTime";
export const FINVERV_USER_EXISTS_URL =
  "fintech-service/api/v1/fintech/finverv/check-profile";
export const REGISTER_FINVERV_USER_URL =
  "fintech-service/api/v1/fintech/finverv/user";
export const GET_FINVERV_URL =
  "fintech-service/api/v1/fintech/finverv/user/key";
export const GET_FINVERV_LINE_URL =
  "fintech-service/api/v1/fintech/finverv/lines";
export const ORDER_LOAN_URL =
  "cart-command-side/api/v2/cart/consumer/finverv/loan-order";
export const FINVERV_APPLICATION_STATUS_URL =
  "fintech-service/api/v1/fintech/finverv/application-status";

export const ORDER_COD_CART_URL =
  "cart-command-side/api/v2/cart/consumer/orderCodCart";

export const VALIDATE_CODE_ATAG =
  "reward-query-side/api/v1/reseller/validate-atag?discountCode=";

export const CHECK_FINBOX_USER_URL =
  "fintech-service/api/v1/fintech/finbox/check-profile";
export const REGISTER_FINBOX_USER_URL =
  "fintech-service/api/v1/fintech/finbox/user";
export const FINBOX_PARTNER_PUSH_URL =
  "fintech-service/api/v1/fintech/finbox/push";
export const GENERATE_FINBOX_URL =
  "fintech-service/api/v1/fintech/finbox/session";
export const ELIGIBILITY_FINBOX_URL =
  "fintech-service/api/v1/fintech/finbox/eligibility";
export const BANNER_FINBOX_URL = "fintech-service/api/v1/fintech/finbox/banner";
export const CREDIT_LINE_FINBOX_URL =
  "fintech-service/api/v1/fintech/finbox/credit-line";
export const LOAN_ORDER_FINBOX_URL =
  "cart-command-side/api/v2/cart/consumer/finbox/loan-order";
export const TRANSACTION_FINBOX_URL =
  "fintech-service/api/v1/fintech/finbox/creditline/transactions";
// Affiliate

export const BUSINESS_INFO_URL =
  "business-query-side/api/v1/business/getBusinessInfo";

export const GET_RESELLER_PROFILE =
  "reward-query-side/api/v1/reseller/getResellerProfile";

export const VALIDATE_RESELLER_PROFILE_CODE =
  "reward-query-side/api/v1/reseller/checkResellerProfileCode?code=";

export const GET_GEO_POINT =
  "cart-query-side/api/v2/cart/consumer/getGeoPoint?address=";

export const ADD_RESELLER_PROFILE =
  "reward-query-side/api/v1/reseller/addResellerProfile";

export const ADD_RESELLER = "reward-query-side/api/v1/reseller/addReseller";

// export const GET_PAYMENT_TRANSACTIONS =
//   'cart-query-side/api/v1/resellerpayment/getPaymentTransactions'

export const GET_PAYMENT_TRANSACTIONS =
  "cart-query-side/api/v2/cart/consumer/ResellerPayments/ByBusiness";

export const GET_AFFILIATE_PERCENTAGE =
  "reward-query-side/api/v1/reseller/business/affiliate-percentage";

export const GET_AFFILIATE_PRODUCTS_BY_BID =
  "product-query-side/api/v1/consumer/product/affiliate-products?pageNo=";

export const GET_AFFILIATE_VALIDITY =
  "reward-query-side/api/v1/reseller/getAffiliateValidity?profileId=";

export const ADD_INFLUENCER_SHARE =
  "businesssocial-command-side/api/v1/businessSocial/addinfluencerShare";

export const CREATE_LOAN_ORDER_URL =
  "cart-command-side/api/v2/cart/consumer/finverv/loan-order";
export const UPDATE_RESELLER_BANK =
  "reward-query-side/api/v1/reseller/updateAffiliateBankInfo";

export const GET_REF_RATES_BY_BUSINESS =
  "reward-query-side/api/v1/reseller/business/getRefRatesbyBusiness";
export const UPDATE_GST_INFO =
  "account-command-side/api/v1/account/updateGstInfo";

export const BUYER_APP_LOOKUP =
  "lookup-service/api/v1/lookup/ondcBapDisplayName?subscriberId=ALL";

export const ALL_ACTIVE_PRODUCTS =
  "/product-query-side/api/v1/open/product/all/getAllProductsActive";
export const VARIANT_COUNT =
  "/product-query-side/api/v1/open/product/all/getVariantsCountActiveV2";
// "/product-query-side/api/v1/open/product/all/getVariantsCountActive";
export const CHILD_PRODUCTS =
  "/product-query-side/api/v1/open/product/all/getChildProductsActive";
export const PRODUCTBYCAT =
  "product-query-side/api/v1/open/product/group/getAllProductsBySubCat";
export const SIMILAR_PRODUCTS =
  "product-query-side/api/v1/open/product/getAllSimilarProducts";
export const CATEGORY_DETAILS =
  "product-query-side/api/v1/open/product/categoryAllProducts";

export const GET_UNICOM_INVENTORY_SNAPSHOT =
  "costbouaa/api/v1/open/business/uniCommerce/inventory/inventorySnapshot/get";
export const UNICOM_CREATE_SALE_ORDER =
  "costbouaa/api/v2/business/catalogPartner/uniCommerce/oms/saleOrder/create";
export const UNICOM_VERIFY_SALE_ORDER =
  "costbouaa/api/v2/business/catalogPartner/uniCommerce/oms/saleOrder/verify";

export const BUY_AGAIN_PRODUCT =
  "cart-query-side/api/v2/cart/consumer/buyAgain-products";
// "cart-query-side/api/v2/cart/consumer/buyAgain-variantProducts";
export const MULTI_PRODUCTS =
  "product-query-side/api/v1/open/product/viewMultiActiveProduct";

export const GET_MAIN_BRANCH =
  "business-query-side/api/v1/open/business/getMasterBranchInfo";
// export const GET_BRANCH_INFO = "business-query-side/api/v2/business/getBranchInfo";
export const GET_BRANCH_INFO =
  "business-query-side/api/v1/open/business/getBranchInfo";

export const ONDC_SPECIFICATION =
  "lookup-service/api/v1/open/getondcspecifications";
export const ONDC_FULL_SPECIFICATION =
  "lookup-service/api/v1/open/getondcMandatoryOptional";

export const ONDC_PRICING =
  "cart-query-side/api/v2/cart/consumer/delivery/price";

export const LINK_SHORTEN_URL = "notification-service/api/v1/notification/shorten-url"


// BuyMore
export const BUYMORE_INVENTORY_DATA = 'catalog-partner-service/api/v2/open/buyMore/InventoryData'
// export const BUYMORE_INVENTORY_DATA = 'catalog-partner-service/api/v2/business/buyMore/InventoryData'

export const GEOCODING_API = 'cart-query-side/api/v2/cart/consumer/maps/geocode/latToAddress'