import React, { useEffect, useState } from "react";
import { Box, Drawer, Stack, Typography, useMediaQuery } from "@mui/material";
import {
  blackColor,
  blackShade22,
  greenColor08,
  greyShade74,
  whiteColor,
} from "configs/styles/muiThemes";
import ProductMobilePopupVariantLoader from "components/skeletonLoaders/product/ProductMobilePopupVariantLoader";
import ProductTile from "./ProductTile";
import {
  getOndcFullSpecificationAPI,
  getOndcSpecificationAPI,
  getProductData,
} from "services/api_calls/productDescription/ProductDescriptionApi";
import { getViewChildProductDetailsAPI } from "services/api_calls/subCategory/SubCategoryApis";
import { preparePopupChildProducts } from "pages/SingleProduct/VariantProduct/VariantHelper";

function VariantPopupCard({
  product,
  cartData,
  onQtyUpdate,
  cartId,
  onAddProduct,
  onRemoveProduct,
  viewOnly,
  buyProduct,
  onClose,
}) {
  const [productDetails, setProductDetails] = useState(null);
  const [childProducts, setChildProducts] = useState([]);

  const [specification, setSpecification] = useState(null);
  const [fullSpecification, setFullSpecification] = useState(null);

  const [loader, setLoader] = useState(false);

  const getSpecificationData = async (product) => {
    const specificationPayload = {
      pscCode1: `${product?.productMainCategory?.[0]?.ondcCode}${product?.productSubCategory?.[0]?.ondcSubCode}`,
      status: "active",
    };
    const fullSpecificationPayload = {
      domainCode: product?.productMainCategory?.[0]?.ondcCode,
      status: "active",
    };
    const spec = await getOndcSpecificationAPI(specificationPayload);
    const fullSpec = await getOndcFullSpecificationAPI(
      fullSpecificationPayload
    );
    setSpecification(spec);
    setFullSpecification(fullSpec);
  };

  useEffect(() => {
    setLoader(true);
    const fetchMasterProduct = async () => {
      const data = await getProductData(
        product?.variantInfo?.masterProductVariantId
      );
      setProductDetails(data);
    };

    fetchMasterProduct();
  }, [product]);

  useEffect(() => {
    if (productDetails?.product) {
      getSpecificationData(productDetails?.product);
    }
  }, [productDetails, product]);

  useEffect(() => {
    if (
      productDetails?.product?.id !==
        product?.variantInfo?.masterProductVariantId ||
      !fullSpecification ||
      !specification
    )
      return;

    const fetchChildProducts = async () => {
      const childProductsData = await getViewChildProductDetailsAPI(
        product?.variantInfo?.masterProductVariantId
      );
      setChildProducts(
        preparePopupChildProducts(
          childProductsData?.details,
          productDetails?.product,
          specification,
          fullSpecification
        )
      );
      setLoader(false);
    };

    if (productDetails) fetchChildProducts();
  }, [productDetails, product, specification, fullSpecification]);
  return (
    <Stack
      gap={2}
      p={{ md: "20px 16px", xs: 0 }}
      sx={{ borderRadius: { xs: "auto", md: "20px" }, background: greyShade74 }}
    >
      <Typography
        fontSize={{ md: 14, xs: 13.5 }}
        fontWeight={600}
        lineHeight={1.5}
        color={blackShade22}
      >
        {product?.productName}
      </Typography>
      {loader ? (
        <ProductMobilePopupVariantLoader />
      ) : (
        <Stack gap={{ md: "12px", xs: "8px" }}>
          {childProducts?.map((item, index) => (
            <ProductTile
              key={index}
              originalAmt={item?.productOriginalPrice}
              amt={item?.productSalePrice}
              img={item?.productPhoto?.[0]?.docURL}
              variantList={item?.variantList}
              product={item}
              cartData={cartData}
              onQtyUpdate={onQtyUpdate}
              cartId={cartId}
              onAddProduct={onAddProduct}
              onRemoveProduct={onRemoveProduct}
              maxQty={item?.maxOrderQuantity}
              minQty={item?.minOrderQuantity}
              viewOnly={viewOnly}
              onClose={onClose}
            />
          ))}
        </Stack>
      )}
      {/* <Box
          sx={{
            background: greenColor08,
            p: "10px 15px",
            borderRadius: "10px",
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            fontSize={14}
            fontWeight={600}
            lineHeight={1.5}
            color={whiteColor}
          >
            Item Total: ₹ {cartData ? cartData?.orderTotalProductCost : 0}
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={600}
            lineHeight={1.5}
            color={whiteColor}
          >
            Confirm
          </Typography>
        </Box> */}
    </Stack>
  );
}

export default VariantPopupCard;
