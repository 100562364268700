import axios from "axios";

export const getBusinessIdData = async (data) => {
  try {
    const response = await axios.get(
      `https://web.costbo.com/business-query-side/api/v1/business/checkCostboWebURL?costboWebURL=${data}`
    );
    console.log("BUSINESS", response.data);
    const oldBusinnesId = sessionStorage.getItem("businessId");
    if (oldBusinnesId !== response?.data?.businessId) {
      sessionStorage.removeItem("totalViews");
      sessionStorage.removeItem("socialActivity");
      sessionStorage.removeItem("reviewSummary");
      sessionStorage.removeItem("businessInfo");
      sessionStorage.setItem("businessId", response?.data?.businessId);
    }

    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBusinessIdDataByDomain = async (domainName) => {
  try {
    const hostName = domainName?.replace(/(^\w+:|^)\/\/www\./, '$1//') || domainName;
    const response = await axios.get(
      `https://web.costbo.com/business-query-side/api/v1/open/business/getDomainBusinessInfo?domain=${hostName}`,
      {
        headers: {
          token: 'C@stb@1234',
          "Content-Type": "application/json",
        },
      }
    );
    console.log("BUSINESS", response.data);
    const oldBusinnesId = sessionStorage.getItem("businessId");

    const domainInfo = response?.data || null;

    if (domainInfo?.exists) {
      if (oldBusinnesId !== domainInfo?.details?.businessId) {
        sessionStorage.removeItem("totalViews");
        sessionStorage.removeItem("socialActivity");
        sessionStorage.removeItem("reviewSummary");
        sessionStorage.removeItem("businessInfo");
        sessionStorage.setItem("businessId", domainInfo?.details?.businessId);
      }
    }

    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
