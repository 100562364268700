import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Overview from "./pages/Overview";
import DealsCoupons from "./pages/DealsCoupons";
import Review from "./pages/Review";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CatalogMui from "./pages/CatalogMui";
import SingleProduct from "./pages/SingleProduct";
import Cart from "./pages/Cart";
import DiscountDelivery from "./pages/DiscountDelivery";
import Confirmation from "./pages/Confirmation";
import Dashboard from "./pages/Dashboard";
import AllOrders from "./pages/AllOrders";
import OpenOrders from "./pages/OpenOrders";
import CompletedOrders from "./pages/CompletedOrders";
import CanceledOrders from "./pages/CanceledOrders";
import OrderDetails from "./pages/OrderDetails";
import Search from "./pages/Search";
import OtherOrders from "pages/OtherOrders";
import TermsAndConditions from "pages/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ScrollToTop from "components/ScrollToTop";
import { useEffect, useState } from "react";
import { GlobalDebug } from "remove-consoles";
import { Suspense } from "react";
import ShopCostBoHome from "pages/ShopCostBoHome";
import UserAuth from "pages/UserAuth";
import { getAccountData } from "services/api_calls/dashboard_api/DashboardApi";
import { getAccessToken } from "utils/authHelpers";
import ReturnPolicy from "pages/ReturnPolicy";
import { getUserLocation } from "utils/geoLocationHelper";
import FullHeightLoader from "components/FullHeightLoader";
import { useDispatch } from "react-redux";
import { getCommercialDetails } from "redux/productDescription/ProductDescriptionSlice";
import { getBusinessIdData } from "services/api_calls/root/RootApi";
import ShareAndEarn from "./pages/ShareAndEarn";
import AffiliateEnroll from "./pages/AffiliateEnroll";
import AffiliateBankDetails from "pages/AffiliateBankDetails";
import AffiliatePrograms from "pages/AffiliatePrograms";
import PayCartConfirmation from "pages/PayCartConfirmation";
import PaycartDetails from "pages/PaycartDetails";
import AddAddress from "pages/DiscountDelivery/AddAddress";
import ProductDetail from "pages/ProductDetail";
import { domainCheckList, siteRoutes } from "utils/data";
import { getBusinessIdentifier } from "utils/urlHelper";

const isLogged = false;

const routes = (
  <Suspense fallback={<p></p>}>
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" exact element={<ShopCostBoHome />} />
          <Route path="/:businessName" exact element={<Home />} />
          <Route path="/:businessName/catalog" element={<CatalogMui />} />
          <Route path="/:businessName/overview" element={<Overview />} />
          <Route
            path="/:businessName/dealsCoupons"
            element={<DealsCoupons />}
          />
          <Route path="/:businessName/review" element={<Review />} />
          <Route
            path="/:businessName/singleProduct/:productId"
            element={<ProductDetail />}
            // element={<SingleProduct />}
          />
          <Route
            path="/:businessName/oldSingleProduct/:productId"
            element={<SingleProduct />}
          />
          <Route path="/:businessName/cart" element={<Cart />} />
          <Route
            path="/:businessName/discountDelivery"
            element={<DiscountDelivery />}
          />
          <Route path="/:businessName/addAddress" element={<AddAddress />} />

          <Route
            path="/:businessName/confirmation"
            element={<Confirmation />}
          />
          <Route
            path="/:businessName/easycartConfirmation"
            element={<PayCartConfirmation />}
          />
          <Route path="/:businessName/dashboard" element={<Dashboard />} />
          <Route path="/:businessName/allOrders" element={<AllOrders />} />
          <Route path="/:businessName/openOrders" element={<OpenOrders />} />
          <Route
            path="/:businessName/completedOrders"
            element={<CompletedOrders />}
          />
          <Route
            path="/:businessName/canceledOrders"
            element={<CanceledOrders />}
          />
          <Route path="/:businessName/otherOrders" element={<OtherOrders />} />
          <Route
            path="/:businessName/orderDetails/:cartId"
            element={<OrderDetails />}
          />
          <Route
            path="/:businessName/easycart/:paycartId"
            element={<PaycartDetails />}
          />

          <Route path="/:businessName/search" element={<Search />} />
          {/* SubDomain Auth */}
          <Route path="/:businessName/userauth" element={<UserAuth />} />
          <Route
            //  path='/:businessName/termsAndConditions'
            path="/termsAndConditions"
            element={<TermsAndConditions />}
          />
          <Route
            //  path='/:businessName/privacyPolicy'
            path="/privacyPolicy"
            element={<PrivacyPolicy />}
            // element={
            //   isLogged ? (
            //     <PrivacyPolicy />
            //   ) : (
            //     <Navigate to="/itree" replace />
            //   )
            // }
          />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/cancellation-policy" element={<ReturnPolicy />} />

          {/* Affiliate */}
          <Route path="/:businessName/affiliates" element={<ShareAndEarn />} />
          <Route
            path="/:businessName/affiliateEnroll"
            element={<AffiliateEnroll />}
          />
          <Route
            path="/:businessName/affiliateBankDetails"
            exact
            element={<AffiliateBankDetails />}
          />
          <Route
            path="/:businessName/affiliateHome"
            exact
            element={<AffiliatePrograms />}
          />
        </Routes>
      </ScrollToTop>
    </Router>
  </Suspense>
);

const domainRoutes = (
  <Suspense fallback={<p></p>}>
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" exact element={<Home isDomainUrl={true} />} />
          <Route path="/catalog" element={<CatalogMui isDomainUrl={true} />} />
          <Route path="/overview" element={<Overview isDomainUrl={true} />} />
          <Route path="/dealsCoupons" element={<DealsCoupons isDomainUrl={true} />} />
          <Route path="/review" element={<Review isDomainUrl={true} />} />
          <Route path="/singleProduct/:productId" element={<ProductDetail isDomainUrl={true} />} />
          <Route path="/cart" element={<Cart isDomainUrl={true} />} />
          <Route path="/discountDelivery" element={<DiscountDelivery />} />
          <Route path="/addAddress" element={<AddAddress />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/easycartConfirmation" element={<PayCartConfirmation />} />
          <Route path="/dashboard" element={<Dashboard isDomainUrl={true}/>} />
          <Route path="/allOrders" element={<AllOrders isDomainUrl={true} />} />
          <Route path="/openOrders" element={<OpenOrders isDomainUrl={true} />} />
          <Route path="/completedOrders" element={<CompletedOrders isDomainUrl={true} />} />
          <Route path="/canceledOrders" element={<CanceledOrders isDomainUrl={true} />} />
          <Route path="/otherOrders" element={<OtherOrders />} />
          <Route path="/orderDetails/:cartId" element={<OrderDetails />} />
          <Route path="/easycart/:paycartId" element={<PaycartDetails />} />
          <Route path="/search" element={<Search />} />
          {/* SubDomain Auth */}
          <Route path="/userauth" element={<UserAuth />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/cancellation-policy" element={<ReturnPolicy />} />
          {/* Affiliate */}
          <Route path="/affiliates" element={<ShareAndEarn isDomainUrl={true} />} />
          <Route path="/affiliateEnroll" element={<AffiliateEnroll />} />
          <Route path="/affiliateBankDetails" exact element={<AffiliateBankDetails />} />
          <Route path="/affiliateHome" exact element={<AffiliatePrograms isDomainUrl={true} />} />
        </Routes>
      </ScrollToTop>
    </Router>
  </Suspense>
);

function App() {
  const dispatch = useDispatch();

  const [currentRoutes, setCurrentRoutes] = useState(null)

  const checkTokenValid = async () => {
    const currentUrl = window.location.hostname;
    const businessName = sessionStorage.getItem("businessName");
    const token = getAccessToken();

    if (token) {
      const response = await getAccountData();

      if (response?.status === 401) {
        sessionStorage.clear();
        localStorage.clear();

        if (businessName) {
          // window.location.replace(`https://shop.costbo.com/${businessName}`)
          window.location.replace(`https://shop.costbo.com/${businessName}`);
        } else {
          window.location.replace(`https://shop.costbo.com/`);
        }
      }

      console.log("TOKEN_CHECK:", response);
      console.log("TOKEN_CHECK_HOSTNAME:", currentUrl);
    }
  };

  // function to determine whether to costboWebUrl
  const prepareDomainRoutes = ()=>{
    const hostname = window.location.hostname;
    const url = window.location?.href || ''

    const businessIdentifier = getBusinessIdentifier(url) || ''
    const costBoDomains = domainCheckList

    // const isCostBoDomain = costBoDomains?.includes(hostname) || hostname?.includes('costbo.com')
    const isCostBoDomain =  hostname?.includes('costbo.com')
    const isSubBranch = siteRoutes?.includes(businessIdentifier?.toLowerCase())

    console.log("NAVIGATE_TO", {
      businessIdentifier, 
      costBoDomains, 
      isCostBoDomain,
      isSubBranch,
    });

    // Determine routes based on conditions
    if (isCostBoDomain) {
      setCurrentRoutes(routes);
    } else {
      setCurrentRoutes(isSubBranch ? domainRoutes : routes);
    }

    // console.log("NAVIGATE_TO", {
    //   businessIdentifier, 
    //   costBoDomains, 
    //   isCostBoDomain,
    //   isSubBranch,
    // });

    // // if the hostname present in domainCheckList, use costBoWebUrl
    // if(isCostBoDomain){
    //   console.log("NAVIGATE_TO", "no-domain");
    //   setCurrentRoutes(routes)
    // } else if (!isCostBoDomain){
    //   if(isSubBranch){
    //     console.log("NAVIGATE_TO", "domain--");
    //     setCurrentRoutes(domainRoutes)
    //   }else{
    //     console.log("NAVIGATE_TO", "sub-domain");
    //     setCurrentRoutes(routes)
    //   }
    // }
    // else{
    //   console.log("NAVIGATE_TO", "domain");
    //   setCurrentRoutes(domainRoutes)
    // }
  }

  useEffect(() => {
    if (
      window.location.hostname !== "prelive-shop.costbo.com" &&
      window.location.hostname !== "prelive-ondc.costbo.com" &&
      window.location.hostname !== "localhost"
    ) {
      const handleKeyDown = (e) => {
        // Disable shortcuts for DevTools (macOS and Windows)
        if (
          (e.metaKey &&
            e.altKey &&
            (e.key === "I" || e.key === "J" || e.key === "C")) || // macOS DevTools shortcuts
          (e.ctrlKey &&
            e.shiftKey &&
            (e.key === "I" || e.key === "J" || e.key === "C")) || // Windows/Linux DevTools shortcuts
          e.key === "F12" || // Universal F12 key
          (e.metaKey && e.key === "U") || // macOS View Source
          (e.ctrlKey && e.key === "U") // Windows/Linux View Source
        ) {
          e.preventDefault();
          alert("This action is disabled!");
        }
      };

      const handleContextMenu = (e) => {
        e.preventDefault(); // Disable right-click menu
      };

      // Add event listeners
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("contextmenu", handleContextMenu);

      // Cleanup event listeners on unmount
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("contextmenu", handleContextMenu);
      };
    }
  }, []);

  useEffect(() => {
    process.env.REACT_APP_ENV === "STAGING" && GlobalDebug(false);
    // console.log = () => {}
    console.warn = () => {};
    // checkTokenValid();
    prepareDomainRoutes()
    getUserLocation();
  }, []);

  // return <div className="main-wrapper">{routes}</div>;
  return <div className="main-wrapper">{currentRoutes}</div>;
}

export default App;
