import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import {
  blackColor,
  greenColor05,
  greyShade41,
  headingColor,
  lightGreenShade02,
  whiteColor,
} from "configs/styles/muiThemes";
import React from "react";

const MarkerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#1C1C1C"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    aria-labelledby="icon-svg-title- icon-svg-desc-"
    role="img"
    class="sc-rbbb40-0 haYhpV"
  >
    <title>loction-pin</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.50376 0.135614C5.50502 0.734541 3.10602 3.13025 2.60623 6.12488C2.00648 9.81826 4.00564 13.1123 7.10435 14.3102C8.10393 14.7095 9.00355 15.4082 9.50334 16.4064C9.70326 16.7059 9.80322 17.1052 10.0031 17.4047C10.1031 17.1052 10.303 16.7059 10.5029 16.4064C11.0027 15.4082 11.9023 14.8093 12.9019 14.3102C15.6008 13.2122 17.5 10.517 17.5 7.42255C17.5 2.9306 13.3018 -0.762775 8.50376 0.135614ZM10.0031 10.0179C8.60372 10.0179 7.50418 8.91987 7.50418 7.52237C7.50418 6.12488 8.60372 5.02685 10.0031 5.02685C11.4025 5.02685 12.5021 6.12488 12.5021 7.52237C12.5021 8.91987 11.4025 10.0179 10.0031 10.0179Z"
    ></path>
    <path
      fill="#10847e"
      d="M10.0033 20C11.1074 20 12.0025 19.5531 12.0025 19.0018C12.0025 18.4505 11.1074 18.0036 10.0033 18.0036C8.89921 18.0036 8.00415 18.4505 8.00415 19.0018C8.00415 19.5531 8.89921 20 10.0033 20Z"
    ></path>
  </svg>
);

const AddressConfirmAlert = ({
  title,
  content,
  address,
  onClose,
  onConfirm,
  city,
  state,
  pincode,
  isOpen,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          // padding: 1,
          width: { xs: "90%", md: "32%" },
          textAlign: "center",
          borderRadius: "10px",
          mt: -10,
        },
      }}
    >
      <Box
        sx={{
          borderRadius: "15px 15px 0px 0px",
          padding: "10px 15px",
          textAlign: "center",
          bgcolor: greyShade41,
        }}
      >
        <Typography
          textAlign="center"
          fontSize={14}
          fontWeight={600}
          lineHeight="28px"
          color={headingColor}
        >
          {title}
        </Typography>
      </Box>
      <DialogContent sx={{ p: "15px" }}>
        <DialogContentText lineHeight="20px" textAlign="left" py="5px">
          {/* <Typography
            fontSize={13}
            fontWeight={500}
            color={blackColor}
            textAlign="center"
          >
            {content}
          </Typography> */}

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "9px",
              bgcolor: lightGreenShade02,
              p: "10px 8px",
              borderRadius: "6px",
              // mt: 2,
            }}
          >
            <Box
              width={{ xs: "19px", md: "21px" }}
              height={{ xs: "19px", md: "21px" }}
              mt={0.2}
            >
              <MarkerIcon />
            </Box>

            <Typography
              fontSize={{ xs: 11.5, md: 12 }}
              fontWeight={600}
              color={greenColor05}
              lineHeight="20px"
              letterSpacing="0px"
            >
              {address}
            </Typography>
          </Box>

          <Typography
            fontSize={13}
            fontWeight={400}
            color={blackColor}
            textAlign="center"
            mt={2}
          >
            Your order will be delivered to the above address in {" "}<br />
            <Typography fontSize={13} fontWeight={600} >
              {city}, {state} - {pincode}
            </Typography>
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ bgcolor: greyShade41, p: "10px 15px" }}>
        <Button onClick={onClose} variant="outlined" sx={styles.alertBtn}>
          NO
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          disableElevation
          sx={{
            ...styles.alertBtn,
            color: whiteColor,
            backgroundColor: blackColor,
            "&:hover": {
              backgroundColor: whiteColor,
              color: blackColor,
            },
          }}
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddressConfirmAlert;

const styles = {
  alertBtn: {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "20px",
    p: "4px 20px",
    color: blackColor,
    border: `1px solid ${blackColor}`,
    bgcolor: whiteColor,
    mr: "8px",
    textTransform: "uppercase",
    "&:hover": {
      border: `1px solid ${blackColor}`,
      backgroundColor: blackColor,
      color: whiteColor,
    },
  },
};
