import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  blackColor,
  blackShade19,
  borderCard05,
  borderTextbox,
  greyColor3,
  loginPrimary,
  soldTextColor,
  textBtnColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  addInfluencerShareAPI,
  addResellerOnShareAPI,
  getAffiliateValidityApi,
  getShortLink,
} from "../../services/api_calls/affiliate/AffiliateApi";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AffiliateShareMenu from "components/SocialShareMenu/AffiliateShareMenu";
import { textOneLines } from "utils/styleUtility";
import { getOperatingSystem } from "utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import {
  getRefRatesByBusinessID,
  updateAffiliateValidity,
} from "redux/affiliate/AffiliateSlice";
import ModalCard from "components/ModalCard";

function AffiliateShareModal({
  productDetails,
  commissionDetails,
  rellerProfileData,
  influencerShareType,
  open,
  onClose,
  isProductShare,
}) {
  const dispatch = useDispatch();

  const businessId = sessionStorage.getItem("businessId");
  const businessName = sessionStorage.getItem("businessName");
  const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));
  const [isAffiliateExist, setIsAffiliateExist] = useState(false);
  const [shortShareLink, setShortShareLink] = useState("");

  const [isCopied, setIsCopied] = useState(false);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { businessAffiliateData, affiliateValidity } = useSelector(
    (state) => state.affiliate
  );

  // Fetching Affiliate Percentage and Validing to Display
  const [affiCustomerPercentage, setAffiCustomerPercentage] = useState(0);
  const [affiCommissionPercentage, setAffiCommissionPercentage] = useState(0);

  const getAffiliatePercentage = async () => {
    const affiliateCommissionPer = affiliateValidity?.commissionPercentage || 0;
    const affiliateCustomerPer = affiliateValidity?.discountPercentage || 0;

    const businessCommissionPer =
      businessAffiliateData?.businessResellers?.[0]?.affiliatePercentage || 0;
    const businessCustomerPer =
      businessAffiliateData?.businessResellers?.[0]?.consumerPercentage || 0;

    if (affiliateCommissionPer > 0 && affiliateCustomerPer > 0) {
      setAffiCommissionPercentage(affiliateCommissionPer);
      setAffiCustomerPercentage(affiliateCustomerPer);
    } else {
      setAffiCommissionPercentage(businessCommissionPer);
      setAffiCustomerPercentage(businessCustomerPer);
    }

    console.log(
      "getAffiliatePercentage_affiliateCommissionPer_affiliateCustomerPer",
      affiliateCommissionPer,
      "_",
      affiliateCustomerPer
    );
    console.log(
      "getAffiliatePercentage_businessCommissionPer_businessCustomerPer",
      businessCommissionPer,
      "_",
      businessCustomerPer
    );

    console.log("getAffiliatePercentage_VALID", affiliateValidity);
    console.log("getAffiliatePercentage_B_PERCENTAGE", businessAffiliateData);
  };

  useEffect(() => {
    getAffiliatePercentage();
  }, [businessAffiliateData, affiliateValidity]);

  // Affiliate Share
  const whatsappShareRef = useRef(null);
  const faceBookShareRef = useRef(null);
  const twitterShareRef = useRef(null);
  const linkedInShareRef = useRef(null);
  const telegramShareRef = useRef(null);

  // generate short share link
  const generateShortShareLink = async () => {
    const businessData = {
      name: businessInfo?.[0]?.businessName,
      costboWebURL: businessName,
    };

    const res = await getShortLink({
      businessData,
      rellerProfileData: rellerProfileData?.details,
      productId: productDetails?.id,
      isProductShare,
    });

    if (res?.shortURL) {
      setShortShareLink(res?.shortURL);
      return res?.shortURL;
    } else {
      const link = `https://shop.costbo.com/${businessName}?atag=${rellerProfileData?.details?.resellerCode}`;
      setShortShareLink(link);
      return link;
    }
  };

  const openShareModal = (shareMedium) => {
    if (shareMedium === "whatsapp") {
      whatsappShareRef.current.click();
    } else if (shareMedium === "facebook") {
      faceBookShareRef.current.click();
    } else if (shareMedium === "linkedIn") {
      linkedInShareRef.current.click();
    } else if (shareMedium === "twitter") {
      twitterShareRef.current.click();
    } else if (shareMedium === "telegram") {
      telegramShareRef.current.click();
    }
  };

  const handleShareClick = async (shareMedium, isCopy) => {
    const businessData = businessInfo?.[0];
    const commissionData = commissionDetails?.businessResellers?.[0];
    const commerceInfo = JSON.parse(sessionStorage.getItem("commerceInfo"));

    // POST API Data
    // const commissionPostData = {
    //   commissionPercentage: commissionData?.affiliatePercentage,
    //   discountPercentage: commissionData?.consumerPercentage,
    // }
    const commissionPostData = {
      commissionPercentage: affiCommissionPercentage,
      discountPercentage: affiCustomerPercentage,
    };

    const businessPostData = {
      id: businessData?.id,
      name: businessData?.businessName,
      logoURL: businessData?.logoURL,
      costboWebURL: businessName,
      businessCategories: businessData?.businessCategories,
      documentsURL: businessData?.documentsURL,
      mainProducts: businessData?.mainProducts,
      businessArea: commerceInfo?.info?.businessArea,
      description: "",
    };

    const productPostData = {
      ...productDetails,
      productId: productDetails?.id,
      name: productDetails?.productName,
    };

    let shortLink = shortShareLink || "";

    if (!shortShareLink) {
      shortLink = await generateShortShareLink();
    }

    if (isCopy) {
      navigator.clipboard.writeText(shortLink);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } else {
      setTimeout(() => {
        openShareModal(shareMedium);
      }, 1000);
    }

    if (!isAffiliateExist) {
      // const isAffiliateValid = await getAffiliateValidityApi(businessData?.id)
      const isAffiliateValid = affiliateValidity;

      console.log("isAffiliateExist_____:", {
        isAffiliateExist,
        affiliateValidity,
      });

      if (isAffiliateValid?.affiliateexist === false) {
        const response = await addResellerOnShareAPI({
          productDetails: productPostData,
          commissionDetails: commissionPostData,
          rellerProfileData: rellerProfileData?.details,
          businessData: businessPostData,
        });

        if (response) {
          setIsAffiliateExist(true);
          dispatch(
            updateAffiliateValidity({
              ...affiliateValidity,
              affiliateexist: true,
            })
          );
        }
      } else {
        setIsAffiliateExist(true);
      }
    }

    const deviceName = getOperatingSystem(window);
    const shareResponse = await addInfluencerShareAPI({
      productDetails: productPostData,
      commissionDetails: commissionPostData,
      businessData: businessPostData,
      deviceName,
      shareMedium: influencerShareType ? influencerShareType : shareMedium,
    });
  };

  useEffect(() => {
    return () => {
      setShortShareLink("");
      setIsAffiliateExist(false);
    };
  }, []);

  return (
    <ModalCard handleClose={onClose} open={open} width="35%">
      <Box sx={styles.shareCard}>
        <Box sx={styles.shareHeader}>
          <Typography
            variant="h6"
            fontSize={17}
            fontWeight={600}
            color={blackColor}
          >
            Share
          </Typography>

          <Box display="flex" alignItems="center" gap="14px">
            {/* {shortShareLink && ( */}
            <Box
              component="a"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                fontSize: { xs: 13, md: 13 },
                fontWeight: 600,
                color: blackColor,
                cursor: "pointer",
                // mt: 0.5,
                mr: 3,
              }}
              onClick={() => handleShareClick("shareLink", true)}
            >
              Copy Share Link
              {isCopied ? (
                <TaskAltIcon
                  sx={{ fontSize: { xs: 16, md: 16 }, color: blackColor }}
                />
              ) : (
                <ContentCopyIcon
                  sx={{ fontSize: { xs: 16, md: 16 }, color: blackColor }}
                />
              )}
            </Box>
            {/* )} */}

            {/* <IconButton size="small" onClick={onClose}>
                <CloseRoundedIcon />
              </IconButton> */}
          </Box>
        </Box>

        <Box p="10px 16px">
          <Grid container columnSpacing={{ md: 2, xs: 1 }}>
            <Grid item xs={7}>
              <Box mt={1}>
                {influencerShareType === "business" ? (
                  <></>
                ) : (
                  <>
                    <Typography
                      variant="h5"
                      fontSize={{ md: 15, xs: 12 }}
                      fontWeight={600}
                      lineHeight={{ md: "24px", xs: "18px" }}
                      color={blackColor}
                      // sx={{ ...textOneLines }}
                    >
                      {productDetails?.productName}
                    </Typography>

                    {/* <Typography
                        variant="body2"
                        fontSize={13}
                        fontWeight={500}
                        m="3px 0"
                        sx={{
                          fontSize: "12px",
                          padding: "4px 4px",
                          borderRadius: "50px",
                          color: "#cf2e2e",
                          backgroundColor: "#e546461c",
                          textAlign: "center",
                          fontWeight: 500,
                          position: "relative",
                          width: "20%",
                        }}
                      >
                        Sold By
                      </Typography> */}
                  </>
                )}

                {businessInfo?.[0]?.businessName && (
                  <Box
                    display="flex"
                    flexDirection={
                      influencerShareType === "business" ? "column" : "row"
                    }
                    alignItems={"center"}
                    // justifyContent={influencerShareType === 'business' ? 'center' : 'flex-start'}
                    gap="12px"
                    mt={2}
                  >
                    <Box
                      component="img"
                      src={businessInfo?.[0]?.logoURL}
                      alt="costBo"
                      sx={{
                        width: {
                          md: influencerShareType === "business" ? 48 : 48,
                          xs: 24,
                        },
                        height: {
                          md: influencerShareType === "business" ? 48 : 48,
                          xs: 24,
                        },
                        borderRadius: "8px",
                        border: borderCard05,
                        p: "5px",
                      }}
                    />

                    <Typography
                      variant="h6"
                      fontSize={{ md: 14, xs: 13 }}
                      fontWeight={600}
                      lineHeight="18px"
                      color={blackColor}
                    >
                      {businessInfo?.[0]?.businessName}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={5}>
              <Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{
                    position: "relative",
                    top: "8px",
                    borderRadius: "8px",
                    padding: { md: "10px 15px", xs: "10px 7px" },
                    background:
                      "linear-gradient(90.19deg, #d9f0e5 3.48%, #deecfc 103.06%)",
                    border: "1px solid #bfeddd",
                  }}
                >
                  <Typography
                    variant="body2"
                    fontSize={{ md: 13, xs: 12 }}
                    fontWeight={500}
                    lineHeight={{ md: "22px", xs: "18px" }}
                    color={blackShade19}
                    textAlign="center"
                  >
                    Your Commission
                  </Typography>

                  {/* <Box sx={styles.precentageBox('rgb(1, 164, 181)')}>
                      {
                        commissionDetails?.businessResellers?.[0]
                          ?.affiliatePercentage
                      }
                      %
                    </Box> */}
                  <Box sx={styles.precentageBox(whiteColor)}>
                    {affiCommissionPercentage}%
                  </Box>

                  <Typography
                    variant="body2"
                    fontSize={{ md: 13, xs: 12 }}
                    fontWeight={500}
                    lineHeight={{ md: "22px", xs: "18px" }}
                    color={blackShade19}
                    textAlign="center"
                    mt={1.5}
                  >
                    Buyer Discount
                  </Typography>

                  {/* <Box sx={styles.precentageBox(textBtnColor)}>
                      {
                        commissionDetails?.businessResellers?.[0]
                          ?.consumerPercentage
                      }
                      %
                    </Box> */}
                  <Box sx={styles.precentageBox(whiteColor)}>
                    {affiCustomerPercentage}%
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          p="10px 16px"
          mt={{ md: 3, xs: 1.5 }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0px 0px 15px 15px ",
            padding: { md: "10px 30px", xs: "10px" },
            background: "#f5f6f8",
          }}
        >
          <AffiliateShareMenu
            url={shortShareLink}
            isSpaceBetween
            // quote={`${
            //   influencerShareType === 'business'
            //     ? ''
            //     : `${productDetails?.productName} at ₹${productDetails?.productSalePrice}\n\n`
            // }GET additional ${
            //   commissionDetails?.businessResellers?.[0]?.consumerPercentage
            // }% OFF on all products \nUse below link for instant discount!`}

            quote={`${
              influencerShareType === "business"
                ? ""
                : `${productDetails?.productName} at ₹${productDetails?.productSalePrice}\n\n`
            }${
              affiCustomerPercentage == 0
                ? `Buy authentic products directly from our Brand store at lower prices!`
                : `GET additional ${affiCustomerPercentage}% OFF on all products \nUse below link for instant discount!`
            }`}
            onClick={handleShareClick}
            whatsappShareRef={whatsappShareRef}
            faceBookShareRef={faceBookShareRef}
            twitterShareRef={twitterShareRef}
            linkedInShareRef={linkedInShareRef}
            telegramShareRef={telegramShareRef}
          />
        </Box>
      </Box>
    </ModalCard>
  );
}

export default AffiliateShareModal;

const styles = {
  // shareConatiner: {
  //   // width: { xs: "80%", md: "504px" },
  //   // position: "absolute",
  //   // top: { xs: "", md: "50%" },
  //   // left: { xs: "", md: "50%" },
  //   // bottom: { xs: "0px", md: "" },
  //   // transform: { xs: "", md: "translate(-50%, -50%)" },
  //   // borderRadius: "10px",
  //   // overflow:"hidden",
  //   // boxShadow: 24,
  // },

  shareCard: {
    // height: '40vh',
    backgroundColor: whiteColor,
    borderRadius: { xs: "16px", md: "16px" },
  },

  shareHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 12px 10px 16px",
    // borderBottom: borderTextbox,
    borderRadius: "15px 15px 0px 0px",
    // width: { xs: '100%', md: '504px' },
    padding: "10px 15px",
    textAlign: "left",
    background: "#f5f6f8",
  },

  precentageBox: (bgColor) => ({
    width: { md: 35, xs: 30 },
    height: { md: 35, xs: 30 },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: 600,
    borderRadius: "5px",
    color: blackColor,
    backgroundColor: bgColor,
    marginTop: "4px",
  }),
};
