import { PrivacyTip, Delete } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  useMediaQuery,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import {
  noProductImgUrl,
  productNotAvailabelMsg,
  productPriceChangeMsg,
} from "configs/Constants";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  backgroundColor01,
  blackColor,
  blackShade05,
  borderLineColor,
  catalogSubHeaderColor,
  categoryLabelColor,
  delectIconInactiveColor,
  greenColor03,
  greenColor05,
  greyShade52,
  headingColor,
  lightGreen02,
  lightGreenShade01,
  navLinkColor,
  offerTextColor,
  orangeColor01,
  outOfStockTextColor,
  redColor02,
  savingTextColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import QuantityPicker from "../CatalogMui/QuantityPicker";
import CartQuantityPicker from "./CartQuantityPicker";
import { textOneLines, textTwoLines } from "utils/styleUtility";
import CheckIcon from "@mui/icons-material/Check";
import { getDiscountPercentage } from "utils/utilities";
import Skeleton from "react-loading-skeleton";
import { navigateTo } from "utils/urlHelper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      color: whiteColor,
      backgroundColor: "#30363c",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
      "& .MuiMenuItem-root.Mui-selected": {
        backgroundColor: "#007bff", // Sets background color for selected item
        "&:hover": {
          backgroundColor: whiteColor, // Sets background color on hover
        },
      },
    },
  },
};
function CartProductCard({
  image,
  productName,
  caterogy,
  oldUnitPrice,
  unitprice,
  discount,
  price,
  saving,
  quantity,
  isDiscount,
  isOutofStock,
  onDeleteProduct,
  readOnly,
  minQty,
  maxQty,
  cartData,
  cartId,
  product,
  orderProduct,
  onQtyUpdate,
  isPriceChange,
  isEcommerceDisabled,
  minQtyCheck,
  maxQtyCheck,
  validateCart,
  onRemoveProduct,
  onPriceUpdate,
  weightZeroError,
  isOfferOrderPresent,
  productId,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  //For Ui count update
  const [qtyCount, setQtyCount] = useState(quantity);
  const businessName = sessionStorage.getItem("businessName");
  let businessFullName = sessionStorage.getItem("businessFullName");

  const [isImgFailed, setIsImgFailed] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    setQtyCount(quantity);
  }, [productName]);

  const getDeleteIconColor = () => {
    if (!validateCart) {
      return false;
    }

    return (
      isEcommerceDisabled ||
      isPriceChange ||
      minQtyCheck ||
      maxQtyCheck ||
      weightZeroError ||
      isOfferOrderPresent
    );
  };
  const handleChange = (event) => {
    // setQuantity(event.target.value)
    onQtyUpdate({
      cartId: cartId,
      productId: product?.productId,
      // quantity: quantity - 1,
      quantity: event.target.value,
    });
  };
  return matches ? (
    <Box
      sx={{
        padding: "20px 20px",
        display: "flex",
        borderBottom: "1px solid #e8e8f0",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        alignItems={{
          xs: "flex-start",
          md:
            isPriceChange ||
            minQtyCheck ||
            weightZeroError ||
            isOfferOrderPresent
              ? "center"
              : "center",
        }}
        spacing={{ md: 0, xs: 1 }}
        gap={2.5}
      >
        <Grid item md={1} xs={12}>
          <Box
            // component={Link}
            // to={`/${businessName}/singleProduct/${productId}`}
            onClick={()=>{
              navigateTo(navigate, `singleProduct/${productId}`)
            }}
          >
            <Box
              component="img"
              src={image}
              alt={businessFullName + " CostBo"}
              backgroundColor={whiteColor}
              sx={styles.productImg}
              onError={({ currentTarget }) => {
                if (!isImgFailed) {
                  currentTarget.src = noProductImgUrl;
                  setIsImgFailed(true);
                } else {
                  // Both Images are failing to Load
                }
              }}
            />
          </Box>
        </Grid>
        <Grid item md={5.6} xs={12} alignItems={"center"}>
          <Stack>
            {/* <Typography
              variant='subtitle1'
              fontSize='13px'
              color={categoryLabelColor}
            >
              By Catalog
              {JSON.stringify(validateCart)} 
            </Typography>*/}
            <Typography
              variant="h6"
              component="h4"
              fontSize={{ xs: 13, md: 13 }}
              fontWeight={500}
              lineHeight="24px"
              color={blackColor}
              // mt={-0.7}
            >
              {productName}
            </Typography>
            <Box display={"flex"} gap={1}>
              {oldUnitPrice !== unitprice ? (
                <Typography
                  color={greyShade52}
                  fontSize={13}
                  fontWeight={500}
                  lineHeight={"24px"}
                >
                  MRP ₹ {Number(oldUnitPrice).toFixed(2)}
                </Typography>
              ) : null}
              <Typography
                color={blackColor}
                fontSize={15}
                fontWeight={600}
                lineHeight={"24px"}
              >
                ₹ {Number(unitprice).toFixed(2)}
              </Typography>
              {oldUnitPrice !== unitprice ? (
                <Typography
                  variant="subtitle2"
                  component="p"
                  color={greenColor03}
                  mt={0.3}
                  fontSize={13}
                >
                  {getDiscountPercentage(unitprice, oldUnitPrice)}% OFF
                </Typography>
              ) : null}
            </Box>
            {validateCart ? (
              <>
                <Box display={"flex"} alignItems={"center"}>
                  <Box
                    sx={{
                      // width: '80%',
                      background: "#fff8e3",
                      borderRadius: "4px",
                      marginRight: "8px",
                      marginTop: "8px",
                      border: "1px solid #e6ebf4",
                      width: "fit-content",
                    }}
                  >
                    {/* Price Change Check */}
                    {isPriceChange ? (
                      <>
                        <Typography
                          sx={{
                            color: blackColor,
                            fontWeight: 500,
                            fontSize: "11.5px",
                            lineHeight: "20px",
                            padding: "2px 8px",
                          }}
                        >
                          {productPriceChangeMsg}{" "}
                          {Number(orderProduct?.productSalePrice).toFixed(2)}
                        </Typography>
                      </>
                    ) : null}
                    {/* Ecommerce Enable Check */}
                    {isEcommerceDisabled || weightZeroError ? (
                      <Typography
                        sx={{
                          color: blackColor,
                          fontWeight: 500,
                          fontSize: "11.5px",
                          lineHeight: "20px",
                          padding: "2px 8px",
                        }}
                      >
                        {productNotAvailabelMsg}
                      </Typography>
                    ) : null}
                    {/* Offer Order Already Purchased Check */}
                    {isOfferOrderPresent ? (
                      <>
                        <Typography
                          sx={{
                            color: blackColor,
                            fontWeight: 500,
                            fontSize: "11.5px",
                            lineHeight: "20px",
                            padding: "2px 8px",
                          }}
                        >
                          Promotional Products are in limited quantity per
                          person, you could try other products in cart
                        </Typography>
                      </>
                    ) : null}
                    {/* Min Order Qty Check */}
                    {!isEcommerceDisabled ? (
                      minQtyCheck ? (
                        <>
                          <Typography
                            sx={{
                              color: blackColor,
                              fontWeight: 500,
                              fontSize: "11.5px",
                              lineHeight: "20px",
                              padding: "2px 8px",
                            }}
                          >
                            Minimum order quantity is{" "}
                            {orderProduct?.minOrderQuantity}. Please update the
                            quantity.
                          </Typography>
                        </>
                      ) : null
                    ) : null}
                    {/* Max Order Qty Check */}
                    {!isEcommerceDisabled ? (
                      maxQtyCheck ? (
                        <>
                          <Typography
                            sx={{
                              color: blackColor,
                              fontWeight: 500,
                              fontSize: "11.5px",
                              lineHeight: "20px",
                              padding: "2px 8px",
                            }}
                          >
                            Current order quatity is {quantity}, Maximum order
                            quantity is {orderProduct?.maxOrderQuantity}. Please
                            update the quantity.
                          </Typography>
                        </>
                      ) : null
                    ) : null}
                  </Box>
                  <Box>
                    {isPriceChange ? (
                      <Button
                        sx={{ ...styles.applyBtm }}
                        variant="contained"
                        disableElevation
                        onClick={() => {
                          onPriceUpdate();
                        }}
                      >
                        Apply
                      </Button>
                    ) : null}
                    {!isEcommerceDisabled ? (
                      minQtyCheck ? (
                        <Button
                          sx={{ ...styles.applyBtm }}
                          variant="contained"
                          disableElevation
                          onClick={() => {
                            onQtyUpdate({
                              cartId: cartId,
                              productId: product?.productId,
                              quantity: orderProduct?.minOrderQuantity,
                            });
                          }}
                        >
                          Apply
                        </Button>
                      ) : null
                    ) : null}
                    {!isEcommerceDisabled ? (
                      maxQtyCheck ? (
                        <Button
                          sx={{ ...styles.applyBtm }}
                          variant="contained"
                          disableElevation
                          onClick={() => {
                            onQtyUpdate({
                              cartId: cartId,
                              productId: product?.productId,
                              quantity: orderProduct?.maxOrderQuantity,
                            });
                          }}
                          backgroundColor={blackColor}
                        >
                          Apply
                        </Button>
                      ) : null
                    ) : null}
                  </Box>
                </Box>
              </>
            ) : null}
          </Stack>
        </Grid>

        <Grid item md={2} xs={12} alignItems={"center"}>
          <Box textAlign="center" mt={1}>
            <Typography
              fontSize={12}
              fontWeight={400}
              lineHeight="24px"
              color={greyShade52}
            >
              Total Price
            </Typography>

            <Typography
              fontSize={14}
              fontWeight={600}
              lineHeight="16px"
              color={blackColor}
              p="2px 0 3px"
            >
              ₹ {Number(Number(unitprice) * Number(quantity)).toFixed(2)}
            </Typography>

            <Typography
              fontSize={12}
              fontWeight={400}
              lineHeight="24px"
              color={greyShade52}
            >
              Savings{" "}
              <Typography
                component="span"
                color={greenColor05}
                fontWeight={600}
              >
                ₹ {Number(saving).toFixed(2)}
              </Typography>
            </Typography>
          </Box>
        </Grid>

        <Grid item md={2.4} xs={5} alignItems={"center"} mt={2}>
          <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
            <Box>
              {minQty && maxQty ? (
                <>
                  <FormControl
                    sx={{
                      width: "100%",
                      minHeight: "45px",
                      alignItems: "end",
                      padding: "0px !important",
                      "& .css-hdw1oc": {
                        display: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline, .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                        {
                          padding: "0px !important",
                          border: "none",
                        },
                    }}
                  >
                    <Select
                      value={quantity}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={handleChange}
                      sx={{
                        width: "100px",
                        border: "1px solid #b4c0d3",
                        borderRadius: "6px",
                        height: "36px",
                        padding: "0px !important",
                        fontSize: "12.5px",
                        fontWeight: "500",
                        color: "#30363c",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            color: blackColor,
                            // m: 0.5,
                            backgroundColor: whiteColor,
                            // backgroundColor: '#30363c',
                            padding: "0px !important",
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            "& .MuiMenuItem-root": {
                              "&:hover": {
                                // m: 0.5,
                                borderRadius: "4px",
                                padding: "6px 13px !important",
                                backgroundColor: "#578CEE",
                                color: "#fff",
                              },
                            },
                            "& .MuiMenuItem-root.Mui-selected": {
                              // m: 0.5,
                              borderRadius: "4px",
                              backgroundColor: "#578CEE",
                              padding: "6px 13px !important",
                              color: "#fff",
                              "&:hover": {
                                // m: 0.5,
                                padding: "6px 13px !important",
                                backgroundColor: blackColor,
                                color: whiteColor,
                              },
                            },
                          },
                        },
                      }}
                    >
                      {[...Array(maxQty - minQty + 1)].map((_, index) => (
                        <MenuItem key={index + minQty} value={index + minQty}>
                          {index + minQty}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body2"
                      fontSize="10px"
                      fontWeight={600}
                      color={catalogSubHeaderColor}
                      display="inline"
                    >
                      Min {minQty}
                    </Typography>

                    <Typography
                      variant="body2"
                      fontSize="10px"
                      fontWeight={600}
                      color={catalogSubHeaderColor}
                      display="inline"
                    >
                      Max {maxQty}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Skeleton
                  variant="rounded"
                  width="100px"
                  height="32px"
                  sx={{ my: "18px !important" }}
                />
              )}
            </Box>
            {!readOnly ? (
              <Box
                component="img"
                src={
                  "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/cart-delete.svg"
                }
                width={{ xs: "20px", md: "25px" }}
                height={{ xs: "20px", md: "25px" }}
                alt={businessFullName + " CostBo"}
                // sx={{
                //   color: getDeleteIconColor()
                //     ? outOfStockTextColor
                //     : delectIconInactiveColor,
                // }}
                onClick={onDeleteProduct}
                sx={{ mt: 1 }}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : (
    // <Box py={1.8} borderTop={'1px solid' + borderLineColor}>
    //<Grid
    //     container
    //     alignItems={{ xs: 'flex-start', md: 'center' }}
    //     spacing={{ md: 3, xs: 1 }}
    //   >
    //     <Grid item xs={12} md={readOnly ? 5 : 4.5}>
    //       <Grid container spacing={2} alignItems='flex-start'>
    //         <Grid item>
    //           <Box
    //             component={Link}
    //             to={`/${businessName}/singleProduct/${productId}`}
    //           >
    //             <Box
    //               component='img'
    //               src={image}
    //               alt={businessFullName + ' CostBo'}
    //               backgroundColor={whiteColor}
    //               sx={styles.productImg}
    //               onError={({ currentTarget }) => {
    //                 if (!isImgFailed) {
    //                   currentTarget.src = noProductImgUrl
    //                   setIsImgFailed(true)
    //                 } else {
    //                   // Both Images are failing to Load
    //                 }
    //               }}
    //             />
    //           </Box>
    //         </Grid>
    //         <Grid item xs>
    //           <Stack>
    //             {/* <Typography
    //               variant='subtitle1'
    //               fontSize='13px'
    //               color={categoryLabelColor}
    //             >
    //               {JSON.stringify(validateCart)}
    //             </Typography> */}
    //             <Typography
    //               variant='h6'
    //               component='h4'
    //               fontSize={{ xs: 13, md: 14 }}
    //               fontWeight={500}
    //               lineHeight='24px'
    //               color={headingColor}
    //               mt={0.7}
    //             >
    //               {productName}
    //             </Typography>
    //           </Stack>
    //         </Grid>
    //       </Grid>
    //     </Grid>

    //     <Grid item xs={5} md={2}>
    //       <Box>
    //         {discount ? (
    //           <Typography
    //             variant='subtitle1'
    //             component='p'
    //             fontSize={{ xs: 13, md: 13 }}
    //             fontWeight={400}
    //             color={offerTextColor}
    //             display='inline'
    //             sx={{ textDecorationLine: 'line-through' }}
    //           >
    //             ₹{Number(oldUnitPrice).toFixed(2)}
    //           </Typography>
    //         ) : null}

    //         <Typography
    //           variant='subtitle1'
    //           component='h4'
    //           fontSize={{ xs: 13, md: 14 }}
    //           color={headingColor}
    //           display='inline'
    //           ml={1}
    //         >
    //           ₹{Number(unitprice).toFixed(2)}
    //         </Typography>

    //         {discount ? (
    //           <Typography
    //             variant='subtitle2'
    //             component='p'
    //             color={orangeColor01}
    //             mt={0.3}
    //             // ml={{xs:0.7, md:0}}
    //             // display={{ xs: 'inline', md: 'block' }}
    //             display='block'
    //           >
    //             {discount}% OFF
    //           </Typography>
    //         ) : null}
    //       </Box>
    //     </Grid>

    //     <Grid item xs={5} md={2.3}>
    //       {readOnly ? (
    //         <>
    //           <Typography
    //             variant='subtitle1'
    //             fontSize='13px'
    //             color={offerTextColor}
    //             display={{ xs: 'block', md: 'none' }}
    //           >
    //             Qty
    //           </Typography>
    //           <Typography
    //             variant='body1'
    //             color={headingColor}
    //             fontSize='13px'
    //             ml={{ md: 4, xs: 1 }}
    //             // width="45px"
    //             fontWeight={600}
    //             display={'inline'}
    //           >
    //             {qtyCount}
    //           </Typography>
    //         </>
    //       ) : (
    //         <CartQuantityPicker
    //           onIncrement={() => {
    //             if (quantity < maxQty && quantity >= minQty) {
    //               onQtyUpdate({
    //                 cartId: cartId,
    //                 productId: product?.productId,
    //                 quantity: quantity + 1,
    //               })
    //             }
    //           }}
    //           onDecrement={() => {
    //             if (quantity > minQty && quantity <= maxQty) {
    //               onQtyUpdate({
    //                 cartId: cartId,
    //                 productId: product?.productId,
    //                 quantity: quantity - 1,
    //               })
    //             }
    //           }}
    //           minQty={minQty}
    //           maxQty={maxQty}
    //           count={quantity}
    //         />
    //       )}
    //     </Grid>

    //     <Grid item xs={0} md={1.2} display={{ xs: 'none', md: 'inline-flex' }}>
    //       <Typography
    //         variant='subtitle1'
    //         component='h4'
    //         lineHeight='24px'
    //         fontSize={14}
    //         color={headingColor}
    //       >
    //         ₹{Number(price).toFixed(2)}
    //       </Typography>
    //     </Grid>

    //     <Grid item xs={2} md={readOnly ? 1 : 2}>
    //       <Box
    //         display='flex'
    //         alignItems='center'
    //         justifyContent='space-between'
    //         mr={0.6}
    //       >
    //         <Typography
    //           variant='subtitle1'
    //           component='h4'
    //           lineHeight='24px'
    //           fontSize={14}
    //           color={savingTextColor}
    //           display={{ xs: 'none', md: 'inline' }}
    //         >
    //           ₹{Number(saving).toFixed(2)}
    //         </Typography>
    //         {!readOnly ? (
    //           <IconButton
    //             sx={{
    //               backgroundColor: whiteColor,
    //               border: '1px solid' + borderLineColor,
    //             }}
    //             onClick={onDeleteProduct}
    //           >
    //             {/* <Delete
    //               fontSize='small'
    //               sx={{
    //                 color: getDeleteIconColor()
    //                   ? outOfStockTextColor
    //                   : delectIconInactiveColor,
    //               }}
    //             /> */}
    //             <Box
    //               component='img'
    //               src={'https://storage.googleapis.com/bodefaults/shopweb/home/delete.svg'}
    //               width={{ xs: '20px', md: '20px' }}
    //               height={{ xs: '20px', md: '20px' }}
    //               alt={businessFullName + ' CostBo'}
    //               sx={{
    //                 color: getDeleteIconColor()
    //                   ? outOfStockTextColor
    //                   : delectIconInactiveColor,
    //               }}
    //             />
    //           </IconButton>
    //         ) : null}
    //       </Box>
    //     </Grid>
    //   </Grid>

    //   {/* Price Change Check */}
    //   {validateCart ? (
    //     isPriceChange ? (
    //       <Box
    //         display='flex'
    //         alignItems='center'
    //         justifyContent='flex-end'
    //         textAlign={{ xs: 'left', md: 'right' }}
    //       >
    //         <PrivacyTip
    //           sx={{
    //             fontSize: { md: '20px', xs: '18px' },
    //             color: { md: navLinkColor, xs: redColor02 },
    //             mr: 0.7,
    //           }}
    //         />
    //         <Typography
    //           variant='subtitle1'
    //           component='p'
    //           fontSize='12.5px'
    //           color={outOfStockTextColor}
    //           display='inline'
    //           mt={1.6}
    //         >
    //           {productPriceChangeMsg}{' '}
    //           {Number(orderProduct?.productSalePrice).toFixed(2)}
    //           <Button
    //             sx={{ mx: 1.5, p: 0.3 }}
    //             variant='contained'
    //             disableElevation
    //             onClick={() => {
    //               onPriceUpdate()
    //             }}
    //           >
    //             Apply
    //           </Button>
    //         </Typography>
    //       </Box>
    //     ) : null
    //   ) : null}

    //   {/* Ecommerce Enable Check */}
    //   {validateCart ? (
    //     isEcommerceDisabled || weightZeroError ? (
    //       <Box
    //         display='flex'
    //         alignItems='center'
    //         justifyContent='flex-end'
    //         textAlign={{ xs: 'left', md: 'right' }}
    //       >
    //         <PrivacyTip
    //           sx={{
    //             fontSize: { md: '20px', xs: '18px' },
    //             color: { md: navLinkColor, xs: redColor02 },
    //             mr: 0.7,
    //           }}
    //         />
    //         <Typography
    //           variant='subtitle1'
    //           component='p'
    //           fontSize='12.5px'
    //           color={outOfStockTextColor}
    //           display='inline'
    //           mt={1.6}
    //         >
    //           {productNotAvailabelMsg}
    //         </Typography>
    //       </Box>
    //     ) : null
    //   ) : null}

    //   {/* Offer Order Already Purchased Check */}
    //   {validateCart ? (
    //     isOfferOrderPresent ? (
    //       <Box
    //         display='flex'
    //         alignItems='center'
    //         justifyContent='flex-end'
    //         textAlign={{ xs: 'left', md: 'right' }}
    //       >
    //         <PrivacyTip
    //           sx={{
    //             fontSize: { md: '20px', xs: '18px' },
    //             color: { md: navLinkColor, xs: redColor02 },
    //             mr: 0.7,
    //           }}
    //         />
    //         <Typography
    //           variant='subtitle1'
    //           component='p'
    //           fontSize='12.5px'
    //           color={outOfStockTextColor}
    //           display='inline'
    //           mt={1.6}
    //         >
    //           Promotional Products are in limited quantity per person, you could
    //           try other products in cart
    //         </Typography>
    //       </Box>
    //     ) : null
    //   ) : null}

    //   {/* Min Order Qty Check */}
    //   {validateCart ? (
    //     !isEcommerceDisabled ? (
    //       minQtyCheck ? (
    //         <Box
    //           display='flex'
    //           alignItems='center'
    //           justifyContent='flex-end'
    //           textAlign={{ xs: 'left', md: 'right' }}
    //         >
    //           <PrivacyTip
    //             sx={{
    //               fontSize: { md: '20px', xs: '18px' },
    //               color: { md: navLinkColor, xs: redColor02 },
    //               mr: 0.7,
    //             }}
    //           />
    //           <Typography
    //             variant='subtitle1'
    //             component='p'
    //             fontSize='12.5px'
    //             color={outOfStockTextColor}
    //             display='inline'
    //             mt={1.6}
    //           >
    //             Minimum order quantity is {orderProduct?.minOrderQuantity}.
    //             Please update the quantity.
    //             <Button
    //               sx={{ mx: 1.5, p: 0.3 }}
    //               variant='contained'
    //               disableElevation
    //               onClick={() => {
    //                 onQtyUpdate({
    //                   cartId: cartId,
    //                   productId: product?.productId,
    //                   quantity: orderProduct?.minOrderQuantity,
    //                 })
    //               }}
    //             >
    //               Apply
    //             </Button>
    //           </Typography>
    //         </Box>
    //       ) : null
    //     ) : null
    //   ) : null}

    //   {/* Max Order Qty Check */}
    //   {validateCart ? (
    //     !isEcommerceDisabled ? (
    //       maxQtyCheck ? (
    //         <Box
    //           display='flex'
    //           alignItems='center'
    //           justifyContent='flex-end'
    //           textAlign={{ xs: 'left', md: 'right' }}
    //         >
    //           <PrivacyTip
    //             sx={{
    //               fontSize: { md: '20px', xs: '18px' },
    //               color: { xs: '#da3412', md: navLinkColor },
    //               mr: 0.7,
    //             }}
    //           />
    //           <Typography
    //             variant='subtitle1'
    //             component='p'
    //             fontSize='12.5px'
    //             color={outOfStockTextColor}
    //             display='inline'
    //             mt={1.6}
    //           >
    //             Maximum order quantity is {orderProduct?.maxOrderQuantity}.
    //             Please update the quantity.
    //             <Button
    //               sx={{ mx: 1.5, p: 0.3 }}
    //               variant='contained'
    //               disableElevation
    //               onClick={() => {
    //                 onQtyUpdate({
    //                   cartId: cartId,
    //                   productId: product?.productId,
    //                   quantity: orderProduct?.maxOrderQuantity,
    //                 })
    //               }}
    //             >
    //               Apply
    //             </Button>
    //           </Typography>
    //         </Box>
    //       ) : null
    //     ) : null
    //   ) : null}
    // </Box>
    <Box
      py={1.2}
      // borderTop={'1px solid' + borderLineColor}
      backgroundColor={whiteColor}
      borderRadius={"10px"}
      borderBottom={`1px solid ${borderLineColor}`}
    >
      <Grid
        container
        alignItems={{ xs: "flex-start", md: "center" }}
        spacing={{ md: 3, xs: 1 }}
      >
        <Grid item xs={12} md={readOnly ? 5 : 4.5}>
          <Grid container spacing={1} px={1}>
            <Grid item>
              <Box
                sx={{
                  width: "46px",
                  height: "50px",
                  // display: 'flex',
                  // justifyContent: 'center',
                  // alignItems: 'center',
                  background: whiteColor,
                  borderRadius: "8px",
                  padding: "4px 6px",
                  border: `1px solid ${borderLineColor}`,
                }}
                // component={Link}
                // to={`/${businessName}/singleProduct/${productId}`}
                onClick={()=>{
                  navigateTo(navigate, `singleProduct/${productId}`)
                }}
              >
                <Box
                  component="img"
                  src={image}
                  alt={businessFullName + " CostBo"}
                  backgroundColor={whiteColor}
                  width={"46px"}
                  height={"46px"}
                  sx={{
                    transition: "all 0.3s ease-in-out",
                    transform: "scale(1)",
                    "&:hover": {
                      transform: "scale(0.97)",
                    },
                  }}
                  // sx={styles.productImgMobile}
                  onError={({ currentTarget }) => {
                    if (!isImgFailed) {
                      currentTarget.src = noProductImgUrl;
                      setIsImgFailed(true);
                    } else {
                      // Both Images are failing to Load
                    }
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs>
              <Stack>
                {/* <Typography
                  variant='subtitle1'
                  fontSize='13px'
                  color={categoryLabelColor}
                >
                  {JSON.stringify(validateCart)}
                </Typography> */}
                <Typography
                  variant="h6"
                  // component="h4"
                  fontSize={{ xs: 12, md: 14 }}
                  fontWeight={500}
                  lineHeight={1.5}
                  color={{ md: headingColor, xs: blackColor }}
                  sx={textTwoLines}
                >
                  {productName}
                </Typography>
                {!matches && (
                  <Box display={"flex"} gap={1} ml={-1} alignItems={"center"}>
                    <Typography
                      variant="subtitle1"
                      component="h4"
                      fontSize={{ xs: 13, md: 14 }}
                      color={{ md: headingColor, xs: blackColor }}
                      display="inline"
                      ml={1}
                      fontWeight={600}
                    >
                      {/* ₹{Number(unitprice).toFixed(2)} */}₹
                      {Number(Number(unitprice) * Number(quantity)).toFixed(2)}
                    </Typography>
                    {discount ? (
                      <Typography
                        variant="subtitle1"
                        component="p"
                        fontSize={{ xs: 13, md: 13 }}
                        fontWeight={400}
                        color={offerTextColor}
                        display="inline"
                        sx={{ textDecorationLine: "line-through" }}
                      >
                        ₹{Number(saving).toFixed(2)}
                        {/* ₹{Number(oldUnitPrice).toFixed(2)} */}
                      </Typography>
                    ) : null}

                    {oldUnitPrice != unitprice ? (
                      <Box sx={styles.percentageOffTag}>
                        {getDiscountPercentage(unitprice, oldUnitPrice)}% Off
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        {matches ? (
          <Grid item xs={5} md={2}>
            <Box>
              {discount ? (
                <Typography
                  variant="subtitle1"
                  component="p"
                  fontSize={{ xs: 13, md: 13 }}
                  fontWeight={400}
                  color={offerTextColor}
                  display="inline"
                  sx={{ textDecorationLine: "line-through" }}
                >
                  ₹{Number(oldUnitPrice).toFixed(2)}
                </Typography>
              ) : null}

              <Typography
                variant="subtitle1"
                component="h4"
                fontSize={{ xs: 13, md: 14 }}
                color={headingColor}
                display="inline"
                ml={1}
              >
                ₹{Number(unitprice).toFixed(2)}
              </Typography>

              {discount ? (
                <Typography
                  variant="subtitle2"
                  component="p"
                  color={orangeColor01}
                  mt={0.3}
                  // ml={{xs:0.7, md:0}}
                  // display={{ xs: 'inline', md: 'block' }}
                  display="block"
                >
                  {discount}% OFF
                </Typography>
              ) : null}
            </Box>
          </Grid>
        ) : (
          <Grid item xs={2.8} md={2}></Grid>
        )}

        <Grid item xs={5} md={2.3}>
          {readOnly ? (
            <>
              <Typography
                variant="subtitle1"
                fontSize="13px"
                color={offerTextColor}
                display={{ xs: "block", md: "none" }}
              >
                Qty
              </Typography>
              <Typography
                variant="body1"
                color={headingColor}
                fontSize="13px"
                ml={{ md: 4, xs: 1 }}
                // width="45px"
                fontWeight={600}
                display={"inline"}
              >
                {qtyCount}
              </Typography>
            </>
          ) : (
            <CartQuantityPicker
              onIncrement={() => {
                if (quantity < maxQty && quantity >= minQty) {
                  onQtyUpdate({
                    cartId: cartId,
                    productId: product?.productId,
                    quantity: quantity + 1,
                  });
                }
              }}
              onDecrement={() => {
                if (quantity > minQty && quantity <= maxQty) {
                  onQtyUpdate({
                    cartId: cartId,
                    productId: product?.productId,
                    quantity: quantity - 1,
                  });
                }
              }}
              minQty={minQty}
              maxQty={maxQty}
              count={quantity}
            />
          )}
        </Grid>

        <Grid item xs={0} md={1.2} display={{ xs: "none", md: "inline-flex" }}>
          <Typography
            variant="subtitle1"
            component="h4"
            lineHeight="24px"
            fontSize={14}
            color={headingColor}
          >
            ₹{Number(price).toFixed(2)}
          </Typography>
        </Grid>

        <Grid item xs={2} md={readOnly ? 1 : 2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mr={0.6}
          >
            <Typography
              variant="subtitle1"
              component="h4"
              lineHeight="24px"
              fontSize={14}
              color={savingTextColor}
              display={{ xs: "none", md: "inline" }}
            >
              ₹{Number(saving).toFixed(2)}
            </Typography>
            {!readOnly ? (
              <IconButton
                sx={{
                  backgroundColor: whiteColor,
                  border: "1px solid" + borderLineColor,
                }}
                onClick={onDeleteProduct}
              >
                {/* <Delete
                  fontSize='small'
                  sx={{
                    color: getDeleteIconColor()
                      ? delectIconInactiveColor
                      : // : delectIconInactiveColor,
                        outOfStockTextColor,
                  }}
                /> */}
                <Box
                  component="img"
                  src={
                    "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/delete.svg"
                  }
                  width={{ xs: "20px", md: "20px" }}
                  height={{ xs: "20px", md: "20px" }}
                  alt={businessFullName + " CostBo"}
                  sx={{
                    color: getDeleteIconColor()
                      ? outOfStockTextColor
                      : delectIconInactiveColor,
                    transition: "all 0.3s ease-in-out",
                    transform: "scale(1)",
                    "&:hover": {
                      transform: "scale(0.97)",
                    },
                  }}
                />
              </IconButton>
            ) : null}
          </Box>
        </Grid>
      </Grid>

      {/* Price Change Check */}
      {validateCart ? (
        isPriceChange ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            textAlign={{ xs: "left", md: "right" }}
          >
            <PrivacyTip
              sx={{
                fontSize: { md: "20px", xs: "18px" },
                color: { md: navLinkColor, xs: "#da3412" },
                mr: 0.7,
              }}
            />
            <Typography
              variant="subtitle1"
              component="p"
              fontSize="12.5px"
              color={outOfStockTextColor}
              display="inline"
              mt={1.6}
            >
              {productPriceChangeMsg}{" "}
              {Number(orderProduct?.productSalePrice).toFixed(2)}
              <Button
                sx={{ ...styles.applyBtm }}
                variant="contained"
                disableElevation
                onClick={() => {
                  onPriceUpdate();
                }}
              >
                Apply
              </Button>
            </Typography>
          </Box>
        ) : null
      ) : null}

      {/* Ecommerce Enable Check */}
      {validateCart ? (
        isEcommerceDisabled || weightZeroError ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            textAlign={{ xs: "left", md: "right" }}
          >
            <PrivacyTip
              sx={{
                fontSize: { md: "20px", xs: "18px" },
                color: { md: navLinkColor, xs: "#da3412" },
                mr: 1,
              }}
            />
            <Typography
              variant="subtitle1"
              component="p"
              fontSize="12px"
              color={outOfStockTextColor}
              display="inline"
              mt={1}
            >
              {productNotAvailabelMsg}
            </Typography>
          </Box>
        ) : null
      ) : null}

      {/* Offer Order Already Purchased Check */}
      {validateCart ? (
        isOfferOrderPresent ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            textAlign={{ xs: "left", md: "right" }}
          >
            <PrivacyTip
              sx={{
                fontSize: { md: "20px", xs: "18px" },
                color: { md: navLinkColor, xs: "#da3412" },
                mr: 0.7,
              }}
            />
            <Typography
              variant="subtitle1"
              component="p"
              fontSize="12.5px"
              color={outOfStockTextColor}
              display="inline"
              mt={1.6}
            >
              Promotional Products are in limited quantity per person, you could
              try other products in cart
            </Typography>
          </Box>
        ) : null
      ) : null}
      {/* Min Order Qty Check */}
      {validateCart ? (
        !isEcommerceDisabled ? (
          minQtyCheck ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              textAlign={{ xs: "left", md: "right" }}
            >
              <PrivacyTip
                sx={{
                  fontSize: { md: "20px", xs: "18px" },
                  color: { md: navLinkColor, xs: "#da3412" },
                  mr: 0.7,
                }}
              />
              <Typography
                variant="subtitle1"
                component="p"
                fontSize="12.5px"
                color={outOfStockTextColor}
                display="inline"
                mt={1.6}
              >
                Minimum order quantity is {orderProduct?.minOrderQuantity}.
                Please update the quantity.
                <Button
                  sx={{ ...styles.applyBtm }}
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    onQtyUpdate({
                      cartId: cartId,
                      productId: product?.productId,
                      quantity: orderProduct?.minOrderQuantity,
                    });
                  }}
                >
                  Apply
                </Button>
              </Typography>
            </Box>
          ) : null
        ) : null
      ) : null}

      {/* Max Order Qty Check */}
      {validateCart ? (
        !isEcommerceDisabled ? (
          maxQtyCheck ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              textAlign={{ xs: "left", md: "right" }}
            >
              <PrivacyTip
                sx={{
                  fontSize: { md: "20px", xs: "18px" },
                  color: { md: navLinkColor, xs: "#da3412 " },
                  mr: 0.7,
                }}
              />
              <Typography
                variant="subtitle1"
                component="p"
                fontSize="12.5px"
                color={outOfStockTextColor}
                display="inline"
                mt={1.6}
              >
                Current order quatity is {quantity}, Maximum order quantity is{" "}
                {orderProduct?.maxOrderQuantity}. Please update the quantity.
                <Button
                  sx={{ ...styles.applyBtm }}
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    onQtyUpdate({
                      cartId: cartId,
                      productId: product?.productId,
                      quantity: orderProduct?.maxOrderQuantity,
                    });
                  }}
                  backgroundColor={blackColor}
                >
                  Apply
                </Button>
              </Typography>
            </Box>
          ) : null
        ) : null
      ) : null}
    </Box>
  );
}

export default CartProductCard;

const styles = {
  productImg: {
    width: "45.3px",
    height: "58px",
    border: "1px solid" + borderLineColor,
    borderRadius: "10px",
    p: "10px",
    objectFit: "contain",
    transform: "scale(1)",
    "&:hover": {
      transform: "scale(0.97)",
    },
  },
  productImgMobile: {
    width: "46px",
    height: "46px",
    border: "1px solid" + borderLineColor,
    borderRadius: "10px",
    p: "6px",
    objectFit: "contain",
  },

  offTag: {
    display: "inline-block",
    backgroundColor: "primary.main",
    borderRadius: "0 6px 0 0",
    p: "4px 15px",
    borderRadius: "5px",
  },
  percentageOffTag: {
    width: "50px",
    fontSize: 12,
    fontWeight: 600,
    color: lightGreen02,
    // bgcolor: lightGreenShade01,
    p: "0px 6px",
    borderRadius: "10px",
  },
  applyBtm: {
    backgroundColor: "#fff",
    color: "#e23e1d",
    borderRadius: "6px",
    fontWeight: 600,
    fontSize: "12px",
    border: "1px solid #e23e1d",
    width: "13%", // Set to 13% width
    marginTop: "0px",
    padding: "5px 10px 6px", // Padding top, horizontal, and bottom
    lineHeight: "14px",
    textAlign: "center",
    position: "relative",
    top: "4px",
    textTransform: "uppercase",
  },
};
