import moment from "moment";
import { getProfileId, getUserDetails } from "./authHelpers";

export const checkEcommerceDisable = (
  orderProduct,
  cartProduct,
  hasCatalogPartner,
  unicomInventoryInfo,
  buymoreInventoryInfo
) => {
  const startDate = moment
    .unix(orderProduct?.commerceStartDate / 1000)
    .format("YYYY-MM-DD");
  const endDate = moment
    .unix(orderProduct?.commerceEndDate / 1000)
    .format("YYYY-MM-DD");
  const currentDate = moment().format("YYYY-MM-DD");

  const isValidDate = moment(currentDate).isBetween(
    startDate,
    endDate,
    null,
    "[]"
  );

  // if catalog partner is unicommerce, disable ecommerce check
  if (hasCatalogPartner) {
    console.log("UNI__INVENTORY___,", unicomInventoryInfo);
    const catalogPartner =
      cartProduct?.catalogPartnerItemInfo?.catalogPartner?.toLowerCase() || "";
    const itemSkuCode = cartProduct?.catalogPartnerItemInfo?.skuCode || "";

    let inventory = 0;

    if (catalogPartner == "unicommerce") {
      const itemInventoryInfo = unicomInventoryInfo?.find(
        (item) => item?.itemTypeSKU == itemSkuCode
      );

      if (!itemSkuCode || !itemInventoryInfo) return true;

      inventory = Number(itemInventoryInfo?.inventory || 0);
    }

    if (catalogPartner == "buymore") {
      
      const itemInventoryInfo = buymoreInventoryInfo?.find(
        (item) => item?.portal_sku == itemSkuCode
      );
      console.log("IS_VALID____:", {itemSkuCode, itemInventoryInfo})
      
      if (!itemSkuCode || !itemInventoryInfo) return true;

      inventory = Number(itemInventoryInfo?.current_stock || 0);
    }

    const availableQuantity = inventory;

    const currentOrderQuantity = Number(cartProduct?.currentQuantity || 0);

    const isNotAvailable = orderProduct?.availability?.[0] === "No";
    const isCommerceDisabled = orderProduct?.commerceEnabled === "NO";
    const isProductInactive = orderProduct?.status === "inactive";

    const qtyError = currentOrderQuantity > availableQuantity;

    console.log("IS_VALID____:",{isNotAvailable,
      isCommerceDisabled,
      isProductInactive,
      isValidDate:!isValidDate,
      qtyError});
    

    return (
      isNotAvailable ||
      isCommerceDisabled ||
      isProductInactive ||
      !isValidDate ||
      qtyError
    );
  }

  return (
    orderProduct?.availability?.[0] === "No" ||
    orderProduct?.commerceEnabled === "NO" ||
    orderProduct?.status === "inactive" ||
    !isValidDate ||
    cartProduct?.currentQuantity > orderProduct?.availableQuantity
  );
};

// export const checkEcommerceDisable = (
//   orderProduct,
//   cartProduct,
//   hasCatalogPartner,
//   unicomInventoryInfo,
//   buymoreInventoryInfo
// ) => {
//   const convertTimestampToDate = (timestamp) =>
//     moment.unix(timestamp / 1000).format("YYYY-MM-DD");

//   const startDate = convertTimestampToDate(orderProduct?.commerceStartDate);
//   const endDate = convertTimestampToDate(orderProduct?.commerceEndDate);
//   const currentDate = moment().format("YYYY-MM-DD");

//   const isValidDate = moment(currentDate).isBetween(
//     startDate,
//     endDate,
//     null,
//     "[]"
//   );

//   const isProductInactive =
//     orderProduct?.availability?.[0] === "No" ||
//     orderProduct?.commerceEnabled === "NO" ||
//     orderProduct?.status === "inactive";

//   if (hasCatalogPartner) {
//     const catalogPartner =
//       cartProduct?.catalogPartnerItemInfo?.catalogPartner?.toLowerCase() || "";
//     const itemSkuCode = cartProduct?.catalogPartnerItemInfo?.skuCode || "";

//     if (!itemSkuCode) return true;

//     // Helper function to get inventory
//     const getInventory = (inventoryList, key) =>
//       Number(inventoryList?.find((item) => item[key] === itemSkuCode)?.inventory || 0);

//     const inventory =
//       catalogPartner === "unicommerce"
//         ? getInventory(unicomInventoryInfo, "itemTypeSKU")
//         : catalogPartner === "buymore"
//         ? getInventory(buymoreInventoryInfo, "portal_sku")
//         : 0;

//     if (inventory === 0) return true;

//     const availableQuantity = inventory;
//     const currentOrderQuantity = Number(cartProduct?.currentQuantity || 0);

//     return (
//       isProductInactive ||
//       !isValidDate ||
//       currentOrderQuantity > availableQuantity
//     );
//   }

//   return (
//     isProductInactive ||
//     !isValidDate ||
//     cartProduct?.currentQuantity > orderProduct?.availableQuantity
//   );
// };

export const createCartHelper = (localData) => {
  const profileId = getProfileId();
  const businessId = sessionStorage.getItem("businessId");

  const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));
  const userInfo = getUserDetails();

  console.log("createCartHelper_PID", profileId);
  console.log("createCartHelper_BID", businessId);
  console.log("createCartHelper_BINFO", businessInfo);
  console.log("createCartHelper_UINFO", userInfo);
  // console.log('createCartHelper_Product', product)

  const data = {
    ...localData,
    profileId: profileId,
    // businessId: businessId,
    profilePhoto: userInfo?.account?.photoURL,
    profileName: userInfo?.account?.name,
    businessLogo: businessInfo?.[0]?.logoURL,
    businessName: businessInfo?.[0]?.businessName,
    businessCity: businessInfo?.[0]?.city,
    orderStatus: "cartcreated",
    notesConsumer: "",

    orderTotalTax: 0,
    shippingCost: 0.0,
    shippingTax: 0.0,
    claimedPoint: 0.0,
    deliverMethod: ["Customer location"],
    modeOfPayment: [""],
    customerAddress: {
      name: "",
      addressLine1: "",
      addressLine2: "",
      houseNo: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      latitude: 0.0,
      longitude: 0.0,
      selected: false,
    },
  };
  return data;
};

export const updateCartHelper = (cartData, orderProducts, product) => {
  console.log("UPDATE_CART_HELPER_CARTDATA:", cartData);
  console.log("UPDATE_CART_HELPER_ORDER_PRO:", orderProducts);
  console.log("UPDATE_CART_HELPER_PRODUCT:", product);

  let totalProductCost = 0;

  const filterProducts = cartData?.productList?.filter((item) => {
    if (item?.productId !== product?.productId)
      totalProductCost =
        totalProductCost + item?.productSalePrice * item?.currentQuantity;
    return item?.productId !== product?.productId;
  });

  console.log("UPDATE_CART_HELPER_FilterList:", filterProducts);
  console.log("UPDATE_CART_HELPER_totalProductCost:", totalProductCost);

  const data = {
    id: cartData?.cartId,
    orderId: cartData?.orderId,
    profileId: cartData?.profileId,
    businessId: cartData?.businessId,
    paymentId: null,
    profilePhoto: cartData?.profilePhoto,
    profileName: cartData?.profileName,
    businessLogo: cartData?.businessLogo,
    businessName: cartData?.businessName,
    businessCity: cartData?.businessCity,
    orderStatus: "cartcreated",
    orderPaymentStatus: "",
    orderReceivedStatus: null,
    orderReviewStatus: null,
    notesBusiness: null,
    notesConsumer: "",
    deliveryDateRange: null,
    claimId: null,
    claimType: null,
    clientType: "WEB-ORDER",
    cartType: null,
    paymentLink: null,
    domainOrder: false,
    totalQuantity: 59,
    orderTotalTax: cartData?.orderTotalTax,
    orderTotalProductCost: totalProductCost,
    orderTotalCost: totalProductCost,
    shippingCost: 0,
    shippingTax: 0,
    claimedPoint: 0,
    maxClaimPoint: 0,
    productList: filterProducts,
    deliverMethod: ["Customer location"],
    modeOfPayment: [""],
    deliverySlots: null,
    discountDetails: [],
    customerAddress: {
      name: "",
      consumerBusiness: null,
      gstNo: null,
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      houseNo: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      landMark: "",
      phoneNo: null,
      alternatePhoneNo: null,
      addressType: null,
      emails: null,
      latitude: 0,
      longitude: 0,
      selected: false,
      preferredLocation: null,
      updatedOn: null,
    },
    businessAddress: null,
    deliveryType: null,
    shipmentInfo: null,
    deliveryDetails: null,
    storePickupDetail: null,
    packageInfo: null,
    ownerShipInfo: null,
    paymentInfo: null,
    referralInfo: null,
    resellerInfo: null,
    btobInfo: null,
    codCost: null,
    weFastInfo: null,
  };

  return data;
};

export const updateCartProductPriceHelper = (
  cartData,
  orderProducts,
  product
) => {
  console.log("UPDATE_PRICE_HELPER_CARTDATA:", cartData);
  console.log("UPDATE_PRICE_HELPER_ORDER_PRO:", orderProducts);
  console.log("UPDATE_PRICE_HELPER_PRODUCT:", product);

  let totalProductCost = 0;

  let productList = cartData?.productList;

  productList?.forEach((item, index) => {
    if (item?.productId === orderProducts?.id) {
      console.log("UPDATE_PRICE_HELPER_FIND:", item);
      productList[index] = {
        ...item,
        productOriginalPrice: orderProducts?.productOriginalPrice,
        productSalePrice: orderProducts?.productSalePrice,
        productTotalPrice:
          item?.currentQuantity * orderProducts?.productSalePrice,
        percentageOff: orderProducts?.percentageOff,
      };
    }
  });

  productList?.forEach((item) => {
    totalProductCost =
      totalProductCost + item?.productSalePrice * item?.currentQuantity;
  });

  console.log("UPDATE_PRICE_HELPER_updatedProducts:", productList);
  console.log("UPDATE_PRICE_HELPER_totalProductCost:", totalProductCost);

  const data = {
    id: cartData?.cartId,
    orderId: cartData?.orderId,
    profileId: cartData?.profileId,
    businessId: cartData?.businessId,
    paymentId: null,
    profilePhoto: cartData?.profilePhoto,
    profileName: cartData?.profileName,
    businessLogo: cartData?.businessLogo,
    businessName: cartData?.businessName,
    businessCity: cartData?.businessCity,
    orderStatus: "cartcreated",
    orderPaymentStatus: "",
    orderReceivedStatus: null,
    orderReviewStatus: null,
    notesBusiness: null,
    notesConsumer: "",
    deliveryDateRange: null,
    claimId: null,
    claimType: null,
    clientType: "WEB-ORDER",
    cartType: null,
    paymentLink: null,
    domainOrder: false,
    totalQuantity: 59,
    orderTotalTax: cartData?.orderTotalTax,
    orderTotalProductCost: totalProductCost,
    orderTotalCost: totalProductCost,
    shippingCost: 0,
    shippingTax: 0,
    claimedPoint: 0,
    maxClaimPoint: 0,
    productList: productList,
    deliverMethod: ["Customer location"],
    modeOfPayment: [""],
    deliverySlots: null,
    discountDetails: [],
    customerAddress: {
      name: "",
      consumerBusiness: null,
      gstNo: null,
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      houseNo: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      landMark: "",
      phoneNo: null,
      alternatePhoneNo: null,
      addressType: null,
      emails: null,
      latitude: 0,
      longitude: 0,
      selected: false,
      preferredLocation: null,
      updatedOn: null,
    },
    businessAddress: null,
    deliveryType: null,
    shipmentInfo: null,
    deliveryDetails: null,
    storePickupDetail: null,
    packageInfo: null,
    ownerShipInfo: null,
    paymentInfo: null,
    referralInfo: null,
    resellerInfo: null,
    btobInfo: null,
    codCost: null,
    weFastInfo: null,
  };

  return data;
};

export const addLocalCartHelper = (product) => {
  let cart = JSON.parse(sessionStorage.getItem("cartData"));
  let totalQty = 0;
  let totalProductCost = 0;
  let newCart = {};
  if (!cart) {
    newCart = {
      businessId: sessionStorage.getItem("businessId"),
      paymentId: null,
      totalQuantity: product.currentQuantity,
      orderTotalProductCost:
        product.productSalePrice * product?.currentQuantity,
      orderTotalCost: product.productSalePrice * product?.currentQuantity,
      productList: [product],
    };
  } else {
    cart.productList = [...cart.productList, product];
    cart.productList?.forEach((item) => {
      totalProductCost =
        totalProductCost + item?.productSalePrice * item?.currentQuantity;
      totalQty = totalQty + item?.currentQuantity;
    });
    newCart = {
      ...cart,
      totalQuantity: totalQty,
      orderTotalProductCost: totalProductCost,
      orderTotalCost: totalProductCost,
    };
  }

  return newCart;
};
export const updateLocalCartHelper = (product) => {
  const cart = JSON.parse(sessionStorage.getItem("cartData"));
  let totalQty = 0;
  let totalProductCost = 0;

  let productList = cart?.productList;

  productList?.forEach((item, index) => {
    if (item?.productId === product?.productId) {
      console.log("UPDATE_PRICE_HELPER_FIND:", item);
      productList[index] = {
        ...item,
        productTotalPrice: product?.quantity * item?.productSalePrice,
        currentQuantity: product?.quantity,
      };
    }
  });

  productList?.forEach((item) => {
    totalProductCost =
      totalProductCost + item?.productSalePrice * item?.currentQuantity;
    totalQty = totalQty + item?.currentQuantity;
  });

  const data = {
    businessId: cart?.businessId,
    totalQuantity: totalQty,
    orderTotalProductCost: totalProductCost,
    orderTotalCost: totalProductCost,
    productList: productList,
  };

  return data;
};

export const updateMergeCartHelper = (cartData, localCartData) => {
  console.log("UPDATE_CART_HELPER_CARTDATA:", cartData);
  console.log("UPDATE_CART_HELPER_localCartData:", localCartData);
  // console.log('UPDATE_CART_HELPER_PRODUCT:', product)

  const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));
  const userInfo = getUserDetails();

  console.log("UPDATE_CART_HELPER_businessInfo:", businessInfo);
  console.log("UPDATE_CART_HELPER_userInfo:", userInfo);

  let totalQty = 0;
  let totalProductCost = 0;

  let productList = cartData?.productList;
  let localProductList = localCartData?.productList;
  let commonList = [];

  let arr3 = [].concat(
    productList.filter((obj1) =>
      localProductList.every((obj2) => obj1.productId !== obj2.productId)
    ),
    localProductList.filter((obj2) =>
      productList.every((obj1) => obj2.productId !== obj1.productId)
    )
  );

  console.log("Reduced", arr3);

  // let newProductList = []
  localProductList?.forEach((product) => {
    productList?.forEach((item) => {
      if (item?.productId === product?.productId) {
        console.log("UPDATE_PRICE_HELPER_FIND:", item, product);
        commonList.push({
          ...item,
          productTotalPrice:
            (product?.currentQuantity + item?.currentQuantity) *
            item?.productSalePrice,
          currentQuantity: product?.currentQuantity + item?.currentQuantity,
        });
      }
    });
  });

  let updatedProductList = [...arr3, ...commonList];

  // for(let i=0;i<localProductList.length)

  // console.log('AFTER_UPDATE', productList)

  updatedProductList?.forEach((item) => {
    totalProductCost =
      totalProductCost + item?.productSalePrice * item?.currentQuantity;
    totalQty = totalQty + item?.currentQuantity;
  });

  const data = {
    id: cartData?.cartId,
    orderId: cartData?.orderId,
    profileId: cartData?.profileId,
    businessId: cartData?.businessId,
    paymentId: null,
    profilePhoto: userInfo?.account?.photoURL,
    profileName: userInfo?.account?.name,
    businessLogo: businessInfo?.[0]?.logoURL,
    businessName: businessInfo?.[0]?.businessName,
    businessCity: businessInfo?.[0]?.city,
    orderStatus: "cartcreated",
    orderPaymentStatus: "",
    orderReceivedStatus: null,
    orderReviewStatus: null,
    notesBusiness: null,
    notesConsumer: "",
    deliveryDateRange: null,
    claimId: null,
    claimType: null,
    clientType: "WEB-ORDER",
    cartType: null,
    paymentLink: null,
    domainOrder: false,
    totalQuantity: totalQty,
    orderTotalTax: cartData?.orderTotalTax,
    orderTotalProductCost: totalProductCost,
    orderTotalCost: totalProductCost,
    shippingCost: 0,
    shippingTax: 0,
    claimedPoint: 0,
    maxClaimPoint: 0,
    productList: updatedProductList,
    deliverMethod: ["Customer location"],
    modeOfPayment: [""],
    deliverySlots: null,
    discountDetails: [],
    customerAddress: {
      name: "",
      consumerBusiness: null,
      gstNo: null,
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      houseNo: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      landMark: "",
      phoneNo: null,
      alternatePhoneNo: null,
      addressType: null,
      emails: null,
      latitude: 0,
      longitude: 0,
      selected: false,
      preferredLocation: null,
      updatedOn: null,
    },
    businessAddress: null,
    deliveryType: null,
    shipmentInfo: null,
    deliveryDetails: null,
    storePickupDetail: null,
    packageInfo: null,
    ownerShipInfo: null,
    paymentInfo: null,
    referralInfo: null,
    resellerInfo: null,
    btobInfo: null,
    codCost: null,
    weFastInfo: null,
  };

  return data;
};

export const createCartLoginHelper = (product) => {
  const profileId = getProfileId();
  const businessId = sessionStorage.getItem("businessId");

  const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));
  const userInfo = getUserDetails();

  // console.log('createCartLoginHelper_PID', profileId)
  // console.log('createCartLoginHelper_BID', businessId)
  // console.log('createCartLoginHelper_BINFO', businessInfo)
  // console.log('createCartLoginHelper_UINFO', userInfo)
  // console.log('createCartLoginHelper_Product', product)

  const data = {
    profileId: profileId,
    businessId: businessId,
    profilePhoto: userInfo?.account?.photoURL,
    profileName: userInfo?.account?.name,
    businessLogo: businessInfo?.[0]?.logoURL,
    businessName: businessInfo?.[0]?.businessName,
    businessCity: businessInfo?.[0]?.city,
    orderStatus: "cartcreated",
    notesConsumer: "",

    totalQuantity: product?.product?.currentQuantity,
    orderTotalTax: 0.0,
    orderTotalProductCost: product?.product?.productTotalPrice,
    orderTotalCost: product?.product?.productTotalPrice,

    shippingCost: 0.0,
    shippingTax: 0.0,
    claimedPoint: 0.0,
    productList: [{ ...product?.product }],
    deliverMethod: ["Customer location"],
    modeOfPayment: [""],
    customerAddress: {
      name: "",
      addressLine1: "",
      addressLine2: "",
      houseNo: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      latitude: 0.0,
      longitude: 0.0,
      selected: false,
    },
  };
  return data;
};

export const removeCartProductLoginHelper = (productId) => {
  const profileId = getProfileId();
  const businessId = sessionStorage.getItem("businessId");

  const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));
  const cartData = JSON.parse(sessionStorage.getItem("cartData"));
  const userInfo = getUserDetails();

  // console.log('createCartLoginHelper_PID', profileId)
  // console.log('createCartLoginHelper_BID', businessId)
  // console.log('createCartLoginHelper_BINFO', businessInfo)
  // console.log('createCartLoginHelper_UINFO', userInfo)
  // console.log('createCartLoginHelper_Product', product)

  let totalProductCost = 0;
  let totalQty = 0;

  const productList = cartData?.productList?.filter((item) => {
    totalProductCost =
      totalProductCost + item?.productSalePrice * item?.currentQuantity;
    totalQty = totalQty + item?.currentQuantity;
    return productId !== item.productId;
  });

  const data = {
    profileId: profileId,
    businessId: businessId,
    profilePhoto: userInfo?.account?.photoURL,
    profileName: userInfo?.account?.name,
    businessLogo: businessInfo?.[0]?.logoURL,
    businessName: businessInfo?.[0]?.businessName,
    businessCity: businessInfo?.[0]?.city,
    orderStatus: "cartcreated",
    notesConsumer: "",

    totalQuantity: totalQty,
    orderTotalTax: 0.0,
    orderTotalProductCost: totalProductCost,
    orderTotalCost: totalProductCost,

    shippingCost: 0.0,
    shippingTax: 0.0,
    claimedPoint: 0.0,
    productList: productList,
    deliverMethod: ["Customer location"],
    modeOfPayment: [""],
    customerAddress: {
      name: "",
      addressLine1: "",
      addressLine2: "",
      houseNo: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      latitude: 0.0,
      longitude: 0.0,
      selected: false,
    },
  };

  console.log("removeCartProductLoginHelper", data);
  return data;
};
