import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Suspense, useEffect, useRef, useState } from "react";
import {
  blackColor,
  borderCard01,
  borderLineColor,
  filterBgColor,
  headingColor,
  iconInactiveColor,
  navLinkColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

import Slider from "react-slick/lib/slider";
import ProductCard from "pages/CatalogMui/ProductCard";
import { getOutOfStock } from "utils/productHelper";
import ScrollIndicator from "components/ScrollIndicator";
import ProductHeaderCard from "./MobileUICards/ProductHeaderCard";
import MobileProductCard from "./MobileUICards/MobileProductCard";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

function HomeMetricGroup({ title, products, type, caption }) {
  const slider = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const containerRef = useRef(null);

  const row1 = products.filter((_, index) => index % 2 === 0);
  const row2 = products.filter((_, index) => index % 2 !== 0);

  const photos = [
    {
      img: "photo1.jpg",
      description: "Description 1",
      price: 100,
      discount: 20,
      oldPrice: 120,
      currencySymbol: "₹",
      greyShade47: "#EAEAF1",
      blackColor: "#000",
      offerTextColor: "#FF0000",
      textOneLines: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      img: "photo2.jpg",
      description: "Description 2",
      price: 150,
      discount: 10,
      oldPrice: 170,
      currencySymbol: "₹",
      greyShade47: "#EAEAF1",
      blackColor: "#000",
      offerTextColor: "#FF0000",
      textOneLines: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      img: "photo3.jpg",
      description: "Description 3",
      price: 200,
      discount: 15,
      oldPrice: 230,
      currencySymbol: "₹",
      greyShade47: "#EAEAF1",
      blackColor: "#000",
      offerTextColor: "#FF0000",
      textOneLines: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      img: "photo4.jpg",
      description: "Description 4",
      price: 250,
      discount: 5,
      oldPrice: 260,
      currencySymbol: "₹",
      greyShade47: "#EAEAF1",
      blackColor: "#000",
      offerTextColor: "#FF0000",
      textOneLines: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      img: "photo5.jpg",
      description: "Description 5",
      price: 300,
      discount: 25,
      oldPrice: 350,
      currencySymbol: "₹",
      greyShade47: "#EAEAF1",
      blackColor: "#000",
      offerTextColor: "#FF0000",
      textOneLines: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      img: "photo6.jpg",
      description: "Description 6",
      price: 350,
      discount: 30,
      oldPrice: 400,
      currencySymbol: "₹",
      greyShade47: "#EAEAF1",
      blackColor: "#000",
      offerTextColor: "#FF0000",
      textOneLines: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    // Add more photo objects as needed
  ];
  const rows = [[], []];
  products.forEach((photo, index) => {
    rows[index % 2].push(photo);
  });

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  const onSlideNext = () => {
    if (currentSlideIndex < products?.length - 6) {
      slider.current.slickNext();
    }
  };

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev();
    }
  };
  const scrollBoxRef = useRef(null);

  const handleScroll = () => {
    const { scrollWidth, scrollLeft, clientWidth } = scrollBoxRef.current;
    if (scrollWidth - scrollLeft === clientWidth) {
      setIsScrollEnd(true);
    } else {
      setIsScrollEnd(false);
    }
  };

  useEffect(() => {
    const scrollBox = scrollBoxRef.current;
    scrollBox.addEventListener("scroll", handleScroll);
    return () => {
      scrollBox.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      // Check if the horizontal scroll has reached the end
      const isEnd =
        container?.scrollLeft + container?.clientWidth + 200 >=
        container?.scrollWidth;

      setIsScrollEnd(isEnd);
    };

    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Suspense fallback={<p></p>}>
      {matches ? (
        <Box mt={1} mb="20px !important" position="relative">
          {/* Header */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography
                variant="h2"
                fontSize={{ md: "17px", xs: "15px" }}
                color={headingColor}
                display="inline"
              >
                {title}
              </Typography>
            </Grid>

            {products?.length > 6 ? (
              <Grid item>
                <Grid container spacing={1.5}>
                  <Grid item display={{ xs: "none", md: "block" }}>
                    <Box
                      width="40px"
                      height="40px"
                      backgroundColor={
                        currentSlideIndex === 0 ? filterBgColor : whiteColor
                      }
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius={2}
                      sx={{ cursor: "pointer" }}
                      border={"1px solid " + borderLineColor}
                      onClick={() => onSlidePrev()}
                    >
                      <ChevronLeft
                        fontSize="medium"
                        sx={{
                          color:
                            currentSlideIndex === 0
                              ? iconInactiveColor
                              : navLinkColor,
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item display={{ xs: "none", md: "block" }}>
                    <Box
                      width="40px"
                      height="40px"
                      backgroundColor={
                        currentSlideIndex === products?.length - 6
                          ? filterBgColor
                          : whiteColor
                      }
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius={2}
                      border={"1px solid " + borderLineColor}
                      sx={{ cursor: "pointer" }}
                      onClick={() => onSlideNext()}
                    >
                      <ChevronRight
                        fontSize="medium"
                        sx={{
                          color:
                            currentSlideIndex === products?.length - 6
                              ? iconInactiveColor
                              : navLinkColor,
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          {/* Products List */}
          <Grid container mt={{ xs: 0, md: 0.3 }} spacing={1}>
            {matches ? (
              <Grid item xs={0} md={2}>
                <SectionInfoCard type={type} caption={caption} />
              </Grid>
            ) : null}

            <Grid item xs={12} md={10}>
              {products?.length > 6 ? (
                <>
                  <Box display={{ xs: "none", md: "block" }}>
                    <Slider
                      ref={slider}
                      {...settings}
                      beforeChange={(oldIndex, newIndex) => {
                        setCurrentSlideIndex(newIndex);
                      }}
                    >
                      {products?.map((item, index) => {
                        if (item?.variantInfo?.masterVariant) return null;

                        return (
                          <Box py={0.5} ml={1} key={`${title}_${index}`}>
                            {
                              <ProductCard
                                img={item?.productPhoto?.[0]?.docURL}
                                price={item?.productSalePrice}
                                oldPrice={item?.productOriginalPrice}
                                description={item?.productName}
                                discount={item?.percentageOff}
                                style={{ my: 1.5 }}
                                // isOutOfStock={getOutOfStock(item)}
                                // isComingSoon={item?.commerceEnabled === 'NO'}
                                minQty={item?.minOrderQuantity}
                                maxQty={item?.maxOrderQuantity}
                                disableAddtoCart
                                product={item}
                              />
                            }
                          </Box>
                        );
                      })}
                    </Slider>
                  </Box>

                  <Box display={{ xs: "block", md: "none" }}>
                    <Box sx={styles.scrollBox} ref={containerRef}>
                      {products?.map((item, index) => {
                        if (item?.variantInfo?.masterVariant) return null;

                        return (
                          <Box py={0.5} ml={1} key={`${title}_${index}`}>
                            <ProductCard
                              img={item?.productPhoto?.[0]?.docURL}
                              price={item?.productSalePrice}
                              oldPrice={item?.productOriginalPrice}
                              description={item?.productName}
                              discount={item?.percentageOff}
                              style={{ my: 1.5 }}
                              isOutOfStock={getOutOfStock(item)}
                              isComingSoon={item?.commerceEnabled === "NO"}
                              minQty={item?.minOrderQuantity}
                              maxQty={item?.maxOrderQuantity}
                              disableAddtoCart
                              product={item}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </>
              ) : matches ? (
                <Box display="flex" flexDirection="row" pl={1}>
                  {products?.map((item, index) => {
                    if (item?.variantInfo?.masterVariant) return null;

                    return (
                      <Box py={0.5} mr={2} key={`${title}_${index}`}>
                        {
                          <ProductCard
                            img={item?.productPhoto?.[0]?.docURL}
                            price={item?.productSalePrice}
                            oldPrice={item?.productOriginalPrice}
                            description={item?.productName}
                            discount={item?.percentageOff}
                            style={{ my: 1.5 }}
                            // isOutOfStock={getOutOfStock(item)}
                            // isComingSoon={item?.commerceEnabled === 'NO'}
                            minQty={item?.minOrderQuantity}
                            maxQty={item?.maxOrderQuantity}
                            disableAddtoCart
                            product={item}
                          />
                        }
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Box sx={styles.scrollBox}>
                  {products?.map((item, index) => {
                    if (item?.variantInfo?.masterVariant) return null;

                    return (
                      <Box py={0.5} ml={1} key={`${title}_${index}`}>
                        <ProductCard
                          img={item?.productPhoto?.[0]?.docURL}
                          price={item?.productSalePrice}
                          oldPrice={item?.productOriginalPrice}
                          description={item?.productName}
                          discount={item?.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={getOutOfStock(item)}
                          isComingSoon={item?.commerceEnabled === "NO"}
                          minQty={item?.minOrderQuantity}
                          maxQty={item?.maxOrderQuantity}
                          disableAddtoCart
                          product={item}
                        />
                      </Box>
                    );
                  })}
                </Box>
                // <Slider
                //   ref={slider}
                //   {...settings}
                //   beforeChange={(oldIndex, newIndex) => {
                //     setCurrentSlideIndex(newIndex)
                //   }}
                // >
                //   {products?.map((item, index) => {
                //     return (
                //       <Box py={0.5} ml={1}>
                //         {
                //           <ProductCard
                //             img={item?.productPhoto?.[0]?.docURL}
                //             price={item?.productSalePrice}
                //             oldPrice={item?.productOriginalPrice}
                //             description={item?.productName}
                //             discount={item?.percentageOff}
                //             style={{ my: 1.5 }}
                //             isOutOfStock={getOutOfStock(item)}
                //             isComingSoon={item?.commerceEnabled === 'NO'}
                //             minQty={item?.minOrderQuantity}
                //             maxQty={item?.maxOrderQuantity}
                //             disableAddtoCart
                //             product={item}
                //           />
                //         }
                //       </Box>
                //     )
                //   })}
                // </Slider>
              )}
            </Grid>
          </Grid>

          {!isScrollEnd && products?.length > 2 && (
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                position: "absolute",
                right: "0px",
                top: "57%",
              }}
            >
              <ScrollIndicator />
            </Box>
          )}
        </Box>
      ) : (
        <Box mt={-1.5}>
          <ProductHeaderCard title={title} hideBtn />
          <Box position={"relative"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflowX: "auto",
                // whiteSpace: 'nowrap',
                "&::-webkit-scrollbar": {
                  display: "none",
                },

                "-webkit-overflow-scrolling": "touch",
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              }}
              ref={scrollBoxRef}
            >
              {rows.map((row, rowIndex) => (
                <Box key={rowIndex} sx={{ display: "flex", gap: "15px" }}>
                  {row.map((item, index) => {
                    if (item?.variantInfo?.masterVariant) return null;

                    return (
                      <Box
                        key={index}
                        // sx={{ flex: '0 0 0', width: 'calc(100% - 10px)', mr: 1 }}
                      >
                        <MobileProductCard
                          productid={item?.id}
                          img={item.productPhoto?.[0]?.docURL}
                          price={item.productSalePrice}
                          oldPrice={item?.productOriginalPrice}
                          description={item?.productName}
                          productDescription={item?.productDescription}
                          discount={products?.[0]?.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={getOutOfStock(item)}
                          isComingSoon={item?.commerceEnabled === "NO"}
                          minQty={item?.minOrderQuantity}
                          maxQty={item?.maxOrderQuantity}
                          disableAddtoCart
                          product={item}
                          direction
                          mapLength={products?.length}
                          rowIndex={rowIndex}
                          variantCount={item?.count}
                        />
                      </Box>
                    );
                  })}
                </Box>
              ))}
              {/* {!isScrollEnd && products?.length > 2 && (
                <Box
                  sx={{
                    position: "absolute",
                    right: "-8px",
                    zIndex: "9",
                    top: "39%",
                    margin: "auto 0",
                    bottom: "84%",
                  }}
                >
                  <IconButton
                    sx={{ ...styles.arrowIconBtn, right: "0px" }}
                    onClick={onSlideNext}
                  >
                    <KeyboardDoubleArrowRightIcon
                      color="inherit"
                      sx={{ fontSize: "20px" }}
                    />
                  </IconButton>
                </Box>
              )} */}
            </Box>
          </Box>
        </Box>
      )}
    </Suspense>
  );
}

export default HomeMetricGroup;

const styles = {
  scrollBox: {
    display: "flex",
    alignItems: "center",

    overflowX: "scroll",
    overscrollBehaviorInline: "contain",

    "&::-webkit-scrollbar": {
      display: "none",
    },

    "-webkit-overflow-scrolling": "touch",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },

  arrowIconBtn: {
    width: "30px",
    height: "38px",
    color: blackColor,
    bgcolor: whiteColor,
    p: 0.7,
    border: borderCard01,
    borderRadius: "7px",
    position: "absolute",
    top: "40%",
    boxShadow: "0 1px 5px rgb(202 202 214 / 55%)",
    "&:hover": {
      color: blackColor,
      bgcolor: whiteColor,
    },
  },
};

const getImageColor = (type) => {
  switch (type) {
    case "trending":
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_trending_product.png",
        bgColor: "#ffe5f5",
      };
    case "top-deals":
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_top_deals.png",
        bgColor: "#f5e7cf",
      };
    case "bestseller":
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_best_sellers.png",
        bgColor: "#fbe6ff",
      };
    case "new-store":
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_new_in_our_store.png",
        bgColor: "#dde8ff",
      };
    case "price-right":
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_product-below-certain.png",
        bgColor: "#dde8ff",
      };
    default:
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_top_deals.png",
        bgColor: "#e7f4db",
      };
  }
};

const SectionInfoCard = ({ type, caption }) => {
  const data = getImageColor(type);
  let businessFullName = sessionStorage.getItem("businessFullName");

  return (
    <Box
      height="210px"
      //   backgroundColor='#f5e7cf'
      backgroundColor={data.bgColor}
      p={1}
      borderRadius={1.5}
      //   display='flex'
      display={{ xs: "none", md: "flex" }}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={1.5}
    >
      <Box
        component="img"
        src={{
          ...data.img,
          transition: "all 0.3s ease-in-out",
          transform: "scale(1)",
          "&:hover": {
            transform: "scale(0.97)",
          },
        }}
        alt={businessFullName + " CostBo"}
        width="180px"
        height="auto"
      />
      {/* <Typography
        variant='h5'
        color={headingColor}
        component='p'
        mt={1.4}
        textAlign='center'
      >
        {caption}
      </Typography> */}
    </Box>
  );
};
