import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ProductCardMinimal from "components/Cards/ProductCardMinimal";
import {
  blackColor,
  borderCard01,
  borderTextbox,
  filterBgColor,
  iconInactiveColor,
  lightBlueColor01,
  navLinkColor,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import { navigateTo } from "utils/urlHelper";

const ProductMetricGroup = ({
  title,
  products,
  imgUrl,
  bgcolor,
  buyProduct,
  onViewMore,
}) => {
  const slider = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const navigate = useNavigate();
  const businessName = sessionStorage.getItem("businessName");

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const onSlideNext = () => {
    if (currentSlideIndex < products?.length - 5) {
      slider.current.slickNext();
    }
  };

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev();
    }
  };

  return (
    <Box sx={styles.card}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={styles.infoCard(bgcolor)}
      >
        <Box component="img" src={imgUrl} sx={styles.img} />

        {/* {onViewMore&&<Button variant='contained' disableElevation sx={styles.shopBtn} onClick={onViewMore}>
          VIEW MORE
        </Button>} */}
      </Stack>

      <Box width="calc(100% - 229px)" pb={"10px"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          padding={"15px 5px 15px 12px"}
        >
          <Typography
            fontSize={16}
            fontWeight={600}
            lineHeight="20px"
            color={blackColor}
          >
            {title}
          </Typography>
          {products?.length > 5 && (
            <Grid item display={{ xs: "none", md: "block" }}>
              <Grid container spacing={1.5}>
                <Grid item>
                  <Box
                    width="30px"
                    height="30px"
                    // backgroundColor={arrowBgColor}
                    backgroundColor={
                      currentSlideIndex > 0 ? filterBgColor : whiteColor
                    }
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={2}
                    sx={{ cursor: "pointer" }}
                    // onClick={() => onPrev()}
                    onClick={onSlidePrev}
                    border={borderTextbox}
                  >
                    <ChevronLeft
                      // fontSize={"medium"}
                      sx={{
                        fontSize: "22px",
                        color:
                          currentSlideIndex > 0
                            ? blackColor
                            : iconInactiveColor,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item>
                  <Box
                    width="30px"
                    height="30px"
                    // backgroundColor={arrowBgColor}
                    backgroundColor={
                      currentSlideIndex < products?.length - 5
                        ? filterBgColor
                        : whiteColor
                    }
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={2}
                    sx={{ cursor: "pointer" }}
                    // onClick={() => onNext()}
                    onClick={onSlideNext}
                    border={borderTextbox}
                  >
                    <ChevronRight
                      sx={{
                        fontSize: "22px",
                        color:
                          currentSlideIndex < products?.length - 5
                            ? blackColor
                            : iconInactiveColor,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
        {products?.length > 5 ? (
          <Box
            sx={{
              borderRadius: "20px",
            }}
            position="relative"
          >
            <Box overflow="hidden">
              <Slider
                ref={slider}
                {...settings}
                beforeChange={(oldIndex, newIndex) => {
                  setCurrentSlideIndex(newIndex);
                }}
              >
                {products?.map((item, index) => (
                  <ProductCardMinimal
                    key={`${title}_${index}`}
                    title={buyProduct ? item?.name : item?.productName}
                    imgUrl={
                      buyProduct
                        ? item?.item?.productPhoto?.[0]?.docURL
                        : item?.productPhoto?.[0]?.docURL
                    }
                    oldPrice={
                      buyProduct
                        ? item?.item?.productOriginalPrice
                        : item?.productOriginalPrice
                    }
                    price={
                      buyProduct
                        ? item?.item?.productSalePrice
                        : item?.productSalePrice
                    }
                    discount={
                      buyProduct
                        ? item?.item?.percentageOff
                        : item?.percentageOff
                    }
                    onClick={() => {
                      // navigate(`/${businessName}/singleProduct/${item?.id}`);
                      navigateTo(navigate, `singleProduct/${buyProduct ? item?.productId : item?.id}`)
                    }}
                    variantCount={item?.count}
                    product={item}
                    buyProduct={buyProduct}
                  />
                ))}
              </Slider>
            </Box>

            {/* {products?.length > 5 && (
              <>
                {currentSlideIndex > 0 && (
                  <IconButton
                    sx={{ ...styles.arrowIconBtn, left: "-20px" }}
                    onClick={onSlidePrev}
                  >
                    <KeyboardArrowLeftIcon color="inherit" />
                  </IconButton>
                )}

                {currentSlideIndex < products?.length - 5 && (
                  <IconButton
                    sx={{ ...styles.arrowIconBtn, right: "-20px" }}
                    onClick={onSlideNext}
                  >
                    <KeyboardArrowRightIcon color="inherit" />
                  </IconButton>
                )}
              </>
            )} */}
          </Box>
        ) : (
          <Grid container>
            {products?.slice(0, 5)?.map((item, index) => (
              <Grid key={`${title}_${index}`} item md={2.4}>
                <ProductCardMinimal
                  title={buyProduct ? item?.name : item?.productName}
                  imgUrl={
                    buyProduct
                      ? item?.item?.productPhoto?.[0]?.docURL
                      : item?.productPhoto?.[0]?.docURL
                  }
                  oldPrice={
                    buyProduct
                      ? item?.item?.productOriginalPrice
                      : item?.productOriginalPrice
                  }
                  price={
                    buyProduct
                      ? item?.item?.productSalePrice
                      : item?.productSalePrice
                  }
                  discount={
                    buyProduct ? item?.item?.percentageOff : item?.percentageOff
                  }
                  onClick={() => {
                    // navigate(
                    //   `/${businessName}/singleProduct/${
                    //     buyProduct ? item?.productId : item?.id
                    //   }`
                    // );
                    navigateTo(navigate, `singleProduct/${buyProduct ? item?.productId : item?.id}`)
                  }}
                  variantCount={item?.count}
                  product={item}
                  buyProduct={buyProduct}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default ProductMetricGroup;

const styles = {
  card: {
    display: "flex",
    borderRadius: "15px",
    bgcolor: whiteColor,
    mb: "30px",
  },

  infoCard: (bgcolor) => ({
    width: "206px",
    height: "255px",
    borderRadius: "15px 0 0 15px",
    p: "10px",
    bgcolor: bgcolor,
  }),

  img: {
    width: "200px",
    height: "180px",
    objectFit: "contain",
    transition: "all 0.3s ease-in-out",
    transform: "scale(1)",
    "&:hover": {
      transform: "scale(0.97)",
    },
  },

  shopBtn: {
    fontSize: 12.5,
    fontWeight: 600,
    color: blackColor,
    bgcolor: whiteColor,
    lineHeight: "18px",
    p: "8px 16px",
    borderRadius: "20px",
    "&:hover": {
      bgcolor: blackColor,
      color: whiteColor,
    },
  },

  arrowIconBtn: {
    width: "32px",
    height: "38px",
    color: blackColor,
    bgcolor: whiteColor,
    p: 0.7,
    border: borderCard01,
    borderRadius: "7px",
    position: "absolute",
    top: "40%",
    boxShadow: "0 1px 5px rgb(202 202 214 / 55%)",
    "&:hover": {
      color: blackColor,
      bgcolor: whiteColor,
    },
  },
};
