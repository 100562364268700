import { NavigateNextRounded } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { breadcrum_ShopMore } from "configs/Constants";
import {
  blackColor,
  borderCart,
  greenColor05,
  greyShade46,
  greyShade49,
  greyShade50,
  headingColor,
  orangeColor01,
  tabTextOrangeColor,
  whiteColor,
} from "configs/styles/muiThemes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getActiveBusiness } from "redux/header/headerSlice";
import { navigateTo } from "utils/urlHelper";

const BreadcrumbNew = ({
  links,
  itemCount,
  totalPrice,
  discount,
  currentPage,
  setCurrentPage,
  onProceed,
  btn,
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { businessDetails } = useSelector((state) => state.header);
  const businessName = sessionStorage.getItem("businessName");

  const [businessTitle, setBusinessTitle] = useState("");

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    const businessInfo = sessionStorage.getItem("businessInfo");
    if (!businessInfo) {
      dispatch(getActiveBusiness());
    } else {
      const data = JSON.parse(businessInfo);
      setBusinessTitle(data?.[0]?.businessName);
    }
  }, [businessDetails]);
  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: whiteColor,
        p: "8px 15px",
        borderRadius: "10px",
        border: `1px solid ${greyShade49}`,
        minHeight: "34px",
      }}
    >
      <Breadcrumbs separator={<NavigateNextRounded fontSize="small" />}>
        <Typography
          key="1"
          color={blackColor}
          fontSize="13px"
          fontWeight={500}
          // component={Link}
          // to={`/${businessName}`}
          onClick={() => {
            navigateTo(navigate, "");
          }}
        >
          {businessTitle?.length > 30
            ? businessTitle?.slice(0, 30) + "..."
            : businessTitle}
          {/* {businessTitle?.split(" ").slice(0, 2).join(" ")} */}
        </Typography>
        {links?.map((link, index) => {
          return (
            <Typography
              key="2"
              color={
                currentPage === "discount" || currentPage === "address-page"
                  ? blackColor
                  : index == links?.length - 1
                  ? greyShade50
                  : blackColor
              }
              fontSize="13px"
              fontWeight={500}
              // component={
              //   currentPage === "discount" || currentPage === "address-page"
              //     ? Link
              //     : index == links?.length - 1
              //     ? "p"
              //     : Link
              // }
              // to={link?.disabled ? "" : `/${businessName}${link.to}`}
              component="p"
              onClick={() => {
                navigateTo(navigate, `${link?.to?.slice(1)}`);
              }}
            >
              {link.label}
            </Typography>
          );
        })}
        {matches ? (
          currentPage === "discount" || currentPage === "address-page" ? (
            <Typography
              key="2"
              color={
                currentPage === "address-page"
                  ? blackColor
                  : { xs: orangeColor01, md: greyShade50 }
              }
              fontSize="13px"
              fontWeight={500}
              onClick={() => {
                setCurrentPage("discount");
              }}
              sx={{ cursor: currentPage === "address-page" ? "pointer" : "" }}
            >
              Discounts/Coupons
            </Typography>
          ) : null
        ) : null}

        {matches ? (
          currentPage === "address-page" ? (
            <Typography
              key="2"
              color={
                currentPage === "address-page" ? greyShade50 : headingColor
              }
              fontSize="14px"
            >
              Address & Delivery
            </Typography>
          ) : null
        ) : null}
      </Breadcrumbs>

      {itemCount > 0 ? (
        discount === "" ? (
          <Button
            sx={{
              textTransform: "none",
              color: { md: orangeColor01, xs: blackColor },
              textDecorationLine: "underline",
              fontSize: { md: "12px", xs: 13 },
              fontWeight: { md: 600, xs: 500 },
              textTransform: "uppercase",
              p: "0",
              "&:hover": {
                backgroundColor: whiteColor,
                color: discount ? "#10847e" : orangeColor01,
              },
            }}
            endIcon={<NavigateNextRounded />}
            // component={Link}
            // to={`/${businessName}/catalog`}
            onClick={() => {
              navigateTo(navigate, "catalog");
            }}
          >
            {breadcrum_ShopMore}
          </Button>
        ) : (
          <Box>
            <Typography
              variant="h6"
              color={greyShade46}
              fontSize="13px"
              fontWeight={400}
              display="inline"
              px={2}
              borderRight={borderCart}
            >
              {itemCount} items in cart
            </Typography>

            <Typography
              variant="h5"
              color={headingColor}
              fontSize="15px"
              fontWeight={600}
              display="inline"
              mx={2}
            >
              ₹ {Number(totalPrice).toFixed(2)}
            </Typography>

            {discount !== "address-page" && (
              <Button
                // component={!btn && Link}
                // to={
                //   discount === "discount" || discount === "cart"
                //     ? `/${businessName}/discountDelivery`
                //     : `/${businessName}/Cart`
                // }
                // onClick={() => {
                  
                // }}
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  color: whiteColor,
                  p: "3px 20px",
                  height: "34px",
                  border: discount
                    ? "2px solid " + greenColor05
                    : "2px solid " + orangeColor01,
                  bgcolor: discount ? greenColor05 : orangeColor01,
                  textTransform: "uppercase",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: whiteColor,
                    color: discount ? greenColor05 : orangeColor01,
                  },
                  '&.Mui-disabled': {
                    border:'none'
                  }
                }}
                variant="contained"
                disableElevation
                size="small"
                disabled={isDisabled}
                onClick={() => {
                  switch (discount) {
                    case "discount":
                      setCurrentPage("address-page");
                      break;
                    case "cart":
                      onProceed();
                      break;
                    default:
                      navigateTo(
                        navigate,
                        discount === "discount" || discount === "cart"
                          ? `discountDelivery`
                          : `Cart`
                      );
                      // You can handle any other default behavior here if needed
                      break;
                  }
                }}
              >
                {discount === "cart"
                  ? "Proceed To Checkout"
                  : discount === "discount"
                  ? " Continue Checkout"
                  : "GO TO CART"}
              </Button>
            )}
          </Box>
        )
      ) : null}
    </Box>
  );
};

export default BreadcrumbNew;

const styles = {
  gotoCardBtn: {
    fontSize: "12px",
    fontWeight: 600,
    color: whiteColor,
    p: "2px 15px",
    border: "2px solid " + tabTextOrangeColor,
    bgcolor: tabTextOrangeColor,
    textTransform: "none",
    borderRadius: "25px",
    "&:hover": {
      backgroundColor: whiteColor,
      color: "primary.main",
    },
  },
};
