import { Skeleton, Stack } from "@mui/material";
import React from "react";

function ProductMobilePopupVariantLoader() {
  return (
    <Stack gap={{ md: "12px", xs: "8px" }}>
      <Skeleton variant="rounded" width="100%" height="70px" />
      <Skeleton variant="rounded" width="100%" height="70px" />
      <Skeleton variant="rounded" width="100%" height="70px" />
    </Stack>
  );
}

export default ProductMobilePopupVariantLoader;
