import moment from 'moment/moment'
import { LATITUDE, LONGITUDE } from '../../../configs/Constants'
import {
  getPhoneNumber,
  getProfileId,
  getUserName,
} from '../../../utils/authHelpers'
import { getApiInstance, getApiInstanceShortIo } from '../../ApiInstance'
import {
  ADD_INFLUENCER_SHARE,
  ADD_RESELLER,
  ADD_RESELLER_PROFILE,
  GET_AFFILIATE_PERCENTAGE,
  GET_AFFILIATE_PRODUCTS_BY_BID,
  GET_AFFILIATE_VALIDITY,
  GET_GEO_POINT,
  GET_PAYMENT_TRANSACTIONS,
  GET_REF_RATES_BY_BUSINESS,
  GET_RESELLER_PROFILE,
  LINK_SHORTEN_URL,
  UPDATE_RESELLER_BANK,
  VALIDATE_RESELLER_PROFILE_CODE,
} from '../../constant'
import { getBusinessIdentifier } from 'utils/urlHelper'
import { siteRoutes } from 'utils/data'

// Get Reseller Profile Details
export const getResellerProfileApi = async (data) => {
  try {
    const instance = getApiInstance()
    const response = await instance.get(GET_RESELLER_PROFILE)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

// Validate Affiliate Code
export const validateAffiliateCodeApi = async (data) => {
  try {
    const instance = getApiInstance()
    const response = await instance.get(
      `${VALIDATE_RESELLER_PROFILE_CODE}${data}`
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

// get Payment Transactions
export const getPaymentTransactionsApi = async (data) => {
  const profileId = getProfileId()
  const businessId = sessionStorage.getItem('businessId')

  try {
    const instance = getApiInstance({
      header: {
        businessId,
        resellerProfileId: profileId,
      },
    })
    const response = await instance.get(`${GET_PAYMENT_TRANSACTIONS}`, {
      params: {
        pageNo: data?.pageNo,
        status: data?.status,
        month: data?.month,
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

// get Geo Points, city, state..
export const getGeoPointApi = async (data) => {
  try {
    const instance = getApiInstance()
    const response = await instance.get(`${GET_GEO_POINT}${data}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

// Add Reseller Profile Details
export const addResellerProfileAPI = async (data) => {
  const profileId = getProfileId()
  const postData = prepareResellerData(data)

  try {
    const instance = getApiInstance()
    const response = await instance.post(`${ADD_RESELLER_PROFILE}`, {
      ...postData,
    })
    return response.data
  } catch (error) {
    console.log(error)
    return null
  }
}

const prepareResellerData = (resellerInfo) => {
  const profileId = getProfileId()
  const phoneNumber = getPhoneNumber()
  const lat = localStorage.getItem('lat') || LATITUDE
  const long = localStorage.getItem('long') || LONGITUDE

  const data = {
    profileId: profileId,
    name: resellerInfo?.code?.name,
    panNo: resellerInfo?.bankDetails?.panNumber || '',
    phoneNo: phoneNumber,
    currency: '₹',
    countryCode: '+91',
    type: 'individual',
    status: 'active',
    gstNo: '',
    expelledReason: 'None',
    addressInfo: {},
    bankInfo: {},
    resellerExpertises: [],
    resellerCode: resellerInfo?.code?.affiliateCode,
    referralCode: resellerInfo?.code?.refferedBy,
    socialMediaInfo: [],
  }

  return data
}

// Update Reseller Banking Details
export const updateResellerBankDetailsAPI = async (data) => {
  const profileId = getProfileId()
  const username = getUserName()

  const postData = {
    key: data?.key,
    profileId: profileId,
    accountNo: data?.bankDetails?.accountNo,
    ifsc: data?.bankDetails?.ifscCode,
    bankName: data?.bankDetails?.bankName,
    accountType: data?.bankDetails?.accountType,
    panNumber: data?.bankDetails?.panNumber,
    phoneNo: null,
    accountName: username,
    emailId: null,
  }

  console.log('Update_Bank_RELLSER:', postData)

  try {
    const instance = getApiInstance()
    const response = await instance.post(`${UPDATE_RESELLER_BANK}`, {
      ...postData,
    })
    return response.data
  } catch (error) {
    console.log(error)
    return null
  }
}

// get affiliate percentage products
export const getAffiliateProductsApi = async (data) => {
  try {
    const instance = getApiInstance()
    const response = await instance.get(`${GET_AFFILIATE_PERCENTAGE}`, {
      params: {
        gte: data?.gte,
        lte: data?.lte,
      },
    })

    const postData = []

    response?.data?.details?.forEach((element) => {
      postData.push(element?.businessId)
    })

    const products = await getAffiliateProductsByBusinessId({
      postData,
      pageNo: '1',
    })

    return { ...products, affiliatePercentage: response?.data?.details }
  } catch (error) {
    console.log(error)
    return null
  }
}

// get Affiliate Products By BusinessId
export const getAffiliateProductsByBusinessId = async (data) => {
  const profileId = getProfileId()

  try {
    const instance = getApiInstance()
    const response = await instance.post(
      `${GET_AFFILIATE_PRODUCTS_BY_BID}1`,
      data?.postData
    )
    return response.data
  } catch (error) {
    console.log(error)
    return null
  }
}

// get Affiliate Validity
export const getAffiliateValidityApi = async (businessId) => {
  const profileId = getProfileId()

  try {
    const instance = getApiInstance({
      header: {
        businessId,
      },
    })
    const response = await instance.get(`${GET_AFFILIATE_VALIDITY}${profileId}`)
    return response.data
  } catch (error) {
    console.log(error)
    return null
  }
}

// Add Reseller When Share Clicked
export const addResellerOnShareAPI = async (data) => {
  const postData = prepareAddResellerOnShareData(data)
  console.log('ADD_RESELLER:', postData)

  try {
    const instance = getApiInstance()
    const response = await instance.post(`${ADD_RESELLER}`, {
      ...postData,
    })
    return response.data
  } catch (error) {
    console.log(error)
    return null
  }
}

const getCatalogUrl = (businessData)=>{
  const hostname = window.location.hostname;
  const url = window.location?.href

  const businessIdentifier = getBusinessIdentifier(url) || ''

  const isCostBoDomain =  hostname?.includes('costbo.com')
  const isSubBranch = siteRoutes?.includes(businessIdentifier?.toLowerCase())

  // Determine routes based on conditions
  if (isCostBoDomain) {
    const businessName = sessionStorage.getItem("businessName");
    console.log("NAVIGATE_TO_isCostBoDomain:", {businessIdentifier, isCostBoDomain, isSubBranch});
   
    return `https://ondc.costbo.com/${businessData?.costboWebURL}`
  } else {
    console.log("NAVIGATE_TO_not_isCostBoDomain:", {businessIdentifier, isCostBoDomain, isSubBranch});
    // const businessName = sessionStorage.getItem("businessName");
    const routeStr = isSubBranch ? `https://${hostname}` : `https://ondc.costbo.com/${businessData?.costboWebURL}`
    return routeStr
  }
}

const prepareAddResellerOnShareData = (postDetails) => {
  const profileId = getProfileId()
  const phoneNumber = getPhoneNumber()
  const username = getUserName()

  const productDetails = postDetails?.productDetails
  const commissionDetails = postDetails?.commissionDetails
  const rellerProfileData = postDetails?.rellerProfileData
  const businessData = postDetails?.businessData

  const lat = localStorage.getItem('lat') || LATITUDE
  const long = localStorage.getItem('long') || LONGITUDE

  const catalogUrl = getCatalogUrl(businessData)

  const data = {
    businessId: businessData?.id,
    businessName: businessData?.name,
    profileId: profileId,
    phoneNo: phoneNumber,
    resellerName: username,
    // resellerBusinessName: businessData?.name,
    // resellerBusinessId: businessData?.id,
    resellerBusinessName: null,
    resellerBusinessId: null,
    resellerCode: rellerProfileData?.resellerCode,
    resellerType: 'individual',
    status: 'active',
    cancelReason: 'NA',
    resellerPan: '',
    commissionPercentage: commissionDetails?.commissionPercentage,
    commissionAmount: 0.0,
    discountPercentage: commissionDetails?.discountPercentage,
    activatedDate: moment().valueOf(), //Today Date
    deactivatedDate: moment('31 Dec 2025', 'DD MMM YYYY').valueOf(),
    codeValidityStart: moment().valueOf(), //Today Date
    codeValidityEnd: moment('31 Dec 2025', 'DD MMM YYYY').valueOf(),
    shipmentDistance: 0,
    hyperLocal: 'N',
    catalogUrl,
    // catalogUrl: `https://www.costbo.com/${businessData?.costboWebURL}`,
    businessLogo: businessData?.logoURL,
    latitude: lat,
    longitude: long,
    businessCategories: businessData?.businessCategories,
    documentsURL: businessData?.documentsURL,
    mainProducts: businessData?.mainProducts,
    businessArea: businessData?.businessArea,
    businessDescription: businessData?.description,
    productInfo: [
      {
        code: productDetails?.productId,
        productName: productDetails?.name,
      },
    ],
  }

  return data
}

// Add Influencer Share
export const addInfluencerShareAPI = async (data) => {
  const postData = prepareAddInfluencerShareData(data)
  // console.log('ADD_INFLUENCER_SHARE:', postData)

  try {
    const instance = getApiInstance()
    const response = await instance.post(`${ADD_INFLUENCER_SHARE}`, {
      ...postData,
    })
    return response.data
  } catch (error) {
    console.log(error)
    return null
  }
}

const prepareAddInfluencerShareData = (postDetails) => {
  const profileId = getProfileId()

  const productDetails = postDetails?.productDetails
  const commissionDetails = postDetails?.commissionDetails
  const businessData = postDetails?.businessData
  const shareMedium = postDetails?.shareMedium
  const shareType = postDetails?.shareType

  const deviceName = postDetails?.deviceName

  const lat = localStorage.getItem('lat') || LATITUDE
  const long = localStorage.getItem('long') || LONGITUDE

  const data = {
    profileId: profileId,
    businessId: businessData?.id,
    productId: productDetails?.productId || '',
    latitude: lat,
    longitude: long,
    shareType: 'influencerproductshare',
    shareInfo: {
      influencerId: profileId,
      businessName: businessData?.name,
      productName: productDetails?.name || '',
      businessLogo: businessData?.logoURL,
      productPic: productDetails?.productPhoto?.[0]?.docURL || '',
      type: shareType || 'product',
      influencerCommission: commissionDetails?.[0]?.commissionPercentage,
      consumerCommission: commissionDetails?.[0]?.discountPercentage,
      status: 'active',
      deviceName: deviceName,
      shareMedium: shareMedium,
    },
  }

  return data
}

// link shortening

const getCatalogSharableLink = (businessData, rellerProfileData, data)=>{
  const hostname = window.location.hostname;
  const url = window.location?.href

  const businessIdentifier = getBusinessIdentifier(url) || ''

  const isCostBoDomain =  hostname?.includes('costbo.com')
  const isSubBranch = siteRoutes?.includes(businessIdentifier?.toLowerCase())

  // Determine routes based on conditions
  if (isCostBoDomain) {
    const businessName = sessionStorage.getItem("businessName");
    console.log("NAVIGATE_TO_isCostBoDomain:", {businessIdentifier, isCostBoDomain, isSubBranch});

    const shopCostBoURL = `https://ondc.costbo.com/${businessData?.costboWebURL}?atag=${rellerProfileData?.resellerCode}`
    const sharableLink = data.isProductShare ? `https://ondc.costbo.com/${businessData?.costboWebURL}/singleProduct/${data?.productId}?atag=${rellerProfileData?.resellerCode}` : shopCostBoURL
    return sharableLink
  } else {
    console.log("NAVIGATE_TO_not_isCostBoDomain:", {businessIdentifier, isCostBoDomain, isSubBranch});
    // const businessName = sessionStorage.getItem("businessName");

    const domainUrl = isSubBranch ? `https://${hostname}` : `https://ondc.costbo.com/${businessData?.costboWebURL}`

    const shopCostBoURL = `${domainUrl}?atag=${rellerProfileData?.resellerCode}`
    const sharableLink = data.isProductShare ? `${domainUrl}/singleProduct/${data?.productId}?atag=${rellerProfileData?.resellerCode}` : shopCostBoURL
    
    return sharableLink
  }
}

export const getShortLink = async (data) => {
  const profileId = getProfileId()
  const businessData = data?.businessData
  const rellerProfileData = data?.rellerProfileData

  const todayDate = moment().format('DDMMYYYY')

  const currentDate = moment()
  const expiryDate = moment(currentDate).add(30, 'days').valueOf()

  const sixDigitRandomNum = Math.floor(100000 + Math.random() * 900000)

  // const shopCostBoURL = `https://shop.costbo.com/${businessData?.costboWebURL}?atag=${rellerProfileData?.resellerCode}`
  // const sharableLink = data?.isProductShare ? `https://shop.costbo.com/${businessData?.costboWebURL}/singleProduct/${data?.productId}?atag=${rellerProfileData?.resellerCode}` : shopCostBoURL

  const sharableLink = getCatalogSharableLink(businessData, rellerProfileData, data)
  const postData = {
    tags: [`${rellerProfileData?.resellerCode}`],
    domain: 'bulkdirect.in',
    allowDuplicates: false,
    // originalURL: `https://shop.costbo.com/${businessData?.costboWebURL}?atag=${rellerProfileData?.resellerCode}`,
    originalURL: sharableLink,
    title: businessData?.name,
    redirectType: 301,
    ttl: expiryDate, //current date in ms + 30days
    // expiresAt: expiryDate,
    utmSource: 'affiliate',
    utmMedium: 'App',
    utmCampaign: `${todayDate}-launchoffer`,
    utmTerm: 'referral',
    utmContent: `${profileId}-${sixDigitRandomNum}`,
    cloaking: false,
  }

  console.log('Link_short', postData)

  try {
    // const instance = getApiInstanceShortIo()
    const instance = getApiInstance()
    const response = await instance.post(LINK_SHORTEN_URL, {
      ...postData,
    })
    return response.data
  } catch (error) {
    console.log(error)
    return null
  }
}

// Get Reseller Rates By Business
export const getRefRatesByBusinessApi = async (data) => {
  try {
    const instance = getApiInstance()
    const response = await instance.get(GET_REF_RATES_BY_BUSINESS, {
      params: {
        businessId: data,
      },
    })

    console.log("BUSINESS_AFF_DATA", response.data);
    return response.data
  } catch (error) {
    console.log(error)
  }
}