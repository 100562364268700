import { getBusinessIdDetailsByDomain } from "redux/rootSlice/RootSlice";
import { domainCheckList, siteRoutes } from "./data";

export const getUrlPathParameter = (url, key) => {
  const params = new URLSearchParams(url)
  return params.get(key)
}

export const setSessionStorage = (url) => {
  // sessionStorage.removeItem('data')
  // const code = getUrlPathParameter(url, 'businessId')
  // const primary = getUrlPathParameter(url, 'primary')
  // const sec = getUrlPathParameter(url, 'sec')

  // if (code === null || code.length === 0) return
  // //   sessionStorage.setItem('businessId', '186492f9a0fa41e29ab17dcc0ab3ba7f')
  // // else sessionStorage.setItem('businessId', code)

  // if (primary === null || primary.length === 0)
  //   sessionStorage.setItem('primary', '40A376')
  // else sessionStorage.setItem('primary', primary)

  // if (sec === null || sec.length === 0) sessionStorage.setItem('sec', '3B5095')
  // else sessionStorage.setItem('sec', sec)
}

export const getBusinessIdentifier = (url) => {
  try {
    // Create a URL object
    const urlObj = new URL(url);
    // Get the path segments
    const pathSegments = urlObj.pathname.split('/').filter(segment => segment);
    // Return the first segment as the business identifier
    return pathSegments[0] || null; // Return null if no identifier is found
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
}

// resolve domain url to business id
export const resolveDomainUrl = (dispatch) => {
  const hostname = window.location.hostname;
  // const hostname = 'https://haldirams-demo.com';
  dispatch(getBusinessIdDetailsByDomain(`https://${hostname}`)).then((res) => {
    console.log("HOME_USE_EFFECT_WEB_URL_HIT_domain", res?.payload);
  });
};

export const navigateTo = (navigate, route, state)=>{
  const hostname = window.location.hostname;
  const url = window.location?.href

  const businessIdentifier = getBusinessIdentifier(url) || ''
  const costBoDomains = domainCheckList

  // const isCostBoDomain = costBoDomains?.includes(hostname) || hostname?.includes('costbo.com')
  const isCostBoDomain =  hostname?.includes('costbo.com')
  const isSubBranch = siteRoutes?.includes(businessIdentifier?.toLowerCase())

  // Determine routes based on conditions
  if (isCostBoDomain) {
    const businessName = sessionStorage.getItem("businessName");
    console.log("NAVIGATE_TO_isCostBoDomain:", {businessIdentifier, isCostBoDomain, isSubBranch});
    navigate(`/${businessName}/${route}`, state || {})
  } else {
    console.log("NAVIGATE_TO_not_isCostBoDomain:", {businessIdentifier, isCostBoDomain, isSubBranch});
    // const businessName = sessionStorage.getItem("businessName");
    const routeStr = isSubBranch ? `/${route}` : `/${sessionStorage.getItem("businessName")}/${route}`
    navigate(routeStr, state || {})
  }
  
  // if(domainCheckList?.includes(hostname)){
  //   const businessName = sessionStorage.getItem("businessName");
  //   console.log("NAVIGATE_TO:", `/${businessName}/${route}`);
  //   navigate(`/${businessName}/${route}`, state || {})
  // }else{
  //   console.log("NAVIGATE_TO:", `/${route}`);
  //   navigate(`/${route}`, state || {})
  // }
}