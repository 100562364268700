import { Box, Button, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import {
  backgroundColor,
  backgroundColor01,
  blackColor,
  borderLineColor,
  categoryLabelColor,
  delectIconInactiveColor,
  greenColor04,
  greenColor05,
  headingColor,
  lightGreen03,
  orangeColor01,
  savingTextColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import DeliveryAddress from './DeliveryAddress'
import DeliveryMethod from './DeliveryMethod'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { useNavigate } from 'react-router-dom'
import TableProductCard from 'components/TableProductCard'
import SpecialNotes from './SpecialNotes'
import { getUserDetails } from 'utils/authHelpers'
import { orderConfirmation_Info } from 'configs/Constants'
import PaymentType from 'pages/OrderDetails/PaymentType'
import ShoppingBasketRoundedIcon from '@mui/icons-material/ShoppingBasketRounded'
import { navigateTo } from 'utils/urlHelper';

function ConfirmationContent({ commercialDetails, cartDetails }) {

  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  let totalQty = 0
  let totalSavings = 0

  const businessName = sessionStorage.getItem('businessName')
  let navigate = useNavigate()

  const userInfo = getUserDetails()

  return (
    <>
      {matches ? (
        <>
          <Grid
            container
            spacing={3}
            backgroundColor={{xs:backgroundColor, md: backgroundColor01}}
          >
            <Grid item xs={12} md={8.25}>
              <Box
                sx={{
                  ...styles.cartContainer,
                  p: 3,
                  // width: '100%',
                  // border: '1px solid #e2e5ec',
                  // borderRadius: '12px',
                  // background: '#fff',
                  // padding: '10px',
                  // // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.06)',
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    marginTop: "4px",
                    marginBottom: "0px",
                    color: "#000",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                >
                  {" "}
                  Congratulations !!
                </Typography>
                <Box sx={{ margin: 0, padding: 0, border: 0 }}>
                  <Typography
                    sx={{
                      color: "#9ba3aa",
                      fontSize: "13.5px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      marginTop: "4px",
                      padding: "0px 10px",
                    }}
                  >
                    Your order is accepted. Your items are on the way and should
                    arrive shortly.
                  </Typography>
                  <Box
                    component="img"
                    maxHeight={{ xs: "250px", md: "250px" }}
                    maxWidthwidth={"250px"}
                    borderRadius={"8px"}
                    src={
                      "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/success.gif"
                    }
                    alt="CostBo"
                  />
                </Box>
                <Box padding={"10px 15px"}>
                  <Typography
                    sx={{ color: blackColor, fontSize: 14, fontWeight: 500 }}
                  >
                    Order ID:{" "}
                    <Typography
                      component={"strong"}
                      sx={{
                        color: { md: orangeColor01, xs: lightGreen03 },
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "27px",
                        marginTop: "12px",
                        marginBottom: "6px",
                      }}
                    >
                      {cartDetails?.orderId}
                    </Typography>
                  </Typography>

                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      color: blackColor,
                      fontSize: "13px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      // marginTop: '12px',
                      // marginBottom: '6px',
                    }}
                  >
                    {orderConfirmation_Info} {userInfo?.account?.email}
                  </Typography>
                </Box>
                <Button
                  sx={styles.actionBtnMobile()}
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={() => {
                    // navigate(`/${businessName}/catalog`);
                    navigateTo(navigate, `catalog`)
                  }}
                
                >
                  Continue Shopping
                </Button>
              </Box>

              <Box sx={styles.cartContainer} my={2}>
                <Typography
                  variant="h5"
                  component="h3"
                  fontSize={{ xs: 14, md: 16 }}
                  color={{ md: headingColor, xs: blackColor }}
                  ml={1}
                  mt={1.5}
                  mb="6px !important"
                  pb={1}
                >
                  <ShoppingBasketRoundedIcon
                    sx={{
                      margin: "0 6px -3px 0",
                      fontSize: { md: "18px", xs: "22px" },
                      // height:"15px",
                      //width:"22px"
                    }}
                  />
                  {cartDetails?.productList?.reduce((accumulator, product) => {
                    return accumulator + product.currentQuantity;
                  }, 0) || 0}{" "}
                  Items in Order
                </Typography>

                {/* <Grid
                  container
                  py={1}
                  display={{ xs: 'none', md: 'inline-flex' }}
                  spacing={{ md: 3 }}
                >
                  <Grid item xs={5}>
                    <Typography
                      variant='subtitle1'
                      component='h4'
                      fontSize='13px'
                      color={delectIconInactiveColor}
                      ml={1.8}
                    >
                      PRODUCT
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Typography
                      variant='subtitle1'
                      component='h4'
                      fontSize='13px'
                      color={delectIconInactiveColor}
                    >
                      UNIT PRICE
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant='subtitle1'
                      component='h4'
                      fontSize='13px'
                      color={delectIconInactiveColor}
                    >
                      QUANTITY
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography
                      variant='subtitle1'
                      component='h4'
                      fontSize='13px'
                      color={delectIconInactiveColor}
                      ml={0.6}
                    >
                      PRICE
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      variant='subtitle1'
                      component='h4'
                      fontSize='13px'
                      color={delectIconInactiveColor}
                    >
                      SAVINGS
                    </Typography>
                  </Grid>
                </Grid> */}
                {cartDetails?.productList?.map((item, index) => {
                  const savings =
                    Number(item?.productOriginalPrice) *
                      Number(item?.currentQuantity) -
                    Number(item?.productSalePrice) *
                      Number(item?.currentQuantity);
                  totalQty = totalQty + item.currentQuantity;
                  totalSavings = totalSavings + savings;
                  return (
                    <TableProductCard
                      key={"cart_product_" + index}
                      image={item?.productPicURL}
                      productName={item?.productName}
                      oldUnitPrice={item?.productOriginalPrice}
                      unitprice={item?.productSalePrice}
                      discount={item?.percentageOff}
                      price={item?.productTotalPrice}
                      saving={savings}
                      quantity={item.currentQuantity}
                      isOutofStock={item.isOutOfStock}
                      cartData={cartDetails?.productList}
                      cartId={cartDetails?.cartId}
                      product={item}
                      readOnly={true}
                    />
                  );
                })}

                {/* {dummyCartProducts.map((item, index) => {
              return (
                <CartProductCard
                  key={'cart_product_' + index}
                  image='https://storage.googleapis.com/bo3151920215/business/38bd76c370274724ba9feaa032266ee4/product/8cc515416c36427b9a83e0c2c3f6afaa/Product4-687725.png'
                  productName='31449 Michelin Air Conditioning System Cleaner - 150 ml'
                  caterogy='Auto Parts'
                  oldUnitPrice='340.00'
                  unitprice='240.00'
                  discount={20}
                  price='300.00'
                  saving='00.00'
                  quantity={item.quantity}
                  isDiscount={item.isDiscount}
                  isOutofStock={item.isOutOfStock}
                  onDeleteProduct={() => {}}
                  readOnly={true}
                />
              )
            })} */}
              </Box>
            </Grid>
            <Grid item xs={12} md={3.75} mt={0.5}>
              <Typography
                variant="h5"
                component="h3"
                fontSize={{ xs: 14, md: 14 }}
                color={headingColor}
                pb={1}
                textAlign={{ md: "left", xs: "left" }}
                mt={-1}
              >
                Summary
              </Typography>
              <Box
                border={"1px solid " + borderLineColor}
                borderRadius="6px"
                p={"6px 10px"}
                backgroundColor={whiteColor}
              >
                <Box>
                  <SummaryDataRow
                    label="Subtotal:"
                    value={cartDetails?.orderTotalProductCost}
                  />
                  <SummaryDataRow
                    label="Total Quantity:"
                    value={cartDetails?.totalQuantity}
                  />
                  <SummaryDataRow
                    label="Taxes:"
                    value="Included"
                    isValueGreen
                  />
                  <SummaryDataRow
                    label="Shipping & Handling:"
                    value={cartDetails?.shippingCost}
                  />

                  {cartDetails?.codCost && cartDetails?.codCost?.chargeTo != 'business' ? (
                    <SummaryDataRow
                      label="COD Cost:"
                      value={`₹${cartDetails?.codCost?.codCharges?.toFixed(2)}`}
                    />
                  ) : null}

                  <SummaryDataRow
                    label="Discount:"
                    value={cartDetails?.claimedPoint}
                  />
                </Box>

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pt={0.7}
                  borderTop={"1px solid " + borderLineColor}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      fontSize={{ md: 14, xs: 13 }}
                      color={{ md: headingColor, xs: blackColor }}
                      display="inline"
                    >
                      Total
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      fontSize={14}
                      color={
                        // isValueGreen
                        //   ?
                        {
                          md: greenColor04,
                          xs: lightGreen03,
                        }
                        // : blackColor
                      }
                      display="inline"
                    >
                      ₹{cartDetails?.orderTotalCost}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box mt={{ xs: "24px", md: "0px" }}>
                {matches && (
                  <Box display={"flex"} pb={1}>
                    <Typography
                      variant="h5"
                      component="h3"
                      fontSize={{ xs: 14, md: 14 }}
                      color={headingColor}
                      textAlign={{ md: "left", xs: "left" }}
                      mt={1.5}
                    >
                      {cartDetails?.deliveryType?.type
                        ? "Customer Address"
                        : "Delivery Address"}
                    </Typography>
                  </Box>
                )}
                <DeliveryAddress address={cartDetails?.customerAddress} />
              </Box>
              {matches && (
                <Box display={"flex"}>
                  <Typography
                    variant="h5"
                    component="h3"
                    fontSize={{ xs: 14, md: 14 }}
                    color={blackColor}
                    textAlign={{ md: "right", xs: "left" }}
                  >
                    Delivery Method
                  </Typography>
                </Box>
              )}
              <DeliveryMethod
                delivery={cartDetails?.deliveryDateRange}
                shipType={cartDetails?.deliveryType?.type}
              />
              {matches && (
                <Box display={"flex"}>
                  <Typography
                    variant="h5"
                    component="h3"
                    fontSize={{ xs: 14, md: 14 }}
                    color={blackColor}
                    textAlign={{ md: "right", xs: "left" }}
                  >
                    Payment Type
                  </Typography>
                </Box>
              )}
              <PaymentType
                paymentType={
                  cartDetails?.paymentInfo?.paymentDetail?.paymentType
                }
              />

              {cartDetails ? (
                cartDetails?.notesConsumer ? (
                  <SpecialNotes note={cartDetails?.notesConsumer} />
                ) : null
              ) : null}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container spacing={3.5} backgroundColor={backgroundColor} mb={5}>
          <Grid item xs={12} md={9}>
            {/* <Typography
              variant='h2'
              color={{ md: headingColor, xs: blackColor }}
              mb={{ xs: '0px !important', md: '6px !important' }}
              lineHeight='28px'
              fontSize={{ xs: 17, md: 22 }}
            >
              Congartulations !!
            </Typography>
            <Typography
              variant='h5'
              fontSize={{ xs: 12, md: 16 }}
              color={{ md: headingColor, xs: '#9ba3aa' }}
              mb='6px !important'
              lineHeight={{ xs: '17px', md: '50px' }}
            >
              Your Order is accepted.Your items are on the way and should arrive
              shortly.
            </Typography> */}
            <Box
              sx={{
                ...styles.cartContainer,
                p: 3,
                // width: '100%',
                // border: '1px solid #e2e5ec',
                // borderRadius: '12px',
                // background: '#fff',
                // padding: '10px',
                // // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.06)',
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  marginTop: "4px",
                  marginBottom: "0px",
                  color: "#000",
                  textTransform: "capitalize",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                {" "}
                Congratulations !!
              </Typography>
              <Box sx={{ margin: 0, padding: 0, border: 0 }}>
                <Typography
                  sx={{
                    color: "#9ba3aa",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "18px",
                    marginTop: "4px",
                    padding: "0px 10px",
                  }}
                >
                  Your order is accepted. Your items are on the way and should
                  arrive shortly.
                </Typography>
                <Box
                  component="img"
                  maxHeight={{ xs: "250px", md: "80px" }}
                  maxWidthwidth={"250px"}
                  borderRadius={"8px"}
                  src={
                    "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/success.gif"
                  }
                  alt="CostBo"
                />
              </Box>
              <Box>
                <Typography
                  sx={{ color: blackColor, fontSize: 14, fontWeight: 500 }}
                >
                  Order ID:{" "}
                  <Typography
                    component={"strong"}
                    sx={{
                      color: orangeColor01,
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "27px",
                      marginTop: "12px",
                      marginBottom: "6px",
                    }}
                  >
                    {cartDetails?.orderId}
                  </Typography>
                </Typography>

                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    color: blackColor,
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    // marginTop: '12px',
                    marginBottom: '6px !important',
                  }}
                >
                  {orderConfirmation_Info} {userInfo?.account?.email}
                </Typography>
              </Box>
              <Box alignItems={"center"}>
                <Button
                  sx={styles.actionBtnMobile()}
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    // navigate(`/${businessName}/catalog`);
                    navigateTo(navigate, `catalog`)
                  }}
                >
                  Continue Shopping
                </Button>
              </Box>
            </Box>
            {/* <Box
              sx={{ ...styles.cartContainer, p: 3 }}
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              <CheckCircleRoundedIcon
                sx={{
                  fontSize: { xs: '46px', md: '65px' },
                  color: savingTextColor,
                }}
              />
              <Box
                my='16px !important'
                py={2}
                px={{ md: 4, xs: 1 }}
                border={'1px solid' + borderLineColor}
                borderRadius={1.5}
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  component='p'
                  fontSize={{ xs: '14px', md: '16px' }}
                  lineHeight='24px'
                  color={headingColor}
                  textAlign='center'
                  display='inline'
                >
                  Order ID:{' '}
                  <Typography
                    variant='subtitle1'
                    component='p'
                    fontSize={{ xs: '14px', md: '16px' }}
                    fontWeight={600}
                    color={orangeColor01}
                    // textAlign="center"
                    display='inline'
                  >
                    {cartDetails?.orderId}
                  </Typography>
                </Typography>
                <Typography
                  variant='body1'
                  color={categoryLabelColor}
                  fontWeight={500}
                  textAlign='center'
                  mt={1}
                >
                  {orderConfirmation_Info} {userInfo?.account?.email}
                </Typography>
              </Box>
              <Button
                sx={styles.actionBtn('outline')}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  navigate(`/${businessName}/catalog`)
                }}
              >
                Continue Shopping
              </Button>
            </Box> */}
            <Box sx={styles.cartContainer} mt={2}>
              {/* <DiscountHeader
              icon={<CategoryRoundedIcon sx={{ color: headingColor }} />}
              title="Items"
            /> */}
              <Typography
                variant="h5"
                component="h3"
                fontSize={{ xs: 14, md: 15 }}
                color={{ md: headingColor, xs: blackColor }}
                ml={1}
                mt={1.5}
                mb="6px !important"
              >
                <ShoppingBasketRoundedIcon
                  sx={{
                    margin: "0 6px -3px 0",
                    fontSize: { md: "18px", xs: "22px" },
                    // height:"15px",
                    //width:"22px"
                  }}
                />
                {cartDetails?.productList?.reduce((accumulator, product) => {
                  return accumulator + product.currentQuantity;
                }, 0) || 0}{" "}
                Items in Order
              </Typography>

              <Grid
                container
                py={1}
                display={{ xs: "none", md: "inline-flex" }}
                spacing={{ md: 3 }}
              >
                <Grid item xs={5}>
                  <Typography
                    variant="subtitle1"
                    component="h4"
                    fontSize="13px"
                    color={delectIconInactiveColor}
                    ml={1.8}
                  >
                    PRODUCT
                  </Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography
                    variant="subtitle1"
                    component="h4"
                    fontSize="13px"
                    color={delectIconInactiveColor}
                  >
                    UNIT PRICE
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="subtitle1"
                    component="h4"
                    fontSize="13px"
                    color={delectIconInactiveColor}
                  >
                    QUANTITY
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography
                    variant="subtitle1"
                    component="h4"
                    fontSize="13px"
                    color={delectIconInactiveColor}
                    ml={0.6}
                  >
                    PRICE
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="subtitle1"
                    component="h4"
                    fontSize="13px"
                    color={delectIconInactiveColor}
                  >
                    SAVINGS
                  </Typography>
                </Grid>
              </Grid>
              {cartDetails?.productList?.map((item, index) => {
                const savings =
                  Number(item?.productOriginalPrice) *
                    Number(item?.currentQuantity) -
                  Number(item?.productSalePrice) *
                    Number(item?.currentQuantity);
                totalQty = totalQty + item.currentQuantity;
                totalSavings = totalSavings + savings;
                return (
                  <TableProductCard
                    key={"cart_product_" + index}
                    image={item?.productPicURL}
                    productName={item?.productName}
                    oldUnitPrice={item?.productOriginalPrice}
                    unitprice={item?.productSalePrice}
                    discount={item?.percentageOff}
                    price={item?.productTotalPrice}
                    saving={savings}
                    quantity={item.currentQuantity}
                    isOutofStock={item.isOutOfStock}
                    cartData={cartDetails?.productList}
                    cartId={cartDetails?.cartId}
                    product={item}
                    readOnly={true}
                  />
                );
              })}

              {/* {dummyCartProducts.map((item, index) => {
              return (
                <CartProductCard
                  key={'cart_product_' + index}
                  image='https://storage.googleapis.com/bo3151920215/business/38bd76c370274724ba9feaa032266ee4/product/8cc515416c36427b9a83e0c2c3f6afaa/Product4-687725.png'
                  productName='31449 Michelin Air Conditioning System Cleaner - 150 ml'
                  caterogy='Auto Parts'
                  oldUnitPrice='340.00'
                  unitprice='240.00'
                  discount={20}
                  price='300.00'
                  saving='00.00'
                  quantity={item.quantity}
                  isDiscount={item.isDiscount}
                  isOutofStock={item.isOutOfStock}
                  onDeleteProduct={() => {}}
                  readOnly={true}
                />
              )
            })} */}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              pb={1.5}
              textAlign={{ md: "right", xs: "left" }}
              mt={-2}
            >
              Summary
            </Typography>
            <Box
              border={"1px solid " + borderLineColor}
              borderRadius="6px"
              p={1.5}
              backgroundColor={whiteColor}
            >
              <Box>
                <SummaryDataRow
                  label="Subtotal:"
                  value={cartDetails?.orderTotalProductCost}
                />
                <SummaryDataRow
                  label="Total Quantity:"
                  value={cartDetails?.totalQuantity}
                />
                <SummaryDataRow label="Taxes:" value="Included" isValueGreen />
                <SummaryDataRow
                  label="Shipping & Handling:"
                  value={cartDetails?.shippingCost}
                />

                {cartDetails?.codCost && cartDetails?.codCost?.chargeTo != 'business' ? (
                  <SummaryDataRow
                    label="COD Cost:"
                    value={`₹${cartDetails?.codCost?.codCharges?.toFixed(2)}`}
                  />
                ) : null}

                <SummaryDataRow
                  label="Discount:"
                  value={cartDetails?.claimedPoint}
                />
              </Box>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                pt={0.7}
                borderTop={"1px solid " + borderLineColor}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    component="p"
                    fontSize={{ md: 14, xs: 13 }}
                    color={{ md: headingColor, xs: blackColor }}
                    display="inline"
                  >
                    Total
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    variant="h5"
                    component="p"
                    fontSize={14}
                    color={{ md: orangeColor01, xs: blackColor }}
                    display="inline"
                  >
                    ₹{cartDetails?.orderTotalCost}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box mt={{ xs: "24px", md: "0px" }}>
              <DeliveryAddress address={cartDetails?.customerAddress} />
            </Box>
            <DeliveryMethod
              delivery={cartDetails?.deliveryDateRange}
              shipType={cartDetails?.deliveryType?.type}
            />

            <PaymentType
              paymentType={cartDetails?.paymentInfo?.paymentDetail?.paymentType}
            />

            {cartDetails ? (
              cartDetails?.notesConsumer ? (
                <SpecialNotes note={cartDetails?.notesConsumer} />
              ) : null
            ) : null}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ConfirmationContent

const SummaryDataRow = ({ label, value, isValueGreen }) => {
  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      py={{ md: 0.2, xs: 0 }}
    >
      <Grid item>
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={{ md: '12.5px', xs: 12 }}
          color={blackColor}
          display='inline'
        >
          {label}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant='h6'
          component='p'
          fontSize={{ md: '12.5px', xs: 12 }}
          color={
            isValueGreen ? { md: greenColor04, xs: lightGreen03 } : blackColor
          }
          display='inline'
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

const styles = {
  cartContainer: {
    backgroundColor: whiteColor,
    // boxShadow: "0 4px 8px rgb(0 0 0 / 6%)",
    px: 1.5,
    py: 0.5,
    borderRadius: 2.2,
    border: "1px solid" +" #e6ebf4",
    mt:2
  },
  actionBtn: (outline) => ({
    fontSize: "15px",
    fontWeight: 500,
    color: outline ? orangeColor01 : whiteColor,
    p: "7px 24px",
    mb: "10px !important",
    border: "1px solid " + orangeColor01,
    backgroundColor: outline ? whiteColor : orangeColor01,
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: outline ? orangeColor01 : whiteColor,
      color: outline ? whiteColor : orangeColor01,
    },
  }),
  actionBtnMobile: (outline) => ({
    mt:{xs: 1,md:0.5},
    marginRight: "0px",
    width: { xs: "200px", md: "23%" },
    borderRadius: "5px",
    color: "#ffffff",
    fontWeight: 600,
    fontSize: "12.5px",
    textTransform: "uppercase",
    background: "#000",
    padding: "3px 5px",
    border: "1px solid #000000",
    lineHeight: "30px",
    "&:hover": {
      backgroundColor: outline ? orangeColor01 : whiteColor,
      color: outline ? whiteColor : blackColor,
    },
  }),
};
