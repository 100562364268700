import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAccountDetails,
  getAllOpenOrderDetails,
  getOrdersCount,
} from 'redux/dashboard/dashboardSlice'
import FooterMui from '../components/FooterMui'
import OpenOrdersContent from './OpenOrders/OpenOrdersContent'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserName } from 'utils/authHelpers'
import TopComponent from './TopComponent'
import BreadcrumbsSection from 'components/Breadcrumbs'
import { Box, Container, useMediaQuery } from '@mui/material'
import { backgroundColor01, whiteColor } from 'configs/styles/muiThemes'
import { getBusinessIdDetails } from 'redux/rootSlice/RootSlice'
import HeaderLoader from 'components/skeletonLoaders/HeaderLoader'
import { getCommercialDetails } from 'redux/productDescription/ProductDescriptionSlice'
import { navigateTo, resolveDomainUrl } from 'utils/urlHelper'

function OpenOrders({isDomainUrl}) {
  const { businessName } = useParams()
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const dispatch = useDispatch()
  const { accountDetails, openOrderDetails, loading, ordersCountData } =
    useSelector((state) => state.dashboard)

  const isLoggedIn = getUserName().length === 0 ? false : true

  let navigate = useNavigate()

  const [pageNo, setPageNo] = useState(1)

  const { businessId } = useSelector((state) => state.rootSlice);
  const { commercialDetails } = useSelector((state) => state.product);

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      sessionStorage.setItem("cartData", "null");
    }

    dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  useEffect(() => {
    if (isDomainUrl) {
      resolveDomainUrl(dispatch);
      sessionStorage.setItem("businessName", businessName);
      return;
    }
    getBusinessData();
  }, [businessName]);

  useEffect(() => {
    if(businessId){
      if (isLoggedIn) {
        if (!commercialDetails) {
          dispatch(getCommercialDetails());
        }

        if (accountDetails == null) {
          dispatch(getAccountDetails())
        }
        if (openOrderDetails == null) {
          dispatch(getAllOpenOrderDetails({ businessId: businessId?.businessId, pageNo: 1 }))
        }
  
        dispatch(getOrdersCount(businessId?.businessId))
      } else {
        navigate(`/${businessName}`)
      }
    }
  }, [businessId])

  const handleOrderPagination = (pageNumber) => {
    dispatch(getAllOpenOrderDetails({ businessId: businessId?.businessId, pageNo: pageNumber }))
    setPageNo(pageNumber)
  }

  return (
    <Box backgroundColor={{ xs: backgroundColor01 }}>
      {/* <Navbar value='' /> */}
      {/* <TopComponent value='' disableBanners /> */}
      {/* <AllOrdersContent /> */}

      <Box
        sx={{
          position: "sticky",
          top: 0,
          width: "100%",
          zIndex: 100,
          backgroundColor: { xs: whiteColor, md: backgroundColor01 },
          pb: 1,
        }}
      >
       <Box minHeight={{ xs: "auto", md: "126.7px" }} bgcolor={whiteColor}>
          {businessId !== null && commercialDetails ? (
            <TopComponent value="dashboard" disableBanners />
          ) : (
            <HeaderLoader hideBanners />
          )}
        </Box>

        {matches && <Container
          sx={{
            maxWidth: "1350px !important",
            mt: { xs: 0, md: "15px" },
            paddingX: "15px !important",
          }}
          backgroundColor={whiteColor}
        >
          <BreadcrumbsSection
            links={[
              { label: "All Orders", to: `/AllOrders` },
              { label: "Open Orders", to: `/OpenOrders` },
            ]}
          />
        </Container>}
      </Box>

      <Container
        sx={{
          maxWidth: "1350px !important",
          mt: { xs: commercialDetails ? 0 : '-92px', md: 0 },
          paddingX: { md: "15px !important", xs: 0 },
        }}
      >
        <OpenOrdersContent
          orderData={openOrderDetails}
          accountDetails={accountDetails}
          openOrderDetails={openOrderDetails}
          loading={loading}
          businessId={businessId}
          ordersCountData={ordersCountData}
          handleOrderPagination={handleOrderPagination}
          pageNo={pageNo}
        />
      </Container>
      {matches && <FooterMui />}
    </Box>
  );
}

export default OpenOrders
