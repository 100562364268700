import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Chip,
  Stack,
} from "@mui/material";
import TextboxWithLabel from "components/TextboxWithLabel";
import {
  bgColor16,
  blackColor,
  borderCard06,
  borderTextbox,
  greenColor05,
  greyShade41,
  greyShade42,
  greyShade76,
  greyShade80,
  greyShade81,
  headingColor,
  orangeColor01,
  redColor,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import AddressCard from "../AddressCards/AddressCard";
import WrongLocationOutlinedIcon from "@mui/icons-material/WrongLocationOutlined";
import { allowOnlyEnglish } from "utils/utilities";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AddressCardNew from "../AddressCards/AddressCardNew";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const AddressSearchContent = ({
  addressDetails,
  setOpenUpdateAddress,
  onSelectMethod,
  onEditAddress,
  onDeleteAddress,
  seletedAddress,
  handleClose,
  onAddAddress,
}) => {
  const [selectedAddrIndex, setSelectedAddrIndex] = useState(-1);
  const [addressList, setAddressList] = useState(addressDetails);

  const [searchQuery, setSearchQuery] = useState({
    name: "",
    phoneNo: "",
    errors: "",
  });

  const [showClearSearch, setShowClearSearch] = useState({
    show: false,
    query: "",
  });

  const handleUpdateValue = (key, value) => {
    setSearchQuery((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSearch = () => {
    if (!searchQuery?.name && !searchQuery?.phoneNo) {
      handleUpdateValue("errors", "Enter Name or Contact Number to search");
      return;
    }

    if (searchQuery?.phoneNo?.length > 0 && searchQuery?.phoneNo?.length < 5) {
      handleUpdateValue("errors", "Enter atleast 5 digits to search");
      return;
    }

    let filteredList = [];
    handleUpdateValue("errors", null);

    if (searchQuery?.name && searchQuery?.phoneNo) {
      filteredList = addressDetails?.filter(
        (item) =>
          item?.name
            ?.toLowerCase()
            ?.includes(searchQuery?.name?.toLowerCase()) &&
          (item?.phoneNo
            ?.toLowerCase()
            ?.includes(searchQuery?.phoneNo?.toLowerCase()) ||
            item?.alternatePhoneNo
              ?.toLowerCase()
              ?.includes(searchQuery?.phoneNo?.toLowerCase()))
      );

      setAddressList(() => filteredList);
      setShowClearSearch({
        show: true,
        query: `${searchQuery?.name}, ${searchQuery?.phoneNo}`,
      });
      return;
    }

    if (searchQuery?.name) {
      filteredList = addressDetails?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchQuery?.name?.toLowerCase())
      );
    }

    if (searchQuery?.phoneNo) {
      filteredList = addressDetails?.filter(
        (item) =>
          item?.phoneNo
            ?.toLowerCase()
            ?.includes(searchQuery?.phoneNo?.toLowerCase()) ||
          item?.alternatePhoneNo
            ?.toLowerCase()
            ?.includes(searchQuery?.phoneNo?.toLowerCase())
      );
    }

    setAddressList(() => filteredList);
    setShowClearSearch({
      show: true,
      // query: searchQuery?.name || searchQuery?.phoneNo,
      query: searchQuery?.name || searchQuery?.phoneNo,
    });
  };

  const handleClearSearch = () => {
    setAddressList(() => addressDetails);
    setSearchQuery({
      name: "",
      phoneNo: "",
      errors: "",
    });
    setShowClearSearch({
      show: false,
      query: "",
    });
  };

  useEffect(() => {
    setAddressList(() => addressDetails);
    return () => {};
  }, [addressDetails]);

  return (
    <Box sx={styles.modelCardContainer}>
      <Box
        sx={{
          maxHeight: { xs: "96vh", md: "88vh" },
          overflowY: "scroll",
          background: whiteColor,
        }}
        borderRadius={{ xs: "15px 15px 0 0", md: "10px" }}
        pt={{ xs: "6px", md: "0px" }}
      >
        <Box sx={{ px: { md: "15px", xs: 1.5 }, m: "10px 0 5px" }}>
          <Typography
            variant="h4"
            fontSize={{ xs: 14, md: 15 }}
            fontWeight={600}
            letterSpacing="-0.3px"
            lineHeight="24px"
            color={headingColor}
          >
            Search Address
          </Typography>
        </Box>

        <Box px="15px">
          <Grid container spacing={{xs:1, md:2}}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography component="label" sx={styles.inputLabel}>
                  Search by Name
                </Typography>

                <Box sx={styles.inputContainer}>
                  <TextField
                    value={searchQuery?.name}
                    fullWidth
                    variant="standard"
                    sx={styles.inputBox}
                    // maxLength={maxLength}
                    onChange={({ target }) => {
                      const finalValue = allowOnlyEnglish(target.value);
                      handleUpdateValue("name", finalValue);
                    }}
                    placeholder=""
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box>
                <Typography component="label" sx={styles.inputLabel}>
                  Search by Contact No
                </Typography>

                <Box sx={styles.inputContainer}>
                  <TextField
                    value={searchQuery?.phoneNo}
                    fullWidth
                    variant="standard"
                    sx={styles.inputBox}
                    maxLength={10}
                    onChange={({ target }) => {
                      const finalValue = allowOnlyEnglish(target.value);
                      handleUpdateValue("phoneNo", finalValue);
                    }}
                    placeholder=""
                    InputProps={{
                      disableUnderline: true,
                      type: "number",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            gap={2}
          >
            <Box flex={1}>
              <Typography component="label" sx={styles.inputLabel}>
                Search by Name
              </Typography>

              <Box sx={styles.inputContainer}>
                <TextField
                  value={searchQuery?.name}
                  fullWidth
                  variant="standard"
                  sx={styles.inputBox}
                  // maxLength={maxLength}
                  onChange={({ target }) => {
                    const finalValue = allowOnlyEnglish(target.value);
                    handleUpdateValue("name", finalValue);
                  }}
                  placeholder=""
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Box>
            </Box>

            <Box flex={1}>
              <Typography component="label" sx={styles.inputLabel}>
                Search by Contact No
              </Typography>

              <Box sx={styles.inputContainer}>
                <TextField
                  value={searchQuery?.phoneNo}
                  fullWidth
                  variant="standard"
                  sx={styles.inputBox}
                  maxLength={10}
                  onChange={({ target }) => {
                    const finalValue = allowOnlyEnglish(target.value);
                    handleUpdateValue("phoneNo", finalValue);
                  }}
                  placeholder=""
                  InputProps={{
                    disableUnderline: true,
                    type: "number",
                  }}
                />
              </Box>
            </Box>
          </Box> */}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            my={1.6}
          >
            {showClearSearch?.show ? (
              <SearchClearChip
                text={showClearSearch?.query || ""}
                onClear={handleClearSearch}
              />
            ) : (
              <Box />
            )}

            <Button
              variant="outlined"
              disableElevation
              sx={styles.searchBtn}
              onClick={handleSearch}
            >
              SEARCH ADDRESS
            </Button>
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p="6px 15px"
          bgcolor={bgColor16}
        >
          <Typography fontSize={14} fontWeight={600} color={blackColor}>
            Saved Address
          </Typography>
          <Button
            variant="text"
            sx={styles.addBtn}
            onClick={onAddAddress}
            startIcon={
              <AddRoundedIcon
                sx={{ fontSize: "16px !important", mr: "-5px" }}
              />
            }
          >
            Add New Address
          </Button>
        </Box>

        <Box>
          <Box sx={styles.addressListContainer}>
            {addressList?.map((address, i) => (
              <AddressCardNew
                key={`address_${i}`}
                index={i}
                selectedAddrIndex={selectedAddrIndex}
                setSelectedAddrIndex={setSelectedAddrIndex}
                isAddressSelected={
                  seletedAddress?.addressLine1 === address?.addressLine1 &&
                  seletedAddress?.zip === address?.zip &&
                  seletedAddress?.phoneNo === address?.phoneNo &&
                  seletedAddress?.updatedOn === address?.updatedOn
                }
                onEdit={() => setOpenUpdateAddress(true)}
                address={address}
                onSelectMethod={() => {
                  onSelectMethod(address);
                }}
                onEditAddress={() => {
                  onEditAddress(address);
                }}
                onDeleteAddress={() => {
                  onDeleteAddress(address);
                }}
                cardStyle={{ mr: 0 }}
                showAlternateNo
              />
            ))}

            {showClearSearch?.show && addressList?.length === 0 && (
              <AddressEmpty onClear={handleClearSearch} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddressSearchContent;

const SearchClearChip = ({ text, onClear }) => {
  return (
    <Box sx={styles.searchChip}>
      Search results for:{" "}
      <Typography
        component="span"
        fontSize={12}
        fontWeight={500}
        color={blackColor}
      >
        {text}
      </Typography>
      <Box
        position="absolute"
        top={-5}
        right={-5}
        sx={{ cursor: "pointer" }}
        onClick={onClear}
      >
        <CancelRoundedIcon sx={{ fontSize: "16px", color: greyShade80 }} />
      </Box>
    </Box>
  );
};

const AddressEmpty = ({ onClear }) => {
  const MapIcon = () => (
    <Box sx={styles.mapIconCircle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#1C1C1C"
        width="18"
        height="18"
        viewBox="0 0 20 21"
        aria-labelledby="icon-svg-title- icon-svg-desc-"
        role="img"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.50376 0.135614C5.50502 0.734541 3.10602 3.13025 2.60623 6.12488C2.00648 9.81826 4.00564 13.1123 7.10435 14.3102C8.10393 14.7095 9.00355 15.4082 9.50334 16.4064C9.70326 16.7059 9.80322 17.1052 10.0031 17.4047C10.1031 17.1052 10.303 16.7059 10.5029 16.4064C11.0027 15.4082 11.9023 14.8093 12.9019 14.3102C15.6008 13.2122 17.5 10.517 17.5 7.42255C17.5 2.9306 13.3018 -0.762775 8.50376 0.135614ZM10.0031 10.0179C8.60372 10.0179 7.50418 8.91987 7.50418 7.52237C7.50418 6.12488 8.60372 5.02685 10.0031 5.02685C11.4025 5.02685 12.5021 6.12488 12.5021 7.52237C12.5021 8.91987 11.4025 10.0179 10.0031 10.0179Z"
        ></path>
        <path
          fill="#e56b46"
          d="M10.0033 20C11.1074 20 12.0025 19.5531 12.0025 19.0018C12.0025 18.4505 11.1074 18.0036 10.0033 18.0036C8.89921 18.0036 8.00415 18.4505 8.00415 19.0018C8.00415 19.5531 8.89921 20 10.0033 20Z"
        ></path>
      </svg>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pt={10}
    >
      <MapIcon />

      <Typography fontSize={13} fontWeight={600} color={blackColor} mt={1}>
        Address Not Found
      </Typography>
      <Button variant="text" sx={styles.clearBtn} onClick={onClear}>
        Clear Search
      </Button>
    </Box>
  );
};

const styles = {
  modelCardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: { xs: "flex-end", md: "center" },
    height: { xs: "100vh", md: "auto" },
  },

  inputContainer: {
    border: borderTextbox,
    borderRadius: "5px",
    padding: { md: "4.5px 10px", xs: "4px 10px" },
    mt: 0.5,
  },

  inputLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: greyShade76,
    letterSpacing: "-0.3px",
    lineHeight: "14px",
  },

  inputBox: {
    fontSize: "14px",
    color: "#777",
    background: whiteColor,
    // padding: { md: "8px 10px", xs: "4px 10px" },
    // ml: { md: 1, xs: 0 },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "red !important",
    },
    "& .MuiInputBase-input::placeholder": {
      opacity: 0.7,
      color: greyShade42, // Change the placeholder color
      fontSize: "13px", // Change the placeholder font size
    },
  },

  searchBtn: {
    fontSize: { md: 12, xs: 12 },
    fontWeight: 500,
    lineHeight: "20px",
    color: blackColor,
    bgcolor: whiteColor,
    borderColor: headingColor,
    p: { md: "7px 17px", xs: "6px 16px" },
    // mr: { md: 1, xs: 0 },
    textTransform: "uppercase",
    borderRadius: "6px",
    "&:hover": {
      color: whiteColor,
      bgcolor: blackColor,
      borderColor: headingColor,
    },
  },

  addressListContainer: {
    height: { xs: "53.5vh", md: "50vh" },
    overflowY: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  addBtn: {
    fontSize: 11.5,
    fontWeight: 600,
    color: orangeColor01,
    bgcolor: "transparent",
    lineHeight: "20px",
    borderRadius: "6px",
    p: "5px 0px",
    "&:hover": {
      color: orangeColor01,
      bgcolor: "transparent",
    },
  },

  searchChip: {
    fontSize: 12,
    fontWeight: 400,
    color: greyShade80,
    bgcolor: greyShade81,
    p: "2px 15px 2px 8px",
    borderRadius: "6px",
    position: "relative",
  },

  mapIconCircle: {
    width: "35px",
    height: "35px",
    bgcolor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "35px",
    border: borderCard06,
    // p: "7px",
  },

  clearBtn: {
    fontSize: 12.5,
    fontWeight: 600,
    color: greenColor05,
    bgcolor: "transparent",
    lineHeight: "20px",
    borderRadius: "6px",
    textDecoration: "underline",
    textTransform: "unset",
    p: "3px 0px",
    "&:hover": {
      color: greenColor05,
      bgcolor: "transparent",
    },
  },
};
