import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ModalCard from "components/ModalCard";
import ProductGalleryModal from "./ProductGalleryModal";
import { backgroundColor02, whiteColor } from "configs/styles/muiThemes";
import ProductImages from "./ProductImages";
import ProductDescriptionHeader from "./ProductDescriptionHeader";
import ProductDescriptionBody from "./ProductDescriptionBody";
import { useSelector } from "react-redux";
import {
  addInfluencerShareAPI,
  addResellerOnShareAPI,
  getAffiliateValidityApi,
  getShortLink,
} from "services/api_calls/affiliate/AffiliateApi";
import { getOperatingSystem } from "utils/utilities";

export default function ProductDescription({
  productDetails,
  similarProductDetails,
  commercialDetails,
  recLoading,
  productId,
  cartProductList,
  totalQty,
  productQty,
  cashback,
  isOutOfStock,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
  inventoryLoader
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [isModal, setIsModal] = useState(false);

  const [activeImg, setActiveImg] = useState(
    productDetails?.product?.productPhoto[0]?.docURL
  );
  const businessName = sessionStorage.getItem("businessName");
  const [windowurl, setWindowurl] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const { businessDetails } = useSelector((state) => state.header);

  useEffect(() => {
    const url = window.location.href;
    setWindowurl(url);
  }, []);

  useEffect(() => {
    setActiveImg(productDetails?.product?.productPhoto[0]?.docURL);
  }, [productDetails]);

  const handleModalClose = () => {
    setIsModal(false);
  };

  // Copy Product Link
  const handleCopyLink = () => {
    const BUSINESS_LINK = window.location.href;
    navigator.clipboard.writeText(BUSINESS_LINK);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  // Initiate Chat
  const handleChatClick = () => {
    const whatsAppNumber =
      commercialDetails?.info?.costboPowered?.whatsAppNumber?.replace("+", "");

    if (whatsAppNumber) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  // Affiliate Social Share
  const [shortShareLink, setShortShareLink] = useState("");
  const [isAffiliateExist, setIsAffiliateExist] = useState(false);

  const { rellerProfileData, businessAffiliateData } = useSelector(
    (state) => state.affiliate
  );

  const whatsappShareRef = useRef(null);
  const faceBookShareRef = useRef(null);
  const twitterShareRef = useRef(null);
  const linkedInShareRef = useRef(null);
  const telegramShareRef = useRef(null);

  // generate short share link
  const generateShortShareLink = async () => {
    const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));

    const businessData = {
      name: businessInfo?.[0]?.businessName,
      costboWebURL: businessName,
    };

    const res = await getShortLink({
      businessData,
      // productDetails: productDetails,
      rellerProfileData: rellerProfileData?.details,
    });

    if (res?.shortURL) {
      setShortShareLink(res?.shortURL);
      return res?.shortURL;
    } else {
      const link = `https://shop.costbo.com/${businessName}?atag=${rellerProfileData?.details?.resellerCode}`;
      setShortShareLink(link);
      return link;
    }
  };

  // Open Affiliate Share Modal
  const openShareModal = (shareMedium) => {
    if (shareMedium === "whatsapp") {
      whatsappShareRef.current.click();
    } else if (shareMedium === "facebook") {
      faceBookShareRef.current.click();
    } else if (shareMedium === "linkedIn") {
      linkedInShareRef.current.click();
    } else if (shareMedium === "twitter") {
      twitterShareRef.current.click();
    } else if (shareMedium === "telegram") {
      telegramShareRef.current.click();
    }
  };

  const handleAffiliateShare = async (shareMedium, isCopy) => {
    const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));
    const businessData = businessInfo?.[0];
    const commissionData = businessAffiliateData?.businessResellers?.[0];
    const commerceInfo = JSON.parse(sessionStorage.getItem("commerceInfo"));

    // POST API Data
    const commissionPostData = {
      commissionPercentage: commissionData?.affiliatePercentage,
      discountPercentage: commissionData?.consumerPercentage,
    };

    const businessPostData = {
      id: businessData?.id,
      name: businessData?.businessName,
      logoURL: businessData?.logoURL,
      costboWebURL: businessName,
      businessCategories: businessData?.businessCategories,
      documentsURL: businessData?.documentsURL,
      mainProducts: businessData?.mainProducts,
      businessArea: commerceInfo?.info?.businessArea,
      description: "",
    };

    const productPostData = {
      ...productDetails?.product,
      productId: productDetails?.product?.id,
      name: productDetails?.product?.productName,
    };

    let shortLink = shortShareLink || "";

    if (!shortShareLink) {
      shortLink = await generateShortShareLink();
    }

    if (isCopy) {
      navigator.clipboard.writeText(shortLink);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } else {
      setTimeout(() => {
        openShareModal(shareMedium);
      }, 1000);
    }

    if (!isAffiliateExist) {
      const isAffiliateValid = await getAffiliateValidityApi(businessData?.id);

      if (isAffiliateValid?.affiliateexist === false) {
        const response = await addResellerOnShareAPI({
          productDetails: productPostData,
          commissionDetails: commissionPostData,
          rellerProfileData: rellerProfileData?.details,
          businessData: businessPostData,
        });

        if (response) {
          setIsAffiliateExist(true);
        }
      } else {
        setIsAffiliateExist(true);
      }
    }

    const deviceName = getOperatingSystem(window);
    const shareResponse = await addInfluencerShareAPI({
      productDetails: productPostData,
      commissionDetails: commissionPostData,
      businessData: businessPostData,
      deviceName,
      shareMedium,
    });
  };

  // const validateCopyType = () => {
  //   if (
  //     businessAffiliateData?.businessResellers?.length > 0 &&
  //     rellerProfileData?.details
  //   ) {
  //     handleAffiliateShare("shareLink", true);
  //   } else {
  //     handleCopyLink();
  //   }
  // };

  return (
    <Box
      backgroundColor={{ xs: backgroundColor02, md: "none" }}
      mb={{ xs: totalQty > 0 ? 0 : 1, md: 0 }}
    >
      <ModalCard handleClose={handleModalClose} open={isModal} width="45%">
        <ProductGalleryModal
          title={productDetails?.product?.productName}
          productImages={productDetails?.product?.productPhoto}
          activeImg={activeImg}
          onActiveClick={(data) => setActiveImg(data)}
          magnify
        />
      </ModalCard>

      <Stack spacing={{ md: 0, xs: 1.5 }}>
        <Grid container spacing={{ md: 0, xs: 0 }} pt={{ xs: 11, md: 0 }}>
          <Grid item md={5.5} xs={12}>
            {!matches && (
              <Box mb={2}>
                <ProductDescriptionHeader
                  title={productDetails?.product?.productName}
                  subCategory={
                    productDetails?.product?.productSubCategory[0]?.value
                  }
                  subCategory2={
                    productDetails?.product?.productSubCategory2[0]?.value
                  }
                  copyLink={handleCopyLink}
                  isCopied={isCopied}
                  handleChatClick={handleChatClick}
                  // Affiliate Share
                  commercialDetails={commercialDetails}
                  businessAffiliateData={businessAffiliateData}
                  rellerProfileData={rellerProfileData?.details}
                  productDetails={productDetails?.product}
                />
              </Box>
            )}
            <Box borderRadius={"10px"}>
              <ProductImages
                onImageClick={() => setIsModal(true)}
                productImages={productDetails?.product?.productPhoto}
                activeImg={activeImg}
                onActiveClick={(data) => setActiveImg(data)}
                copyLink={handleCopyLink}
                isCopied={isCopied}
                handleChatClick={handleChatClick}
                commercialDetails={commercialDetails}
                businessAffiliateData={businessAffiliateData}
                rellerProfileData={rellerProfileData?.details}
                productDetails={productDetails?.product}
              />
            </Box>
          </Grid>
          <Grid item md={5.6} xs={12} ml={{ md: 4, xs: 0 }}>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              {matches && (
                <ProductDescriptionHeader
                  title={productDetails?.product?.productName}
                  subCategory={
                    productDetails?.product?.productSubCategory[0]?.value
                  }
                  subCategory2={
                    productDetails?.product?.productSubCategory2[0]?.value
                  }
                  copyLink={handleCopyLink}
                  isCopied={isCopied}
                  handleChatClick={handleChatClick}
                  // Affiliate Share
                  commercialDetails={commercialDetails}
                  businessAffiliateData={businessAffiliateData}
                  rellerProfileData={rellerProfileData?.details}
                  productDetails={productDetails?.product}
                />
              )}
              <ProductDescriptionBody
                productName={productDetails?.product?.productName}
                productOriginalPrice={
                  productDetails?.product?.productOriginalPrice
                }
                productSalePrice={productDetails?.product?.productSalePrice}
                percentageOff={productDetails?.product?.percentageOff}
                min={productDetails?.product?.minOrderQuantity}
                max={productDetails?.product?.maxOrderQuantity}
                productDetails={productDetails}
                isOutOfStock={isOutOfStock}
                inventoryLoader={inventoryLoader}
                onQtyUpdate={onQtyUpdate}
                productQty={productQty}
                onAddProduct={onAddProduct}
                onRemoveProduct={onRemoveProduct}
                url={windowurl}
                subscribe={
                  productDetails?.product?.subscription?.status === "active"
                }
                availability={
                  productDetails?.product?.availability?.[0] === "Yes"
                }
                subscription={productDetails?.product?.subscription}
                handleChatClick={handleChatClick}
                // Affiliate Share
                copyLink={handleCopyLink}
                isCopied={isCopied}
                businessAffiliateData={businessAffiliateData}
                rellerProfileData={rellerProfileData?.details}
                onAffiliateShareClick={handleAffiliateShare}
                shortShareLink={shortShareLink}
                whatsappShareRef={whatsappShareRef}
                faceBookShareRef={faceBookShareRef}
                twitterShareRef={twitterShareRef}
                linkedInShareRef={linkedInShareRef}
                telegramShareRef={telegramShareRef}
                commercialDetails={commercialDetails}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}

const styles = {
  scrollBox: {
    display: "flex",
    alignItems: "center",
    backgroundColor: whiteColor,
    overflowX: "scroll",
    overscrollBehaviorInline: "contain",

    "&::-webkit-scrollbar": {
      display: "none",
    },

    "-webkit-overflow-scrolling": "touch",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },

  scrollIndicatorIcon: {
    fontSize: "20px",
    fontWeight: 500,
    color: "#ffffff",
    backgroundColor: "#000000",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
};
