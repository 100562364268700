import { Box, Typography, Tabs, Tab, Select, Divider } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {
  dashboardNavColor,
  stepperInactiveColor,
  breadcrumbBgColor,
  whiteColor,
  blackColor,
  backgroundColor,
  greyColor40,
  backgroundColor01,
  orangeColor01,
  greyShade57,
  greyShade49,
} from "../../configs/styles/muiThemes";
import { Link, useNavigate } from "react-router-dom";
import MultiSelectWithLabel from "components/Input/MultiSelectWithLabel";
import SelectWithLabel from "components/Input/SelectWithLabel";
import { getUserName } from "utils/authHelpers";
import { useEffect, useState } from "react";
import { getOrdersCount } from "redux/dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { navigateTo } from 'utils/urlHelper'

function DashboardNavbar({ active }) {
  const businessName = sessionStorage.getItem("businessName");
  const categoryList = [
    { id: 0, label: "openOrders" },
    { id: 1, label: "completedOrders" },
    { id: 2, label: "canceledOrders" },
  ];
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );
  const dispatch = useDispatch();
  const {
    accountDetails,
    orderDetails,
    openOrderDetails,
    cashbackData,
    loading,
    ordersCountData,
  } = useSelector((state) => state.dashboard);

  const businessId = sessionStorage.getItem("businessId");
  useEffect(() => {
    if (isLoggedIn) {
      if (ordersCountData == null) {
        dispatch(getOrdersCount(businessId));
      }
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: {
            md: whiteColor,
            xs: active === "dashboard" ? backgroundColor01 : whiteColor,
          },
          position: "sticky",
          top: 216,
          display: { xs: "none", md: "block" },
          border: "1px solid" + greyShade49,
        }}
        borderRadius={"10px"}
      >
        <Box border px={1.5} pt={1}>
          <Typography color={greyShade57} fontSize={12.5} fontWeight={400}>
            Overview
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            py={0.8}
            // component={Link}
            // to={`/${businessName}/Dashboard`}
            onClick={()=>{
              navigateTo(navigate, `Dashboard`)
            }}
            color={active === "dashboard" ? orangeColor01 : blackColor}
            sx={{ cursor: "pointer" }}
          >
            {/* <ChevronRightRoundedIcon sx={{ mr: '8px', fontSize: '20px' }} /> */}
            <Typography
              color={
                active === "dashboard"
                  ? { md: orangeColor01, xs: blackColor }
                  : blackColor
              }
              fontSize="13px"
              fontWeight={active === "dashboard" ? 600 : 600}
            >
              Dashboard
            </Typography>
          </Box>
          {/* <Typography color={blackColor} fontSize={13} fontWeight={600}>
            Wallet / Cashback
          </Typography> */}
        </Box>
        <Divider sx={{ my: 1.5 }} />
        <Box px={1.5}>
          <Typography color={greyShade57} fontSize={12.5} fontWeight={400}>
            Orders
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            // px={1}
            py={0.8}
            // component={Link}
            // to={`/${businessName}/AllOrders`}
            onClick={()=>{
              navigateTo(navigate, `AllOrders`)
            }}
            color={active === "allOrders" ? orangeColor01 : blackColor}
            sx={{ cursor: "pointer" }}
          >
            {/* <ChevronRightRoundedIcon sx={{ mr: '8px', fontSize: '20px' }} /> */}
            <Typography
              color={
                active === "allOrders"
                  ? { md: orangeColor01, xs: blackColor }
                  : blackColor
              }
              fontSize="13px"
              fontWeight={active === "allOrders" ? 600 : 600}
            >
              All Orders{" "}
              <Box
                component="span"
                color={blackColor}
                fontSize={18}
                fontWeight={500}
                mx={1}
                textAlign="center"
              >
                ·
              </Box>
              {ordersCountData?.details?.allOrder || 0}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            // px={3}
            py={0.8}
            // component={Link}
            // to={`/${businessName}/OpenOrders`}
            onClick={()=>{
              navigateTo(navigate, `OpenOrders`)
            }}
            color={active === "openOrders" ? orangeColor01 : blackColor}
            sx={{ cursor: "pointer" }}
          >
            {/* <ChevronRightRoundedIcon sx={{ mr: '8px', fontSize: '20px' }} /> */}
            <Typography
              color={active === "openOrders" ? orangeColor01 : blackColor}
              fontSize="13px"
              fontWeight={active === "openOrders" ? 600 : 600}
            >
              Open Orders{" "}
              <Box
                component="span"
                color={blackColor}
                fontSize={18}
                fontWeight={500}
                mx={1}
                textAlign="center"
              >
                ·
              </Box>
              {ordersCountData?.details?.openOrder || 0}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            py={0.8}
            // component={Link}
            // to={`/${businessName}/CompletedOrders`}
            onClick={()=>{
              navigateTo(navigate, `CompletedOrders`)
            }}
            color={active === "completedOrders" ? orangeColor01 : blackColor}
            sx={{ cursor: "pointer" }}
          >
            {/* <ChevronRightRoundedIcon sx={{ mr: '8px', fontSize: '20px' }} /> */}
            <Typography
              color={active === "completedOrders" ? orangeColor01 : blackColor}
              fontSize="13px"
              fontWeight={active === "completedOrders" ? 600 : 600}
            >
              Completed Orders{" "}
              <Box
                component="span"
                color={blackColor}
                fontSize={18}
                fontWeight={500}
                mx={1}
                textAlign="center"
              >
                ·
              </Box>
              {ordersCountData?.details?.completedOrder || 0}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            py={0.8}
            // component={Link}
            // to={`/${businessName}/CanceledOrders`}
            onClick={()=>{
              navigateTo(navigate, `CanceledOrders`)
            }}
            color={active === "canceledOrders" ? orangeColor01 : blackColor}
            sx={{ cursor: "pointer" }}
          >
            {/* <ChevronRightRoundedIcon sx={{ mr: '8px', fontSize: '20px' }} /> */}
            <Typography
              color={active === "canceledOrders" ? orangeColor01 : blackColor}
              fontSize="13px"
              fontWeight={active === "canceledOrders" ? 600 : 600}
            >
              Cancelled Orders{" "}
              <Box
                component="span"
                color={blackColor}
                fontSize={18}
                fontWeight={500}
                mx={1}
                textAlign="center"
              >
                ·
              </Box>
              {ordersCountData?.details?.cancelOrder}
            </Typography>
          </Box>
        </Box>

        {/* <Box
        display="flex"
        alignItems="center"
        px={3}
        py={1}
        component={Link}
        // to="/CanceledOrders"
        to={`/OtherOrders/${businessId}`}
        color={active == "otherOrders" ? orangeColor01 : dashboardNavColor}
        sx={{ cursor: "pointer" }}
      >
        <ChevronRightRoundedIcon sx={{ mr: "8px", fontSize: "20px" }} />
        <Typography
          color={active == "otherOrders" ? orangeColor01 : dashboardNavColor}
          fontWeight={active == "otherOrders" ? 600 : 400}
        >
          Other Orders
        </Typography> 
      </Box> */}
        <Box className="form-group order_heading">
          {/* <Select
              className='select'
              // value={selectedOption}
              // onChange={handleChange}
            >
              <Option>All Orders</Option>
              <Option>Open Orders</Option>
              <Option>Completed Orders</Option>
              <Option>Canceled Orders</Option>
            </Select> */}
        </Box>
      </Box>
      {/* <MultiSelectWithLabel
          // label='Business Type'
          value={active}
          // onValueChange={handleBusinessTypeChange}
          menuList={categoryList}
          required
          // error={storeInfoDetails?.errors?.type}
        /> */}

      {/* <SelectWithLabel
        // label='Product Category'
        value={active}
        // onValueChange={(value) => {
        //   handleCategoryFilter(value)
        // }}
        menuList={categoryList}
        isDashboardInput
      /> */}

      <Box
        display={{ xs: "block", md: "none" }}
        backgroundColor={
          active === "dashboard" ? backgroundColor01 : whiteColor
        }
        // margin={"0px -20px 10px"}
        padding={1}
        pb={0}
        // pt={1.5}
        sx={{
          backgroundColor: {
            md: stepperInactiveColor,
            xs: active === "dashboard" ? backgroundColor01 : whiteColor,
          },
          position: "sticky !important",
          top: 80,
          zIndex: 99,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={active === "dashboard" ? 0 : 1}
            // onChange={(event, newValue) => handleChange(event, newValue)}
            aria-label="basic tabs example"
            textColor={blackColor}
            TabIndicatorProps={{
              style: {
                height: "3px",
                backgroundColor: blackColor,
              },
            }}
            sx={{
              minHeight: "40px !important",
              "& .MuiButtonBase-root": {
                minWidth: "auto !important",
              },

              "& .MuiTabs-flexContainer": {
                justifyContent: "space-around",
                // justifyContent: 'space-between',
              },
            }}
            // textColor={active ? blackColor : backgroundColor}
            // fontWeight={active ? 600 : 400}
            // sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Tab
              label="Dashboard"
              // sx={{
              //   textTransform: 'none',
              //   //  color: blackColor
              // }}
              // component={Link}
              // to={`/${businessName}/dashboard`}
              onClick={()=>{
                navigateTo(navigate, `dashboard`)
              }}
              sx={{
                fontSize: { xs: "14px", md: "13px" },
                fontWeight: active == "dashboard" ? 600 : 500,
                p: 1,
                minHeight: "43.5px !important",
                mr: { xs: "10px", md: "55px" },
                color: active == "dashboard" ? blackColor : greyColor40,
                textTransform: "capitalize",
              }}
            />
            <Tab
              label="All Orders"
              sx={{
                fontSize: { xs: "14px", md: "13px" },
                fontWeight: active !== "dashboard" ? 600 : 500,
                p: 1,
                minHeight: "43.5px !important",
                mr: { xs: "10px", md: "55px" },
                color: active !== "dashboard" ? blackColor : greyColor40,
                textTransform: "capitalize",
              }}
              // component={Link}
              // to={`/${businessName}/allOrders`}
              onClick={()=>{
                navigateTo(navigate, `allOrders`)
              }}
            />
          </Tabs>
        </Box>

        {/* {active === 'dashboard' ? null : (
          <Box mt={1.75} display='flex' alignItems='center'>
            <Box
              sx={styles.btnStyle(active === 'openOrders')}
              component={Link}
              to={`/${businessName}/openOrders`}
            >
              <Typography
                variant='body1'
                fontSize={14}
                fontWeight={400}
                color={active === 'openOrders' ? whiteColor : dashboardNavColor}
              >
                Open
              </Typography>
            </Box>
            <Box
              sx={styles.btnStyle(active === 'completedOrders')}
              component={Link}
              to={`/${businessName}/completedOrders`}
            >
              <Typography
                variant='body1'
                fontSize={14}
                fontWeight={400}
                color={
                  active === 'completedOrders' ? whiteColor : dashboardNavColor
                }
              >
                Completed
              </Typography>
            </Box>
            <Box
              sx={styles.btnStyle(active === 'canceledOrders')}
              component={Link}
              to={`/${businessName}/canceledOrders`}
            >
              <Typography
                variant='body1'
                fontSize={14}
                fontWeight={400}
                color={
                  active === 'canceledOrders' ? whiteColor : dashboardNavColor
                }
              >
                Cancelled
              </Typography>
            </Box>
          </Box>
        )} */}
      </Box>
    </>
  );
}

export default DashboardNavbar;

const styles = {
  btnStyle: (isActive) => ({
    backgroundColor: isActive ? orangeColor01 : breadcrumbBgColor,
    padding: "2px 15px",
    borderRadius: "4px",
    mr: 1.2,
    cursor: "pointer",
  }),
};
