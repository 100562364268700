import { Box, IconButton, Typography } from "@mui/material";
import {
  blackColor,
  greenColor03,
  greyShade82,
  orangeColor01,
  orangeColor12,
  orangeColor13,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useState } from "react";
import { textTwoLines } from "utils/styleUtility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  getDiscountPercentage,
  getUOMValue,
  showUOMDetails,
} from "utils/utilities";
import VariantPopup from "pages/CatalogMui/popups/VariantPopup";

const ProductCardMinimal = ({
  title,
  imgUrl,
  price,
  oldPrice,
  discount,
  onClick,
  variantCount,
  product,
  buyProduct,
}) => {
  const [variantPopup, setVariantPopup] = useState(null);

  return (
    <Box
      sx={{ ...styles.card }}
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      position={"relative"}
    >
      {variantPopup?.show && (
        <VariantPopup
          product={variantPopup?.product}
          open={variantPopup?.show}
          onClose={() => {
            setVariantPopup(null);
          }}
          viewOnly
          buyProduct={buyProduct}
        />
      )}
      {Number(oldPrice) != Number(price) && (
        <Box
          sx={{
            position: "absolute",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            gap: "4px",
            minWidth: "0px",
            background: "#10847e",
            color: "rgb(255, 255, 255)",
            borderRadius: "5px",
            lineHeight: "18px",
            padding: "1px 5px",
            fontWeight: 600,
            fontSize: "10px",
            textTransform: "capitalize",
            zIndex: 9,
            top: "0px",
            left: "12px",
          }}
        >
          {getDiscountPercentage(price, oldPrice)}%
        </Box>
      )}
      <Box
        component="img"
        src={imgUrl}
        sx={{
          width: "120px",
          height: "90px",
          objectFit: "contain",
          mb: "10px",
          transition: "all 0.3s ease-in-out",
          transform: "scale(1)",
          "&:hover": {
            transform: "scale(0.97)",
          },
        }}
        onClick={onClick}
      />
      <Box display={"flex"} width={"100%"}>
        <Typography
          height="37px"
          fontSize={12}
          fontWeight={"500 !important"}
          lineHeight="20px"
          color={blackColor}
          mb="10px !important"
          sx={textTwoLines}
          mt={0.5}
          textAlign={"left"}
        >
          {title}
        </Typography>
      </Box>

      {showUOMDetails(product) ? (
        <Typography
          fontSize={11}
          lineHeight={"15px"}
          width="100%"
          textAlign={"start"}
          color={greyShade82}
          pb="6px"
        >
          {getUOMValue(product)}
        </Typography>
      ) : (
        <Box width="100%" height="21px" />
      )}

      <Box
        width={"100%"}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          minHeight={"38px"}
        >
          <Typography
            fontSize={14}
            fontWeight={600}
            lineHeight={"20px"}
            color={blackColor}
          >
            ₹ {price}
          </Typography>
          {oldPrice != price && (
            <Typography
              fontSize={12}
              fontWeight={500}
              color="#adadc4"
              lineHeight={"18px"}
              sx={{ textDecoration: "line-through" }}
            >
              ₹ {oldPrice}
            </Typography>
          )}

          {/* {discount > 0 && (
              <Typography sx={styles.discountText}>{discount}% OFF</Typography>
            )} */}
        </Box>
        {variantCount ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "30px",
              height: "30px",
              color: orangeColor01,
              fontSize: "12px",
              fontWeight: "600",
              cursor: "pointer",
              backgroundColor: orangeColor12,
              borderRadius: "50%",
              border: "1px solid" + orangeColor13,
            }}
            onClick={() => setVariantPopup({ show: true, product })}
          >
            +{Number(variantCount) - 1 || "X"}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default ProductCardMinimal;

const styles = {
  card: {
    p: "0px 12px",
    // height: '100%',
    // bgcolor: whiteColor,
    borderRight: `1px solid #EFEFEF`,
  },

  discountText: {
    fontSize: 12,
    fontWeight: 600,
    color: greenColor03,
    lineHeight: "10px",
    ml: "10px",
    display: "inline",
  },

  actionBtn: {
    width: "28px",
    height: "28px",
    color: blackColor,
    backgroundColor: whiteColor,
    border: `1px solid ${blackColor}`,

    // transition: 'all ease 0.3s',
    "&:hover": {
      color: whiteColor,
      backgroundColor: blackColor,
      // transform: 'scale(1.08)',
    },
  },
};
