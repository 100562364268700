import { useRef, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { GOOGLE_MAPS_API_KEY } from "../../configs/Constants";
import ModalCard from "../ModalCard";
import {
  backgroundColor01,
  blackColor,
  borderBottomLine2,
  borderCard01,
  greenColor,
  greenColor05,
  greyColor40,
  greyColor8,
  greyShade41,
  greyShade75,
  headingColor,
  lightGreenShade02,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { useEffect } from "react";
import { fetchAddressFromLatLng } from "../../utils/geoLocationHelper";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";

const MarkerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#1C1C1C"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    aria-labelledby="icon-svg-title- icon-svg-desc-"
    role="img"
    class="sc-rbbb40-0 haYhpV"
  >
    <title>loction-pin</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.50376 0.135614C5.50502 0.734541 3.10602 3.13025 2.60623 6.12488C2.00648 9.81826 4.00564 13.1123 7.10435 14.3102C8.10393 14.7095 9.00355 15.4082 9.50334 16.4064C9.70326 16.7059 9.80322 17.1052 10.0031 17.4047C10.1031 17.1052 10.303 16.7059 10.5029 16.4064C11.0027 15.4082 11.9023 14.8093 12.9019 14.3102C15.6008 13.2122 17.5 10.517 17.5 7.42255C17.5 2.9306 13.3018 -0.762775 8.50376 0.135614ZM10.0031 10.0179C8.60372 10.0179 7.50418 8.91987 7.50418 7.52237C7.50418 6.12488 8.60372 5.02685 10.0031 5.02685C11.4025 5.02685 12.5021 6.12488 12.5021 7.52237C12.5021 8.91987 11.4025 10.0179 10.0031 10.0179Z"
    ></path>
    <path
      fill="#10847e"
      d="M10.0033 20C11.1074 20 12.0025 19.5531 12.0025 19.0018C12.0025 18.4505 11.1074 18.0036 10.0033 18.0036C8.89921 18.0036 8.00415 18.4505 8.00415 19.0018C8.00415 19.5531 8.89921 20 10.0033 20Z"
    ></path>
  </svg>
);

const PickAddressFromMapModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  userLat,
  userLong,
}) => {
  // const userLat = localStorage.getItem("lat");
  // const userLong = localStorage.getItem("long");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY, // Replace with your API key
    libraries: ["places"],
  });

  const mapRef = useRef(null);

  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [mapCenter, setMapCenter] = useState({
    lat: 13,
    lng: 77.5946,
  });
  const [userAddress, setUserAddress] = useState("");

  const handleMapChange = async ({ center }) => {
    console.log("handleMapChange", center);
    setMapCenter(center);
    const addressInfo = await fetchAddressFromLatLng(center?.lat, center?.lng);
    console.log("handleMapChange___addressInfo", addressInfo);

    setUserAddress(addressInfo);
  };

  const gotoCurrentLocation = async () => {
    setLoading(true);
    try {
      if ("geolocation" in navigator) {
        // Get the user's current position
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;

            // Update the map center
            const newCenter = { lat: latitude, lng: longitude };
            setMapCenter(newCenter);

            // Fetch the address information from latitude and longitude
            const addressInfo = await fetchAddressFromLatLng(
              latitude,
              longitude
            );
            setUserAddress(addressInfo);

            console.log("Current location set:", newCenter, addressInfo);
          },
          (error) => {
            console.error("Error getting current location:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    } catch (error) {
      console.error("Error in gotoCurrentLocation:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmAddress = () => {
    setAlertOpen(true);
  };

  useEffect(() => {
    if (!userLat || !userLong) {
      gotoCurrentLocation();
      return;
    }

    handleMapChange({
      center: {
        lat: Number(userLat),
        lng: Number(userLong),
      },
    });
    // setMapCenter({
    //   lat: Number(userLat),
    //   lng: Number(userLong),
    // });
    return () => {};
  }, [isOpen]);

  return (
    <ModalCard
      open={isOpen}
      handleClose={() => {
        setIsOpen({
          show: false,
          userLat: "",
          userLong: "",
        });
      }}
      width="34%"
      mobileWidth="100%"
      closeBtnStyles={{
        bgcolor: whiteColor,
        p: 0.3,
        top: { xs: 105, md:5 },
        display:{xs:'none', md:'flex'}
      }}
    >
      {!isLoaded ? (
        <Typography>Loading map...</Typography>
      ) : (
        <Box sx={styles.modelCardContainer}>
          <Box sx={styles.card}>
            <Box pb={6.7}>
              <Typography sx={styles.titleText}>Search Address</Typography>

              <PlacesAutocomplete
                onAddressSelecte={(center) => {
                  handleMapChange({ center });
                }}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                height: { xs: "43vh", md: "357px" },
                position: "relative",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={styles.currentLocationBtn}
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : (
                    <MyLocationOutlinedIcon sx={{ height: "14px", mr: -0.6 }} />
                  )
                }
                onClick={gotoCurrentLocation}
              >
                {loading
                  ? "Finding your location..."
                  : "Go to current location"}
              </Button>

              <GoogleMap
                center={mapCenter}
                zoom={20}
                mapContainerStyle={{ width: "100%", height: "100%" }}
                onDragEnd={() => {
                  const center = mapRef.current.getCenter();
                  console.log("MAP___HIT", {
                    lat: center.lat(),
                    lng: center.lng(),
                  });
                  handleMapChange({
                    center: { lat: center.lat(), lng: center.lng() },
                  });
                }}
                onLoad={(map) => (mapRef.current = map)}
                options={{
                  mapTypeControl: false,
                  fullscreenControl: false,
                  streetViewControl: false,
                  keyboardShortcuts: false,
                  // disableDefaultUI: true,
                  zoomControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_RIGHT,
                  },
                }}
              >
                <Marker position={mapCenter} />

                {/* Add custom CSS to hide Map Data and Terms */}
                <style>
                  {`
                .gm-style-cc {
                display: none
                }
                `}
                </style>
              </GoogleMap>
            </Box>

            <Box m="15px">
              <Typography
                fontSize={13.5}
                fontWeight={600}
                color={blackColor}
                lineHeight="15px"
                letterSpacing="-0.3px"
                pb="3px"
              >
                Selected Address
              </Typography>

              <Typography
                fontSize={{ xs: 11, md: 12 }}
                fontWeight={500}
                color={greyColor40}
                letterSpacing="-0.3px"
                pb="10px"
              >
                You can edit the House/Flat Number on the next screen
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "9px",
                  bgcolor: lightGreenShade02,
                  p: "10px 8px",
                  borderRadius: "6px",
                }}
              >
                <Box
                  width={{ xs: "20px", md: "21px" }}
                  height={{ xs: "20px", md: "21px" }}
                  mt={-0.1}
                >
                  <MarkerIcon />
                </Box>

                <Typography
                  fontSize={{ xs: 11.5, md: 12.5 }}
                  fontWeight={600}
                  color={greenColor05}
                  lineHeight="18px"
                  letterSpacing="0px"
                >
                  {userAddress?.fullAddress}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  sx={[styles.actionBtn, styles.proceedBtn]}
                  onClick={handleConfirmAddress}
                >
                  PICK THIS LOCATION
                </Button>
              </Box>
            </Box>

            <AlertPopup
              content={"Is this the exact GPS location of your building ?"}
              isOpen={alertOpen}
              onClose={() => {
                setAlertOpen(false);
              }}
              onConfirm={() => {
                setAlertOpen(false);
                onConfirm(userAddress);
              }}
            />
          </Box>
        </Box>
      )}
    </ModalCard>
  );
};

export default PickAddressFromMapModal;

const PlacesAutocomplete = ({ onAddressSelecte }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const SearchIcon = () => (
    <svg
      width="19"
      height="19"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 21L19 19M10.5 20C11.7476 20 12.9829 19.7543 14.1355 19.2769C15.2881 18.7994 16.3354 18.0997 17.2175 17.2175C18.0997 16.3354 18.7994 15.2881 19.2769 14.1355C19.7543 12.9829 20 11.7476 20 10.5C20 9.25244 19.7543 8.0171 19.2769 6.86451C18.7994 5.71191 18.0997 4.66464 17.2175 3.78249C16.3354 2.90033 15.2881 2.20056 14.1355 1.72314C12.9829 1.24572 11.7476 1 10.5 1C7.98044 1 5.56408 2.00089 3.78249 3.78249C2.00089 5.56408 1 7.98044 1 10.5C1 13.0196 2.00089 15.4359 3.78249 17.2175C5.56408 18.9991 7.98044 20 10.5 20Z"
        stroke="#10847E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );

  const [inputValue, setInputValue] = useState("");

  const handleSelect = async (description) => {
    try {
      const results = await getGeocode({ address: description });
      console.log("handleSelect___results", results);

      const { lat, lng } = await getLatLng(results[0]);
      console.log("handleSelect___results", { lat, lng });
      onAddressSelecte({ lat, lng });
      clearSuggestions();
      setInputValue("");
      // toggleOpen(false);
    } catch (error) {
      console.error("Error fetching coordinates: ", error);
    }
  };

  useEffect(() => {
    // Set up a timeout to update debounced value after 500ms
    const timeoutId = setTimeout(() => {
      setValue(inputValue);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue]);

  useEffect(() => {
    if (status === "OK") {
      // Log only when data is available
      console.log("API__HIT___data_addr", data);
    }
  }, [status, data]);

  const placeholderStyles = `
  input::placeholder {
    color: gray; 
  }
`;

  return (
    <Box
      width="calc(100% - 50px)"
      position="absolute"
      zIndex={30}
      m="0 16px 0 14px"
    >
      <Box sx={styles.autoSearchBar}>
        <SearchIcon />
        <style>{placeholderStyles}</style>
        <input
          type="text"
          value={inputValue}
          onChange={({ target }) => {
            setInputValue(target.value);
          }}
          disabled={!ready}
          style={{
            width: "100%",
            fontSize: 12.5,
            fontFamily: "Poppins",
            backgroundColor: whiteColor,
            padding: "8px 10px",
          }}
          placeholder="Search Address"
        />
      </Box>

      <Box
        width="100%"
        sx={{
          // height: "300px",
          overflowY: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          ml: 2.3,
        }}
      >
        {status === "OK" &&
          data.map(({ place_id, description }) => (
            <Box
              key={place_id}
              sx={styles.autoSuggestTile}
              onClick={() => {
                handleSelect(description);
              }}
            >
              <PinDropOutlinedIcon
                sx={{
                  fontSize: "22px",
                  // color: greenColor05
                  color: blackColor,
                }}
              />
              <Typography
                fontSize={11.5}
                lineHeight="20px"
                fontWeight={400}
                color={blackColor}
              >
                {description}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

const AlertPopup = ({ content, onClose, onConfirm, isOpen }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          // padding: 1,
          width: { xs: "90%", md: "27%" },
          textAlign: "center",
          borderRadius: "10px",
          mt: -14,
        },
      }}
    >
      <Box
        sx={{
          borderRadius: "15px 15px 0px 0px",
          width: "90%",
          padding: "10px 15px",
          textAlign: "center",
          bgcolor: greyShade41,
        }}
      >
        <Typography
          textAlign="center"
          fontSize={15}
          fontWeight={600}
          lineHeight="28px"
          color={headingColor}
        >
          Alert
        </Typography>
        {/* <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton> */}
      </Box>
      <DialogContent sx={{ p: "15px" }}>
        <DialogContentText
          fontSize={13}
          fontWeight={500}
          color={blackColor}
          textAlign="center"
          lineHeight="20px"
          py="5px"
        >
          {content}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ bgcolor: greyShade41, p: "10px 15px" }}>
        <Button onClick={onClose} variant="outlined" sx={styles.alertBtn}>
          NO
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          disableElevation
          sx={{
            ...styles.alertBtn,
            color: whiteColor,
            backgroundColor: blackColor,
            "&:hover": {
              backgroundColor: whiteColor,
              color: blackColor,
            },
          }}
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: { xs: "10px 10px 0 0", md: "10px" },
    overflow: "hidden",
    position: "relative",
  },

  titleText: {
    fontSize: 13.5,
    fontWeight: 600,
    color: blackColor,
    mb: 1,
    letterSpacing: "-0.3px",
    lineHeight: "14px",
    p: "12px 15px 10px 15px",
  },

  addressBox: {
    backgroundColor: greenColor,
    p: "4px 15px",
    textAlign: "center",
  },

  actionBtn: {
    fontSize: { xs: 12.5, md: 13 },
    fontWeight: 500,
    lineHeight: "22px",
    color: greyColor8,
    border: `1px solid ${greyColor8}`,
    p: "7px 28px",
    borderRadius: "8px",
    textTransform: "uppercase",
    letterSpacing: "0px",
    mt: "14px",
  },

  proceedBtn: {
    color: whiteColor,
    background: greenColor05,
    border: `1px solid ${greenColor05}`,
    textTransform: "uppercase",
    "&:hover": {
      color: greenColor05,
      background: whiteColor,
    },
  },

  cancelBtn: {
    color: blackColor,
    background: whiteColor,
    p: "5px 55px",
    border: `1px solid ${blackColor}`,
    textTransform: "uppercase",
    "&:hover": {
      color: whiteColor,
      background: blackColor,
      // border: `1px solid ${greyColor8}`,
    },
  },

  autoSuggestTile: {
    display: "flex",
    alignItems: "center",
    gap: 1,
    bgcolor: whiteColor,
    p: "10px 10px 10px 10px",
    borderBottom: borderCard01,
    cursor: "pointer",
    "&:hover": {
      bgcolor: backgroundColor01,
    },
  },

  autoSearchBar: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    p: "0px 10px",
    bgcolor: whiteColor,
    border: `1px solid ${greyShade75}`,
    borderRadius: 1.5,
  },

  currentLocationBtn: {
    fontSize: "12px",
    color: greenColor05,
    bgcolor: whiteColor,
    p: "3px 10px",
    borderRadius: { xs: "6px", md: "8px" },
    border: `0.5px solid ${greenColor05}`,
    textTransform: "unset",
    position: "absolute",
    bottom: 15,
    right: { xs: 20, md: 30 },
    zIndex: 10,
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 8px 0px",
    "&:hover": {
      bgcolor: whiteColor,
      border: `0.5px solid ${greenColor05}`,
    },
  },

  alertBtn: {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "20px",
    p: "4px 20px",
    color: blackColor,
    border: `1px solid ${blackColor}`,
    bgcolor: whiteColor,
    mr: "8px",
    textTransform: "uppercase",
    "&:hover": {
      border: `1px solid ${blackColor}`,
      backgroundColor: blackColor,
      color: whiteColor,
    },
  },

  modelCardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: { xs: "flex-end", md: "center" },
    height: { xs: "100vh", md: "auto" },
  },
};
