import { Box, Chip, Grid, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import { getOutOfStock } from "utils/productHelper";
import ProductMobileCard from "./ProductMobileCard";
import { blackColor, whiteColor } from "configs/styles/muiThemes";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function FilteredSubCategory({
  allProducts,
  filteredSubCat,
  onRemoveSubCatFilter,
  cartData,
  cartId,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
  hasCatalogPartner,
  checkPartnerInventory,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [products, setProducts] = useState([]);

  useEffect(() => {
    let productList = [];

    filteredSubCat.forEach((item) => {
      allProducts?.productList?.forEach((product) => {
        if (item.code == product.productSubCategory2?.[0]?.code) {
          productList.push(product);
        }
      });
    });

    setProducts(productList);
  }, [filteredSubCat]);

  return (
    <Box>
      {/* <CategoryGroupHeader
        title={selectedCategory?.[0]?.subCatValue}
        totalProduct={selectedCategory?.[0]?.count}
      /> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          mt: { xs: 1.5, md: 2 },
          mx: { xs: 2, md: 0 },
        }}
      >
        {filteredSubCat.map((item, index) => {
          return (
            <Chip
              label={
                <Typography fontSize={12.5} mr={1}>
                  {item.value}
                </Typography>
              }
              //   onClick={handleClick}
              variant="outlined"
              onDelete={() => {
                onRemoveSubCatFilter(item);
              }}
              deleteIcon={
                <CloseOutlinedIcon sx={{ color: "#000 !important" }} />
              }
              size="small"
              sx={{
                mr: 1.5,
                mb: { xs: 0.5, md: 1.5 },
                color: blackColor,
                bgcolor: whiteColor,
                borderColor: blackColor,
                p: "12px 12px !important",
              }}
            />
          );
        })}
      </Box>

      <Grid
        container
        columnSpacing={{ md: 1, xs: 1 }}
        rowSpacing={{ md: 2.5, xs: 1 }}
        pt={{ md: 1.5, xs: 1.5 }}
        pl={{ xs: 1, md: 0 }}
        pr={{ xs: 0, md: 1 }}
        mb={{ xs: 10, md: 0 }}
      >
        {products?.map((item, index) => {
          if (item?.variantInfo?.masterVariant) return null;

          return (
            <Grid item xs={12} sm={4} md={2.4}>
              {matches ? (
                <ProductCard
                  img={item?.productPhoto?.[0]?.docURL}
                  price={item.productSalePrice}
                  oldPrice={item.productOriginalPrice}
                  description={item.productName}
                  discount={item.percentageOff}
                  style={{ my: 1.5 }}
                  // isOutOfStock={getOutOfStock(item)}
                  isOutOfStock={
                    hasCatalogPartner
                      ? checkPartnerInventory(item)
                      : getOutOfStock(item)
                  }
                  isComingSoon={item.commerceEnabled === "NO"}
                  minQty={item.minOrderQuantity}
                  maxQty={item.maxOrderQuantity}
                  currencySymbol={
                    item.currencySymbol ? "₹" : item.currencySymbol
                  }
                  cartData={cartData}
                  product={item}
                  cartId={cartId}
                  onQtyUpdate={(data) => {
                    onQtyUpdate(data);
                  }}
                  onAddProduct={(data) => {
                    onAddProduct(data);
                  }}
                  onRemoveProduct={onRemoveProduct}
                  subscribe={item?.subscription?.status === "active"}
                  availability={
                    item?.availability?.[0]?.toLowerCase() === "yes"
                  }
                  subscription={item?.subscription}
                  variant={item?.variantInfo?.masterProductVariantId}
                  variantCount={item?.count}
                />
              ) : (
                <ProductMobileCard
                  productid={item?.id}
                  img={item?.productPhoto?.[0]?.docURL}
                  price={item.productSalePrice}
                  oldPrice={item.productOriginalPrice}
                  description={item.productName}
                  discount={item.percentageOff}
                  style={{ my: 1.5 }}
                  // isOutOfStock={getOutOfStock(item)}
                  isOutOfStock={
                    hasCatalogPartner
                      ? checkPartnerInventory(item)
                      : getOutOfStock(item)
                  }
                  isComingSoon={item.commerceEnabled === "NO"}
                  minQty={item.minOrderQuantity}
                  maxQty={item.maxOrderQuantity}
                  currencySymbol={
                    item.currencySymbol ? "₹" : item.currencySymbol
                  }
                  cartData={cartData}
                  product={item}
                  cartId={cartId}
                  onQtyUpdate={(data) => {
                    onQtyUpdate(data);
                  }}
                  onAddProduct={(data) => {
                    onAddProduct(data);
                  }}
                  onRemoveProduct={onRemoveProduct}
                  subscribe={item?.subscription?.status === "active"}
                  availability={
                    item?.availability?.[0]?.toLowerCase() === "yes"
                  }
                  subscription={item?.subscription}
                  variant={item?.variantInfo?.masterProductVariantId}
                  variantCount={item?.count}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default FilteredSubCategory;

const styles = {
  btnHover: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};
