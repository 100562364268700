import {
  Box,
  Button,
  IconButton,
  Radio,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  blackColor,
  blackShade25,
  greenColor05,
  greyShade76,
  greyShade80,
  orangeColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useEffect, useState } from "react";
import { textOneLines } from "utils/styleUtility";
import { formatPhoneNumber } from "utils/utilities";
import PinDropRoundedIcon from "@mui/icons-material/PinDropRounded";
import { checkIsUpdateRequired } from "utils/AddressHelper/addressHelper";
import AlertModal from "components/AlertModal";
import { addressCard_DeleteAddressInfo } from "configs/Constants";

const AddressCardNew = ({
  index,
  selectedAddrIndex,
  setSelectedAddrIndex,
  onEdit,
  address,
  readOnly,
  onSelectMethod,
  onDeleteAddress,
  onEditAddress,
  cardStyle,
  isAddressSelected,
  showAlternateNo,
  recipientPhInline,
  carttop,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);

  const [isUpdateRequired, setIsUpdateRequired] = useState(false);

  const addressLine1 = address?.addressLine1;
  const landmark = address?.landmark;
  const city = address?.city;
  const state = address?.state;
  const pincode = address?.zip;

  const handleClickOpen = () => {
    setOpen(true);
    console.log("hitt delete");
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsUpdateRequired(checkIsUpdateRequired(address?.updatedOn));

    return () => {};
  }, [address]);

  const getAddressType = (type) => {
    switch (type) {
      case "Home":
        return "Home";

      case "Office/Commercial":
        return "Office";

      default:
        return "Others";
    }
  };

  return (
    <Box
      sx={{
        p: "12px 15px",
        borderBottom: `7px solid #f5f5f6`,
        cursor: "pointer",
      }}
    >
      <Box display="flex" alignItems="flex-start" gap={1}>
        <Radio
          value="a"
          //   checked={isAddressSelected || false}
          checked={selectedAddrIndex == index}
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
          onChange={() => {
            setSelectedAddrIndex(index);
            // onSelectMethod();
          }}
          size="small"
          sx={{
            p: 0,
            mt: 0.4,
            color: blackColor,
            "&.Mui-checked": {
              color: orangeColor01,
            },
          }}
        />

        <Box flex={1}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            onClick={() => setSelectedAddrIndex(index)}
          >
            <Typography
              sx={{
                fontSize: 12.5,
                fontWeight: 600,
                color: blackColor,
                ...textOneLines,
              }}
            >
              {address?.name}{" "}
              {isAddressSelected && (
                <Typography
                  component="span"
                  fontSize={11}
                  fontWeight={400}
                  color={greyShade76}
                >
                  (Default)
                </Typography>
              )}
            </Typography>

            <Box
              sx={{
                fontSize: 9,
                fontWeight: 700,
                color: greenColor05,
                p: "2px 6px",
                lineHeight: "14px",
                border: `1px solid ${greenColor05}`,
                borderRadius: "30px",
                ml: "5px",
              }}
            >
              {getAddressType(address?.addressType || "")}
            </Box>
          </Box>
          <Typography
            fontSize={11.5}
            fontWeight={400}
            color={greyShade80}
            lineHeight="18px"
            mr={8}
            sx={selectedAddrIndex === index ? {} : { ...textOneLines }}
          >
            {addressLine1?.endsWith(",") ? addressLine1 : `${addressLine1},`}{" "}
            {landmark
              ? landmark?.endsWith(",")
                ? landmark
                : `${landmark},`
              : ""}{" "}
            {city}, {state} - {pincode}
          </Typography>

          {selectedAddrIndex === index && (
            <>
              <Typography
                fontSize={12}
                fontWeight={400}
                lineHeight="18px"
                color={greyShade80}
                mt={0.5}
              >
                Mobile:{" "}
                <Typography
                  component="span"
                  fontSize={12}
                  fontWeight={600}
                  lineHeight="18px"
                  color={blackColor}
                  ml={0.4}
                >
                  {formatPhoneNumber(address?.phoneNo)}
                  {address?.alternatePhoneNo
                    ? `, ${formatPhoneNumber(address?.alternatePhoneNo)}`
                    : ""}
                </Typography>
              </Typography>

              <Box display="flex" justifyContent="space-between" mt="10px">
                <Box display="flex" gap={{xs:1, md:1}}>
                  <Button
                    variant="outlined"
                    sx={styles.btn}
                    onClick={() => {
                      onSelectMethod();
                    }}
                  >
                    Deliver Here
                  </Button>

                  {isUpdateRequired && (
                    <Button
                      variant="text"
                      sx={{ ...styles.btn, ...styles.updateBtn }}
                      onClick={onEditAddress}
                      startIcon={
                        matches && (
                          <PinDropRoundedIcon
                            sx={{
                              fontSize: "16px !important",
                              m: "-1px -4px 0",
                            }}
                          />
                        )
                      }
                    >
                      Update Required
                    </Button>
                  )}
                </Box>

                <Box display="flex" gap={{xs:1, md:1.8}}>
                  <Button
                    maxWidth="30px !important"
                    variant="outlined"
                    sx={{ ...styles.btn, ...styles.editBtn }}
                    onClick={onEditAddress}
                  >
                    Edit
                  </Button>

                  <IconButton
                    sx={{ ...styles.btn, ...styles.deleteBtn }}
                    onClick={() => handleClickOpen()}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <AlertModal
        open={open}
        content={addressCard_DeleteAddressInfo}
        btnName="Delete"
        handleConfirm={() => {
          onDeleteAddress();
          handleClose();
        }}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default AddressCardNew;

const DeleteIcon = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" role="deleteIcon">
    <g fill-rule="nonzero" stroke="none" stroke-width="1">
      <path
        d="M14.65 2.514h-3.065l-.694-1.64a1.365 1.365 0 00-.536-.619A1.411 1.411 0 009.571 0H6.397a1.41 1.41 0 00-.784.255c-.258.17-.436.377-.536.62l-.694 1.64H1.317c-.092 0-.6.054-.6.601 0 .548.508.655.6.655h.953v9.35c0 .544.155 1.007.466 1.39.31.383.685.575 1.121.575h8.254c.437 0 .81-.198 1.121-.594.311-.397.466-.866.466-1.41v-9.31h.953c.092 0 .6-.108.6-.656 0-.547-.508-.602-.6-.602zM6.249 1.365a.28.28 0 01.169-.108H9.56a.28.28 0 01.17.108l.476 1.15H5.762l.486-1.15zm6.09 12.249c-.119.205-.407.215-.427.215H4.057c-.02 0-.339 0-.436-.215-.097-.215-.081-.388-.081-.532v-9.31h8.889v9.31c0 .144.026.326-.092.532z"
        transform="translate(-813 -359) translate(813 359)"
      ></path>
      <path
        d="M6.029 11.426V5.894c0-.047-.096-.367-.56-.367-.462 0-.558.326-.558.367v5.532c0 .045.096.367.559.367.463 0 .559-.322.559-.367zM8.543 11.426V5.894c0-.044-.087-.367-.559-.367-.472 0-.559.322-.559.367v5.532c0 .048.087.367.56.367.47 0 .558-.325.558-.367zM11.057 11.426V5.894c0-.044-.05-.367-.559-.367-.508 0-.558.323-.558.367v5.532c0 .048.05.367.558.367.51 0 .56-.325.56-.367z"
        transform="translate(-813 -359) translate(813 359)"
      ></path>
    </g>
  </svg>
);

const styles = {
  btn: {
    fontSize: 11,
    fontWeight: 600,
    color: orangeColor01,
    bgcolor: whiteColor,
    lineHeight: "20px",
    border: `1px solid ${orangeColor01}`,
    borderRadius: "5px",
    p: { xs: "4.5px 10px", md: "5px 14px" },
    "&:hover": {
      color: whiteColor,
      bgcolor: orangeColor01,
      border: `1px solid ${orangeColor01}`,
    },
  },

  updateBtn: {
    color: greenColor05,
    bgcolor: whiteColor,
    border: "none",
    textDecoration: "underline",
    p: { xs: "4.5px 8px", md: "5px 14px" },
    "&:hover": {
      color: greenColor05,
      bgcolor: whiteColor,
      border: "none",
    },
  },

  editBtn: {
    minWidth:'34px !important',
    color: blackColor,
    bgcolor: whiteColor,
    border: `1px solid ${blackColor}`,
    "&:hover": {
      color: whiteColor,
      bgcolor: blackColor,
      border: `1px solid ${blackColor}`,
    },
  },

  deleteBtn: {
    color: blackColor,
    bgcolor: whiteColor,
    p: "5px 8px",
    border: `1px solid ${blackColor}`,
    "&:hover": {
      color: blackColor,
      bgcolor: whiteColor,
      border: `1px solid ${blackColor}`,
    },
  },
};
