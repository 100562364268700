import { Box, Button, Stack, Typography } from "@mui/material";
import {
  blackShade23,
  blackShade24,
  greenColor08,
  whiteColor,
} from "configs/styles/muiThemes";
import QuantityPicker from "pages/CatalogMui/QuantityPicker";
import { formatString } from "pages/SingleProduct/VariantProduct/VariantHelper";
import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateTo } from "utils/urlHelper";
import { getDiscountPercentage } from "utils/utilities";

function ProductTile({
  product,
  img,
  amt,
  originalAmt,
  variantList,
  cartData,
  minQty,
  maxQty,
  onQtyUpdate,
  cartId,
  onAddProduct,
  onRemoveProduct,
  viewOnly,
  onClose,
}) {
  const businessName = sessionStorage.getItem("businessName");
  const navigate = useNavigate();

  const loading = cartData?.filter((item) => {
    return item?.productId === product?.id;
  });

  const getProductCartCount = (cartList, product) => {
    const isPresent = cartList?.filter((cartProduct) => {
      return cartProduct?.productId === product?.id;
    });

    const cartCount =
      isPresent?.length > 0 ? isPresent?.[0]?.currentQuantity : 0;

    return cartCount;
  };

  const count = getProductCartCount(cartData, product);
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={{ xs: "16px", md: "32px" }}
      sx={{
        background: whiteColor,
        p: "8px 10px",
        border: "1px solid #DEE1F2",
        borderRadius: "12px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flex={1}
        gap="8px"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          // navigate(
          //   `/${businessName}/singleProduct/${
          //     product?.id
          //     // buyProduct ? product?.productId : item?.id
          //   }`
          // );
          navigateTo(
            navigate,
            `singleProduct/${
              product?.id
              // buyProduct ? product?.productId : item?.id
            }`
          );
          onClose();
        }}
      >
        <Box display="flex" alignItems="center" gap="12px">
          <Box
            sx={{
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #e9ebf3",
              position: "relative",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <Box
              component={"img"}
              sx={{
                width: "auto",
                height: "35px",
                objectFit: "contain",
              }}
              src={img}
            />
            {amt !== originalAmt && (
              <Box
                sx={{
                  position: "absolute",
                  top: "-4px",
                  left: 0,
                  zIndex: 99,
                  width: "23px",
                  height: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundImage: 'url("/./assets/images/offer-bg.svg")',
                  backgroundSize: "contain", // Ensures the entire image is visible
                  backgroundPosition: "center", // Centers the image
                  backgroundRepeat: "no-repeat",
                }}
              >
                {/* <Box
                  component="img"
                  src="/./assets/images/offer-bg.svg"
                  width="25px"
                /> */}
                <Typography fontSize={9} fontWeight={600} color={whiteColor}>
                  {getDiscountPercentage(amt, originalAmt)}%
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" gap={3}>
            {variantList?.map((variant) => (
              <Stack>
                <Typography
                  fontSize={11}
                  fontWeight={400}
                  lineHeight={1.5}
                  color={blackShade24}
                >
                  {variant?.type == "uom"
                    ? "Unit of measure"
                    : formatString(variant?.type)}
                  {/* {amt} */}
                </Typography>
                {variant?.type == "colour" ? (
                  <Box
                    sx={{
                      width: "25px",
                      height: "12px",
                      background: variant?.value,
                      borderRadius: "3px",
                      border: "1px solid " + whiteColor,
                      mt: "3px",
                    }}
                  />
                ) : (
                  <Typography
                    fontSize={{ md: 12, xs: 13 }}
                    fontWeight={600}
                    lineHeight={1.5}
                    color={blackShade23}
                  >
                    {variant?.value}
                    {/* {originalAmt} */}
                  </Typography>
                )}
              </Stack>
            ))}
          </Box>
        </Box>
        <Stack>
          <Typography
            fontSize={14}
            fontWeight={600}
            lineHeight={1.5}
            color={blackShade23}
            textAlign={viewOnly ? "right" : "left"}
          >
            {/* ₹ 1800 */}₹ {amt}
          </Typography>
          {amt !== originalAmt && (
            <Typography
              component="del"
              fontSize={11.5}
              fontWeight={600}
              lineHeight={1.5}
              color={blackShade24}
              textAlign={viewOnly ? "right" : "left"}
            >
              {/* ₹ 2800 */}₹ {originalAmt}
            </Typography>
          )}
        </Stack>
      </Box>
      {!viewOnly && (
        <Box>
          {count > 0 ? (
            <QuantityPicker
              count={count}
              onIncrement={() => {
                if (loading?.[0]?.loading) return;

                if (count < maxQty) {
                  onQtyUpdate({
                    cartId: cartId,
                    productId: product?.id,
                    quantity: count + 1,
                  });
                }
              }}
              onDecrement={() => {
                if (loading?.[0]?.loading) return;

                if (count > minQty) {
                  onQtyUpdate({
                    cartId: cartId,
                    productId: product?.id,
                    quantity: count - 1,
                  });
                } else {
                  onRemoveProduct({
                    productId: product?.id,
                  });
                }
              }}
              minQty={minQty}
              maxQty={maxQty}
            />
          ) : (
            <Button
              sx={{
                background: whiteColor,
                color: greenColor08,
                border: "1px solid" + greenColor08,
                borderRadius: "6px",
                fontWeight: 600,
                fontSize: 12,
                p: "3px 24px",
                minWidth: "auto",
              }}
              disableRipple
              onClick={() => {
                if (loading?.[0]?.loading) return;

                let gstTaxValue = 0;

                if (product.gstInfo != null) {
                  gstTaxValue = product.gstInfo.gstPercentage.replace(
                    /[&\/\\#,+()$~%.'":*?<>{}]/g,
                    ""
                  );
                } else {
                  gstTaxValue = 0;
                }

                let productAmtWithoutGST =
                  (product.productSalePrice * 100) /
                  (100 + Number(gstTaxValue));
                let productTaxPerUnit =
                  product.productSalePrice - productAmtWithoutGST;

                onAddProduct({
                  cartId: cartId,
                  product: {
                    productId: product?.id,
                    productName: product?.productName,
                    productPicURL: img,
                    topicId: "",
                    productOriginalPrice: product?.productOriginalPrice,
                    productSalePrice: product?.productSalePrice,
                    originalQuantity: minQty,
                    currentQuantity: minQty,
                    currencySymbol: "₹",
                    percentageOff: product?.percentageOff,
                    quantityChangedFlag: false,
                    priceChangedFlag: false,
                    productTotalPrice: minQty * product?.productSalePrice,
                    productTax: productTaxPerUnit.toFixed(2),
                    productTaxRate: gstTaxValue,
                    measurementInfo: product?.measurementInfo,
                    catalogPartnerItemInfo:
                      product?.catalogPartnerItemInfo?.[0] || null,
                  },
                  clientType: "web",
                });
              }}
            >
              Add
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ProductTile;
