import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DiscountIcon from "@mui/icons-material/Discount";
import moment from "moment";
import Slider from "react-slick/lib/slider";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  backgroundColor01,
  blackColor,
  borderCard,
  borderCard01,
  greenColor01,
  greenColor05,
  greyShade61,
  greyShade62,
  lightBlueColor02,
  lightBlueColor03,
  lightBlueColor04,
  lightOrange03,
  lightOrange04,
  lightOrange05,
  lightPink01,
  lightPink02,
  lightPink03,
  whiteColor,
} from "configs/styles/muiThemes";
import { useNavigate } from "react-router-dom";
import { navigateTo } from "utils/urlHelper";

const DiscountsAndCoupons = ({ dealsCouponsDetails }) => {
  const slider = useRef(null);
  const navigate = useNavigate();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const businessName = sessionStorage.getItem("businessName");

  const settings = {
    dots: true,
    // centerMode: !matches ? true : false,
    // centerPadding: !matches ? "10%" : null,
    vertical: true,
    verticalSwiping: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    arrows: false,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };

  const onSlideNext = () => {
    if (currentSlideIndex < dealsCouponsDetails?.length - 3) {
      slider.current.slickNext();
    }
  };

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev();
    }
  };

  const [isCopiedIndex, setIsCopiedIndex] = useState(-1);

  const handleCopy = (coupon, index) => {
    const COUPON_CODE = `${coupon}`;
    navigator.clipboard.writeText(COUPON_CODE);

    setIsCopiedIndex(index);
    setTimeout(() => {
      setIsCopiedIndex(-1);
    }, 1000);
  };

  return (
    <Box
      sx={{
        m: "25px 0 30px 0 !important",
        display: "flex",
        borderRadius: "15px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          backgroundColor: lightBlueColor04,
          display: "flex",
          alignItems: "flex-end",
          gap: 2,
          width: "50%",
        }}
      >
        <Box
          component={"img"}
          src="https://storage.googleapis.com/bodefaults/shopweb/discount-banner_home.svg"
          width="100px"
          alt="discount-banner"
        />
        <Stack
          sx={{
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h2"
            fontSize={16}
            color={blackColor}
            lineHeight="25px"
          >
            Awesome Discounts, Just a click away
          </Typography>

          <Typography
            variant="body1"
            fontSize={12.5}
            color={blackColor}
            lineHeight="22px"
            mt="3px"
          >
            Use coupon codes at checkout, Save More!
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          backgroundColor: whiteColor,
          width: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "50%" }}>
          {dealsCouponsDetails?.length === 1 ? (
            <CouponCard
              percentageOff={dealsCouponsDetails?.[0]?.discountPercentage}
              couponCode={dealsCouponsDetails?.[0]?.resellerCode}
              expiryDate={dealsCouponsDetails?.[0]?.codeValidityEnd}
              index={dealsCouponsDetails?.[0]}
              // isCopied={isCopiedIndex == index}
              // handleCopy={() =>
              //   handleCopy(dealsCouponsDetails?.[0]?.resellerCode, index)
              // }
            />
          ) : (
            <Slider {...settings}>
              {dealsCouponsDetails?.map((item, index) => {
                return (
                  <CouponCard
                    percentageOff={item?.discountPercentage}
                    couponCode={item?.resellerCode}
                    expiryDate={item?.codeValidityEnd}
                    index={index}
                    key={index}
                    // isCopied={isCopiedIndex == index}
                    // handleCopy={() =>
                    //   handleCopy(dealsCouponsDetails?.[0]?.resellerCode, index)
                    // }
                  />
                );
              })}
            </Slider>
          )}
        </Box>
        <Box
          textAlign="right"
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer", px: 2 }}
          onClick={() => 
            // navigate(`/${businessName}/dealsCoupons`)
            navigateTo(navigate, `dealsCoupons`)
         }
        >
          <Typography
            fontSize={12}
            fontWeight={600}
            color={blackColor}
            lineHeight="20px"
          >
            Know more
          </Typography>
          <ChevronRightRoundedIcon sx={{ color: blackColor }} />
        </Box>
      </Box>
    </Box>
  );
};

export default DiscountsAndCoupons;

const CouponCard = ({
  percentageOff,
  couponCode,
  expiryDate,
  handleCopy,
  isCopied,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p="15px 20px 15px"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box sx={styles.offerTag}>
          {percentageOff}%
          <br />
          OFF
        </Box>

        <Box>
          <Typography
            fontSize={13}
            fontWeight={400}
            color={blackColor}
            lineHeight={1.5}
            mb={"3px !important"}
          >
            Deals of this week
          </Typography>

          <Typography
            fontSize={13.5}
            fontWeight={600}
            lineHeight="22px"
            color={greenColor05}
          >
            Get extra {percentageOff}% Off
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  arrowIconBtn: {
    width: "32px",
    height: "38px",
    color: blackColor,
    bgcolor: whiteColor,
    p: 0.7,
    border: borderCard01,
    borderRadius: "7px",
    position: "absolute",
    top: "40%",
    textTransform: "uppercase",
    boxShadow: "0 1px 5px rgb(202 202 214 / 55%)",
    "&:hover": {
      color: blackColor,
      bgcolor: whiteColor,
    },
  },

  couponBox: (color) => ({
    fontSize: 13,
    fontWeight: 600,
    color: color,
    borderRadius: "6px",
    p: "5px 10px",
    border: `1.5px dashed ${color}`,
  }),

  couponCard: {
    borderRadius: "12px",
    border: `1px solid ${greyShade61}`,
    bgcolor: whiteColor,
    boxShadow: "0 2px 4px 0 rgb(234 234 239)",
    position: "relative",
    "&::before": {
      content: "''",
      width: "12px",
      height: "23px",
      bgcolor: whiteColor,
      position: "absolute",
      top: "54%",
      left: "-1px",
      borderRight: borderCard,
      borderRadius: "0 80px 80px 0",
      // boxShadow: 'inset -4px 0 5px -5px rgba(0, 0, 0, .2), -4px 0 0 0 #f3f3f8',
    },
    "&::after": {
      content: "''",
      width: "12px",
      height: "23px",
      bgcolor: whiteColor,
      position: "absolute",
      top: "54%",
      right: "-1px",
      borderLeft: borderCard,
      borderRadius: "80px 0 0 80px",
      // boxShadow: 'inset 4px 0 5px -5px rgba(0, 0, 0, .2), -4px 0 0 0 #f3f3f8',
    },
  },

  discountIcon: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    bgcolor: whiteColor,
  },

  offerTag: {
    width: "48px",
    height: "48px",
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.25,
    color: whiteColor,
    bgcolor: greenColor05,
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  copyBtn: {
    fontSize: 12.5,
    fontWeight: 600,
    color: greenColor05,
    p: 0,
    textTransform: "uppercase",
    "&:hover": {
      bgcolor: whiteColor,
    },
  },
};
