import {
  Box,
  Button,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { automobileProducts } from "../../utils/demoData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getCommingSoon, getOutOfStock } from "utils/productHelper";
import CategoryGroupHeader from "./CategoryGroupHeader";
import ProductCard from "./ProductCard";
import MobileProductCard from "pages/Home/MobileUICards/MobileProductCard";
import ProductMobileCard from "./ProductMobileCard";
import { blackColor } from "configs/styles/muiThemes";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "components/Loader";

function AllCategoryProducts(props) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [products, setProducts] = useState(props?.producList?.slice(0, 40));
  const [currentPage, setCurrentPage] = useState(2);
  const couponCount = Math.ceil(props?.producList?.length / 40);

  // const [pageNo, setPageNo] = useState(2);

  const [hasMoreData, setHasMoreData] = useState(true);

  const PAGE_SIZE = 40;

  const handlePagination = (value) => {
    const deals = props?.producList?.slice(value * 40 - 40, value * 40);
    setProducts((prev) => [...prev, ...deals]);
    setCurrentPage(value + 1);
    console.log("HITTT", deals, value);
  };

  useEffect(() => {
    if ((currentPage - 1) * PAGE_SIZE > products?.length) {
      setHasMoreData(false);
    }

    return () => {};
  }, [currentPage]);

  return (
    <Box>
      <Box mt={{ xs: 1, md: 0 }}>
        <CategoryGroupHeader
          title="All Products"
          showSortBy
          onSort={(option) => {
            props.onClickSort(option);
          }}
          displaySelection={props.optionData}
          totalProduct={props.totalCount}
        />
      </Box>

      <InfiniteScroll
        dataLength={products?.length || 0}
        next={() => handlePagination(currentPage)}
        hasMore={hasMoreData}
        loader={
          <Box>
            <Loader />
          </Box>
        }
      >
        <Grid
          container
          columnSpacing="9.6px"
          rowSpacing={{ md: 2.5, xs: 0 }}
          pt={{ md: 1.5, xs: 0 }}
          pl={{ xs: 1, md: 0 }}
          pr={{ xs: 0, md: 1 }}
          pb={{ md: 0, xs: 14 }}
        >
          {products.map((item, index) => {
            // if (item?.variantInfo?.masterVariant) return null;
            return (
              <Grid item xs={12} sm={3} md={2.4} key={"all_product_" + index}>
                {matches ? (
                  <ProductCard
                    img={item?.productPhoto?.[0]?.docURL}
                    price={item.productSalePrice}
                    oldPrice={item.productOriginalPrice}
                    description={item.productName}
                    productDescription={item.productDescription}
                    discount={item.percentageOff}
                    style={{ my: 1.5 }}
                    isOutOfStock={
                      props?.hasCatalogPartner
                        ? props?.checkPartnerInventory(item)
                        : getOutOfStock(item)
                    }
                    // isOutOfStock={getOutOfStock(item)}
                    isComingSoon={getCommingSoon(item)}
                    cartData={props?.cartData?.productList}
                    product={item}
                    minQty={item.minOrderQuantity}
                    maxQty={item.maxOrderQuantity}
                    currencySymbol={
                      item.currencySymbol ? "₹" : item.currencySymbol
                    }
                    cartId={props?.cartData?.cartId}
                    onQtyUpdate={(data) => {
                      props.onQtyUpdate(data);
                    }}
                    onAddProduct={(data) => {
                      props.onAddProduct(data);
                    }}
                    onRemoveProduct={props.onRemoveProduct}
                    subscribe={item?.subscription?.status === "active"}
                    availability={
                      item?.availability?.[0]?.toLowerCase() === "yes"
                    }
                    subscription={item?.subscription}
                    variant={item?.variantInfo?.masterProductVariantId}
                    variantCount={item?.count}
                  />
                ) : (
                  <ProductMobileCard
                    productid={item?.id}
                    img={item?.productPhoto?.[0]?.docURL}
                    price={item.productSalePrice}
                    oldPrice={item.productOriginalPrice}
                    description={item.productName}
                    productDescription={item.productDescription}
                    discount={item.percentageOff}
                    style={{ my: 1.5 }}
                    isOutOfStock={
                      props?.hasCatalogPartner
                        ? props?.checkPartnerInventory(item)
                        : getOutOfStock(item)
                    }
                    // isOutOfStock={getOutOfStock(item)}
                    isComingSoon={getCommingSoon(item)}
                    cartData={props?.cartData?.productList}
                    product={item}
                    minQty={item.minOrderQuantity}
                    maxQty={item.maxOrderQuantity}
                    currencySymbol={
                      item.currencySymbol ? "₹" : item.currencySymbol
                    }
                    cartId={props?.cartData?.cartId}
                    onQtyUpdate={(data) => {
                      props.onQtyUpdate(data);
                    }}
                    onAddProduct={(data) => {
                      props.onAddProduct(data);
                    }}
                    onRemoveProduct={props.onRemoveProduct}
                    subscribe={item?.subscription?.status === "active"}
                    availability={
                      item?.availability?.[0]?.toLowerCase() === "yes"
                    }
                    subscription={item?.subscription}
                    variant={item?.variantInfo?.masterProductVariantId}
                    variantCount={item?.count}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>

      {/* {matches && (
        <Stack
          spacing={2}
          mt={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            // count={Math.round(props.totalCount / 40)}
            // color='primary'
            count={couponCount}
            page={currentPage}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  next: NextBtn,
                  previous: PreviousBtn,
                }}
                {...item}
              />
            )}
            onChange={handlePagination}
            sx={{ color: blackColor }}
          />
        </Stack>
      )} */}
    </Box>
  );
}

const PreviousBtn = () => {
  return (
    <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
      Prev
    </Button>
  );
};

const NextBtn = () => {
  return (
    <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
      Next
    </Button>
  );
};

export default AllCategoryProducts;

const styles = {
  btnHover: {
    color: blackColor,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};
