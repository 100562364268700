import React from "react";
import ModalCard from "./ModalCard";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  blackColor,
  greenColor05,
  greyShade41,
  orangeColor03,
  whiteColor,
} from "configs/styles/muiThemes";

function AlertModal({
  title,
  content,
  amt,
  msg,
  open,
  handleClose,
  btnName,
  handleConfirm,
  hideCancel,
}) {
  return (
    <ModalCard handleClose={handleClose} open={open} width="30%">
      <Stack
        sx={{
          backgroundColor: whiteColor,
          borderRadius: "15px",
          overflow: "hidden",
        }}
      >
        <Box sx={{ backgroundColor: greyShade41, p: "10px 15px" }}>
          <Typography
            fontSize={16}
            fontWeight={600}
            color={blackColor}
            lineHeight="28px"
            textAlign={"center"}
          >
            {title || "Alert"}
          </Typography>
        </Box>
        <Stack gap="5px" sx={{ p: "15px" }}>
          <Typography
            fontSize={13}
            fontWeight={500}
            color={blackColor}
            lineHeight="20px"
            textAlign={"center"}
            mt="5px"
          >
            {content}
          </Typography>
          {amt && (
            <Typography
              fontSize={20}
              fontWeight={600}
              color={greenColor05}
              lineHeight="30px"
              textAlign={"center"}
            >
              ₹ {amt}
            </Typography>
          )}
          {msg && (
            <Typography
              fontSize={13}
              fontWeight={400}
              color={orangeColor03}
              lineHeight="20px"
              textAlign={"center"}
            >
              {msg}
            </Typography>
          )}
        </Stack>
        <Box
          sx={{
            backgroundColor: greyShade41,
            p: "10px 15px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "15px",
          }}
        >
          {!hideCancel ? (
            <Button onClick={handleClose} sx={styles.btn}>
              Cancel
            </Button>
          ) : (
            <Box sx={{ width: "30px", height: "20px" }}></Box>
          )}
          <Button
            onClick={handleConfirm}
            sx={{
              ...styles.btn,
              color: whiteColor,
              bgcolor: blackColor,
              "&:hover": {
                color: blackColor,
                bgcolor: whiteColor,
                border: "1px solid" + blackColor,
              },
            }}
          >
            {btnName || "Confirm"}
          </Button>
        </Box>
      </Stack>
    </ModalCard>
  );
}

export default AlertModal;

const styles = {
  btn: {
    p: "3px 20px",
    color: blackColor,
    backgroundColor: whiteColor,
    fontSize: 12,
    fontWeight: 600,
    borderRadius: "5px",
    height: "auto",
    border: "1px solid" + blackColor,
    "&:hover": {
      backgroundColor: blackColor,
      color: whiteColor,
    },
  },
};
