import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Radio,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  headingColor,
  iconInactiveColor,
  arrowBgColor,
  filterBgColor,
  whiteColor,
  radioBtnLabelColor,
  greyColor9,
  blackColor,
  orangeColor01,
  backgroundColor,
  orangeColor03,
} from "../../configs/styles/muiThemes";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { navigateTo } from "utils/urlHelper";
function DiscountHeader({
  title,
  subtitle,
  icon,
  add,
  type,
  discountType,
  onCancelDiscount,
  onAddAddress,
  onChangeAddress,
  onNext,
  onPrev,
  slideIndex,
  addressCount,
  address,
  buttonName,
  items,
  showInstorePick,
  isInstorePick,
  onInstorePickClick,
  collapseCartTabel,
  onToggleCartItems,
  specialInstructions,
  collapseInstructions,
  onToggleInstructions,
  disablePB,
  gst,
  onSearchClick,
  topmargin,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const navigate = useNavigate()

  const businessName = sessionStorage.getItem("businessName");
  return (
    <Grid
      container
      pt={{ md: topmargin ? topmargin : 0, xs: topmargin ? topmargin : 1 }}
      pb={{ md: disablePB ? 0 : 1.3, xs: disablePB ? disablePB : 0.8 }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      // spacing={1.5}
    >
      <Grid item>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 16 }}
              color={blackColor}
              display="inline"
            >
              {icon} {"    "}
              {title}
              <Typography
                variant="h5"
                component="h3"
                fontSize={{ xs: 12, md: 13 }}
                color={{ md: headingColor, xs: greyColor9 }}
                fontWeight={400}
                display={{ xs: "block", md: "inline" }}
                ml={0.6}
              >
                {subtitle}
              </Typography>
            </Typography>
          </Grid>

          {showInstorePick ? (
            <Grid item>
              <Radio
                checked={isInstorePick}
                onChange={() => {
                  onInstorePickClick();
                }}
                value="a"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
                sx={{
                  color: blackColor, // default color
                  "&.Mui-checked": {
                    color: blackColor, // color when checked
                  },
                }}
              />
              <Typography
                variant="subtitle1"
                component="p"
                color={radioBtnLabelColor}
                display="inline"
              >
                Instore Pickup (Open Hours)
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {address ? (
        add ? (
          <>
            <Grid item sx={{ display: { md: "none", xs: "block" } }}>
              {/* <IconButton sx={styles.searchBtn} onClick={onSearchClick}>
                <SearchIcon />
              </IconButton> */}
              {/* {!matches && ( */}
              {/* <Button
                sx={{}}
                disableElevation
                onClick={onSearchClick}
                startIcon={<SearchIcon sx={{ fontSize: "17px" }} />}
              >
                Search
              </Button> */}
              {/* )} */}
            </Grid>

            <Grid item sx={{ display: { md: "block", xs: "none" } }}>
              <Grid
                container
                columnSpacing={1}
                direction="row"
                alignItems="center"
              >
                <Grid item>
                  {/* <Button onClick={onSearchClick}>Search</Button> */}
                  <Box display="flex" alignItems="center" gap={1}>
                    <Button sx={styles.searchBtn} onClick={onSearchClick}>
                      <SearchIcon sx={{ fontSize: "17px" }} />
                      Search
                    </Button>
                    <Button sx={styles.actionBtn} onClick={onAddAddress}>
                      <AddRoundedIcon sx={{ fontSize: "17px" }} />
                      Add new Address
                    </Button>
                  </Box>
                </Grid>

                {addressCount > 2 ? (
                  <Grid item display={{ xs: "none", md: "block" }}>
                    <Grid container spacing={1} direction="row">
                      <Grid item>
                        <Box
                          // width="30px"
                          // height="30px"
                          // backgroundColor={arrowBgColor}
                          p="4px"
                          backgroundColor={
                            slideIndex === 0 ? filterBgColor : arrowBgColor
                          }
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={1}
                          sx={{ cursor: "pointer" }}
                          onClick={() => onPrev()}
                        >
                          <ChevronLeft
                            sx={{
                              fontSize: "20px",
                              color:
                                slideIndex === 0
                                  ? iconInactiveColor
                                  : headingColor,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          p="4px"
                          // width="30px"
                          // height="30px"
                          // backgroundColor={arrowBgColor}
                          backgroundColor={
                            addressCount - 2 === slideIndex
                              ? filterBgColor
                              : arrowBgColor
                          }
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={1}
                          sx={{ cursor: "pointer" }}
                          onClick={() => onNext()}
                        >
                          <ChevronRight
                            sx={{
                              fontSize: "20px",
                              color:
                                addressCount - 2 === slideIndex
                                  ? iconInactiveColor
                                  : headingColor,
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item>
            <Box display="flex" gap={1}>
              {matches ? (
                <Button sx={styles.searchBtn} onClick={onSearchClick}>
                  <SearchIcon sx={{ fontSize: "17px" }} />
                  Search
                </Button>
              ) : (
                <IconButton
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: "#F58220",
                    fontSize: "13px",
                    fontWeight: 500,
                    textDecoration: "underline",
                    marginLeft: "15px",
                    mt: 1,
                    gap: 1,
                    textTransform: "uppercase",
                  }}
                  onClick={onSearchClick}
                >
                  <Box
                    component="img"
                    src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/address-search.svg"
                    // src='https://storage.googleapis.com/bodefaults/shopweb/discounts_wallet.svg'
                    alt={" CostBo"}
                    width={{ xs: "14px", md: "46px" }}
                    height={{ xs: "26px", md: "30px" }}
                    sx={{ color: "#F58220" }}
                  />
                  Search
                </IconButton>
              )}
              {matches && (
                <Button sx={styles.actionBtn} onClick={() => onChangeAddress()}>
                  <EditOutlinedIcon sx={{ fontSize: "17px" }} />
                  Change Address
                </Button>
              )}
            </Box>
          </Grid>
        )
      ) : null}
      {items ? (
        <>
          <Grid item display={{ xs: "none", md: "flex" }}>
            <Button
              variant="outlined"
              sx={styles.actionBtn}
              // component={Link}
              // to={`/${businessName}/cart`}
              onClick={()=>{
                navigateTo(navigate, `cart`)
              }}
            >
              Modify Cart
            </Button>
          </Grid>

          <Grid item display={{ xs: "flex", md: "none" }}>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                onToggleCartItems();
              }}
              color={blackColor}
            >
              {collapseCartTabel ? (
                <ExpandMoreRounded
                  fontSize="medium"
                  sx={{ color: blackColor }}
                />
              ) : (
                <ExpandLessRounded
                  fontSize="medium"
                  sx={{ color: blackColor }}
                />
              )}
            </IconButton>
          </Grid>
        </>
      ) : null}
      {gst ? (
        <>
          <Grid item display={{ xs: "flex", md: "none" }}>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                onToggleCartItems();
              }}
            >
              {collapseCartTabel ? (
                <ExpandMoreRounded fontSize="medium" />
              ) : (
                <ExpandLessRounded fontSize="medium" />
              )}
            </IconButton>
          </Grid>
        </>
      ) : null}

      {specialInstructions ? (
        <Grid item>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => {
              onToggleInstructions();
            }}
            color={blackColor}
          >
            {collapseInstructions ? (
              <ExpandMoreRounded fontSize="medium" color={blackColor} />
            ) : (
              <ExpandLessRounded fontSize="medium" color={blackColor} />
            )}
          </IconButton>
        </Grid>
      ) : null}

      {discountType ? (
        <Grid item>
          <Button
            variant="outlined"
            sx={styles.actionBtn}
            onClick={onCancelDiscount}
            size="small"
          >
            Cancel Discount
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default DiscountHeader;

const styles = {
  actionBtn: {
    backgroundColor: whiteColor,
    border: "1px solid" + blackColor,
    color: blackColor,
    fontSize: "11px",
    fontWeight: 600,
    p: "4px 12px",
    borderRadius: "6px",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    gap: "3px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: blackColor,
    },
  },

  searchBtn: {
    backgroundColor: whiteColor,
    border: "1px solid" + orangeColor03,
    color: orangeColor03,
    fontSize: "11px",
    fontWeight: 600,
    p: "4px 12px",
    borderRadius: "6px",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    gap: "3px",
    "&:hover": {
      color: whiteColor,
      backgroundColor: orangeColor03,
    },
  },
};
