import { getApiInstance } from "services/ApiInstance";
import {
  BUYMORE_INVENTORY_DATA,
  GET_UNICOM_INVENTORY_SNAPSHOT,
} from "services/constant";

export const getUnicomInventoryApi = async (postData) => {
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance({
      header: { facility: postData?.facilityCode, businessId },
    });

    const response = await instance.post(
      GET_UNICOM_INVENTORY_SNAPSHOT + `?catalogPartner=unicommerce`,
      postData?.skuList || []
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBuyMoreInventoryApi = async (postData) => {
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance({
      header: { businessId },
    });

    const response = await instance.get(BUYMORE_INVENTORY_DATA, {
      params: {
        catalogPartner: "buymore",
        pageNumber: 1,
        pageSize: 300,
        warehouseId: postData?.facilityCode,
        search: postData?.skuList?.join(',') || "",
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
