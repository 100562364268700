import { ADD_SEARCH_INFO_URL, SEARCH_PRODUCTS_API } from "services/constant";
import { getApiInstance } from "../../ApiInstance";
import { getVariantCountAPI } from "../subCategory/SubCategoryApis";

export const getSearchProducts = async (data) => {
  const businessId = sessionStorage.getItem("businessId");
  const instance = getApiInstance();
  const key = data?.key;
  const pageNo = data?.pageNo ? data?.pageNo : 0;

  try {
    const response = await instance.get(
      `${SEARCH_PRODUCTS_API}?key=${key}&businessId=${businessId}&page=${pageNo}`
    );

    const details = response?.data?.details?.filter(
      (item) => !item?.variantInfo?.masterVariant
    );

    if (details?.length > 0) {
      const array = [...details];
      const masterProductVariantIds = array
        .filter((item) => item?.variantInfo?.masterProductVariantId) // Filter only those with a master property
        .map((item) => item?.variantInfo?.masterProductVariantId); // Map to the master.id property
      const countRes = await getVariantCountAPI(
        businessId,
        masterProductVariantIds
      );

      array.forEach((item) => {
        // Check if the current item has a master property
        if (item.variantInfo?.masterProductVariantId) {
          // Find a matching item in the second array
          const match = countRes?.productVariantCount?.find(
            (e) =>
              e.masterProductVariantId ===
              item.variantInfo?.masterProductVariantId
          );

          // If a match is found, add the count to the first array's object
          if (match) {
            item.count = match.count;
          }
        }
      });

      console.log("SEARCH_WITH_VAERIANT_PRODUCTS:", array);

      return { details: array };
    }

    console.log("SEARCH_PRODUCTS:", { details });

    return { details };
  } catch (error) {
    console.log(error);
  }
};
export const addSearchInfo = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(ADD_SEARCH_INFO_URL, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
