import {
  ALL_ACTIVE_PRODUCTS,
  CATEGORY_DETAILS,
  CHILD_PRODUCTS,
  GET_UNICOM_INVENTORY_SNAPSHOT,
  PRODUCTBYCAT,
  VARIANT_COUNT,
} from "services/constant";
import { getApiInstance } from "../../ApiInstance";
import { getUniqueProductIds } from "utils/utilities";

export const getSubCategoryData = async (param) => {
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    });

    const response = await instance.get(
      PRODUCTBYCAT
      // `product-query-side/api/v1/product/group/subCategoryProducts`
    );

    if (response?.data?.details?.length > 0) {
      const array = [...response?.data?.details];
      const masterProductVariantIds = getUniqueProductIds(
        response.data.details
      );

      const countRes = await getVariantCountAPI(
        businessId,
        masterProductVariantIds
      );

      array.forEach((item) => {
        // Check if the current item has a master property
        item.products.forEach((product) => {
          if (product.variantInfo?.masterProductVariantId) {
            // Find a matching item in the second array
            const match = countRes?.productVariantCount?.find(
              (e) =>
                e.masterProductVariantId ===
                product.variantInfo?.masterProductVariantId
            );

            // If a match is found, add the count to the first array's object
            if (match) {
              product.count = match.count;
            }
          }
        });
      });

      console.log(
        "getUniqueProductIds",
        array,
        countRes,
        masterProductVariantIds
      );

      return array;
    }

    const detailedArr = response ? response.data.details : [];
    console.log("Detailed", detailedArr);
    return detailedArr;
  } catch (error) {
    console.log(error);
  }
};

export const getProductsBySubCat = async (param) => {
  const businessId = sessionStorage.getItem("businessId");
  const catCode = param ? param.catCode : "";
  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    });

    const response = await instance.get(
      `product-query-side/api/v1/product/productsBySubcategory?subcatcodes=${catCode}`
    );
    const detailedArr = response ? response.data.details : [];
    console.log(detailedArr);
    return detailedArr;
  } catch (error) {
    console.log(error);
  }
};

export const getAllProducts = async (param) => {
  const businessId = sessionStorage.getItem("businessId");
  const pageNo = param ? param.pageNo : 1;
  const discount = param ? param.discountHightToLow : false;
  const lowPrice = param ? param.priceLowoHig : false;
  const highPrice = param ? param.highPrice : false;
  const business = param ? param.business : false;

  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    });

    const response = await instance.get(
      `product-query-side/api/v1/open/product/productsByBusiness/${businessId}?business=${business}&percentageOff=${discount}&highToLow=${highPrice}&lowToHigh=${lowPrice}&pageSize=500&pageNo=${pageNo}`
    );
    console.log("ALL_PRODUCTS:", response?.data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllActiveProducts = async (params) => {
  const businessId = sessionStorage.getItem("businessId");
  const pageNo = params ? params.pageNo : 1;
  const percentageOff = params ? params.discountHightToLow : false;
  const lowToHigh = params ? params.priceLowoHig : false;
  const highToLow = params ? params.highPrice : false;
  const business = params ? params.business : false;
  try {
    const instance = getApiInstance();
    const response = await instance.get(ALL_ACTIVE_PRODUCTS, {
      params: {
        pageNo,
        pageSize: 500,
        status: "active",
        highToLow,
        percentageOff,
        lowToHigh,
        business,
      },
      headers: {
        businessId,
      },
    });

    if (response?.data?.details?.length > 0) {
      const array = [...response?.data?.details];
      const masterProductVariantIds = array
        .filter((item) => item?.variantInfo?.masterProductVariantId) // Filter only those with a master property
        .map((item) => item?.variantInfo?.masterProductVariantId); // Map to the master.id property
      const countRes = await getVariantCountAPI(
        businessId,
        masterProductVariantIds
      );

      array.forEach((item) => {
        // Check if the current item has a master property
        if (item.variantInfo?.masterProductVariantId) {
          // Find a matching item in the second array
          const match = countRes?.productVariantCount?.find(
            (e) =>
              e.masterProductVariantId ===
              item.variantInfo?.masterProductVariantId
          );

          // If a match is found, add the count to the first array's object
          if (match) {
            item.count = match.count;
          }
        }
      });

      console.log("getInventoryVariantProductsAPI Hit1", array);

      return { productList: array, totalProduct: array?.length || 0 };
    }

    return { productList: [] };
  } catch (error) {
    console.log(error);
  }
};

export const getVariantCountAPI = async (
  businessId,
  masterProductVariantIds
) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(
      VARIANT_COUNT,
      masterProductVariantIds,
      {
        params: {
          status: "active",
        },
        headers: {
          businessId,
        },
      }
    );
    console.log("getMainProductsAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryDetails = async () => {
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    });

    const response = await instance.get(CATEGORY_DETAILS);

    return response.data.details;
  } catch (error) {
    console.log(error);
  }
};

export const getViewChildProductDetailsAPI = async (masterProductVariantId) => {
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance();
    const response = await instance.get(CHILD_PRODUCTS, {
      params: {
        page: 1,
        pageSize: 20,
        businessId,
        masterProductVariantId,
        status: "active",
      },
      headers: {
        businessId,
      },
    });
    console.log("getViewProductDetailsAPI", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


