import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material'
import BusinessInfoCard from './BusinessInfoCard'
import MainProductCard from './MainProductCard'
import MapComponent from './MapComponent'
import {
  backgroundColor01,
  blackColor,
  descriptionColor,
  greyColor40,
  headingColor,
  tabTextOrangeColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import MapIcon from '@mui/icons-material/Map'
import PersonIcon from '@mui/icons-material/Person'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BusinessInfoMobileCard from './BusinessInfoMobileCard'
import ReviewMobileCard from './ReviewMobileCard'
import Review from 'pages/Review'
import { navigateTo } from 'utils/urlHelper'

const OverviewContentNew = ({ data, loading }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [tabValue, setTabValue] = useState(0)

  const navigate = useNavigate()

  const stepList = [
    { id: 0, label: 'About Us', mobileLabel: 'About Us' },
    { id: 1, label: 'Business Info', mobileLabel: 'Business Info' },
    {
      id: 2,
      label: 'Review',
      mobileLabel: 'Review',
    },
  ]
  console.log('data123', data)

  const handleChange = (newValue) => {
    // dispatch(clearCatalogData());
    const businessUrl = sessionStorage.getItem('costboWEBURL')

    if (newValue === 0) {
      // navigate(`/${data?.costboWebURL}/Overview`)
      navigateTo(navigate, `Overview`)
    }
    // else if (newValue === 1) {
    //   navigate(`/${data?.costboWebURL}/BusinessInfo`)
    // }
    else if (newValue === 2) {
      // navigate(`/${data?.costboWebURL}/review`)
      navigateTo(navigate, `review`)
    } else {
    }

    setTabValue(newValue)
  }
  console.log(data)

  return matches ? (
    <Container sx={{ maxWidth: '1370px !important' }}>
      <Typography
        variant='h2'
        fontSize={{ md: '16px', xs: '16px' }}
        fontWeight={600}
        align='left'
        p="8px 0 6px 0"
        color={blackColor}
      >
        Overview
      </Typography>

      {data && (
        <Grid container spacing={3.5}>
          <Grid item xs={12} md={8}>
            {/* <Typography
              variant='subtitle1'
              align='left'
              paddingTop={2.4}
              paddingBottom={0.65}
              color={blackColor}
            >
              Description
            </Typography> */}
            <Box mt={1}>
              {data && (
                <Typography
                  variant='body1'
                  color={blackColor}
                  align='justify'
                  fontSize={13}
                  fontWeight={400}
                  sx={{ whiteSpace: "pre-line" }}
                >
                  {data.description}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={4} mt={-2}>
            <Typography
              variant='h5'
              paddingBottom={1.5}
              fontSize={{xs:13,md:14}}
              color={headingColor}
              align='left'
            >
              Main Products
            </Typography>
            <Box>
              {data?.mainProducts ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <MainProductCard
                      products={data?.mainProducts?.slice(
                        0,
                        Math.round(data?.mainProducts?.length)
                      )}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={6}>
                    <MainProductCard
                      products={data?.mainProducts?.slice(
                        Math.round(data?.mainProducts?.length / 2)
                      )}
                    />
                  </Grid> */}
                </Grid>
              ) : null}
            </Box>
            <BusinessInfoCard
              icon={<PersonIcon sx={{ fontSize: '20px', color: blackColor }} />}
              title='Business Contact Name'
              description={data.ownerName}
            />

            <BusinessInfoCard
              icon={<MapIcon sx={{ fontSize: '20px', color: blackColor }} />}
              title='Address'
              description={data.address}
              address={true}
            />
            {/* <WorkingHoursCard
              title='Working Hours'
              description='Mr. Mohan'
              workigHourData={data.businessHours}
            /> */}
          </Grid>
          <Grid item md={12} xs={3} mt={-2}>
            <Typography
              variant='h5'
              fontSize={16}
              fontWeight={600}
              paddingBottom={2.25}
              color={headingColor}
              align='left'
            >
              Location
            </Typography>

            <MapComponent
              latitude={data.loc ? data.loc.y : 12.9716}
              longitude={data.loc ? data.loc.x : 77.5946}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  ) : (
    <Box sx={{ maxWidth: '1300px !important' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          bgcolor: backgroundColor01,
          px: '20px',
          mt:1
        }}
      >
        <Box
          sx={styles.tabStyle(tabValue == 0)}
          onClick={() => {
            handleChange(0)
          }}
        >
          About Us
        </Box>
        <Box
          sx={styles.tabStyle(tabValue == 1)}
          onClick={() => {
            handleChange(1)
          }}
        >
          Business Info
        </Box>
        <Box
          sx={styles.tabStyle(tabValue == 2)}
          onClick={() => {
            handleChange(2)
          }}
        >
          Review
        </Box>
      </Box>

      {tabValue === 0 && (
        <Box p={2} backgroundColor={whiteColor}>
          <Typography
            variant='h2'
            fontSize={{ md: '19px', xs: '15px' }}
            fontWeight={600}
            lineHeight='20px'
            align='left'
            color={blackColor}
          >
            Our Story
          </Typography>
          {data && (
            <Grid container spacing={3.5} sx={{ pl: matches ? '10px' : 0 }}>
              <Grid item xs={12} md={8}>
                <Box>
                  {data && (
                    <Typography
                      variant='body1'
                      fontSize={12.5}
                      fontWeight={500}
                      lineHeight={'20px'}
                      color={blackColor}
                      align='justify'
                      sx={{ whiteSpace: 'pre-wrap' }}
                      pt={1}
                    >
                      {data.description}
                    </Typography>
                  )}
                </Box>

                <Typography
                  variant='h5'
                  fontSize={15}
                  fontWeight={600}
                  paddingTop={2}
                  paddingBottom={1}
                  color={blackColor}
                  align='left'
                >
                  Main Products
                </Typography>
                <Box>
                  {data?.mainProducts ? (
                    <Grid container spacing={2} mb={2} pb={5}>
                      <Grid item xs={12} sm={6}>
                        {/* <MainProductCard
                          products={data?.mainProducts?.slice(
                            0,
                            Math.round(data?.mainProducts?.length / 2)
                          )}
                        /> */}
                        <MainProductCard products={data?.mainProducts} />
                      </Grid>
                    </Grid>
                  ) : null}
                </Box>

                {/* <Typography
                  variant='h5'
                  paddingTop={3}
                  paddingBottom={2.25}
                  color={headingColor}
                  align='left'
                >
                  Location
                </Typography>

                <MapComponent
                  latitude={data.loc ? data.loc.y : 12.9716}
                  longitude={data.loc ? data.loc.x : 77.5946}
                /> */}
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      {tabValue === 1 && <BusinessInfoMobileCard data={data} />}
      {/* {tabValue === 2 && <Review/>} */}
      {/* {tabValue === 2 && <ReviewMobileCard data={data} />} */}
    </Box>
  )
}

export default OverviewContentNew

const styles = {
  tabStyle: (isActive) => ({
    fontSize: 14,
    fontWeight: 500,
    color: blackColor,
    p: '4px 10px',
    borderBottom: isActive ? `2.5px solid ${blackColor}` : 'none',
    textAlign: 'center',
  }),
}
