import moment from "moment";

export const getDayOfWeek = (day) => {
  switch (day) {
    case 1:
      return "Sunday";
    case 2:
      return "Monday";
    case 3:
      return "Tuesday";
    case 4:
      return "Wednesday";
    case 5:
      return "Thursday";
    case 6:
      return "Friday";
    case 7:
      return "Saturday";
    default:
      return "No Data";
  }
};

export const checkIsStoreOpen = (fromTime, toTime) => {
  var beginningTime = moment(fromTime, "hh:mm A");
  var endTime = moment(toTime, "hh:mm A");

  return moment().isBefore(endTime) && moment().isAfter(beginningTime)
    ? true
    : false;
};

export const getGraphData = (reviewSummary, summary) => {
  console.log(summary);
  const newsummary = [];
  summary?.forEach((item) => {
    reviewSummary?.forEach((review) => {
      item =
        item?.reviewRating === Math.round(review?.reviewRating)
          ? { ...item, percent: item.percent + review?.percent }
          : item;
    });
    newsummary.push(item);
  });
  return newsummary;
};

export const summary = [
  {
    reviewRating: 5.0,
    count: 0,
    percent: 0,
  },
  {
    reviewRating: 4.0,
    count: 0,
    percent: 0,
  },
  {
    reviewRating: 3.0,
    count: 0,
    percent: 0,
  },
  {
    reviewRating: 2.0,
    count: 0,
    percent: 0,
  },
  {
    reviewRating: 1.0,
    count: 0,
    percent: 0,
  },
];

export const updateSessionCartQty = (productId, qty) => {
  let cartData = JSON.parse(sessionStorage.getItem("cartData"));
  cartData?.productList?.forEach((item, index) => {
    if (item.productId === productId) {
      cartData.productList[index] = { ...item, currentQuantity: qty };
      if (item.currentQuantity < qty) {
        cartData.orderTotalProductCost =
          cartData?.orderTotalProductCost + item?.productSalePrice;
      } else {
        cartData.orderTotalProductCost =
          cartData?.orderTotalProductCost - item?.productSalePrice;
      }
    }
  });

  console.log("SESSION_CART_DATA_4");
  sessionStorage.setItem("cartData", JSON.stringify(cartData));

  console.log("UPDATE_SESSION_CART:", cartData);
  console.log("UPDATE_SESSION_CART_PID:", productId);
  console.log("UPDATE_SESSION_CART_QTY:", qty);
};

export const getOperatingSystem = (window) => {
  // console.log(
  //   'device',
  //   window.navigator.userAgent,
  //   window.navigator.mediaDevices
  // )
  // window.navigator.mediaDevices
  //   .enumerateDevices()
  //   .then((devices) => {
  //     devices.forEach((device) => {
  //       console.log(
  //         'hukgkyfgjhym',
  //         `${device.kind}: ${device.label} id = ${device.deviceId}`
  //       )
  //     })
  //   })
  //   .catch((err) => {
  //     console.log(`${err.name}: ${err.message}`)
  //   })
  let operatingSystem = "unknown";
  if (window.navigator.userAgent.indexOf("Win") !== -1) {
    operatingSystem = "Windows";
  }
  if (window.navigator.userAgent.indexOf("Mac") !== -1) {
    operatingSystem = "Mac";
  }
  if (window.navigator.userAgent.indexOf("X11") !== -1) {
    operatingSystem = "UNIX";
  }
  if (window.navigator.userAgent.indexOf("Linux") !== -1) {
    operatingSystem = "Linux";
  }
  if (window.navigator.userAgent.indexOf("Android") !== -1) {
    operatingSystem = "Android";
  }
  if (window.navigator.userAgent.indexOf("iPhone") !== -1) {
    operatingSystem = "iPhone";
  }

  return operatingSystem;
};

export const getBrowser = (window) => {
  let currentBrowser = "unknown";
  if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
    currentBrowser = "Chrome";
  } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
    currentBrowser = "Mozilla Firefox";
  } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
    currentBrowser = "Internet Exployer";
  } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
    currentBrowser = "Edge";
  } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
    currentBrowser = "Safari";
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "Opera";
  } else {
    console.log("Others");
  }

  return currentBrowser;
};

export const fintechApplyData = [
  "Hassle free working capital in minutes",
  "No interest, upto 1 month*",
  "GST/PAN, Aadhar needed, One time verification, Upto 10L revolving line of credit",
];

export const fintechData = [
  "GST/PAN Needed",
  "UP to 2 months interest free",
  "One time verification",
  "Up to 5L revolving credit line",
];

export const addThousandsSeparator = (number, removeDecimal = false) => {
  if (!number) return "0";
  const num_parts = Number(number).toFixed(2).toString().split(".");
  num_parts[0] = num_parts[0].replace(
    /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g,
    ","
  );

  const result = num_parts.join(".");
  return removeDecimal ? result.slice(0, result.length - 3) : result;
};

export const allowOnlyEnglish = (inputValue) => {
  // check if the input contains only English characters
  const sanitizedValue = inputValue.replace(
    /[^a-zA-Z0-9\s!@#$%^&*()-_+=<>?.,:;'"{}[\]|/\\]/g,
    ""
  );

  return sanitizedValue;
};

export function getUniqueProductIds(data) {
  const ids = new Set(); // Use a Set to store unique values

  data?.forEach((item) => {
    item?.products?.forEach((product) => {
      if (product?.variantInfo?.masterProductVariantId)
        ids?.add(product?.variantInfo?.masterProductVariantId); // Add each product id to the set
    });
  });

  return Array?.from(ids); // Convert the set to an array
}

export function getUniqueMetricProductIds(data) {
  const allIds = [];

  // Loop through each category in the object and collect all ids
  for (const key in data) {
    data[key].forEach((item) => {
      if (item?.variantInfo?.masterProductVariantId)
        allIds.push(item?.variantInfo?.masterProductVariantId);
    });
  }

  // Remove duplicates by converting to a Set and then back to an array
  const uniqueIds = [...new Set(allIds)];
  return uniqueIds;
}

export const getTimeToShipUnitValue = (symbol) => {
  switch (symbol) {
    case "M":
      return {
        label: "Minutes",
        unit: "M",
      };

    case "H":
      return {
        label: "Hours",
        unit: "H",
      };

    case "D":
      return {
        label: "Days",
        unit: "D",
      };

    default:
      return {
        label: "Minutes",
        unit: "M",
      };
  }
};

export const getTimeToShipValue = (unitSymbol, timeToShip) => {
  if (unitSymbol && timeToShip) {
    if (unitSymbol === "D") {
      if (timeToShip?.includes("PT")) {
        return timeToShip?.slice(2, -1);
      }
      return timeToShip?.slice(1, -1);
    } else {
      return timeToShip?.slice(2, -1);
    }
  }
};

export const getDiscountPercentage = (salePrice, originalPrice) => {
  const discountPercentage =
    ((Number(originalPrice) - Number(salePrice)) / Number(originalPrice)) * 100;
  return Math.round(discountPercentage);
};

export const getOrderDeliveryTypeText = (type, shipType) => {
  switch (type?.toLowerCase()) {
    case "business":
      return "Business Delivery";
    case "delhivery":
      return "Automated Delivery";
    case "Delhivery":
      return "Automated Delivery";
    case "telyport":
      return "Automated Hyperlocal";
    case "delhiveryb2b":
      return "Automated B2B";
    case "ondc":
      return `ONDC ${shipType ? ` - ${toTitleCase(shipType)}` : ""}`;

    default:
      return "";
  }
};

export const toTitleCase = (str) => {
  if (str?.length > 0)
    return str?.replace(/\w\S*/g, function (txt) {
      return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
    });

  return str;
};

export const formatStrTime = (input) => {
  const [hours, minutes, seconds] = input.split(":").map(Number);

  let totalHours = hours;

  // Calculate years, months, days, and remaining hours
  const years = Math.floor(totalHours / (24 * 365));
  totalHours %= 24 * 365;

  const months = Math.floor(totalHours / (24 * 30));
  totalHours %= 24 * 30;

  const days = Math.floor(totalHours / 24);
  totalHours %= 24;

  const result = [];

  if (years > 0) result.push(`${years} ${years === 1 ? "yr" : "yrs"}`);
  if (months > 0) result.push(`${months} ${months === 1 ? "mo" : "mos"}`);
  if (days > 0) result.push(`${days} ${days === 1 ? "day" : "days"}`);
  if (totalHours > 0)
    result.push(`${totalHours} ${totalHours === 1 ? "hr" : "hrs"}`);
  if (minutes > 0) result.push(`${minutes} ${minutes === 1 ? "min" : "mins"}`);
  if (seconds > 0 && hours === 0)
    result.push(`${seconds} ${seconds === 1 ? "sec" : "secs"}`);

  return result.join(" ");
};

export const formatMSTime = (ms) => {
  // Convert milliseconds to total hours, minutes, and seconds
  const totalSeconds = Math.floor(ms / 1000);
  const seconds = totalSeconds % 60;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes % 60;
  const totalHours = Math.floor(totalMinutes / 60);

  let remainingHours = totalHours;

  // Calculate years, months, days, and remaining hours
  const years = Math.floor(remainingHours / (24 * 365));
  remainingHours %= 24 * 365;

  const months = Math.floor(remainingHours / (24 * 30));
  remainingHours %= 24 * 30;

  const days = Math.floor(remainingHours / 24);
  remainingHours %= 24;

  const result = [];

  if (years > 0) result.push(`${years} ${years === 1 ? "yr" : "yrs"}`);
  if (months > 0) result.push(`${months} ${months === 1 ? "mon" : "mons"}`);
  if (days > 0) result.push(`${days} ${days === 1 ? "day" : "days"}`);
  if (remainingHours > 0)
    result.push(`${remainingHours} ${remainingHours === 1 ? "hr" : "hrs"}`);
  if (minutes > 0) result.push(`${minutes} ${minutes === 1 ? "min" : "mins"}`);
  if (seconds > 0 && totalHours === 0)
    result.push(`${seconds} ${seconds === 1 ? "sec" : "secs"}`);

  return result.join(" ");
};

export const getDifferentInTime = (time1, time2) => {
  const time = Math.abs(time1 - time2);

  return formatMSTime(time);
};

export const getCancelledByText = (status) => {
  switch (status) {
    case "bordercanceled":
      return "Canceled by Business";
    case "cordercanceled":
      return "Canceled by Customer";
    case "lordercanceled":
      return "Canceled by Logistics";

    default:
      return "";
  }
};

// Get Cancel Date by status
export const getCancelDateByStatus = (orderInfo) => {
  const orderStatus = orderInfo?.orderStatus || "";
  switch (orderStatus) {
    case "bordercanceled":
      return orderInfo?.orderCancelDateBusiness
        ? moment(orderInfo?.orderCancelDateBusiness)?.format(
            "Do MMM, YYYY hh:mm A"
          )
        : "N/A";
    case "cordercanceled":
      return orderInfo?.orderCancelDateConsumer
        ? moment(orderInfo?.orderCancelDateConsumer)?.format(
            "Do MMM, YYYY hh:mm A"
          )
        : "N/A";
    case "lordercanceled":
      return orderInfo?.orderCancelDateBusiness
        ? moment(orderInfo?.orderCancelDateBusiness)?.format(
            "Do MMM, YYYY hh:mm A"
          )
        : "N/A";
    default:
      return "N/A";
  }
};

// Add +91 and space after
export const formatPhoneNumber = (number) => {
  if (!number) return "";
  return number.startsWith("+91")
    ? number.replace("+91", "+91 ")
    : `+91 ${number}`;
};

export const showUOMDetails = (product) => {
  return (
    (product?.productMainCategory?.[0]?.ondcCode === "ONDC:RET10" ||
      product?.productMainCategory?.[0]?.ondcCode === "ONDC:RET13") &&
    product?.productDisplayUnitOfMeasure
  );
};
export const getUOMValue = (product) => {
  return `${product?.productDisplayUnitOfMeasure?.value} ${getUOMShortForm(
    product?.productDisplayUnitOfMeasure?.unit
  )}`;
};

const getUOMShortForm = (unit) => {
  switch (unit) {
    case "unit":
      return "pieces";
    case "dozen":
      return "dozen";
    case "gram":
      return "g";
    case "kilogram":
      return "kg";
    case "tonne":
      return "ton";
    case "litre":
      return "l";
    case "millilitre":
      return "ml";
    default:
      return unit; // Return the original value if not found
  }
};