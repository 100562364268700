import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import {
  borderTextbox,
  errorTextColor,
  greenColor05,
  greyShade75,
  greyShade76,
  primaryColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import { styled } from "@mui/material/styles";
import { allowOnlyEnglish } from "utils/utilities";

const CustomDisableInput = styled(TextField)(() => ({
  ".MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000",
    color: "#000",
  },
}));

function TextboxWithLabel({
  label,
  placeholder,
  infoText,
  alertText,
  required,
  value,
  onValueChange,
  disabled,
  maxLength,
  isError,
  errorMsg,
  small,
  startIcon,
  endIcon,
  margingright,
  widthtext,
  inputStyles,
}) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: -0.4, md: 0 },
          justifyContent: "space-between",
        }}
      >
        <Typography sx={styles.label}>
          {label}{" "}
          {required ? (
            <Typography component="span" variant="body1" sx={{ color: "#f00" }}>
              *
            </Typography>
          ) : null}
          <Typography component="span" sx={{ fontSize: 9.5, lineHeight:{xs:'18px', md:'22px'} }}>
            {infoText}
          </Typography>
        </Typography>

        {alertText && (
          <Typography
            component="span"
            sx={{
              fontSize: { xs: 9.5, md: 10 },
              fontWeight: 500,
              color: greenColor05,
              lineHeight:'20px'
            }}
          >
            {alertText}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          border: `1px solid ${greyShade75}`,
          borderRadius: "6px",
          p: "4px 10px",
          mb: 1,
          mt: "4px",
        }}
      >
        <CustomDisableInput
          value={value}
          variant="standard"
          sx={{
            // ...styles.inputBox(whiteColor, small, widthtext),
            // ...inputStyles,

            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "18px",
          }}
          fullWidth
          // maxLength={maxLength}
          onChange={({ target }) => {
            // if (target.value.length == 11) return false //limits to 10 digit entry
            const finalValue = allowOnlyEnglish(target.value);
            onValueChange(finalValue);
          }}
          placeholder={placeholder}
          InputProps={{
            startAdornment: startIcon ? (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ) : null,
            endAdornment: endIcon ? (
              <InputAdornment position="end">{endIcon}</InputAdornment>
            ) : null,
            disableUnderline: true,
          }}
          inputProps={{ maxLength: maxLength }}
          disabled={disabled}
        />
      </Box>
      {isError ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={11.5}
          color='red'
        >
          {errorMsg}
        </Typography>
      ) : null}
    </Box>
  );
}

export default TextboxWithLabel;

const styles = {
  label: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "-0.3px",
    color: { md: greyShade76, xs: greyShade76 },
  },

  inputBox: (color, small, widthtext) => ({
    border: borderTextbox,
    borderRadius: "6px",
    fontSize: "13px",
    color: "#777",
    background: color,
    padding: {
      md: small ? "4px 10px" : "8px 10px",
      xs: small ? "8px 10px" : "4px 10px",
    },
    // width: widthtext ? widthtext : "100%",
    "& .MuiInputBase-root.Mui-disabled": {
      color: "red !important",
    },
  }),

  infolabel: {
    display: "block",
    fontSize: "13px",
    textAlign: "right",
    py: "8px",
    color: "#9e9fa3",
  },

  btnContainer: { display: "flex", justifyContent: "flex-end", mt: 1 },

  submitBtn: {
    fontSize: "14px",
    fontWeight: 500,
    color: whiteColor,
    p: "7px 28px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "primary.main",
    },
  },
};
