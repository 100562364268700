import {
  Box,
  Button,
  Container,
  IconButton,
  keyframes,
  Tooltip,
  Typography,
} from "@mui/material";
import { navBar_BuyOnlineMsg, navBar_Menu } from "configs/Constants";
import { bgColor, blackColor, whiteColor } from "configs/styles/muiThemes";
import React, { useEffect, useRef, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSocialActivityOfBusiness,
  updateFollowDetails,
} from "redux/header/headerSlice";
import AffiliateShareModal from "pages/Affiliates/AffiliateShareModal";
import SocialShareVertical from "components/SocialShareVertical";
import { addTimelineShare } from "services/api_calls/header/HeaderApi";
import { AnimationTwoTone } from "@mui/icons-material";

const TopBar = ({
  commercialDetails,
  dealsCouponsDetails,
  businessFullName,
  onMenuClick,
  businessDetails,
  businessName,
  businessAffiliateData,
  rellerProfileData,
  setLogin,
  isMultiBranch,
  onChangeBranch,
}) => {
  const marqueX = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }`;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const textRef = useRef(null);
  const [textSize, setTextSize] = useState(0);

  const [showShareOptions, setShowShareOptions] = useState(false);
  const [windowurl, setWindowurl] = useState("");

  const { isUserLogin, loginDetails } = useSelector((state) => state.auth);
  const { social } = useSelector((state) => state.header);

  const whatsAppNumber =
    commercialDetails?.info?.costboPowered?.whatsAppNumber?.replace("+", "");

  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  // Handle Follow and unfollow business
  const followUnfollow = () => {
    if (!isUserLogin) {
      setLogin();
      return;
    }
    dispatch(updateFollowDetails(social)).then((res) => {
      dispatch(getSocialActivityOfBusiness());
    });
  };

  const getMinOrderAmount = (orderInfoData)=>{
    if(orderInfoData?.length <= 0) return 0

    const orderInfoObj = orderInfoData?.reduce((acc, curr) => ({ ...acc, ...curr }), {});

    return orderInfoObj?.minOrderAmount || 0
  }

  useEffect(() => {
    const url = window.location.href;
    setWindowurl(url);
  }, []);
  useEffect(() => {
    console.log("testRef", textRef?.current?.offsetWidth);

    setTextSize(textRef?.current?.offsetWidth);
  }, []);

  return (
    <Box sx={styles.topBarStyles}>
      <Container
        sx={{
          maxWidth: "1350px !important",
          px: "15px !important",
          height: "30px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" gap="20px">
            <Box sx={styles.menuBtn}>
              <IconButton
                color="inherit"
                aria-label="menu"
                sx={{ p: "0px !important" }}
                onClick={() => onMenuClick()}
              >
                <MenuIcon sx={{ color: whiteColor, fontSize: "17px" }} />
              </IconButton>

              <Typography
                variant="h6"
                color={whiteColor}
                fontSize={12.5}
                fontWeight={500}
                lineHeight={1}
              >
                {navBar_Menu}
              </Typography>
            </Box>

            {/* <Typography component='a' sx={styles.textStyle}>
              Home
            </Typography> */}
            {/* <Box sx={styles.menuBtn}>
              <Typography
                component="a"
                sx={styles.textStyle}
                onClick={handleChatClick}
              >
                Contact Us
              </Typography>
            </Box> */}
            <Box
              component="img"
              src={`https://storage.googleapis.com/bodefaults/shopweb/ondc-logo.svg`}
              width={{
                xs: "100px",
                md: "72px",
              }}
              height="auto"
              alt={businessFullName + " CostBo"}
            />
          </Box>

          <Box sx={{ ...styles.topBar(isMultiBranch) }}>
            <Typography
              ref={textRef}
              textAlign="center"
              variant="h6"
              sx={{
                width: textSize > 0 ? `${textSize}px` : "100%",
                fontSize: "12px",
                fontWeight: 500,
                color: whiteColor,
                display: "inline-block",
                whiteSpace: "nowrap",
                animation: `${marqueX} 30s linear infinite`,
                animationDirection: "reverse",
                transform: "translateX(330px)",
              }}
              // sx={styles.marqueText(marqueX)}
            >
              • {navBar_BuyOnlineMsg}{" "}
              {commercialDetails?.info?.rewardPointPercent ? (
                <span style={{ marginLeft: "20px" }}>
                  • {commercialDetails?.info?.rewardPointPercent}% Cashback on
                  all orders!
                </span>
              ) : (
                ""
              )}
              {commercialDetails?.info?.immediateDiscount ? (
                <span style={{ marginLeft: "20px" }}>
                  • Free Shipping Above ₹
                  {commercialDetails?.info?.immediateDiscount}!
                </span>
              ) : (
                ""
              )}
              {/* {commercialDetails?.info?.orderInfoDetails?.length > 0 ? ( */}
                <span style={{ marginLeft: "20px" }}>
                  • Minimum Order Amount ₹
                  {getMinOrderAmount(commercialDetails?.info?.orderInfoDetails)}
                </span>
              {/* ) : (
                ""
              )} */}
              {dealsCouponsDetails?.length > 0
                ? dealsCouponsDetails?.map((item) => {
                    return (
                      <span style={{ marginLeft: "20px" }}>
                        • Get {item.discountPercentage}% Off, use coupon code{" "}
                        {item.resellerCode}
                      </span>
                    );
                  })
                : ""}
              {commercialDetails?.info?.fintech?.active ? (
                <Box
                  component="img"
                  src="https://storage.googleapis.com/bodefaults/shopweb/new.png"
                  width="30px"
                  height="auto"
                  alt={businessFullName + " CostBo"}
                  sx={{ mr: 1, ml: 2.5 }}
                />
              ) : null}
              {commercialDetails?.info?.fintech?.active
                ? `Instant credit for orders above ₹ ${commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit}`
                : null}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="flex-end" gap="8px" mb={-0.6}>
            {/* {commercialDetails?.info?.costboPowered?.affiliateEnabled &&
              businessAffiliateData?.businessResellers?.length > 0 && (
                <>
                  <IconLinkBtn
                    tooltipText='Brand Affiliate'
                    iconUrl='/./assets/tempImgs'
                    onClick={() => {
                      navigate(
                        rellerProfileData?.details
                          ? `/${businessName}/affiliateHome`
                          : `/${businessName}/affiliates`,
                        {
                          state: { disableAutoNav: true },
                        }
                      )
                    }}
                  />

                  {/* <AffiliateButton
                              handleClick={() => {
                                navigate(
                                  rellerProfileData?.details
                                    ? `/${businessName}/affiliateHome`
                                    : `/${businessName}/affiliates`,
                                  {
                                    state: { disableAutoNav: true },
                                  }
                                )
                              }}
                              businessName={businessName}
                            ///> 
                </>
              )} */}

            {isMultiBranch && (
              <Button sx={styles.changeBranchBtn} onClick={onChangeBranch}>
                Change Branch
              </Button>
            )}

            <IconLinkBtn
              iconUrl={
                social?.status === 1
                  ? // ? "https://storage.googleapis.com/bodefaults/shopweb/payments/follow.svg"
                    "	https://storage.googleapis.com/bodefaults/shopweb/payments/follow.svg"
                  : "https://storage.googleapis.com/bodefaults/shopweb/payments/follow.svg"
              }
              tooltipText={social?.status === 1 ? "UnFollow" : "Follow"}
              onClick={() => {
                followUnfollow();
              }}
            />

            <Box position="relative" mr={-0.8}>
              <IconLinkBtn
                iconUrl="https://storage.googleapis.com/bodefaults/shopweb/affiliates/share.svg"
                onClick={() => {
                  if (
                    commercialDetails?.info?.costboPowered?.affiliateEnabled &&
                    businessAffiliateData?.businessResellers?.length > 0 &&
                    rellerProfileData
                  ) {
                    setShowShareOptions(true);
                  } else {
                    setShowShareOptions(true);

                    setTimeout(() => {
                      setShowShareOptions(false);
                    }, 3000);
                  }
                }}
              />

              {showShareOptions ? (
                <Box
                  position="absolute"
                  left="-160px"
                  bottom="-50px"
                  zIndex={10}
                >
                  {commercialDetails?.info?.costboPowered?.affiliateEnabled &&
                  businessAffiliateData?.businessResellers?.length > 0 &&
                  rellerProfileData ? (
                    <AffiliateShareModal
                      // productDetails={openShareModal?.data?.productDetails}
                      commissionDetails={businessAffiliateData}
                      rellerProfileData={rellerProfileData}
                      open={showShareOptions}
                      onClose={() => {
                        setShowShareOptions(false);
                      }}
                      influencerShareType="business"
                    />
                  ) : (
                    <SocialShareVertical
                      // url={`${siteBaseURL}${businessName}`}
                      url={`${windowurl}`}
                      quote=""
                      back={() => {
                        setShowShareOptions(false);
                        if (isUserLogin)
                          addTimelineShare({
                            shareType: "timelineshare",
                            latitude: 77.65563062,
                            longitude: 12.92352506,
                          });
                      }}
                    />
                  )}
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TopBar;

const IconLinkBtn = ({ iconUrl, onClick, tooltipText }) => {
  const IconLink = () => (
    <Box component="a" sx={{ cursor: "pointer" }} onClick={onClick}>
      <Box
        component="img"
        src={iconUrl}
        sx={{ width: "28px", height: "17px", objectFit: "contain" }}
      />
    </Box>
  );
  return tooltipText ? (
    <Tooltip title={tooltipText} arrow>
      <Box>
        <IconLink />
      </Box>
    </Tooltip>
  ) : (
    <IconLink />
  );
};

const styles = {
  topBarStyles: {
    bgcolor: blackColor,
    p: "5px 0",
  },

  menuBtn: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },

  textStyle: {
    fontSize: 12.5,
    fontWeight: 500,
    color: whiteColor,
    cursor: "pointer",
    textAlign: "center",
  },

  topBar: (isMultiBranch)=>({
    width: isMultiBranch ? "66.5vw" : '73vw',
    overflowX: "hidden",
  }),

  marqueText: (marqueX) => ({
    fontSize: "12px",
    fontWeight: 500,
    color: whiteColor,
    display: "inline-block",
    whiteSpace: "nowrap",
    animation: `${marqueX} 30s linear infinite`,
    animationDirection: "reverse",
    transform: "translateX(330px)",
  }),

  changeBranchBtn: {
    fontSize: 12,
    fontWeight: 500,
    color: whiteColor,
    lineHeight: "10px",
    textDecoration: "underline",
    p: "0 5.5px 2.5px 0",
    textWrap: "nowrap",
    textTransform: "unset",
    ml: 0,
  },
};
