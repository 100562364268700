import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBusinessIdData,
  getBusinessIdDataByDomain,
} from "services/api_calls/root/RootApi";

const initialState = {
  businessId: null,
  loading: false,

  //Loaders
  businessIdLoading: false,
};

//Fetching business id
export const getBusinessIdDetails = createAsyncThunk(
  "getBusinessIdDetails",
  async (param, thunkAPI) => {
    const res = getBusinessIdData(param);
    return res;
  }
);
//Fetching business id buy domain name
export const getBusinessIdDetailsByDomain = createAsyncThunk(
  "getBusinessIdDetailsByDomain",
  async (param, thunkAPI) => {
    const res = getBusinessIdDataByDomain(param);
    return res;
  }
);

export const rootDetailsSlice = createSlice({
  name: "rootSlice",
  initialState,
  reducers: {},
  extraReducers: {
    //Fetching business id
    [getBusinessIdDetails.pending]: (state) => {
      state.businessIdLoading = true;
      state.loading = true;
    },
    [getBusinessIdDetails.fulfilled]: (state, { payload }) => {
      state.businessIdLoading = false;
      state.loading = false;
      state.businessId = payload;
    },
    [getBusinessIdDetails.rejected]: (state) => {
      state.businessIdLoading = false;
      state.loading = false;
    },

    //Fetching business id buy domain name
    [getBusinessIdDetailsByDomain.pending]: (state) => {
      state.businessIdLoading = true;
      state.loading = true;
    },
    [getBusinessIdDetailsByDomain.fulfilled]: (state, { payload }) => {
      state.businessIdLoading = false;
      state.loading = false;
      state.businessId = {
        businessId: payload?.details?.businessId,
        validate: true,
      };
    },
    [getBusinessIdDetailsByDomain.rejected]: (state) => {
      state.businessIdLoading = false;
      state.loading = false;
    },
  },
});

export const rootDetailsReducer = rootDetailsSlice.reducer;
