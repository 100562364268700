import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import {
  blackColor,
  blackShade25,
  borderCard06,
  greenColor05,
  greyShade76,
  orangeColor01,
  orangeColor14,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useEffect, useState } from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { checkIsUpdateRequired } from "utils/AddressHelper/addressHelper";
import { formatPhoneNumber } from "utils/utilities";
import PinDropRoundedIcon from "@mui/icons-material/PinDropRounded";

const MapIcon = () => (
  <Box sx={styles.mapIconCircle}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#1C1C1C"
      width="18"
      height="18"
      viewBox="0 0 20 21"
      aria-labelledby="icon-svg-title- icon-svg-desc-"
      role="img"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.50376 0.135614C5.50502 0.734541 3.10602 3.13025 2.60623 6.12488C2.00648 9.81826 4.00564 13.1123 7.10435 14.3102C8.10393 14.7095 9.00355 15.4082 9.50334 16.4064C9.70326 16.7059 9.80322 17.1052 10.0031 17.4047C10.1031 17.1052 10.303 16.7059 10.5029 16.4064C11.0027 15.4082 11.9023 14.8093 12.9019 14.3102C15.6008 13.2122 17.5 10.517 17.5 7.42255C17.5 2.9306 13.3018 -0.762775 8.50376 0.135614ZM10.0031 10.0179C8.60372 10.0179 7.50418 8.91987 7.50418 7.52237C7.50418 6.12488 8.60372 5.02685 10.0031 5.02685C11.4025 5.02685 12.5021 6.12488 12.5021 7.52237C12.5021 8.91987 11.4025 10.0179 10.0031 10.0179Z"
      ></path>
      <path
        fill="#e56b46"
        d="M10.0033 20C11.1074 20 12.0025 19.5531 12.0025 19.0018C12.0025 18.4505 11.1074 18.0036 10.0033 18.0036C8.89921 18.0036 8.00415 18.4505 8.00415 19.0018C8.00415 19.5531 8.89921 20 10.0033 20Z"
      ></path>
    </svg>
  </Box>
);

const SelectedAddressCard = ({
  name,
  addressLine1,
  landmark,
  city,
  state,
  country,
  pincode,
  phoneNo,
  alternatePhoneNo,
  updatedOn,
  isNoAddress,
  onAddAddress,
  onChangeAddress,
  onUpdateAddress,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [isUpdateRequired, setIsUpdateRequired] = useState(false);

  useEffect(() => {
    setIsUpdateRequired(checkIsUpdateRequired(updatedOn));

    return () => {};
  }, [updatedOn]);

  return (
    <Box
      sx={{
        ...styles.card,
        flexDirection: isNoAddress ? "row" : { xs: "column", md: "row" },
      }}
    >
      <Box
        flex={1}
        display="flex"
        alignItems={isNoAddress ? "center" : "flex-start"}
        gap={{ xs: "10px", md: "15px" }}
      >
        <MapIcon />

        {isNoAddress ? (
          <Box>
            <Typography
              component="h4"
              fontSize={{ xs: 12, md: 13 }}
              fontWeight={500}
              color={blackShade25}
              lineHeight="16px"
              mt={-0.5}
            >
              Add Your Delivery Address
            </Typography>
          </Box>
        ) : (
          <Box flex={1}>
            <Typography
              component="h4"
              fontSize={{ xs: 11.5, md: 14 }}
              fontWeight={400}
              color={blackShade25}
            >
              Deliver to:{" "}
              <Typography
                component="span"
                fontSize={{ xs: 11.5, md: 14 }}
                fontWeight={600}
              >
                {name}
              </Typography>
              <Typography
                component="span"
                fontSize={11}
                fontWeight={400}
                color={greyShade76}
                ml="6px"
              >
                (Default)
              </Typography>
            </Typography>

            <Typography
              fontSize={{ xs: 11.5, md: 12 }}
              fontWeight={400}
              color={blackShade25}
              lineHeight="20px"
              mt={{xs:0, md:0.6}}
            >
              {addressLine1?.endsWith(",") ? addressLine1 : `${addressLine1},`}{" "}
              {landmark
                ? landmark?.endsWith(",")
                  ? landmark
                  : `${landmark},`
                : ""}{" "}
              {city}, {state} - {pincode}
            </Typography>

            <Typography
              fontSize={{ xs: 11, md: 12 }}
              fontWeight={600}
              color={blackShade25}
            >
              Mobile: {formatPhoneNumber(phoneNo)}
              {alternatePhoneNo
                ? `, ${formatPhoneNumber(alternatePhoneNo)}`
                : ""}
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        width={isNoAddress ? "auto" : { xs: "100%", md: "auto" }}
        display="flex"
        alignItems="center"
        justifyContent={{ xs: "flex-end", md: "center" }}
        flexDirection={{ xs: "row", md: "row" }}
        gap="10px"
      >
        {isNoAddress ? (
          <>
            <Button
              variant="outlined"
              sx={styles.btn}
              onClick={onAddAddress}
              startIcon={
                <AddRoundedIcon
                  sx={{ fontSize: "16px !important", mr: "-5px" }}
                />
              }
            >
              {matches ? "Add New Address" : "Add Address"}
            </Button>
          </>
        ) : (
          <>
            {isUpdateRequired && (
              <Button
                variant="text"
                sx={{
                  ...styles.btn,
                  ...styles.updateBtn,
                  order: { xs: 2, md: 1 },
                }}
                onClick={onUpdateAddress}
                startIcon={
                  matches && (
                    <PinDropRoundedIcon
                      sx={{ fontSize: "16px !important", m: "-1px -4px 0" }}
                    />
                  )
                }
              >
                Update Required
              </Button>
            )}

            <Button
              variant="outlined"
              sx={{
                ...styles.btn,
                textDecoration: { xs: "underline", md: "none" },
                order: { xs: 1, md: 2 },
              }}
              onClick={onChangeAddress}
            >
              Change Address
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SelectedAddressCard;

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: { xs: "5px", md: "15px" },
    p: { xs: "6px", md: "16px" },
    bgcolor: orangeColor14,
    border: borderCard06,
    borderRadius: { xs: "10px", md: "12px" },
    m: { xs: "8px 0 15px", md: "0px" },
  },

  mapIconCircle: {
    width: { xs: "33px", md: "35px" },
    height: { xs: "33px", md: "35px" },
    bgcolor: whiteColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "35px",
    border: borderCard06,
    // p: "7px",
  },

  btn: {
    fontSize: 11,
    fontWeight: 600,
    color: orangeColor01,
    bgcolor: { xs: "transparent", md: whiteColor },
    lineHeight: "20px",
    border: { xs: "none", md: `1px solid ${orangeColor01}` },
    borderRadius: "6px",
    p: { xs: "5px 6px", md: "5px 14px" },
    "&:hover": {
      color: whiteColor,
      bgcolor: { xs: "transparent", md: orangeColor01 },
      border: `1px solid ${orangeColor01}`,
    },
  },

  updateBtn: {
    color: greenColor05,
    bgcolor: "transparent",
    border: "none",
    textDecoration: "underline",
    "&:hover": {
      color: greenColor05,
      bgcolor: "transparent",
      border: "none",
    },
  },
};
