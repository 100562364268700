import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Radio,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  backgroundColor01,
  blackColor,
  borderLineColor,
  discountBgColor,
  errorTextColor,
  greenColor05,
  headingColor,
  minOrderTextColor,
  orangeColor01,
  secondaryColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { addressCard_DeleteAddressInfo } from "configs/Constants";
import moment from "moment";
import { checkIsUpdateRequired } from "utils/AddressHelper/addressHelper";
import { textOneLines } from "utils/styleUtility";
import { textTwoLines } from "utils/styleUtility";
import AlertModal from "components/AlertModal";

function AddressCard({
  onEdit,
  address,
  readOnly,
  onSelectMethod,
  onDeleteAddress,
  onEditAddress,
  cardStyle,
  isAddressSelected,
  showAlternateNo,
  recipientPhInline,
  carttop,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);

  const [isUpdateRequired, setIsUpdateRequired] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    console.log("hitt delete");
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsUpdateRequired(checkIsUpdateRequired(address?.updatedOn));

    return () => {};
  }, [address]);

  return matches ? (
    <Box>
      <AlertModal
        open={open}
        content={addressCard_DeleteAddressInfo}
        btnName="Delete"
        handleConfirm={() => {
          onDeleteAddress();
          handleClose();
        }}
        handleClose={handleClose}
      />
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {addressCard_DeleteAddressInfo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onDeleteAddress();
              handleClose();
            }}
            sx={{
              backgroundColor: blackColor,
              color: whiteColor,
              border: "1px solid" + blackColor,
              p: "2px 10px",
              fontSize: 12,
              "&:hover": {
                backgroundColor: whiteColor,
                color: blackColor,
              },
            }}
            // color={blackColor}
            textTransform={"uppercase"}
          >
            Delete
          </Button>
          <Button
            onClick={handleClose}
            autoFocus
            sx={{
              color: blackColor,
              blackgroundColor: whiteColor,
              border: "1px solid" + blackColor,
              p: "2px 10px",
              fontSize: 12,
              "&:hover": {
                color: whiteColor,
                backgroundColor: blackColor,
              },
            }}
            textTransform={"uppercase"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog> */}
      <Box mr={!readOnly ? 2 : 0} sx={{...cardStyle}}>
        <Box
          sx={{
            // backgroundColor: 'rgba(255, 255, 255, 1)', // You can replace this with your CSS variable value
            border: "1px solid #e5e5ed",
            borderRadius: "10px",
            padding: "8px 12px",
            backgroundColor: !readOnly ? whiteColor : backgroundColor01,
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"} justifyContent={"flex-start"} ml={-1.5}>
              {!readOnly ? (
                <Radio
                  value="a"
                  checked={isAddressSelected || false}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  onChange={() => {
                    onSelectMethod();
                  }}
                  size="small"
                  sx={{
                    width: 35,
                    height: 27,
                    color: blackColor, // default color
                    "&.Mui-checked": {
                      color: blackColor, // color when checked
                    },
                  }}
                />
              ) : null}

              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: blackColor,
                  ml: 1,
                }}
              >
                {address &&
                  `${address?.name.slice(0, 15)}${
                    address?.name?.length > 16 ? "..." : ""
                  }`}
              </Typography>
            </Box>
            <Box display={"flex"} gap={1}>
              <Box
                onClick={() => onEditAddress()}
                component="img"
                src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/edit.svg"
                // src='https://storage.googleapis.com/bodefaults/shopweb/discounts_wallet.svg'
                alt={" CostBo"}
                width={{ xs: "16px", md: "18px" }}
                height={{ xs: "16px", md: "18px" }}
                sx={{
                  padding: "5px",
                  // backgroundColor: 'rgb(0, 0, 0)',
                  borderRadius: "5px",
                  border: `1px solid ${borderLineColor}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: whiteColor,
                  cursor: "pointer",
                }}
              />
              {!readOnly && (
                <Box
                  onClick={() => handleClickOpen()}
                  component="img"
                  src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/trash.svg"
                  // src='https://storage.googleapis.com/bodefaults/shopweb/discounts_wallet.svg'
                  alt={" CostBo"}
                  width={{ xs: "16px", md: "18px" }}
                  height={{ xs: "16px", md: "18px" }}
                  sx={{
                    padding: "5px",
                    // backgroundColor: 'rgb(0, 0, 0)',
                    borderRadius: "5px",
                    border: `1px solid ${borderLineColor}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                />
              )}
            </Box>
          </Box>
          <Box
            onClick={() => {
              if (!readOnly) {
                onSelectMethod();
              }
            }}
          >
            <Typography
              fontSize={12}
              fontWeight={500}
              lineHeight={1.5}
              color={blackColor}
              height={!readOnly ? "36px" : "auto"}
              mt={1}
            >
              {address?.addressLine1?.slice(0, 50)}
              {address?.addressLine1?.length > 50 ? "..." : null},{" "}
              {address?.city}, {address?.state} - {address?.zip}
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderTop: "1px solid #EAEAF1",
                paddingTop: "10px",
                marginTop: "8px",
              }}
            >
              <Stack>
                <Box display={"flex"}>
                  <Typography
                    sx={{
                      color: "#838390",
                      fontWeight: 400,

                      fontSize: "12.5px",
                    }}
                  >
                    Phone :
                  </Typography>
                  <Typography
                    color={blackColor}
                    fontSize={12.5}
                    fontWeight={500}
                    ml={1}
                  >
                    {address?.phoneNo}{" "}
                    {/* {recipientPhInline
                  ? address?.alternatePhoneNo
                    ? `, +91${address?.alternatePhoneNo}`
                    : ''
                  : ''} */}
                  </Typography>
                </Box>

                {showAlternateNo && address?.alternatePhoneNo?.length > 0 && (
                  <Box display={"flex"}>
                    <Typography
                      sx={{
                        color: "#838390",
                        fontWeight: 400,

                        fontSize: "12.5px",
                      }}
                    >
                      Recipient Ph :
                    </Typography>
                    <Typography
                      color={blackColor}
                      fontSize={12.5}
                      fontWeight={500}
                      ml={1}
                    >
                      {/* {address?.phoneNo}{' '} */}
                      {address?.alternatePhoneNo
                        ? ` +91${address?.alternatePhoneNo}`
                        : ""}
                    </Typography>
                  </Box>
                )}
              </Stack>

              {isUpdateRequired && (
                <Button
                  variant="outlined"
                  onClick={() => onEditAddress()}
                  sx={styles.updateRequiredBtn}
                >
                  UPDATE REQUIRED
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box pt={carttop ? 1.5 : 0}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {console.log("hitt_delete1")}
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {addressCard_DeleteAddressInfo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              console.log("hitt_delete12");

              onDeleteAddress();
              handleClose();
            }}
            variant="contained"
            sx={{
              px: 2,
              color: whiteColor,
              border: `1px solid ${blackColor}`,
              backgroundColor: blackColor,
              textTransform: "uppercase",
              "&:hover": {
                backgroundColor: whiteColor,
                color: blackColor,
              },
            }}
          >
            Delete
          </Button>
          <Button
            onClick={handleClose}
            autoFocus
            variant="outlined"
            sx={{
              px: 2,
              color: blackColor,
              border: `1px solid ${blackColor}`,
              mr: "15px",
              textTransform: "uppercase",
              "&:hover": {
                backgroundColor: blackColor,
                color: whiteColor,
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        onClick={() => {
          if (!readOnly) {
            onSelectMethod();
          }
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 1)",
            border: "1px solid #e5e5ed",
            borderRadius: "10px",
            padding: "12px",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              ml={readOnly ? 0 : -1}
            >
              {!readOnly ? (
                <Radio
                  value="a"
                  checked={isAddressSelected || false}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  // onChange={() => {
                  //   onSelectMethod()
                  // }}
                  size="small"
                  sx={{
                    width: 35,
                    height: 27,
                    color: blackColor, // default color
                    "&.Mui-checked": {
                      color: blackColor, // color when checked
                    },
                  }}
                />
              ) : (
                <Box
                  component="img"
                  src="https://storage.googleapis.com/bodefaults/shopweb/reviews/location-white.svg"
                  // src='https://storage.googleapis.com/bodefaults/shopweb/discounts_wallet.svg'
                  alt={" CostBo"}
                  width={{ xs: "16px", md: "46px" }}
                  height={{ xs: "16px", md: "30px" }}
                  sx={{
                    padding: "5px",
                    backgroundColor: "rgb(0, 0, 0)",
                    borderRadius: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              )}

              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: blackColor,
                  ml: 1,
                }}
              >
                {address &&
                  `${address?.name.slice(0, 15)}${
                    address?.name?.length > 16 ? "..." : ""
                  }`}
              </Typography>
            </Box>
            <Box display={"flex"} gap={1}>
              <Box
                onClick={() => onEditAddress()}
                component="img"
                src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/edit.svg"
                // src='https://storage.googleapis.com/bodefaults/shopweb/discounts_wallet.svg'
                alt={" CostBo"}
                width={{ xs: "16px", md: "46px" }}
                height={{ xs: "16px", md: "30px" }}
                sx={{
                  padding: "5px",
                  // backgroundColor: 'rgb(0, 0, 0)',
                  borderRadius: "5px",
                  border: `1px solid ${borderLineColor}`,

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              {!readOnly && (
                <Box
                  onClick={() => {
                    console.log("hitt_delete");

                    handleClickOpen();
                  }}
                  component="img"
                  src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/trash.svg"
                  // src='https://storage.googleapis.com/bodefaults/shopweb/discounts_wallet.svg'
                  alt={" CostBo"}
                  width={{ xs: "16px", md: "46px" }}
                  height={{ xs: "16px", md: "30px" }}
                  sx={{
                    padding: "5px",
                    // backgroundColor: 'rgb(0, 0, 0)',
                    borderRadius: "5px",
                    border: `1px solid ${borderLineColor}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                />
              )}
            </Box>
          </Box>
          <Typography
            fontSize={12}
            fontWeight={500}
            lineHeight={1.5}
            color={blackColor}
            mt={1}
            height={{ xs: "34px", md: "auto" }}
          >
            {address?.addressLine1?.slice(0, 50)}
            {address?.addressLine1?.length > 50 ? "..." : null}, {address?.city}
            , {address?.state} - {address?.zip}
          </Typography>
          <Stack
            sx={{
              borderTop: "1px solid #EAEAF1",
              paddingTop: "6px",
              marginTop: "8px",
            }}
          >
            <Box display={"flex"} alignItems='center' justifyContent={"space-between"}>
              <Box display={"flex"} flexDirection={"column"}>
                <Box display={"flex"}>
                  <Typography
                    sx={{
                      color: "#838390",
                      fontWeight: 400,
                      fontSize: "12.5px",
                    }}
                  >
                    Phone :
                  </Typography>
                  <Typography
                    color={blackColor}
                    fontSize={12.5}
                    fontWeight={500}
                    ml={1}
                  >
                    {address?.phoneNo}{" "}
                    {/* {recipientPhInline
                  ? address?.alternatePhoneNo
                    ? `, +91${address?.alternatePhoneNo}`
                    : ""
                  : ""} */}
                  </Typography>
                </Box>

                {showAlternateNo && address?.alternatePhoneNo && (
                  <Box display={"flex"}>
                    <Typography
                      sx={{
                        color: "#838390",
                        fontWeight: 400,

                        fontSize: "12.5px",
                      }}
                    >
                      Recipient :
                    </Typography>
                    <Typography
                      color={blackColor}
                      fontSize={12.5}
                      fontWeight={500}
                      ml={1}
                    >
                      {/* {address?.phoneNo}{" "} */}
                      {
                        // recipientPhInline
                        //   ?
                        address?.alternatePhoneNo
                          ? ` +91${address?.alternatePhoneNo}`
                          : ""
                      }
                    </Typography>
                  </Box>
                )}
              </Box>

              {isUpdateRequired && (
                <Button
                  variant="outlined"
                  onClick={() => onEditAddress()}
                  sx={{...styles.updateRequiredBtn, fontSize:10.5, p:'6px 10px'}}
                >
                  UPDATE REQUIRED
                </Button>
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default AddressCard;

const styles = {
  addressCard: {
    backgroundColor: discountBgColor,
    borderRadius: 1.8,
    border: "1px solid" + borderLineColor,
    py: 0.3,
    mr: { md: 2, xs: 0.5 },
  },
  actionBtn: {
    "&:hover": {
      color: errorTextColor,
    },
  },

  updateRequiredBtn: {
    fontSize: 11,
    fontWeight: 600,
    backgroundColor: whiteColor,
    color: greenColor05,
    borderRadius: "6px",
    border: `1px solid ${greenColor05}`,
    lineHeight: "20px",
    p: "4px 12px",
    // mr: 1,
    textTransform: "uppercase",
    "&:hover": {
      color: whiteColor,
      bgcolor: greenColor05,
      border: `1px solid ${greenColor05}`,
    },
  },
};
