import { Drawer, useMediaQuery } from "@mui/material";
import { greyShade74 } from "configs/styles/muiThemes";
import ModalCard from "components/ModalCard";
import VariantPopupCard from "./cards/VariantPopupCard";

function VariantPopup({
  open,
  onClose,
  product,
  cartData,
  onQtyUpdate,
  cartId,
  onAddProduct,
  onRemoveProduct,
  viewOnly,
  buyProduct,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return matches ? (
    <ModalCard handleClose={onClose} open={open} width="30%">
      <VariantPopupCard
        product={product}
        cartData={cartData}
        onQtyUpdate={onQtyUpdate}
        cartId={cartId}
        onAddProduct={onAddProduct}
        onRemoveProduct={onRemoveProduct}
        viewOnly={viewOnly}
        buyProduct={buyProduct}
        onClose={onClose}
      />
    </ModalCard>
  ) : (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      PaperProps={{
        style: {
          borderRadius: "20px 20px 0 0",
          padding: "12px 15px",
          background: greyShade74,
          minHeight: "30vh",
          maxHeight: "60vh",
          //   position: "relative",
        },
      }}
    >
      <VariantPopupCard
        product={product}
        cartData={cartData}
        onQtyUpdate={onQtyUpdate}
        cartId={cartId}
        onAddProduct={onAddProduct}
        onRemoveProduct={onRemoveProduct}
        viewOnly={viewOnly}
        buyProduct={buyProduct}
      />
    </Drawer>
  );
}

export default VariantPopup;
