import { Badge, Box, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import {
  backgroundColor,
  borderLineColor,
  headingColor,
  productLabelColor,
} from '../../../configs/styles/muiThemes'
import ShoppingBasketRoundedIcon from '@mui/icons-material/ShoppingBasketRounded';

import { Link, useNavigate } from 'react-router-dom'
import { navigateTo } from 'utils/urlHelper';

function CartCount({ count }) {
  const businessName = sessionStorage.getItem('businessName')
  const navigate = useNavigate()
  return (
    <Box
      border={"1px solid " + borderLineColor}
      borderRadius={2.2}
      px={1.85}
      py={1}
      my={{ md: 3, xs: 1 }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography
            variant="h6"
            // component={Link}
            // to={`/${businessName}/Cart`}
            onClick={()=>{
              navigateTo(navigate, `cart`)
            }}
            color={headingColor}
            sx={{ textDecoration: "underline" }}
          >
            {count} items in cart
          </Typography>
        </Grid>

        <Grid item>
          <IconButton
            sx={{
              background: backgroundColor,
              p: 1.4,
              textTransform: "uppercase",
            }}
            // component={Link}
            // to={`/${businessName}/Cart`}
            onClick={()=>{
              navigateTo(navigate, `cart`)
            }}
          >
            <Badge badgeContent={count} color="primary">
              <ShoppingBasketRoundedIcon
                sx={{
                  fontSize: "23px",
                  color: productLabelColor,
                }}
              />
            </Badge>
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CartCount
