import moment from "moment";
import { getUserDetails } from "./authHelpers";
import { getBuyMoreInventoryApi, getUnicomInventoryApi } from "services/api_calls/catalogPartner/CatalogPartnerApis";

export const checkUnicomInventory = (inventoryInfo, product) => {
  const itemSkuCode = product?.catalogPartnerItemInfo?.[0]?.skuCode || "";
  const itemInventoryInfo = inventoryInfo?.find(
    (item) => item?.itemTypeSKU == itemSkuCode
  );

  if (!itemSkuCode || !itemInventoryInfo) return true;

  if (itemSkuCode && itemInventoryInfo) {
    const inventory = Number(itemInventoryInfo?.inventory || 0);
    const inventoryBlocked = Number(itemInventoryInfo?.inventoryBlocked || 0);

    // const availableQuantity = inventory - inventoryBlocked;
    const availableQuantity = inventory

    const minOrderQuantity = Number(product?.minOrderQuantity || 0);

    console.log("checkUnicomInventory____:", {
      inventoryInfo,
      productId: product?.id,
      itemSkuCode,
      itemInventoryInfo,
      availableQuantity,
      minOrderQuantity,
    });

    if (availableQuantity <= 0 || availableQuantity < minOrderQuantity) {
      return true;
    } else {
      return false;
    }
  }
};

// unicom inventory validation in discount delivery page
const validateUnicomInventory = async (cartDetails, commerceInfo) => {
  const catalogPartnerInfo = commerceInfo?.info?.catalogPartnerInfo?.[0];

  if (catalogPartnerInfo?.status == "active") {
    const cartProductList = cartDetails?.productList || [];
    const skuList = cartProductList?.map(
      (item) => item?.catalogPartnerItemInfo?.skuCode || ""
    );
    const facilityCode = catalogPartnerInfo?.warehouse?.facilityCode;

    const inventoryRes = await getUnicomInventoryApi({ facilityCode, skuList });

    let updatedProducts = [];
    let isError = false;

    if (inventoryRes) {
      // unicommerce inventory ddata
      const unicomInventoryInfo = inventoryRes?.inventorySnapshots || [];

      // check availability for all the cart items
      updatedProducts = cartProductList.map((item, index) => {
        const productSkuCode = item?.catalogPartnerItemInfo?.skuCode || "";
        const currentCartQty = Number(item?.currentQuantity || 0);

        const productInventory = unicomInventoryInfo?.find(
          (inventory) => inventory?.itemTypeSKU == productSkuCode
        );

        if (productInventory) {
          const inventory = Number(productInventory?.inventory || 0);
          const inventoryBlocked = Number(
            productInventory?.inventoryBlocked || 0
          );

          // const availableQuantity = inventory - inventoryBlocked;
          const availableQuantity = inventory

          if (currentCartQty > availableQuantity) {
            isError = true;
          }

          return {
            ...item,
            partnerInventory: availableQuantity,
            inventoryErr: currentCartQty > availableQuantity,
          };
        }

        return {
          ...item,
          partnerInventory: 0,
          inventoryErr: false,
        };
      });
    }

    return { updatedProducts, isError };
  } else {
    return { updatedProducts: cartDetails?.productList, isError: false };
  }
};

// unicom error message discount delivery page
export const getPartnerInventoryError = (productList, product) => {
  const cartProducts = productList?.updatedProducts || [];
  const currentProduct = cartProducts?.find(
    (item) =>
      item?.catalogPartnerItemInfo?.skuCode ==
      product?.catalogPartnerItemInfo?.skuCode
  );

  if (currentProduct) {
    if (currentProduct?.inventoryErr) {
      if (currentProduct?.partnerInventory > 0) {
        return `Only ${currentProduct?.partnerInventory} items available in stock. Please adjust your order quantity.`;
      }else{
        return `Product is currently not available. Please remove from the cart`
      }
    }
  }

  return "";
};

export const createSaleOrderpostData = (params) => {
  const userInfo = getUserDetails()

  const { cartDetails, seletedAddress, facilityCode, isCod, shipmentAmount, discountAmount } =
    params;

    console.log("unicomCreateSaleOrder___cartinfo", seletedAddress);
    

  const customerAddress = seletedAddress;
  const cartProducts = cartDetails?.productList || [];

  const productList = [];
  let itemId = 0;

  cartProducts.forEach((item, index) => {
    const quantity = Number(item?.currentQuantity || 0);

    for (let i = 0; i < quantity; i++) {
      const itemData = {
        itemName: item?.productName,
        itemSku: item?.catalogPartnerItemInfo?.skuCode,
        shippingMethodCode: "STD",
        sellingPrice: item?.productSalePrice,
        shippingCharges: 0,
        totalPrice: item?.productSalePrice,
        discount: "0",
        shippingMethodCharges: 0,
        channelTransferPrice: 0,
        shippingAddress: {
          referenceId: "1",
        },
        facilityCode: facilityCode,
        code: `${cartDetails?.orderId}-${itemId}`,
        packetNumber: 1,
        channelProductId: null,
      };

      productList.push(itemData);
      itemId = itemId + 1;
    }
  });

  const data = {
    saleOrder: {
      code: cartDetails?.orderId,
      channel: "COSTBO",
      displayOrderDateTime: moment().valueOf(),
      channelProcessingTime: moment().valueOf(),
      cashOnDelivery: isCod || false,
      currencyCode: "INR",
      displayOrderCode: "",
      customerCode: "",
      customerName: "",
      notificationEmail: "",
      notificationMobile: "",
      addresses: [
        {
          name: customerAddress?.name,
          addressLine1: customerAddress?.addressLine1,
          addressLine2: customerAddress?.addressLine2,
          city: customerAddress?.city,
          district: "",
          pincode: customerAddress?.zip,
          phone: customerAddress?.phoneNo,
          country: customerAddress?.country,
          state: customerAddress?.state,
          id: "1",
          email: userInfo?.account?.email,
        },
      ],
      billingAddress: {
        referenceId: "1",
      },
      shippingAddress: {
        referenceId: "1",
      },
      totalDiscount: Number(discountAmount || 0),
      totalGiftWrapCharges: null,
      totalShippingCharges: Number(shipmentAmount || 0),
      totalCashOnDeliveryCharges: null,
      totalStoreCredit: null,
      totalPrepaidAmount: null,
      customFieldValues: [
        {
          name: "test",
          value: null,
        },
      ],
      saleOrderItems: productList,
    },
    forceAllocate: false,
  };

  return data;
};

// Buymore
export const checkBuymoreInventory = (inventoryInfo, product) => {
  const itemSkuCode = product?.catalogPartnerItemInfo?.[0]?.skuCode || "";
  const itemInventoryInfo = inventoryInfo?.find(
    (item) => item?.portal_sku == itemSkuCode
  );

  if (!itemSkuCode || !itemInventoryInfo) return true;

  if (itemSkuCode && itemInventoryInfo) {
    const inventory = Number(itemInventoryInfo?.current_stock || 0);
    const availableQuantity = inventory

    const minOrderQuantity = Number(product?.minOrderQuantity || 0);

    console.log("checkBuymoreInventory____:", {
      inventoryInfo,
      productId: product?.id,
      itemSkuCode,
      itemInventoryInfo,
      availableQuantity,
      minOrderQuantity,
    });

    if (availableQuantity <= 0 || availableQuantity < minOrderQuantity) {
      return true;
    } else {
      return false;
    }
  }
};

// BuyMore inventory validation in discount delivery page
const validateBuyMoreInventory = async (cartDetails, commerceInfo) => {
  const catalogPartnerInfo = commerceInfo?.info?.catalogPartnerInfo?.[0];

  if (catalogPartnerInfo?.status == "active") {
    const cartProductList = cartDetails?.productList || [];
    const skuList = cartProductList?.map(
      (item) => item?.catalogPartnerItemInfo?.skuCode || ""
    );
    const facilityCode = catalogPartnerInfo?.warehouse?.facilityCode;

    const inventoryRes = await getBuyMoreInventoryApi({ facilityCode, skuList });

    let updatedProducts = [];
    let isError = false;

    if (inventoryRes) {
      // buymore inventory data
      const buymoreInventoryInfo = inventoryRes?.final_list || [];

      // check availability for all the cart items
      updatedProducts = cartProductList.map((item, index) => {
        const productSkuCode = item?.catalogPartnerItemInfo?.skuCode || "";
        const currentCartQty = Number(item?.currentQuantity || 0);

        const productInventory = buymoreInventoryInfo?.find(
          (inventory) => inventory?.portal_sku == productSkuCode
        );

        if (productInventory) {
          const inventory = Number(productInventory?.current_stock || 0);
          const availableQuantity = inventory

          if (currentCartQty > availableQuantity) {
            isError = true;
          }

          return {
            ...item,
            partnerInventory: availableQuantity,
            inventoryErr: currentCartQty > availableQuantity,
          };
        }

        return {
          ...item,
          partnerInventory: 0,
          inventoryErr: false,
        };
      });
    }

    return { updatedProducts, isError };
  } else {
    return { updatedProducts: cartDetails?.productList, isError: false };
  }
};

// Catalog partner inventory validation in discount delivery page
export const validateCatalogPartnetInventory = (cartDetails, commerceInfo)=>{
  const catalogPartnerInfo = commerceInfo?.info?.catalogPartnerInfo?.[0];
  const catalogPartner = catalogPartnerInfo?.catalogPartner?.toLowerCase() || ''

  switch (catalogPartner) {
    case 'unicommerce':
      return validateUnicomInventory(cartDetails, commerceInfo)
     

    case 'buymore':
      return validateBuyMoreInventory(cartDetails, commerceInfo)
      
    default:
      return true;
  }
}


export const checkCatalogPartnetInventory =(inventoryInfo, product) =>{
  const catalogPartner = product?.catalogPartnerItemInfo?.[0]?.catalogPartner || ''

  switch (catalogPartner) {
    case 'unicommerce':
      return checkUnicomInventory(inventoryInfo, product)
     

    case 'buymore':
      return checkBuymoreInventory(inventoryInfo, product)
      
    default:
      return true;
  }
}