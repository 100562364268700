import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { Box, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  arrowBgColor,
  blackColor,
  breadcrumbBgColor,
  catalogSubHeaderColor,
  headingColor,
  quantityIconBgColor,
  quatityIconColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

function CartQuantityPicker({
  count,
  onIncrement,
  onDecrement,
  minQty,
  maxQty,
  removeEnabled,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return matches ? (
    <Box sx={styles.quantityPicker}>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Box
            sx={[
              styles.qtBtn,
              {
                backgroundColor: count <= minQty ? blackColor : blackColor,
              },
            ]}
            borderRadius="7px 0 0 7px"
            onClick={onDecrement}
          >
            <RemoveRounded
              sx={{
                fontSize: 16,
                color:
                  count <= minQty
                    ? removeEnabled
                      ? arrowBgColor
                      : quatityIconColor
                    : whiteColor,
              }}
              //  sx={{ color: quatityIconColor }}
            />
          </Box>
        </Grid>

        <Grid item>
          <Typography
            variant="body1"
            color={whiteColor}
            fontSize="12px"
            width="30px"
            fontWeight={600}
            textAlign="center"
          >
            {count}
          </Typography>
        </Grid>

        <Grid item>
          <Box
            sx={[
              styles.qtBtn,
              {
                backgroundColor: count >= maxQty ? blackColor : blackColor,
              },
            ]}
            borderRadius="0 7px 7px 0"
            onClick={onIncrement}
          >
            <AddRounded
              sx={{
                fontSize: 16,
                color: count >= maxQty ? quatityIconColor : whiteColor,
                // color: count >= maxQty ? arrowBgColor : whiteColor,
              }}
              // sx={{ color: quatityIconColor }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={0.6}
      >
        <Typography
          variant="body2"
          fontSize="10px"
          fontWeight={600}
          color={catalogSubHeaderColor}
          display="inline"
        >
          Min {minQty}
        </Typography>

        <Typography
          variant="body2"
          fontSize="10px"
          fontWeight={600}
          color={catalogSubHeaderColor}
          display="inline"
        >
          Max {maxQty}
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box sx={styles.quantityPicker}>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Box
            sx={[
              styles.qtBtn,
              {
                backgroundColor: count <= minQty ? blackColor : blackColor,
              },
            ]}
            borderRadius="7px 0 0 7px"
            onClick={onDecrement}
          >
            <RemoveRounded
              sx={{
                fontSize: 16,
                color: count >= maxQty ? quatityIconColor : whiteColor,
                color:
                  count > maxQty
                    ? quatityIconColor
                    : count <= minQty
                    ? removeEnabled
                      ? arrowBgColor
                      : quatityIconColor
                    : whiteColor,
              }}
              //  sx={{ color: quatityIconColor }}
            />
          </Box>
        </Grid>

        <Grid item>
          <Typography
            variant="body1"
            color={whiteColor}
            fontSize="12px"
            width="30px"
            fontWeight={600}
            textAlign="center"
          >
            {count}
          </Typography>
        </Grid>

        <Grid item>
          <Box
            sx={[
              styles.qtBtn,
              {
                backgroundColor: count >= maxQty ? blackColor : blackColor,
              },
            ]}
            borderRadius="0 7px 7px 0"
            onClick={onIncrement}
          >
            <AddRounded
              sx={{
                fontSize: 16,
                color: count >= maxQty ? quatityIconColor : whiteColor,
                // color: count >= maxQty ? arrowBgColor : whiteColor,
              }}
              // sx={{ color: quatityIconColor }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={0.6}
      >
        <Typography
          variant="body2"
          fontSize="10px"
          fontWeight={600}
          color={catalogSubHeaderColor}
          display="inline"
        >
          Min {minQty}
        </Typography>

        <Typography
          variant="body2"
          fontSize="10px"
          fontWeight={600}
          color={catalogSubHeaderColor}
          display="inline"
        >
          Max {maxQty}
        </Typography>
      </Box>
    </Box>
    // <Box
    //   sx={{
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "space-between",
    //     padding: "6px",
    //     textAlign: "center",
    //     backgroundColor: whiteColor,
    //     borderRadius: "100px",
    //     width: "105px",
    //     border: "1px solid rgb(235, 235, 243)",
    //   }}
    // >
    //   <Box sx={styles.iconBtn} onClick={onDecrement}>
    //     <RemoveRounded
    //       sx={{
    //         color: blackColor,
    //         fontSize: "20px",
    //       }}
    //     />
    //   </Box>
    //   <Box
    //     sx={{
    //       backgroundColor: whiteColor,
    //       padding: "0",
    //       color: blackColor,
    //       border: "none",
    //       fontSize: "14px",
    //       fontWeight: 500,
    //       outline: "none",
    //       width: "45px",
    //       textAlign: "center",
    //     }}
    //   >
    //     {count}
    //   </Box>
    //   <Box
    //     sx={{
    //       ...styles.iconBtn,
    //       backgroundColor: blackColor,
    //     }}
    //     onClick={onIncrement}
    //   >
    //     <AddRounded
    //       sx={{
    //         fontSize: "20px",
    //         color:
    //           count >= maxQty
    //             ? arrowBgColor
    //             : { md: quatityIconColor, xs: whiteColor },
    //       }}
    //     />
    //   </Box>
    // </Box>
  );
}

export default CartQuantityPicker;

const styles = {
  quantityPicker: {
    height: { xs: "32px", md: "37px" },
    display: "inline-block",
    border: "1px solid #ededed",
    borderRadius: "10px",
    backgroundColor: blackColor,
  },

  qtBtn: {
    width: { xs: "30px", md: "36px" },
    height: { xs: "30px", md: "36px" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    // backgroundColor: quantityIconBgColor,
    backgroundColor: blackColor,
  },

  iconBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#e3e3ef",
    fontSize: "18px",
    color: "#000000",
    position: "relative",
    borderRadius: "15px",
    width: "24px",
    height: "24px",
    lineHeight: "24px",
  },
};
