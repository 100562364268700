import { useEffect, useState } from "react";
import { getGraphData, summary } from "../utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import {
  getOwnReviewDetails,
  getReviewSummaryDetails,
} from "../redux/reviews/reviewsSlice";
import ReviewPageNew from "./Review/ReviewPageNew";
import FooterMui from "../components/FooterMui";
import TopComponent from "./TopComponent";
import {
  Box,
  Container,
  Skeleton,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import { getUserName, removeSessionInfo } from "utils/authHelpers";
import Loader from "components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import { getCouponsData } from "services/api_calls/dealsCoupons_api/DealCouponsApi";
import { getCoupons } from "redux/dealsCoupons/dealsCouponsSlice";
import {
  getCategoryDataFinder,
  getDataForSideBar,
} from "redux/subCatergory/subCategorySlice";
import { getCommercialDetails } from "redux/productDescription/ProductDescriptionSlice";
import { getCartDetails } from "redux/addToCart/AddToCartSlice";
import ReviewMobileCard from "./Overview/ReviewMobileCard";
import {
  backgroundColor01,
  blackColor,
  greyColor40,
  tabTextOrangeColor,
  whiteColor,
} from "configs/styles/muiThemes";
import BreadcrumbsSection from "components/Breadcrumbs";
import { Block } from "@mui/icons-material";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import ReviewContentMobileLoader from "components/skeletonLoaders/overview/ReviewContentMobileLoader";
import { getActiveBusiness } from "redux/header/headerSlice";
import { navigateTo, resolveDomainUrl } from 'utils/urlHelper'

function Review({isDomainUrl}) {
  const { businessName } = useParams()
  const [graphData, setGraphData] = useState([])
  const [textData, SetTextData] = useState('Most Recent')
  const [showLogin, setShowLogin] = useState(false)

  const dispatch = useDispatch();
  const {
    reviewSummary,
    allReviews,
    ownReview,
    connectionCount,
    reviewCount,
    profileImages,
    loading,
  } = useSelector((state) => state.review);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { loginDetails } = useSelector((state) => state.auth);

  const { businessId } = useSelector((state) => state.rootSlice);
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );
  const { commercialDetails } = useSelector((state) => state.product);
  const { cartDetails } = useSelector((state) => state.cart);
  const { allProducts, productsByCategory } = useSelector(
    (state) => state.subCategory
  );
  const { businessDetails } = useSelector((state) => state.header);

  const businessIdData = sessionStorage.getItem("businessId");

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      console.log("BUSINESS_NAME_CHANGED");
      removeSessionInfo();
      // sessionStorage.setItem('cartData', 'null')
    }

    dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  const [tabValue, setTabValue] = useState(2);

  const navigate = useNavigate();

  const stepList = [
    { id: 0, label: "About Us", mobileLabel: "About Us" },
    { id: 1, label: "Business Info", mobileLabel: "Business Info" },
    {
      id: 2,
      label: "Review",
      mobileLabel: "Review",
    },
  ];

  const handleChange = (newValue) => {
    // dispatch(clearCatalogData());
    const businessUrl = sessionStorage.getItem("costboWEBURL");

    if (newValue === 0) {
      // navigate(`/${businessName}/Overview`)
      navigateTo(navigate, 'Overview')
      return
    }
    // else if (newValue === 1) {
    //   navigate(`/${data?.costboWebURL}/BusinessInfo`)
    // }
    if (newValue === 1) {
      // navigate(`/${businessName}/Overview`)
      navigateTo(navigate, 'Overview')
      return
    }

    setTabValue(newValue);
  };

  useEffect(() => {
    if (isDomainUrl) {
      resolveDomainUrl(dispatch);
      return;
    }
    getBusinessData()
  }, [businessName])

  useEffect(() => {
    if (businessId !== null) {
      console.log("REVIEW___HITTY", {businessId});
      
      dispatch(getActiveBusiness());
      dispatch(getDataForSideBar());
      dispatch(getCategoryDataFinder());
      dispatch(getReviewSummaryDetails());
      if (!commercialDetails) {
        dispatch(getCommercialDetails());
      }
      if (isLoggedIn) {
        if (!cartDetails) {
          dispatch(getCartDetails(businessId));
        }
      }
    }

    return () => {
      // dispatch(clearSearchData())
    };
  }, [businessId]);

  // useEffect(() => {
  //   if (businessId?.validate) {
  //     console.log("Valid_BusinessID");
  //     dispatch(getCoupons());
  //   }
  // }, [businessId]);

  // useEffect(() => {
  //   dispatch(getReviewSummaryDetails())
  //   // dispatch(getAllReviewDetails({date:true, isHelpful:false}));

  //   if (isLoggedIn) {
  //     dispatch(getOwnReviewDetails())
  //   }
  // }, [])

  useEffect(() => {

    if (loginDetails || isLoggedIn) {
      dispatch(getOwnReviewDetails());
      setIsLoggedIn(true);
    }
  }, [loginDetails]);

  useEffect(() => {
    setGraphData(getGraphData(reviewSummary?.summary || [], summary));
  }, [reviewSummary]);

  return (
    <Box backgroundColor={backgroundColor01}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          width: "100%",
          zIndex: 100,
          backgroundColor: { xs: whiteColor, md: backgroundColor01 },
        }}
      >
        <Box minHeight={{ xs: "auto", md: "126.7px" }} bgcolor={whiteColor}>
          {businessIdData !== null &&
          commercialDetails !== null 
          // &&
          // productsByCategory?.length > 0 &&
          // allProducts !== null 
          ? (
            <TopComponent
              value={matches ? "reviews" : "overview"}
              showLoginForm={showLogin}
              bannerHide
              disableBanners={matches}
            />
          ) : matches ? (
            <>
              <HeaderLoader hideBanners={!matches} />
            </>
          ) : (
            !businessDetails && (
              <>
                <HeaderLoader hideBanners={!matches} />
                <ReviewContentMobileLoader />
              </>
            )
          )}
        </Box>

        <Container
          sx={{
            display: { md: "block", xs: "none" },
            maxWidth: "1375px !important",
            mt: { xs: 1.8, md: 2.5 },
          }}
          backgroundColor={whiteColor}
        >
          <BreadcrumbsSection
            links={[
              // { label: 'Home', to: `/` },
              { label: "Reviews", to: `/reviews` },
            ]}
          />
        </Container>
      </Box>
      {matches ? (
        <ReviewPageNew
          reviewSummary={reviewSummary}
          ownReview={ownReview}
          graphData={graphData}
          getOwnReviewDetails={() => dispatch(getOwnReviewDetails())}
          getSummary={() => dispatch(getReviewSummaryDetails())}
          data={allReviews}
          reviewCount={reviewCount}
          connectionCount={connectionCount}
          profileImages={profileImages}
          getAllReviewDetails={(option, pageNo) => {
            SetTextData(option);
            // dispatch(getAllReviewDetails({sort:sort,
            //   order:order, pageNo:pageNo}))
            // dispatch(getAllReviewDetails({ option: option, pageNo: pageNo }))
          }}
          optionData={textData}
          isLoggedIn={isLoggedIn}
          setShowLogin={() => setShowLogin(!showLogin)}
        />
      ) : (
        businessDetails && (
          <Box sx={{ maxWidth: "1300px !important" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: backgroundColor01,
                px: "20px",
                mt: 1,
              }}
            >
              <Box
                sx={styles.tabStyle(tabValue == 0)}
                onClick={() => {
                  handleChange(0);
                }}
              >
                About Us
              </Box>
              <Box
                sx={styles.tabStyle(tabValue == 1)}
                onClick={() => {
                  handleChange(1);
                }}
              >
                Business Info
              </Box>
              <Box
                sx={styles.tabStyle(tabValue == 2)}
                onClick={() => {
                  handleChange(2);
                }}
              >
                Review
              </Box>
            </Box>

            <ReviewMobileCard
              reviewSummary={reviewSummary}
              ownReview={ownReview}
              graphData={graphData}
              getOwnReviewDetails={() => dispatch(getOwnReviewDetails())}
              getSummary={() => dispatch(getReviewSummaryDetails())}
              data={allReviews}
              reviewCount={reviewCount}
              connectionCount={connectionCount}
              profileImages={profileImages}
              getAllReviewDetails={(option, pageNo) => {
                SetTextData(option);
                // dispatch(getAllReviewDetails({sort:sort,
                //   order:order, pageNo:pageNo}))
                // dispatch(getAllReviewDetails({ option: option, pageNo: pageNo }))
              }}
              optionData={textData}
              isLoggedIn={isLoggedIn}
              setShowLogin={() => setShowLogin(!showLogin)}
            />
          </Box>
        )
      )}
      {matches && <FooterMui />}
    </Box>
  );
}
export default Review;

const styles = {
  tabStyle: (isActive) => ({
    fontSize: 14,
    fontWeight: 500,
    color: blackColor,
    p: "4px 10px",
    borderBottom: isActive ? `2.5px solid ${blackColor}` : "none",
    textAlign: "center",
  }),
};
