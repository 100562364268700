import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserName } from "utils/authHelpers";
// import { toggleLoginModal } from '../redux/auth/authSlice'
import HeroSection from "./ShareAndEarn/HeroSection";
import HowItWorks from "./ShareAndEarn/HowItWorks";
import ShareEarnHeader from "./ShareAndEarn/ShareEarnHeader";
import WhyChooseCostBo from "./ShareAndEarn/WhyChooseCostBo";
import TopComponent from "./TopComponent";

import { getCartDetails } from "redux/addToCart/AddToCartSlice";
import { getCommercialDetails } from "redux/productDescription/ProductDescriptionSlice";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import {
  getRefRatesByBusinessID,
  getResellerProfileData,
} from "redux/affiliate/AffiliateSlice";
import { getAffiliateValidityApi } from "services/api_calls/affiliate/AffiliateApi";
import { backgroundColor01, whiteColor } from "configs/styles/muiThemes";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import { navigateTo, resolveDomainUrl } from "utils/urlHelper";

function ShareAndEarn({isDomainUrl}) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { businessName } = useParams();

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const businessIdData = sessionStorage.getItem("businessId");

  const { businessId } = useSelector((state) => state.rootSlice);
  const { commercialDetails } = useSelector((state) => state.product);
  const { cartDetails } = useSelector((state) => state.cart);
  const { isUserLogin, loginDetails, isLoggedIn } = useSelector(
    (state) => state.auth
  );
  // const [isLoggedIn, setIsLoggedIn] = useState(
  //   getUserName().length === 0 ? false : true
  // )

  const [autoNavEnabled, setAutoNavEnabled] = useState(false);

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      sessionStorage.setItem("cartData", "null");
    }
    dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  // useEffect(() => {
  //   getBusinessData();
  // }, [businessName]);

  useEffect(() => {
    if (isDomainUrl) {
      resolveDomainUrl(dispatch);
      return;
    }
    getBusinessData();
  }, [businessName]);

  useEffect(() => {
    if (businessIdData !== null) {
      if (!commercialDetails) {
        dispatch(getCommercialDetails());
      }
      if (isLoggedIn) {
        if (!cartDetails) {
          dispatch(getCartDetails(businessId));
        }
      }
    }

    return () => {};
  }, [businessId]);

  const { rellerProfileData, businessAffiliateData, affiliateValidity } =
    useSelector((state) => state.affiliate);
  const [showLogin, setShowLogin] = useState(false);

  const [commissionPercentage, setCommissionPercentage] = useState(0);

  const getAffiliatePercentage = async () => {
    const affiliateCommissionPer = affiliateValidity?.commissionPercentage || 0;
    const businessCommissionPer =
      businessAffiliateData?.businessResellers?.[0]?.affiliatePercentage || 0;

    if (affiliateCommissionPer > 0) {
      setCommissionPercentage(affiliateCommissionPer);
    } else {
      setCommissionPercentage(businessCommissionPer);
    }

    console.log(
      "getAffiliatePercentage_affiliateCommissionPer",
      affiliateCommissionPer
    );
    console.log(
      "getAffiliatePercentage_businessCommissionPer",
      businessCommissionPer
    );

    console.log("getAffiliatePercentage_VALID", affiliateValidity);
    console.log("getAffiliatePercentage_B_PERCENTAGE", businessAffiliateData);
  };

  useEffect(() => {
    getAffiliatePercentage();
  }, [businessAffiliateData, affiliateValidity]);

  const handleGetStarted = () => {
    if (isLoggedIn) {
      if (rellerProfileData?.details) {
        // navigate(`/${businessName}/affiliateHome`)
        navigateTo(navigate, "affiliateHome");
      } else {
        // navigate(`/${businessName}/affiliateEnroll`)
        navigateTo(navigate, "affiliateEnroll");
      }
    } else {
      setShowLogin(!showLogin);
    }
  };

  useEffect(() => {
    if (state?.disableAutoNav && !autoNavEnabled) return;

    if (isLoggedIn) {
      if (rellerProfileData) {
        if (rellerProfileData?.details) {
          // navigate(`/${businessName}/affiliateHome`)
          navigateTo(navigate, "affiliateHome");
        } else {
          // navigate(`/${businessName}/affiliateEnroll`)
          navigateTo(navigate, "affiliateEnroll");
        }
      }
    }

    return () => {};
  }, [isLoggedIn, rellerProfileData]);

  useEffect(() => {
    if (showLogin) {
      setAutoNavEnabled(true);
    } else {
      setAutoNavEnabled(false);
    }
    return () => {
      setAutoNavEnabled(false);
    };
  }, [showLogin]);

  return (
    <Box backgroundColor={whiteColor}>
      <Box minHeight={{ xs: "auto", md: "126.7px" }} bgcolor={whiteColor}>
        {businessId !== null && commercialDetails ? (
          <TopComponent
            value="affiliate"
            disableBanners
            showLoginForm={showLogin}
          />
        ) : (
          <HeaderLoader hideBanners />
        )}
      </Box>

      <Box
        sx={{
          ...styles.wrapper,
          mt: { xs: commercialDetails ? "90px" : 0, md: "40px" },
        }}
      >
        <Box sx={styles.container}>
          <ShareEarnHeader
            title="Brand Affiliate - Share & Earn"
            hideBackBtn
            handleBackClick={() => {}}
            handleSettingClick={() => {}}
            handleSearchClick={() => {}}
          />

          <HeroSection
            handleGetStarted={handleGetStarted}
            rellerProfileData={rellerProfileData}
            businessAffiliateData={businessAffiliateData}
            affiCommissionPercentage={commissionPercentage}
          />
          <WhyChooseCostBo
            businessAffiliateData={businessAffiliateData}
            affiCommissionPercentage={commissionPercentage}
          />
          <HowItWorks />
        </Box>
      </Box>
    </Box>
  );
}

export default ShareAndEarn;

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: { xs: "90px", md: "40px" },
  },

  container: {
    width: "400px",
    // padding: {xs:'50px 0 100px', md:'50px 0 0'},
    paddingBottom: { xs: "70px", md: "0" },
    overflow: "hidden",
    position: "relative",
    boxShadow: "0 0px 2px 0 rgb(148 150 159 / 24%)",
  },
};
